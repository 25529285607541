<template>
    <div
        id="merge-client"
        class="d-ps-absolute d-r0 d-t0 d-h100p d-zi-drawer d-of-hidden d-bl d-blw1 d-bc-subtle"
        v-if="open"
    >
        <iframe-loader
            v-if="koopidBot"
            @message="handleIframePostMessage"
            :src="koopidBot.shortLink"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent, type PropType, inject } from 'vue';
import type { KoopidBot } from '@/utils/koopid';
import IframeLoader from '@/components/iframe-loader/IframeLoader.vue';

export default defineComponent({
    components: {
        IframeLoader
    },
    props: {
        open: {
            type: Boolean as PropType<boolean>,
            required: true
        },
        koopidBot: {
            type: Object as PropType<KoopidBot>
        }
    },
    setup() {
        const orgId: string = inject('orgId')!;

        return {
            orgId
        };
    },
    methods: {
        handleIframePostMessage(e: MessageEvent) {
            // If we receive a close event from the Merge client
            if (e.data.type === 'chat-closed') {
                this.$emit('close');
            }
        }
    }
});
</script>
