export const clickOutsideDirective = {
    beforeMount: (element: any, binding: any) => {
        element.clickOutsideEvent = (event: Event) => {
            // here I check that click was outside the el and his children
            if (!(element == event.target || element.contains(event.target))) {
                // and if it did, call method provided in attribute value
                binding.value();
            }
        };
        const dxConsole =
            document.getElementsByTagName('dx-console')?.[0]?.shadowRoot;

        if (dxConsole) {
            dxConsole.addEventListener('click', element.clickOutsideEvent);
        }
    },
    unmounted: (element: any) => {
        const dxConsole =
            document.getElementsByTagName('dx-console')?.[0]?.shadowRoot;
        if (dxConsole) {
            dxConsole.removeEventListener('click', element.clickOutsideEvent);
        }
    }
};
