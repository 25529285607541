<template>
    <dt-card
        class="d-w100p"
        header-class="d-px16 d-py4 d-bgc-secondary d-bb d-bc-subtle d-btr4"
        content-class="d-px16 d-py8"
        :container-class="{
            'd-bs-sm': editable,
            'd-bs-none h:d-bs-sm': !editable
        }"
    >
        <template #header v-if="editable">
            <span
                class="d-fs-100 d-fw-bold d-h16 d-py4 d-my2 d-d-flex d-ai-center"
            >
                {{ cardTitle }}
            </span>
        </template>
        <template #content>
            <div v-if="editable">
                <div class="d-mb12">
                    <dt-input
                        size="xs"
                        :label="$t('Description')"
                        v-model="text"
                    />
                </div>
                <div class="d-mb12">
                    <dt-input
                        size="xs"
                        :label="`${$t('Button text')} (${$t('Required')})`"
                        v-model="callToAction"
                    />
                </div>
                <dt-input
                    size="xs"
                    :label="`${$t('Target URL')} (${$t('Required')})`"
                    v-model="url"
                    :messages="targetUrlError"
                    @input="validateURL"
                />
            </div>
            <div v-else class="d-d-flex d-fw-wrap d-gg8">
                <p class="d-w100p" v-if="text?.length">
                    {{ text }}
                </p>
                <dt-button size="sm">{{ callToAction }}</dt-button>
            </div>
        </template>
        <template #footer v-if="editable">
            <div class="d-d-flex d-jc-flex-end d-w100p d-mt8 d-gg8">
                <dt-button importance="clear" @click="handleCancel" size="xs">
                    {{ $t('Cancel') }}
                </dt-button>
                <dt-button
                    @click="handleSave"
                    size="xs"
                    :disabled="!shouldSave"
                >
                    {{ $t('Add') }}
                </dt-button>
            </div>
        </template>
    </dt-card>
</template>
<script lang="ts">
import { defineComponent, inject, type PropType } from 'vue';
import type { Block } from '@/components/node-editor/responseBlockEditor';
import {
    DtButton,
    DtCard,
    DtInput,
    VALIDATION_MESSAGE_TYPES
} from '@dialpad/dialtone/vue3';
import type { WebExtensionDetail } from '@/open-api';
import type { ApiService } from '@/services/Api.service';
import type { ResponseBlockEditor } from '@/components/node-editor/responseBlockEditor';
import { URL_REGEX } from '@/utils/Constants';

export default defineComponent({
    props: {
        block: {
            type: Object as PropType<Block>,
            required: true
        },
        editable: {
            type: Boolean as PropType<boolean>,
            required: true
        }
    },
    setup() {
        const orgId: string = inject('orgId')!;
        return {
            orgId
        };
    },
    components: {
        DtInput,
        DtButton,
        DtCard
    },
    computed: {
        /* v8 ignore next 40 */
        isEditable: {
            get(): boolean {
                return this.editable;
            },
            set(editable: boolean) {
                this.$emit('edit', editable);
            }
        },
        authToken(): string {
            return this.$store.getters[`${this.orgId}/authToken`];
        },
        apiService(): ApiService {
            return this.$store.getters[`${this.orgId}/apiService`];
        },
        responseBlockEditor(): ResponseBlockEditor | undefined {
            return this.$store.getters[`${this.orgId}/responseBlockEditor`];
        },
        shouldSave(): boolean {
            return !!this.url?.length && !!this.callToAction?.length;
        },
        cardTitle(): string {
            return this.block.new ? this.$t('Add CTA') : this.$t('Edit CTA');
        }
    },
    methods: {
        resetData() {
            this.url = this.block?.data?.call_to_action_block?.url;
            this.callToAction =
                this.block?.data?.call_to_action_block?.call_to_action;
            this.text = this.block?.data?.call_to_action_block?.text;
        },
        handleRemove() {
            this.responseBlockEditor?.removeBlock(this.block);
        },
        handleCancel(e: Event) {
            e.stopPropagation();
            if (!(this.url && this.callToAction)) {
                this.handleRemove();
            } else {
                this.resetData();
                this.handleSave();
            }
        },
        handleSave(e?: Event) {
            e?.stopPropagation();
            this.block?.setBlockData({
                url: this.url,
                call_to_action: this.callToAction,
                text: this.text
            });
            this.isEditable = false;
        },
        handleUrlChange(newUrl: any) {
            this.url = newUrl;
        },
        validateURL(url: any) {
            if (!url?.length) {
                this.targetUrlError = [
                    {
                        message: this.$t('This is required'),
                        type: VALIDATION_MESSAGE_TYPES.ERROR
                    }
                ];

                return;
            }

            if (!URL_REGEX.test(url)) {
                this.targetUrlError = [
                    {
                        message: this.$t('Invalid URL'),
                        type: VALIDATION_MESSAGE_TYPES.ERROR
                    }
                ];
            } else {
                this.targetUrlError = [];
            }
        }
    },
    data() {
        return {
            url: this.block?.data?.call_to_action_block?.url,
            callToAction:
                this.block?.data?.call_to_action_block?.call_to_action,
            text: this.block?.data?.call_to_action_block?.text,
            webextensions: [] as WebExtensionDetail[],
            webextensionsError: false,
            isFetching: false,
            targetUrlError: [] as any[]
        };
    }
});
</script>
