import en_US from './en-US.json';
import pt_PT from './pt-PT.json';
import es_ES from './es-ES.json';
import fr_FR from './fr-FR.json';
import it_IT from './it-IT.json';
import de_DE from './de-DE.json';
import zh_CN from './zh-CN.json';
import ja_JP from './ja-JP.json';
import nl_NL from './nl-NL.json';
import ru_RU from './ru-RU.json';

export default {
    en_US,
    pt_PT,
    es_ES,
    fr_FR,
    it_IT,
    de_DE,
    zh_CN,
    ja_JP,
    nl_NL,
    ru_RU
};
