export enum IMPROVE_STATES {
    EXPAND = 'Expand knowledge',
    MUTED = 'Muted queries',
    CONSOLIDATE = 'Consolidate'
}

export interface Improve {
    id: string;
    approved?: boolean;
    locale?: string;
    query_count?: number;
}

export interface MutedQuery {
    created_at: number;
    id: string;
    query: string;
}

export const CONSOLIDATE_OPTIONS = [
    {
        value: 'broken',
        label: 'Broken content'
    },
    {
        value: 'unbalanced-under',
        label: 'Not enough queries'
    },
    {
        value: 'unbalanced-over',
        label: 'Too many queries'
    }
];
