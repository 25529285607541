<template>
    <div class="d-p24">
        <h1 class="d-gc12 d-fc-black-800 d-fw-bold d-mb24">
            {{ $t('Expand') }}
        </h1>
        <dt-tab-group size="sm" selected="expand-knowledge-panel">
            <template #tabs>
                <dt-tab id="expand-knowledge" panel-id="expand-knowledge-panel">
                    {{ $t('Expand Knowledge') }}
                </dt-tab>
                <dt-tab
                    id="missing-knowledge"
                    panel-id="missing-knowledge-panel"
                >
                    {{ $t('Missing Knowledge') }}
                </dt-tab>
                <dt-tab id="muted-queries" panel-id="muted-queries-panel">
                    {{ $t('Muted queries') }}
                </dt-tab>
            </template>

            <dt-tab-panel
                id="expand-knowledge-panel"
                tab-id="expand-knowledge"
                class="d-mt16"
            >
                <p class="d-fc-black-800">{{ $t('Show only') }}</p>
            </dt-tab-panel>
            <dt-tab-panel
                id="missing-knowledge-panel"
                tab-id="missing-knowledge"
                class="d-mt16"
            >
                <p class="d-fc-black-800">Missing answer for:</p>
            </dt-tab-panel>
            <dt-tab-panel
                id="muted-queries-panel"
                tab-id="muted-queries"
                class="d-mt16"
            >
                <p class="d-fc-black-800">Missing answer for:</p>
            </dt-tab-panel>
        </dt-tab-group>
    </div>
</template>

<script lang="ts">
import { DtTabGroup, DtTab, DtTabPanel } from '@dialpad/dialtone/vue3';
import { defineComponent } from 'vue';

export default defineComponent({
    components: { DtTabGroup, DtTab, DtTabPanel }
});
</script>
