<template>
    <drawer-template
        :content-class="['d-p0']"
        :drawer-content-height="maxContentHeight"
        class="d-btw0"
        @drawer-template-close="close"
        :hide-close="hideClose"
    >
        <template #header>
            <div class="d-w100p d-box-border">
                <div class="d-truncate d-headline-medium">
                    <slot name="headerTitle">
                        {{ chatbotName }}
                    </slot>
                </div>
            </div>
        </template>
        <template #default>
            <dt-notice
                v-if="hasValidationError"
                :title="$t('Can\'t preview unpublished response')"
                hide-close
                kind="error"
                class="d-bar0 d-py8"
            >
                {{ $t('Please pick another response or restore the response') }}
            </dt-notice>
            <div class="d-h100p">
                <div v-if="shouldShowAiAssist">
                    <agent-assist-widget
                        :key="appId"
                        :app-id="appId"
                        top-bar-hidden="true"
                        auto-open="true"
                        :height="widgetHeight"
                        :read-only="readOnly"
                        :stateless="stateless"
                        :dark-mode="darkMode"
                        :zone="zone"
                        :width="width"
                    />
                </div>
                <div
                    class="d-d-flex d-jc-center d-ai-center d-w100p d-box-border"
                    style="height: calc(100vh - 64px)"
                    v-else
                >
                    <dt-button kind="inverted" loading />
                </div>
            </div>
        </template>
    </drawer-template>
</template>

<script lang="ts">
import { defineComponent, type PropType, inject } from 'vue';
import {
    DtButton,
    DtInput,
    DtLink,
    DtListItem,
    DtNotice,
    DtRecipeComboboxMultiSelect,
    DtSelectMenu
} from '@dialpad/dialtone/vue3';
import DropFile from '@/components/upload-drawer/DropFile.vue';
import DrawerTemplate from '@/components/drawer-template/DrawerTemplate.vue';
import BaseCombobox from '@/components/base-combobox/BaseCombobox.vue';
import WebComponentLoader from '@/components/webcomponent-loader/WebComponentLoader.vue';
import type { DrawerService } from '@/services/Drawer.service';
import { DRAWER_HEADER_HEIGHT, ZONES_ABBR } from '@/utils/Constants';

export default defineComponent({
    props: {
        appId: {
            type: String as PropType<string>
        },
        appName: {
            type: String as PropType<string>,
            required: true
        },
        aiAssistLoading: {
            required: true
        },
        readOnly: {
            type: Boolean as PropType<boolean>,
            default: () => false
        },
        stateless: {
            type: Boolean as PropType<boolean>,
            default: () => false
        },
        width: {
            type: Number,
            default: 360
        },
        hideClose: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        hasValidationError: {
            type: Boolean as PropType<boolean>
        }
    },
    setup() {
        const orgId: string = inject('orgId')!;

        return {
            orgId
        };
    },
    components: {
        BaseCombobox,
        DtButton,
        DtInput,
        DtNotice,
        DtLink,
        DtSelectMenu,
        DtListItem,
        DropFile,
        DrawerTemplate,
        DtRecipeComboboxMultiSelect,
        WebComponentLoader
    },
    computed: {
        // This is done so that the function coverage isn't affected by computed props
        /* v8 ignore next 33 */
        shouldShowAiAssist(): boolean {
            return !this.aiAssistLoading && !!this.maxContentHeight;
        },
        drawerService(): DrawerService | undefined {
            return this.$store.getters[`${this.orgId}/drawerService`];
        },
        darkMode(): boolean {
            return this.$store.getters[`${this.orgId}/darkMode`];
        },
        maxContentHeight(): number {
            return this.$store.getters[`${this.orgId}/maxContentHeight`];
        },
        widgetHeight(): number {
            return this.maxContentHeight - DRAWER_HEADER_HEIGHT;
        },
        zone(): ZONES_ABBR {
            return this.$store.getters[`${this.orgId}/zone`];
        },
        chatbotName(): string {
            return this.appName.length ? this.appName : 'Chatbot';
        }
    },
    methods: {
        close() {
            this.drawerService?.closeDrawer();
            this.$emit('close');
        }
    },
    emits: ['close'],
    data() {
        return {
            drawerContentHeight: 0
        };
    }
});
</script>
