/* tslint:disable */
/* eslint-disable */
/**
 * Mind
 * Kare API v4.0
 *
 * The version of the OpenAPI document: 4.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Defines the aspect (i.e. the purpose) of an `out/text` message. Clients should use the aspect to decide how to render the message. 
 * @export
 * @enum {string}
 */

export const OutTextAspect = {
    ResolutionStuckInALoop: 'resolution/stuck-in-a-loop',
    ResolutionMissing: 'resolution/missing',
    ResolutionAnswer: 'resolution/answer',
    ResolutionAnswerCallToAction: 'resolution/answer/call-to-action',
    ResolutionAnswerText: 'resolution/answer/text',
    ResolutionAnswerQuote: 'resolution/answer/quote',
    ResolutionAnswerChoice: 'resolution/answer/choice',
    ResolutionAnswerImage: 'resolution/answer/image',
    ResolutionAnswerVideo: 'resolution/answer/video',
    ResolutionAnswerAttachments: 'resolution/answer/attachments',
    ResolutionAnswerWebext: 'resolution/answer/webext',
    ResolutionAnswerWorkflow: 'resolution/answer/workflow',
    ResolutionAnswerHandover: 'resolution/answer/handover',
    ResolutionAnswerFeedback: 'resolution/answer/feedback',
    ResolutionDisambiguation: 'resolution/disambiguation',
    TextPlain: 'text/plain',
    LanguageUnsupported: 'language/unsupported',
    LanguageUnknown: 'language/unknown'
} as const;

export type OutTextAspect = typeof OutTextAspect[keyof typeof OutTextAspect];



