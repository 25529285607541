<template>
    <div class="d-w100p d-d-flex d-fd-column d-ai-center">
        <div
            class="d-zi-modal-element notification-wrapper d-ps-absolute d-d-flex d-fd-column d-t0 d-mt8 d-gg8"
        >
            <notification
                v-for="(notification, notificationIndex) in notifications"
                :key="`notification-${notificationIndex}`"
                :message="notification.message!"
                :kind="notification.kind"
                :title="notification.title"
                :action="notification.action"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import Notification from '@/components/notification-wrapper/Notification.vue';
import type { INotification } from '@/utils/types/Notification';

export default defineComponent({
    components: {
        Notification
    },
    setup() {
        const orgId: string = inject('orgId')!;

        return {
            orgId
        };
    },
    computed: {
        notifications(): INotification[] {
            return this.$store.getters[`${this.orgId}/notifications`];
        }
    }
});
</script>
