<template>
    <div class="d-d-flex d-fd-column" ref="improveWrapper">
        <default-template-view
            :title="$t('Optimize')"
            :title-description="
                $t(
                    'Address unanswered questions or actionable insights to improve your chatbot’s content and performance.'
                )
            "
            :table-data="tableData"
            :columns="columnsByState"
            :is-fetching="isFetching"
            :has-loaded="hasLoaded"
            :total-items="total"
            v-model:sort="sortBy"
            v-model:labels="tags"
            v-model:on-page-change="activePage"
            v-model:on-hover-change="hoveredRow"
            :has-error="hasError"
            hide-checkbox
            :items-per-page="itemsPerPage"
            :item-type="currentNodeType"
            hide-search
            @table-row-click="handleOpenResponse"
        >
            <template #actionButtons>
                <dt-button
                    v-feature="'node_editor'"
                    class="d-truncate"
                    size="xs"
                    @click="handleExport"
                    hidden
                >
                    {{ $t('Export') }}
                </dt-button>
            </template>
            <template #filters>
                <dt-tab-group size="sm" borderless id="optimize-tabs">
                    <template #tabs>
                        <dt-tab
                            v-for="optimizeTab in OPTIMIZE_TABS"
                            :id="optimizeTab.value"
                            :panel-id="`${optimizeTab.value}-panel`"
                            :key="optimizeTab"
                            @click="onTabClick(optimizeTab.value)"
                            :tab-class="{
                                'd-bar32': true,
                                'd-bgc-purple-100 d-fc-purple-400':
                                    tabSelected === optimizeTab.value
                            }"
                        >
                            {{
                                `${$t(optimizeTab.title)} ${this.tabSelected === optimizeTab.value && !isFetching ? totalNumber : ''}`
                            }}
                        </dt-tab>
                    </template>
                </dt-tab-group>
            </template>
        </default-template-view>
        <empty-state
            v-if="!tableData?.length && !isFetching && !hasError"
            :item-type="currentNodeType"
        />
        <confirmation-modal
            :title="
                $t(
                    isUnmuteConfirmationModal
                        ? 'Unmute question'
                        : 'Unsnooze question group'
                )
            "
            :description="confirmationModalDescription"
            kind="default"
            :button-title="
                $t(
                    isUnmuteConfirmationModal
                        ? 'Yes, unmute question'
                        : 'Yes, unsnooze question group'
                )
            "
            v-model:show-prompt="showPrompt"
            @confirm="handleConfirm"
            no-translation
        />
    </div>
</template>

<script lang="tsx">
import { defineComponent, h, Fragment, inject } from 'vue';
import type { AxiosPromise } from 'axios';

import type {
    BrokenNode,
    Knowledgebase,
    ListBrokenNodesResponse,
    ListMutedQueriesResponse,
    UnmuteQueriesParamsBody,
    ListClustersResponse,
    ClusterDetail,
    PatchClusterRequest,
    ListNodesResponse
} from '@/open-api';
import { NodeStatus, NodeType } from '@/open-api';

import {
    DtButton,
    DtTooltip,
    DtSelectMenu,
    DtChip,
    DtTabGroup,
    DtTab
} from '@dialpad/dialtone/vue3';

import { handleRequest, type IFilter } from '@/utils/Common';
import type { INotification } from '@/utils/types/Notification';
import {
    IMPROVE_STATES,
    type MutedQuery,
    CONSOLIDATE_OPTIONS
} from '@/utils/types/Improve';
import { NOTICE_KINDS, OPTIMIZE_TABS } from '@/utils/Constants';

import DefaultTemplateView from '@/views/DefaultTemplateView.vue';

import type { IBaseTableColumn } from '@/components/base-table/BaseTable.types';
import FilterPopover from '@/components/filter-popover/FilterPopover.vue';
import EmptyState from '@/components/empty-state/EmptyState.vue';
import SidebarToggle from '@/components/sidebar-drawer/SidebarToggle.vue';
import TemplateButton from '@/components/template-button/TemplateButton.vue';
import ConfirmationModal from '@/components/confirmation-modal/ConfirmationModal.vue';

import type { ApiService } from '@/services/Api.service';

export default defineComponent({
    components: {
        SidebarToggle,
        DefaultTemplateView,
        FilterPopover,
        EmptyState,
        ConfirmationModal,
        DtChip,
        DtSelectMenu,
        DtTooltip,
        DtButton,
        DtTabGroup,
        DtTab
    },
    setup() {
        const orgId: string = inject('orgId')!;

        return {
            orgId
        };
    },
    mounted() {
        const paramSubTab = this.$route?.query?.subTab;

        const currentTab = OPTIMIZE_TABS.find(
            (optimizeTab: any) => optimizeTab.value === paramSubTab
        )
            ? paramSubTab
            : OPTIMIZE_TABS[0].value;

        if (paramSubTab !== currentTab) {
            this.$router.push({
                query: { subTab: currentTab }
            });
        }
        this.fetchTabData(currentTab);
    },
    watch: {
        showPrompt(open: boolean) {
            if (!open) {
                this.selectedCluster = null;
                this.selectedQuery = null;
                this.confirmationModalType = '';
            }
        },
        sortBy: {
            handler(newSort) {
                if (newSort) {
                    this.fetchTabData(this.tabSelected);
                }
            }
        },
        $route(to, from) {
            if (!to.query?.subTab) {
                this.$router.push({
                    query: { subTab: this.tabSelected }
                });
                this.fetchTabData(this.tabSelected);
            } else if (
                from.query?.subTab &&
                from.query?.subTab !== to.query?.subTab
            ) {
                this.fetchTabData(to.query?.subTab);
            }
        }
    },
    computed: {
        authToken(): string {
            return this.$store.getters[`${this.orgId}/authToken`];
        },
        apiService(): ApiService {
            return this.$store.getters[`${this.orgId}/apiService`];
        },
        NodeType() {
            return NodeType;
        },
        knowledgebase(): Knowledgebase {
            return this.$store.getters[`${this.orgId}/currentKnowledgebase`];
        },
        improveWrapperWidth(): number {
            return (this.$refs.improveWrapper as HTMLElement).clientWidth - 24;
        },
        confirmationModalDescription(): string {
            return this.isUnmuteConfirmationModal
                ? `${this.$t('The question')} '${this.selectedQuery?.query}' ${this.$t('will be unmuted. This question can be listed in unanswered question the next time it comes up. Are you sure you want to continue?')}`
                : this.$t(
                      'This action will snooze all of the questions in the question group for 24 hours. You can always unsnooze question groups from the "Snoozed" page. Are you you want to snooze all the questions in this group?'
                  );
        },
        isUnmuteConfirmationModal(): boolean {
            return this.confirmationModalType === 'unmute';
        },
        columnsByState(): IBaseTableColumn<any>[] {
            switch (this.tabSelected) {
                case 'missing_knowledge':
                    return [
                        {
                            type: 'string',
                            title: 'Question group',
                            sort: 'title',
                            sortable: false,
                            template: (column) => {
                                return h(
                                    'div',
                                    {
                                        class: 'd-fc-black-900 d-truncate d-p16',
                                        style: `width: ${
                                            this.improveWrapperWidth / 2
                                        }px`
                                    },
                                    h(
                                        'p',
                                        { class: 'd-truncate' },
                                        column.title
                                    )
                                );
                            }
                        },
                        {
                            title: 'Volume',
                            type: 'string',
                            width: '13rem',
                            key: 'query_count',
                            sortable: false,
                            sort: 'volume',
                            tooltip: {
                                type: 'alert-circle',
                                message:
                                    'How many times this question group occurred'
                            }
                        }
                    ];
                case 'muted_questions':
                    return [
                        {
                            key: 'query',
                            type: 'string',
                            title: 'Question',
                            sort: 'query',
                            template: (column) => {
                                return h(
                                    <div className="d-p16 d-d-flex d-jc-space-between d-ai-center">
                                        <div className="d-fc-black-900 d-truncate d-to-ellipsis d-py4">
                                            {column.query}
                                        </div>
                                        <TemplateButton
                                            hidden={
                                                this.hoveredRow !== column.id
                                            }
                                            title="Unmute"
                                            onClick={() =>
                                                this.handleShowUnmuteConfirmationModal(
                                                    column
                                                )
                                            }
                                        />
                                    </div>
                                );
                            }
                        },
                        {
                            key: 'created_at',
                            type: 'datetime',
                            title: 'Muted at',
                            sort: 'created_at'
                        }
                    ];
                case 'broken_content':
                    return [
                        {
                            type: 'string',
                            title: 'Title',
                            template: (column) => {
                                return h(
                                    <Fragment>
                                        <div className="d-p16">
                                            <p className="d-truncate d-fc-black-900">
                                                {column?.node?.title}
                                            </p>
                                        </div>
                                    </Fragment>
                                );
                            }
                        },
                        {
                            title: 'Complication',
                            type: 'string',
                            template: (column) => {
                                return h(
                                    <Fragment>
                                        <div className="d-p16">
                                            <p className="d-truncate d-fc-black-900 d-d-flex d-jc-flex-end">
                                                {column.broken_choices?.length}
                                            </p>
                                        </div>
                                    </Fragment>
                                );
                            }
                        }
                    ];
                case 'low_confidence':
                    return [
                        {
                            title: 'Questions',
                            type: 'string',
                            sortable: false,
                            width: '10rem',
                            template: (column) => {
                                return h(
                                    <Fragment>
                                        <div className="d-p16">
                                            <p className="d-truncate d-fc-black-900">
                                                {column.counters?.queries}
                                            </p>
                                        </div>
                                    </Fragment>
                                );
                            }
                        },
                        {
                            key: 'issue',
                            type: 'string',
                            title: 'Issue',
                            sortable: false,
                            width: '30rem',
                            template: (column) => {
                                // TODO
                                return h(
                                    <Fragment>
                                        <div className="d-p16">
                                            <p className="d-truncate d-fc-black-900">
                                                More questions needed
                                            </p>
                                        </div>
                                    </Fragment>
                                );
                            }
                        },
                        {
                            key: 'title',
                            type: 'string',
                            title: 'Response title',
                            sort: 'title',
                            template: (column) => {
                                return h(
                                    <Fragment>
                                        <div className="d-p16">
                                            <p className="d-truncate d-fc-black-900">
                                                {column.title}
                                            </p>
                                        </div>
                                    </Fragment>
                                );
                            }
                        }
                    ];
                case 'outdated_responses':
                    return [
                        {
                            type: 'string',
                            title: 'Response title',
                            sort: 'title',
                            template: (column) => {
                                return h(
                                    <Fragment>
                                        <div className="d-p16">
                                            <p className="d-truncate d-fc-black-900">
                                                {column?.title}
                                            </p>
                                        </div>
                                    </Fragment>
                                );
                            }
                        },
                        {
                            key: 'updated_at',
                            type: 'datetime',
                            title: 'Last updated',
                            sort: 'updated_at'
                        }
                    ];
                case 'snoozed':
                    return [
                        {
                            type: 'string',
                            title: 'Question group',
                            sortable: false,
                            template: (column) => {
                                return h(
                                    <div className="d-p16 d-d-flex d-jc-space-between">
                                        <div className="d-fc-black-900 d-truncate d-to-ellipsis d-py4">
                                            {column.title}
                                        </div>
                                        <TemplateButton
                                            hidden={
                                                this.hoveredRow !== column.id
                                            }
                                            title="Unsnooze"
                                            onClick={() =>
                                                this.handleShowUnsnoozeConfirmationModal(
                                                    column
                                                )
                                            }
                                        />
                                    </div>
                                );
                            }
                        },
                        {
                            title: 'Volume',
                            type: 'string',
                            width: '13rem',
                            sortable: false,
                            template: (column) => {
                                return h(
                                    <Fragment>
                                        <div className="d-p16">
                                            <p className="d-truncate d-fc-black-900">
                                                {column.query_count}
                                            </p>
                                        </div>
                                    </Fragment>
                                );
                            }
                        }
                    ];
                default:
                    return [];
            }
        },
        totalNumber(): string {
            return `(${this.total})`;
        }
    },
    methods: {
        async fetchExpand(status = 'active') {
            this.isFetching = true;
            this.hasError = false;
            this.rawData = [];
            this.tableData = [];

            const res = await handleRequest<ListClustersResponse>(
                this.apiService.improve.listClusters(
                    this.$store.getters[`${this.orgId}/authToken`],
                    this.knowledgebase.id!,
                    status,
                    100
                ),
                this.orgId
            );
            this.hasError = !!res.error;

            if (this.hasError) {
                this.isFetching = false;
                this.hasLoaded = true;
            }

            if (res?.data) {
                this.total = res.data.clusters?.length || 0;

                if (!this.hasError) {
                    this.rawData = res.data.clusters;
                    this.tableData = this.rawData;
                    this.isFetching = false;
                    this.hasLoaded = true;
                }
            }
        },
        async fetchMutedQueries() {
            this.isFetching = true;
            this.hasError = false;
            this.rawData = [];

            const res = await handleRequest<ListMutedQueriesResponse>(
                this.apiService.improve.listMutedQueries(
                    this.$store.getters[`${this.orgId}/authToken`],
                    this.knowledgebase.id!,
                    undefined,
                    undefined,
                    this.sortBy.sort,
                    this.sortBy.asc ? 'asc' : 'desc'
                ),
                this.orgId
            );
            this.hasError = !!res.error;
            this.isFetching = false;
            this.hasLoaded = true;

            if (res?.data) {
                this.rawData = res.data.queries as MutedQuery[];
                this.total = this.rawData.length;
                this.tableData = this.rawData;
            }
        },
        async fetchBroken() {
            this.isFetching = true;
            this.hasError = false;
            this.rawData = [];
            this.tableData = [];

            const res = await handleRequest<ListBrokenNodesResponse>(
                this.apiService.improve.listBrokenNodes(
                    this.$store.getters[`${this.orgId}/authToken`],
                    this.knowledgebase.id!
                ),
                this.orgId
            );
            this.hasError = !!res.error;
            this.isFetching = false;
            this.hasLoaded = true;

            if (res?.data) {
                this.rawData = res.data.nodes as BrokenNode[];
                this.total = this.rawData.length;
                this.tableData = this.rawData;
            }
        },
        async fetchUnderRepresented() {
            this.isFetching = true;
            this.hasError = false;
            this.rawData = [];
            this.tableData = [];

            const res = await handleRequest<ListNodesResponse>(
                this.apiService.improve.listUnderRepresentedNodes(
                    this.authToken,
                    this.knowledgebase.id!,
                    this.currentNodeType,
                    undefined,
                    this.sortBy.sort,
                    this.sortBy.asc ? 'asc' : 'desc'
                ),
                this.orgId
            );
            this.hasError = !!res.error;
            this.isFetching = false;
            this.hasLoaded = true;

            if (res?.data) {
                this.rawData = res.data.nodes;
                this.total = res.data.nodes.length;
                this.tableData = res.data.nodes;
            }
        },
        async fetchOutdatedResponses() {
            this.isFetching = true;
            this.hasError = false;
            this.rawData = [];
            this.tableData = [];

            const res = await handleRequest<ListNodesResponse>(
                this.apiService.improve.listOutdatedNodes(
                    this.authToken,
                    this.knowledgebase.id!,
                    this.currentNodeType,
                    undefined,
                    this.sortBy.sort,
                    this.sortBy.asc ? 'asc' : 'desc'
                ),
                this.orgId
            );
            this.hasError = !!res.error;
            this.isFetching = false;
            this.hasLoaded = true;

            if (res?.data) {
                this.rawData = res.data.nodes;
                this.total = res.data.nodes.length;
                this.tableData = res.data.nodes;
            }
        },
        async handleUnmute() {
            if (!this.selectedQuery?.id) {
                return;
            }
            const body: UnmuteQueriesParamsBody = {
                queries: [this.selectedQuery.id]
            };

            const res = await handleRequest<AxiosPromise>(
                this.apiService.improve.unmuteQueries(
                    this.$store.getters[`${this.orgId}/authToken`],
                    this.knowledgebase.id!,
                    body
                ),
                this.orgId
            );
            this.hasError = !!res.error;
            this.isFetching = false;
            this.hasLoaded = true;

            this.$store.commit(`${this.orgId}/addNotification`, {
                kind: NOTICE_KINDS.SUCCESS,
                message: this.$t('Unmuted successfully')
            } as INotification);

            await this.fetchMutedQueries();
        },
        handleOpenResponse(rowData: any) {
            if (this.tabSelected === 'missing_knowledge') {
                this.$router.push({
                    name: 'optimize_wizard',
                    params: {
                        clusterId: rowData.data.id
                    },
                    query: {
                        nodeType: NodeType.Response
                    }
                });
            } else if (
                this.tabSelected === 'snoozed' ||
                this.tabSelected === 'muted_questions'
            ) {
                return;
            } else {
                const nodeId =
                    this.tabSelected === 'broken_content'
                        ? rowData.data.node.id
                        : rowData.data.id;
                this.$router.push({
                    name: 'node_editor',
                    params: {
                        nodeId
                    },
                    query: {
                        nodeType: NodeType.Response,
                        tab: 'improve',
                        subTab: this.tabSelected
                    }
                });
            }
        },
        onTabClick(subTab: string) {
            this.$router.push({
                query: { subTab }
            });
        },
        fetchTabData(subTab: string) {
            this.tabSelected = subTab;

            switch (subTab) {
                case 'missing_knowledge':
                    this.currentNodeType = 'questions';
                    this.fetchExpand();
                    return;
                case 'broken_content':
                    this.currentNodeType = NodeType.Content;
                    this.fetchBroken();
                    return;
                case 'low_confidence':
                    this.currentNodeType = NodeType.Response;
                    this.sortBy.sort = 'title';
                    this.fetchUnderRepresented();
                    return;
                case 'outdated_responses':
                    this.currentNodeType = NodeType.Response;
                    this.sortBy.sort = 'title';
                    this.fetchOutdatedResponses();
                    return;
                case 'muted_questions':
                    this.currentNodeType = 'questions';
                    this.sortBy.sort = 'query';
                    this.fetchMutedQueries();
                    return;
                case 'snoozed':
                    this.currentNodeType = 'questions';
                    this.fetchExpand('snoozed');
                    return;
                default:
                    this.currentNodeType = 'questions';
                    return;
            }
        },
        handleShowUnsnoozeConfirmationModal(cluster: ClusterDetail) {
            this.selectedCluster = cluster;
            this.showPrompt = true;
            this.confirmationModalType = 'unsnooze';
        },
        handleShowUnmuteConfirmationModal(queryData: any) {
            this.selectedQuery = queryData;
            this.showPrompt = true;
            this.confirmationModalType = 'unmute';
        },
        async handleUnsnooze() {
            this.isLoading = true;
            const body: PatchClusterRequest = {
                cluster: {
                    status: 'active'
                }
            };
            const res = await handleRequest<ClusterDetail>(
                this.apiService.improve.patchCluster(
                    this.authToken,
                    this.knowledgebase?.id,
                    this.selectedCluster.id,
                    NodeType.Response,
                    body
                ),
                this.orgId
            );
            this.isLoading = false;

            if (!res.error) {
                this.$router.push({
                    query: { subTab: 'missing_knowledge' }
                });

                this.$store.commit(`${this.orgId}/addNotification`, {
                    kind: NOTICE_KINDS.SUCCESS,
                    message: this.$t('Unsnooze successfully')
                } as INotification);
            }
        },
        handleConfirm() {
            this.isUnmuteConfirmationModal
                ? this.handleUnmute()
                : this.handleUnsnooze();
        },
        handleExport() {}
    },
    data() {
        return {
            isFetching: false,
            hasLoaded: false,
            hasError: false,
            rawData: [] as
                | ClusterDetail[]
                | MutedQuery[]
                | BrokenNode[]
                | ListNodesResponse[],
            tableData: [] as
                | ClusterDetail[]
                | MutedQuery[]
                | BrokenNode[]
                | ListNodesResponse[],
            activePage: 1,
            total: 0,
            itemsPerPage: 100,
            sortBy: { sort: 'title', asc: true },
            selectedConsolidateOption: [
                // @ts-ignore
                this.$route.query.option || CONSOLIDATE_OPTIONS[0].value
            ] as string[],
            markdown: '',
            hoveredRow: '',
            IMPROVE_STATES,
            OPTIMIZE_TABS,
            tags: [] as string[],
            nodeStatus: NodeStatus,
            currentNodeType: 'questions' as any,
            improveStateFilters: [
                ...Object.values(IMPROVE_STATES).map((status) => ({
                    label: status,
                    value: status
                }))
            ] as IFilter[],
            selectedImproveState: [
                // @ts-ignore
                this.$route.query.state || 'Consolidate'
            ] as string[],
            tabSelected: '',
            confirmationModalType: '',
            showPrompt: false,
            selectedCluster: null as any,
            selectedQuery: null as any
        };
    }
});
</script>

<style lang="less">
#optimize-tabs .d-tablist button::after {
    display: none;
}

#optimize-tabs .d-tab--selected {
    color: var(--dt-color-foreground-primary);
}
</style>
