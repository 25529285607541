<template>
    <div class="d-d-flex d-fd-column">
        <default-template-view
            :title="$t('Chatter messages')"
            :title-description="
                $t(
                    'Customize chatter messages for a natural feeling in your chatbot\'s conversations.'
                )
            "
            :search-placeholder="$t('Search chatter messages')"
            :table-data="tableData"
            :render-options="renderOptions"
            :columns="columns"
            :is-fetching="isFetching"
            :has-loaded="hasLoaded"
            :total-items="totalChattering"
            v-model:sort="sortBy"
            v-model:search="search"
            v-model:on-selected-items="selectedChatters"
            v-model:labels="tags"
            :has-filters="hasFilters"
            v-model:on-page-change="activePage"
            :has-error="hasError"
            table-fixed-header
            :item-type="NodeType.Chattering"
            @table-row-click="handleOpenChatter"
        >
            <template #selectedItemsActions>
                <div class="d-d-flex d-ml8" v-if="selectedChatters?.length">
                    <dt-button
                        class="d-mr8"
                        kind="danger"
                        importance="outlined"
                        size="xs"
                        v-if="
                            selectedChatters.length &&
                            selectedStatus[0] !== nodeStatus.Deleted
                        "
                        @click="handleUpdateStatus(nodeStatus.Deleted)"
                    >
                        {{ $t('Archive') }}
                    </dt-button>
                    <dt-button
                        class="d-mr8"
                        importance="outlined"
                        size="xs"
                        v-if="
                            selectedChatters.length &&
                            selectedStatus[0] === nodeStatus.Unpublished
                        "
                        @click="handleUpdateStatus(nodeStatus.Published)"
                    >
                        {{ $t('Publish selected') }}
                    </dt-button>
                    <dt-button
                        class="d-mr8"
                        importance="outlined"
                        size="xs"
                        v-if="
                            selectedChatters.length &&
                            selectedStatus[0] !== nodeStatus.Unpublished
                        "
                        @click="handleUpdateStatus(nodeStatus.Unpublished)"
                    >
                        {{ $t('Revert to draft') }}
                    </dt-button>
                </div>
            </template>
            <template #actionButtons>
                <dt-button
                    v-feature="'node_editor'"
                    class="d-truncate"
                    size="xs"
                    @click="handleShowPrompt"
                >
                    {{ $t('Create chatter') }}
                </dt-button>
            </template>
            <template #filters>
                <filter-popover
                    id="documentStatusPopover"
                    type="radio"
                    :options="statusFilters"
                    title="status"
                    v-model:selected-options="statusSelectedFilters"
                    :fetch="fetchChattering"
                    :total-hits="totalChattering"
                    :is-fetching="isFetching"
                    capitalize
                    hide-checkboxes
                    close-on-select
                    dialog-style="d-w164"
                />
            </template>
        </default-template-view>
        <response-title-modal
            :org-id="orgId"
            v-model:show-prompt="showPrompt"
            :type="NodeType.Chattering"
        />
        <empty-state
            v-if="!rawData.length && !isFetching && !hasError"
            :item-type="NodeType.Chattering"
            :has-filters="hasFilters"
        >
            <template #subtitle>
                <dt-button link @click="handleShowPrompt">
                    {{ $t('Create one') }}
                </dt-button>
            </template>
        </empty-state>
    </div>
</template>

<script lang="tsx">
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { defineComponent, h, inject } from 'vue';

import {
    type NodeStatus as NodeStatusType,
    NodeStatus,
    NodeType,
    type PatchNodesRequest
} from '@/open-api';
import type { ListNodesResponse, NodeDetail, Knowledgebase } from '@/open-api';

import { DtButton, DtTooltip } from '@dialpad/dialtone/vue3';

import DefaultTemplateView from '@/views/DefaultTemplateView.vue';

import type {
    IBaseTableColumn,
    IBaseTableRow
} from '@/components/base-table/BaseTable.types';
import ResponseTitleModal from '@/components/node-editor/ResponseTitleModal.vue';
import FilterPopover from '@/components/filter-popover/FilterPopover.vue';
import EmptyState from '@/components/empty-state/EmptyState.vue';

import {
    handleRequest,
    type IFilter,
    replaceQueryFilterParams,
    capitalizeString
} from '@/utils/Common';
import { NODE_TYPES } from '@/utils/types/Response';
import {
    transformNodeToChatter,
    type Chatter,
    CHATTER_STATES
} from '@/utils/types/Chatter';
import { useRoute } from 'vue-router';
import { POPOVER_TYPES } from '@/components/menu-popover/MenuPopover.types';
import type { INotification } from '@/utils/types/Notification';
import { DEFAULT_ITEMS_PER_PAGE, NOTICE_KINDS } from '@/utils/Constants';

export default defineComponent({
    components: {
        DefaultTemplateView,
        ResponseTitleModal,
        FilterPopover,
        EmptyState,
        DtTooltip,
        DtButton
    },
    setup() {
        const orgId: string = inject('orgId')!;
        const $route = useRoute();
        const selectedStatus = $route.query.status
            ? [$route.query.status?.toString()]
            : [NodeStatus.Published];
        return {
            orgId,
            selectedStatus
        };
    },
    mounted() {
        this.fetchChattering();

        const notificationMsg = this.$route.query.notification as string;

        if (notificationMsg) {
            this.$store.commit(`${this.orgId}/addNotification`, {
                kind: NOTICE_KINDS.SUCCESS,
                message: `${this.$t('Chattering')} ${this.$t(notificationMsg)}`
            } as INotification);
            this.$router.replace({ query: { notification: null } });
        }
    },
    watch: {
        activePage(newPage) {
            if (newPage) {
                this.fetchChattering();
            }
        },
        search(newSearch: string) {
            this.search = newSearch;
            this.fetchChattering();
        },
        sortBy: {
            handler(newSort) {
                if (newSort) {
                    this.fetchChattering();
                }
            }
        }
    },
    computed: {
        NodeType() {
            return NodeType;
        },
        knowledgebase(): Knowledgebase {
            return this.$store.getters[`${this.orgId}/currentKnowledgebase`];
        },
        hasFilters(): boolean {
            return !!this.search?.length;
        },
        tableData(): Chatter[] {
            return this.rawData.map((node) => transformNodeToChatter(node));
        },
        statusSelectedFilters: {
            get(): string[] {
                return this.selectedStatus;
            },
            set(status: string[]) {
                this.selectedStatus.length = 0;
                if (status[0]) this.selectedStatus.push(status[0]);
                this.onStatusFilterChange();
            }
        }
    },
    methods: {
        async fetchChattering() {
            this.isFetching = true;
            this.rawData = [];

            const offset = (this.activePage - 1) * this.itemsPerPage;

            const res = await handleRequest<ListNodesResponse>(
                this.$store.getters[
                    `${this.orgId}/apiService`
                ]?.knowledge.listNodes(
                    this.$store.getters[`${this.orgId}/authToken`],
                    this.knowledgebase?.id,
                    NODE_TYPES.CHATTERING,
                    this.statusSelectedFilters[0] as NodeStatus,
                    undefined,
                    this.search ?? undefined,
                    undefined,
                    this.sortBy.sort,
                    this.sortBy.asc ? 'asc' : 'desc',
                    this.itemsPerPage,
                    offset
                ),
                this.orgId
            );
            this.hasError = !!res.error;
            this.isFetching = false;
            this.hasLoaded = true;

            if (res?.data) {
                this.totalChattering = res.data.total_hits || 0;
                this.rawData = res.data.nodes || [];
            }
        },
        handleShowPrompt() {
            this.showPrompt = true;
        },
        handleOpenChatter(rowData: IBaseTableRow<NodeDetail>) {
            this.$router.push({
                name: 'node_editor',
                params: {
                    nodeId: rowData.data.id
                },
                query: {
                    nodeType: NodeType.Chattering,
                    tab: 'chatter'
                }
            });
        },
        onStatusFilterChange() {
            replaceQueryFilterParams(
                'status',
                this.statusSelectedFilters,
                this.$route,
                this.$router
            );
        },
        handleUpdateStatus(status: NodeStatusType) {
            const nodesArr = this.selectedChatters.map((doc: any) => ({
                id: doc.data.id,
                status
            }));

            this.bulkUpdateStatus(nodesArr);
        },
        async bulkUpdateStatus(nodesArr: any) {
            this.rawData = [];
            this.isFetching = true;
            const resKb = await handleRequest<PatchNodesRequest>(
                this.$store.getters[
                    `${this.orgId}/apiService`
                ]?.knowledge.patchNodes(
                    this.$store.getters[`${this.orgId}/authToken`],
                    this.knowledgebase?.id,
                    { nodes: nodesArr }
                ),
                this.orgId,
                false
            );

            if (resKb?.data) {
                this.selectedChatters = [];
                await this.fetchChattering();
            }
            this.isFetching = false;
        },
        updateChatter(chatter: Chatter, status: NodeStatusType) {
            const nodesArr = [
                {
                    id: chatter.id,
                    status
                }
            ];

            this.bulkUpdateStatus(nodesArr);
        },
        renderOptions() {
            switch (this.selectedStatus[0]) {
                case this.nodeStatus.Published:
                    return [
                        {
                            title: 'Revert to draft',
                            action: (rowData: Chatter) => {
                                this.updateChatter(
                                    rowData,
                                    this.nodeStatus.Unpublished
                                );
                            }
                        },
                        {
                            title: 'Archive',
                            kind: POPOVER_TYPES.DANGER,
                            action: (rowData: Chatter) => {
                                this.updateChatter(
                                    rowData,
                                    this.nodeStatus.Deleted
                                );
                            }
                        }
                    ];

                case this.nodeStatus.Deleted:
                    return [
                        {
                            title: 'Publish',
                            action: (rowData: Chatter) => {
                                this.updateChatter(
                                    rowData,
                                    this.nodeStatus.Published
                                );
                            }
                        },
                        {
                            title: 'Revert to draft',
                            action: (rowData: Chatter) => {
                                this.updateChatter(
                                    rowData,
                                    this.nodeStatus.Unpublished
                                );
                            }
                        }
                    ];

                case this.nodeStatus.Unpublished:
                    return [
                        {
                            title: 'Publish',
                            action: (rowData: Chatter) => {
                                this.updateChatter(
                                    rowData,
                                    this.nodeStatus.Published
                                );
                            }
                        },
                        {
                            title: 'Archive',
                            kind: POPOVER_TYPES.DANGER,
                            action: (rowData: Chatter) => {
                                this.updateChatter(
                                    rowData,
                                    this.nodeStatus.Deleted
                                );
                            }
                        }
                    ];
                default:
                    return [];
            }
        }
    },
    data() {
        return {
            search: '' as string | undefined,
            isFetching: false,
            hasLoaded: false,
            hasError: false,
            rawData: [] as NodeDetail[],
            activePage: 1,
            totalChattering: 0,
            itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
            sortBy: { sort: 'title', asc: true },
            markdown: '',
            activeRowId: '',
            CHATTER_STATES,
            selectedChatters: [] as IBaseTableRow[],
            tags: [] as string[],
            nodeStatus: NodeStatus,
            statusFilters: [
                ...Object.values(NodeStatus).map((status) => ({
                    label:
                        status === NodeStatus.Deleted
                            ? this.$t('archived')
                            : this.$t(capitalizeString(status)),
                    value: status
                }))
            ] as IFilter[],
            showPrompt: false,
            title: '',
            columns: [
                {
                    key: 'title',
                    type: 'string',
                    title: 'Title',
                    template: (column) => {
                        return (
                            <div className="d-px16">
                                <p className="d-truncate d-fc-black-900">
                                    {column.title}
                                </p>
                                <p className="d-fs-100 d-fc-black-600 d-w114 d-truncate">
                                    {column.queries || 0} {this.$t('queries')}
                                </p>
                            </div>
                        );
                    },
                    sort: 'title'
                },
                {
                    key: 'created_at',
                    type: 'datetime',
                    title: 'Created',
                    sort: 'created_at'
                }
            ] as IBaseTableColumn<Chatter>[]
        };
    }
});
</script>
