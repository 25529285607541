<template>
    <div
        v-if="shouldShowResult"
        class="d-d-flex d-fw-wrap d-w100p d-pb12 d-box-border d-pr24 d-gg12"
        :class="{
            'd-h100p': isFetching || !chatbots?.length
        }"
        :style="{ maxHeight: `${maxContentHeight - headerHeight}px` }"
        ref="cardContainer"
        v-on-resize="calculateRows"
    >
        <template v-if="shouldShowLoading">
            <div class="d-d-flex d-fd-column d-ai-center d-w100p d-mt24 d-pt24">
                <dt-button kind="inverted" loading />
            </div>
        </template>
        <template v-else-if="!chatbots?.length && !isFetching">
            <div class="d-d-flex d-fd-column d-ai-center d-w100p d-mt24 d-gg12">
                <h2>{{ $t('No chatbot found') }}</h2>
            </div>
        </template>

        <div class="d-d-flex d-fw-wrap d-pb24 d-gg12" v-else>
            <div
                v-for="(chatBot, index) in chatbots"
                :key="`row-${chatBot.id}`"
                class="d-baw1 d-bar8 d-d-flex d-fd-column d-fw-wrap d-box-border h:d-bgc-secondary d-c-pointer"
                :class="{
                    'd-ba d-bc-subtle': !isChatbotSelected(chatBot),
                    'd-bc-focus':
                        isChatbotSelected(chatBot) &&
                        (!chatBot?.generative ||
                            !isGenerativeChatbotFeatureEnabled),
                    'border-ai d-bc-transparent':
                        isChatbotSelected(chatBot) &&
                        chatBot?.generative &&
                        isGenerativeChatbotFeatureEnabled,
                    'd-ba d-bs-md': isChatbotSelected(chatBot),
                    'd-fl-grow1': !isLastRow(index)
                }"
                :style="{
                    flex: '1 0 var(--dt-size-800)',
                    minWidth: `${CHATBOT_CARD_WIDTH}px`,
                    maxWidth: columnWidthPercentage
                }"
                @click="handleEditKnowledge($event, chatBot)"
            >
                <div
                    class="d-d-flex d-jc-space-between d-fw-wrap d-fl1 d-fd-column d-py12 d-px16"
                >
                    <div class="d-d-flex d-w100p d-fw-wrap">
                        <div class="d-w100p d-d-flex d-h24 d-ai-center">
                            <h4
                                class="d-d-flex d-mr12 d-fs-200 d-t d-td300 d-w80p d-h24 d-of-hidden"
                            >
                                {{ getChatbotName(chatBot) }}
                            </h4>
                            <dt-badge
                                class="d-ml-auto"
                                :type="statusDecoration(chatBot)"
                            >
                                <span class="d-tt-capitalize">
                                    {{ getChatbotStatus(chatBot) }}
                                </span>
                            </dt-badge>
                        </div>
                        <div
                            class="d-w100p d-d-flex d-fw-wrap d-py12 d-ai-center"
                        >
                            <chatbot-knowledgebases
                                :chatbot="chatBot"
                                :row-id="chatBot.id"
                            />
                        </div>
                    </div>
                    <div class="d-d-flex d-ai-center d-ps-relative d-mln8">
                        <dt-tooltip
                            :offset="[-8, -4]"
                            placement="top-start"
                            :message="$t('Manage chatbot')"
                        >
                            <template #anchor>
                                <dt-button
                                    class="d-mr8"
                                    kind="muted"
                                    importance="clear"
                                    @click="
                                        handleEditKnowledge($event, chatBot)
                                    "
                                    :disabled="shouldDisableEditKb(chatBot)"
                                    :loading="loadingAppKb === chatBot.id"
                                >
                                    <template #icon>
                                        <dt-icon name="edit-2" size="200" />
                                    </template>
                                </dt-button>
                            </template>
                        </dt-tooltip>
                        <dt-tooltip
                            placement="top"
                            :message="$t('Configure chatbot')"
                        >
                            <template #anchor>
                                <dt-button
                                    class="d-mr8"
                                    kind="muted"
                                    importance="clear"
                                    :active="
                                        isChatbotSelected(chatBot) &&
                                        editDrawerOpen
                                    "
                                    @click="
                                        openEditChatbotDrawer($event, chatBot)
                                    "
                                >
                                    <template #icon>
                                        <dt-icon name="settings" size="200" />
                                    </template>
                                </dt-button>
                            </template>
                        </dt-tooltip>
                        <dt-tooltip
                            placement="top"
                            :message="`${$t('Try now')} ${
                                limited ? '(' + $t('limited') + ')' : ''
                            }
                                          `"
                        >
                            <template #anchor>
                                <template v-if="shouldShowAssistSDK(chatBot)">
                                    <dt-button
                                        class="d-mr8"
                                        kind="muted"
                                        importance="clear"
                                        :disabled="
                                            chatBot.status !==
                                            NodeStatus.Published
                                        "
                                        :active="
                                            isChatbotSelected(chatBot) &&
                                            dssDrawerOpen
                                        "
                                        @click="
                                            openWidgetDrawer($event, chatBot)
                                        "
                                    >
                                        <template #icon>
                                            <dt-icon name="play" size="200" />
                                        </template>
                                    </dt-button>
                                </template>
                                <template v-else>
                                    <!-- In case we only have one instance only render button, instead of menu popover-->
                                    <dt-button
                                        v-if="chatBot.bots?.length <= 1"
                                        class="d-mr8"
                                        kind="muted"
                                        importance="clear"
                                        :active="
                                            isChatbotSelected(chatBot) &&
                                            dssDrawerOpen
                                        "
                                        :disabled="
                                            shouldDisableDssTryNow(chatBot)
                                        "
                                        @click="
                                            handleOpenMergeClient(
                                                $event,
                                                chatBot
                                            )
                                        "
                                    >
                                        <template #icon>
                                            <dt-icon name="play" size="200" />
                                        </template>
                                    </dt-button>
                                    <menu-popover
                                        v-else-if="shouldShowMergeClient"
                                        id="koopidInstanceActionButtons"
                                        :options="
                                            koopidInstancesOptions(chatBot)
                                        "
                                        :active-button="
                                            isChatbotSelected(chatBot) &&
                                            dssDrawerOpen
                                        "
                                        append-to="parent"
                                        :row-data="chatBot"
                                        :disabled="mergeClientOpen"
                                        icon-size="200"
                                    >
                                        <template #icon>
                                            <dt-icon name="play" size="200" />
                                        </template>
                                    </menu-popover>
                                    <dt-button
                                        v-else
                                        class="d-mr8"
                                        kind="muted"
                                        importance="clear"
                                        :active="
                                            isChatbotSelected(chatBot) &&
                                            dssDrawerOpen
                                        "
                                        :disabled="
                                            shouldDisableDssTryNow(chatBot)
                                        "
                                        @click="
                                            openWidgetDrawer($event, chatBot)
                                        "
                                    >
                                        <template #icon>
                                            <dt-icon name="play" size="200" />
                                        </template>
                                    </dt-button>
                                </template>
                            </template>
                        </dt-tooltip>
                        <menu-popover
                            id="chatbotActionButtons"
                            :options="renderOptions(chatBot.status!)"
                            :row-data="chatBot"
                            append-to="parent"
                            icon-size="200"
                        />
                        <ai-badge :chatbot="chatBot" style-class="d-ml-auto" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        class="d-d-flex d-fd-column d-ai-center d-w100p d-mt24 d-gg4"
        v-else-if="!hasFilters"
    >
        <slot name="emptyResult" />
    </div>
    <delete-chatbot-modal
        :org-id="orgId"
        :chatbot="chatbotToDelete"
        v-model:show-prompt="showDelete"
        @success="handleDeleteChatbot"
    />
</template>
<script lang="ts">
import { DtBadge, DtButton, DtIcon, DtTooltip } from '@dialpad/dialtone/vue3';
import MenuPopover from '@/components/menu-popover/MenuPopover.vue';
import { defineComponent, inject, type PropType } from 'vue';
import {
    type IMenuPopoverOptions,
    POPOVER_TYPES
} from '@/components/menu-popover/MenuPopover.types';
import {
    type Knowledgebase,
    LicenseType,
    ListKnowledgebasesResponse,
    NodeStatus,
    type Widget,
    WidgetStatus
} from '@/open-api';
import {
    CHATBOT_CARD_HEIGHT,
    CHATBOT_CARD_WIDTH,
    CHATBOT_LIST_HEADER_HEIGHT
} from '@/utils/Constants';
import ChatbotKnowledgebases from '@/components/chatbot-list/ChatbotKnowledgebases.vue';
import AiBadge from '@/components/ai-badge/AiBadge.vue';
import { featureIsEnabled } from '@/directives/feature.directive';
import { handleRequest } from '@/utils/Common';
import type { ApiService } from '@/services/Api.service';
import store from '@/store';
import type { KoopidBot, KoopidInstance } from '@/utils/koopid';
import DeleteChatbotModal from '@/components/chatbot-list/DeleteChatbotModal.vue';
import type { WidgetInstance } from '@/store/WidgetsModule';

export default defineComponent({
    props: {
        chatbots: {
            type: Array as PropType<WidgetInstance[]>,
            required: true
        },
        selectedChatbot: {
            type: Object as PropType<WidgetInstance>
        },
        updateApp: {
            type: Function as PropType<any>,
            required: true
        },
        mergeClientOpen: {
            type: Boolean as PropType<boolean>
        },
        fetchApps: {
            type: Function as PropType<any>,
            required: true
        },
        editKnowledge: {
            type: Function as PropType<any>,
            required: true
        },
        openWidgetDrawer: {
            type: Function as PropType<any>,
            required: true
        },
        openEditChatbotDrawer: {
            type: Function as PropType<any>,
            required: true
        },
        openMergeClientDrawer: {
            type: Function as PropType<any>
        },
        getChatbotName: {
            type: Function as PropType<any>,
            required: true
        },
        dssDrawerOpen: {
            type: Boolean as PropType<boolean>
        },
        editDrawerOpen: {
            type: Boolean as PropType<boolean>
        },
        isFetching: {
            type: Boolean as PropType<boolean>
        },
        hasFilters: {
            type: Boolean as PropType<boolean>
        },
        koopidInstances: {
            type: Array as PropType<KoopidInstance[]>,
            default: () => []
        }
    },
    components: {
        DtBadge,
        DtButton,
        DtIcon,
        DtTooltip,
        ChatbotKnowledgebases,
        MenuPopover,
        AiBadge,
        DeleteChatbotModal
    },
    setup() {
        const orgId: string = inject('orgId')!;
        return { orgId };
    },
    updated() {
        if (!this.ro) {
            this.ro = new ResizeObserver(this.calculateRows);
            const containerRef: HTMLDivElement = this.$refs
                ?.cardContainer as HTMLDivElement;
            if (containerRef) this.ro?.observe(containerRef);
        }
    },
    watch: {
        chatbots: {
            handler(newChatbot, oldChatbot) {
                if (newChatbot?.length !== oldChatbot?.length) {
                    this.calculateRows();
                }
            },
            deep: true
        }
    },
    beforeUnmount() {
        const containerRef: HTMLDivElement = this.$refs
            ?.cardContainer as HTMLDivElement;
        if (containerRef) this.ro?.unobserve(containerRef);
    },
    computed: {
        shouldShowLoading(): boolean {
            return this.isFetching;
        },
        NodeStatus() {
            return NodeStatus;
        },
        LicenseType() {
            return LicenseType;
        },
        /* v8 ignore next 250 */
        limited(): boolean {
            return this.$store.getters[`${this.orgId}/limited`];
        },
        columnWidthPercentage(): string {
            return this.maxCardsPerRow !== 1
                ? `calc(${
                      100 / this.maxCardsPerRow
                  }% - var(--dt-size-400) - 1px)`
                : '100%';
        },
        isGenerativeChatbotFeatureEnabled(): boolean {
            return featureIsEnabled('generative_bot');
        },
        apiService(): ApiService {
            return store.getters[`${this.orgId}/apiService`];
        },
        authToken(): string {
            return store.getters[`${this.orgId}/authToken`];
        },
        isCSROrLocalDevelopment(): boolean {
            return (
                window.location.hostname.startsWith('csr') ||
                // If in the CSR/dx-console locally
                window.location.hostname === 'localhost'
            );
        },
        shouldShowMergeClient(): boolean {
            return (
                !this.isFetching &&
                !this.isCSROrLocalDevelopment &&
                this.chatbots?.some(
                    (chatbot: WidgetInstance) =>
                        chatbot.license_type === LicenseType.Dss
                )
            );
        },
        shouldShowResult(): boolean {
            return (
                this.chatbots?.length > 0 ||
                this.isFetching ||
                this.hasFilters ||
                false
            );
        },
        maxContentHeight(): number {
            return this.$store.getters[`${this.orgId}/maxContentHeight`];
        }
    },
    methods: {
        /* v8 ignore next 200 */
        isChatbotSelected(chatbot: Widget) {
            return this.selectedChatbot?.id === chatbot.id;
        },
        statusDecoration(chatbot: Widget) {
            switch (chatbot.status) {
                case WidgetStatus.Published: {
                    return 'success';
                }
                case WidgetStatus.Archived: {
                    return 'critical';
                }
                case WidgetStatus.Unpublished: {
                    return 'warning';
                }
            }
        },
        calculateRows() {
            if (this.$refs.cardContainer) {
                this.cardContainerWidth = (
                    this.$refs.cardContainer as HTMLDivElement
                ).clientWidth;
            }
            this.maxCardsPerRow = Math.floor(
                (this.cardContainerWidth - 30) / CHATBOT_CARD_WIDTH
            );
        },
        isLastRow(index: number) {
            const lastRowStart = this.chatbots.length - this.maxCardsPerRow;
            return lastRowStart < index + 1;
        },
        copyAppId(appId: string, event?: MouseEvent) {
            event?.stopPropagation();
            if (this.copied) return;
            this.copied = true;
            navigator.clipboard.writeText(appId);
            setTimeout(() => {
                this.copied = false;
            }, 3000);
        },
        shouldDisableEditKb(chatbot: WidgetInstance) {
            if (!chatbot) return true;
            return !Object.keys(chatbot.kbs || {}).length;
        },
        async fetchKbs(chatbot: WidgetInstance): Promise<Knowledgebase[]> {
            const res = await handleRequest(
                this.apiService.knowledge.listKnowledgebases(
                    this.authToken,
                    chatbot.id!
                ),
                this.orgId
            );
            const kbs: ListKnowledgebasesResponse = res.data;
            return kbs.knowledgebases || [];
        },
        async handleEditKnowledge(event: Event, chatbot: WidgetInstance) {
            event.stopPropagation();
            if (!chatbot.id) return;
            this.loadingAppKb = chatbot.id!;
            const kbs = await this.fetchKbs(chatbot);
            this.loadingAppKb = '';
            this.editKnowledge(event, chatbot, kbs);
        },
        koopidInstancesOptions(widget: WidgetInstance): IMenuPopoverOptions[] {
            return (
                widget.bots?.map((entry: KoopidBot) => ({
                    title: entry.botname,
                    action: () => {
                        if (this.openMergeClientDrawer)
                            this.openMergeClientDrawer(widget, entry);
                    },
                    kind: POPOVER_TYPES.DEFAULT
                })) || []
            );
        },
        getChatbotStatus(chatbot: any) {
            return chatbot.status === NodeStatus.Unpublished
                ? this.$t('draft')
                : chatbot.status === NodeStatus.Deleted
                  ? this.$t('archived')
                  : this.$t(chatbot.status);
        },
        showDeleteChatbotModal(chatbot: WidgetInstance) {
            this.chatbotToDelete = chatbot;
            this.showDelete = true;
        },
        /* v8 ignore next 5 */
        handleDeleteChatbot() {
            this.showDelete = false;
            this.chatbotToDelete = undefined;
            this.fetchApps();
        },
        renderOptions(status: WidgetStatus): IMenuPopoverOptions[] {
            switch (status.toString()) {
                case WidgetStatus.Published:
                    return [
                        {
                            title: 'Copy chatbot ID',
                            action: (chatbotData: WidgetInstance) => {
                                this.copyAppId(chatbotData.id!);
                            }
                        },
                        {
                            title: 'Revert to draft',
                            action: async (chatbotData: WidgetInstance) => {
                                chatbotData.status = WidgetStatus.Unpublished;
                                await this.updateApp(chatbotData);
                                await this.fetchApps();
                            }
                        },
                        {
                            title: 'Archive',
                            kind: POPOVER_TYPES.DANGER,
                            action: async (chatbotData: WidgetInstance) => {
                                chatbotData.status = WidgetStatus.Archived;
                                await this.updateApp(chatbotData);
                                await this.fetchApps();
                            }
                        }
                    ];

                case WidgetStatus.Archived:
                    return [
                        {
                            title: 'Copy chatbot ID',
                            action: (chatbotData: WidgetInstance) => {
                                this.copyAppId(chatbotData.id!);
                            }
                        },
                        {
                            title: 'Publish',
                            action: async (chatbotData: WidgetInstance) => {
                                chatbotData.status = WidgetStatus.Published;
                                await this.updateApp(chatbotData);
                                await this.fetchApps();
                            }
                        },
                        {
                            title: 'Revert to draft',
                            action: async (chatbotData: WidgetInstance) => {
                                chatbotData.status = WidgetStatus.Unpublished;
                                await this.updateApp(chatbotData);
                                await this.fetchApps();
                            }
                        },
                        {
                            title: 'Delete forever',
                            kind: POPOVER_TYPES.DANGER,
                            action: (chatbotData: WidgetInstance) => {
                                this.showDeleteChatbotModal(chatbotData);
                            }
                        }
                    ];

                case WidgetStatus.Unpublished:
                    return [
                        {
                            title: 'Copy chatbot ID',
                            action: (chatbotData: WidgetInstance) => {
                                this.copyAppId(chatbotData.id!);
                            }
                        },
                        {
                            title: 'Publish',
                            action: async (chatbotData: WidgetInstance) => {
                                chatbotData.status = WidgetStatus.Published;
                                await this.updateApp(chatbotData);
                                await this.fetchApps();
                            }
                        },
                        {
                            title: 'Archive',
                            kind: POPOVER_TYPES.DANGER,
                            action: async (chatbotData: WidgetInstance) => {
                                chatbotData.status = WidgetStatus.Archived;
                                await this.updateApp(chatbotData);
                                await this.fetchApps();
                            }
                        }
                    ];

                default:
                    return [];
            }
        },
        handleOpenMergeClient(event: MouseEvent, chatbot: WidgetInstance) {
            const koopidBot = chatbot.bots[0];
            event.stopPropagation();
            if (this.openMergeClientDrawer)
                this.openMergeClientDrawer(chatbot, koopidBot);
        },
        shouldShowAssistSDK(chatBot: WidgetInstance): boolean {
            return (
                chatBot.license_type === LicenseType.Aaa ||
                chatBot.license_type === LicenseType.Asa ||
                (chatBot.license_type === LicenseType.Dss &&
                    this.isCSROrLocalDevelopment)
            );
        },
        shouldDisableDssTryNow(chatBot: WidgetInstance): boolean {
            return !chatBot?.bots?.length || !!this.mergeClientOpen;
        }
    },
    data() {
        return {
            CHATBOT_CARD_WIDTH,
            CHATBOT_CARD_HEIGHT,
            cardContainerWidth: 0,
            ro: undefined as ResizeObserver | undefined,
            maxCardsPerRow: 0,
            rows: [] as any[],
            copied: false,
            loadingAppKb: '',
            headerHeight: CHATBOT_LIST_HEADER_HEIGHT,
            chatbotToDelete: undefined as Widget | undefined,
            showDelete: false
        };
    }
});
</script>
