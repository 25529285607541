<template>
    <div class="d-d-flex d-fd-column d-jc-center d-ai-center d-mt128">
        <h2>{{ $t('Connecting') }}</h2>
        <dt-icon class="loading" name="loading" size="700" />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { DtIcon } from '@dialpad/dialtone/vue3';

export default defineComponent({
    components: { DtIcon },
    mounted() {
        const queryString = window.location.search;

        const params = new URLSearchParams(queryString);
        const code = params.get('code') as string;
        const state = params.get('state');

        if (state) {
            const finalURL = new URL(atob(state));

            finalURL.searchParams.append('code', code);
            window.location.href = finalURL.toString();
        }
    }
});
</script>
