<template>
    <dt-tab-group
        class="d-box-border"
        v-if="visibleNavigationItems"
        :key="selectedTab"
        borderless
    >
        <template #tabs>
            <dt-tab
                v-for="tabState in visibleNavigationItems"
                :id="tabState.text"
                :panel-id="`${tabState.text}-panel`"
                :key="`${tabState.text}-panel`"
                :selected="isActive(tabState)"
                tab-class="d-px12"
                @click="onTabClick(tabState)"
                :disabled="tabState.soon || (limited && tabState.limited)"
                v-feature="tabState.name"
            >
                {{ $t(tabState.text) }}
            </dt-tab>
        </template>
    </dt-tab-group>
</template>

<script lang="ts">
import { defineComponent, inject, type PropType } from 'vue';
import { DtTabGroup, DtTab } from '@dialpad/dialtone/vue3';
import { KNOWLEDGE_ROUTES } from '@/utils/Constants';
import { convertLocaleToLanguage } from '@/utils/Common';
import { type Knowledgebase, NodeType, type Widget } from '@/open-api';
import type { NavigationItem } from '@/components/sidebar-menu/SidebarMenu.vue';

export default defineComponent({
    props: {
        activeTab: {
            type: String as PropType<string>,
            required: true
        }
    },
    mounted() {
        const isMainTab = this.navigationItems.find(
            (navItem: any) => navItem.name === this.activeTab
        );

        if (!isMainTab) {
            switch (this.$route.query.nodeType) {
                case 'response':
                    this.selectedTab = 'responses';
                    return;
                case 'content':
                    this.selectedTab = 'documents';
                    return;
                case 'chattering':
                    this.selectedTab = 'chatter';
                    return;
                default:
                    this.selectedTab = 'improve';
                    return;
            }
        }
    },
    watch: {
        $route(to, from) {
            // if current route is one of the navigation items then it will select it. Else if it's node_editor we need to check the node type query. Or else it's a sub tab then we don't need to change the selected tab
            this.selectedTab = this.navigationItems.find(
                (navItem: any) => navItem.name === to.name
            )
                ? to.name
                : to.name === 'node_editor'
                  ? this.getNodeTypeTab(to.query?.nodeType)
                  : from.name;
        }
    },
    setup() {
        const orgId: string = inject('orgId')!;
        return {
            orgId
        };
    },
    components: {
        DtTabGroup,
        DtTab
    },
    methods: {
        convertLocaleToLanguage,
        isActive(tabState: any): boolean {
            return tabState.name === this.selectedTab;
        },
        onTabClick(navItem: NavigationItem) {
            this.$router.push({
                name: navItem.name,
                query: navItem.query
            });
        },
        isVisible(name?: string) {
            return !(name === 'chatter' && this.currentChatbot?.generative);
        },
        getNodeTypeTab(nodeType: string) {
            switch (nodeType) {
                case NodeType.Response:
                    return 'responses';
                case NodeType.Chattering:
                    return 'chatter';
                default:
                    return 'documents';
            }
        }
    },
    computed: {
        /* v8 ignore next 30 */
        knowledgebase(): Knowledgebase {
            return this.$store.getters[`${this.orgId}/currentKnowledgebase`];
        },
        visibleNavigationItems(): NavigationItem[] {
            return this.navigationItems.filter((item) =>
                this.isVisible(item.name)
            );
        },
        limited(): boolean {
            return this.$store.getters[`${this.orgId}/limited`];
        },
        currentChatbot(): Widget {
            return this.$store.getters[`${this.orgId}/currentChatbot`];
        }
    },
    data() {
        return {
            navigationItems: KNOWLEDGE_ROUTES,
            selectedTab: this.activeTab
        };
    }
});
</script>

<style lang="less" scoped>
.d-tab {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
</style>
