import { name } from '../package.json';
import { defineWebComponentElement } from './build';
import locales from '@/locales';
import { createI18n } from 'vue-i18n';

const VueCustomApp = defineWebComponentElement();

const messages = {
    'en-US': locales.en_US,
    'pt-PT': locales.pt_PT,
    'es-ES': locales.es_ES,
    'fr-FR': locales.fr_FR,
    'it-IT': locales.it_IT,
    'de-DE': locales.de_DE,
    'zh-CN': locales.zh_CN,
    'ja-JP': locales.ja_JP,
    'nl-NL': locales.nl_NL,
    'ru-RU': locales.ru_RU
};

export const i18n = createI18n({
    globalInjection: true,
    legacy: false,
    locale: 'en-US',
    fallbackLocale: 'en-US', // set fallback locale
    messages
});

if (customElements.get(name) === undefined) {
    customElements.define(name, VueCustomApp);
}
