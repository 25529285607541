/* tslint:disable */
/* eslint-disable */
/**
 * Mind
 * Kare API v4.0
 *
 * The version of the OpenAPI document: 4.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const OpenTargetType = {
    Dialog: 'dialog',
    Floating: 'floating',
    Blank: 'blank',
    Self: 'self'
} as const;

export type OpenTargetType = typeof OpenTargetType[keyof typeof OpenTargetType];



