import type { Module } from 'vuex';
import {
    GetStatusResponse,
    GetStatusResponseStatusEnum,
    NodeDetail,
    NodeStatus,
    Organisation,
    type WebcrawlerDomain
} from '@/open-api';
import type { ApiService } from '@/services/Api.service';
import { DEFAULT_ITEMS_PER_PAGE } from '@/utils/Constants';
import { handleRequest } from '@/utils/Common';

export enum FetchingEnum {
    Fetching = 'fetching'
}
export type ImportStatusType = GetStatusResponseStatusEnum | FetchingEnum;

export enum IMPORT_TYPES {
    BOX = 'box',
    DOC360 = 'doc360',
    GDRIVE = 'gdrive',
    WEBCRAWLER = 'webcrawler',
    ZENDESK = 'zendesk'
}

export interface ImportStatusModuleState {
    webcrawler?: {
        [x: string]: ImportStatusType;
    };
    zendesk?: ImportStatusType;
    box?: ImportStatusType;
    document360?: ImportStatusType;
    gdrive?: ImportStatusType;
    error: boolean;
}

export default function (org: Organisation) {
    return {
        namespaced: true,
        state: {
            error: false
        },
        getters: {
            webcrawler(state) {
                return state.webcrawler;
            },
            zendesk(state) {
                return state.zendesk;
            },
            box(state) {
                return state.box;
            },
            document360(state) {
                return state.document360;
            },
            gdrive(state) {
                return state.gdrive;
            },
            error(state): boolean {
                return state.error;
            }
        },
        actions: {
            async fetchWebcrawlerStatus(
                { commit, rootGetters },
                { kbId, hosts }: { kbId: string; hosts: WebcrawlerDomain[] }
            ) {
                const apiService: ApiService =
                    rootGetters[`${org.id}/apiService`];
                const authToken: string = rootGetters[`${org.id}/authToken`];

                for (let i = 0; i < hosts.length; i++) {
                    commit('setWebcrawlerHost', {
                        host: hosts[i].host!,
                        status: FetchingEnum.Fetching
                    });

                    if (hosts[i]?.host) {
                        const res: any = await handleRequest<GetStatusResponse>(
                            apiService.ingestion.getWebcrawlerLatestImportStatus(
                                authToken,
                                kbId!,
                                hosts[i].host!
                            ),
                            org.id,
                            true
                        );

                        if (res.data?.status) {
                            commit('setWebcrawlerHost', {
                                host: hosts[i].host!,
                                status: res.data.status
                            });
                        }
                    }
                }
            },
            async fetchImportStatusByType(
                { commit, rootGetters },
                { kbId, importType }: { kbId: string; importType: IMPORT_TYPES }
            ) {
                const apiService: ApiService =
                    rootGetters[`${org.id}/apiService`];
                const authToken: string = rootGetters[`${org.id}/authToken`];

                commit('setError', false);

                try {
                    switch (importType.toString()) {
                        case IMPORT_TYPES.BOX: {
                            commit('setBox', FetchingEnum.Fetching);
                            const res = await handleRequest<GetStatusResponse>(
                                apiService.ingestion.getBoxLatestImportStatus(
                                    authToken,
                                    kbId!
                                ),
                                org.id,
                                true
                            );
                            if (res.data?.status) {
                                commit('setBox', res.data.status);
                            }
                            break;
                        }
                        case IMPORT_TYPES.DOC360: {
                            commit('setDocument360', FetchingEnum.Fetching);
                            const res = await handleRequest<GetStatusResponse>(
                                apiService.ingestion.getDocument360LatestImportStatus(
                                    authToken,
                                    kbId!
                                ),
                                org.id,
                                true
                            );
                            if (res.data?.status) {
                                commit('setDocument360', res.data.status);
                            }
                            break;
                        }
                        case IMPORT_TYPES.GDRIVE: {
                            commit('setGDrive', FetchingEnum.Fetching);
                            const res = await handleRequest<GetStatusResponse>(
                                apiService.ingestion.getGDriveLatestImportStatus(
                                    authToken,
                                    kbId!
                                ),
                                org.id,
                                true
                            );
                            if (res.data?.status) {
                                commit('setGDrive', res.data.status);
                            }
                            break;
                        }
                        case IMPORT_TYPES.ZENDESK: {
                            commit('setZendesk', FetchingEnum.Fetching);
                            const res = await handleRequest<GetStatusResponse>(
                                apiService.ingestion.getZendeskLatestImportStatus(
                                    authToken,
                                    kbId!
                                ),
                                org.id,
                                true
                            );
                            if (res.data?.status) {
                                commit('setZendesk', res.data.status);
                            }
                            break;
                        }
                    }
                } catch (e) {
                    commit('setError', true);
                }
            },
            async fetch({ dispatch }, { kbId, hosts }) {
                dispatch('fetchWebcrawlerStatus', { kbId, hosts });
                dispatch('fetchImportStatusByType', {
                    kbId,
                    importType: IMPORT_TYPES.BOX
                });
                dispatch('fetchImportStatusByType', {
                    kbId,
                    importType: IMPORT_TYPES.DOC360
                });
                dispatch('fetchImportStatusByType', {
                    kbId,
                    importType: IMPORT_TYPES.GDRIVE
                });
                dispatch('fetchImportStatusByType', {
                    kbId,
                    importType: IMPORT_TYPES.ZENDESK
                });
            }
        },
        mutations: {
            setWebcrawlerHost(
                state,
                { host, status }: { host: string; status: ImportStatusType }
            ) {
                state.webcrawler = {
                    ...state.webcrawler,
                    [host]: status
                };
            },
            setBox(state, status: ImportStatusType) {
                state.box = status;
            },
            setDocument360(state, status: ImportStatusType) {
                state.document360 = status;
            },
            setGDrive(state, status: ImportStatusType) {
                state.gdrive = status;
            },
            setZendesk(state, status: ImportStatusType) {
                state.zendesk = status;
            },
            setError(state, error: boolean) {
                state.error = error;
            }
        }
    } as Module<ImportStatusModuleState, any>;
}
