<template>
    <dt-card
        class="response-controls d-d-flex d-fw-nowrap d-flg8 d-ps-relative d-bar8 d-ba d-bc-subtle d-wmx628 d-wmn512 d-gg8"
        :content-class="{
            'd-p0 d-bar8': true,
            'd-bs-md': isDragHover
        }"
        container-class="d-bs-none d-fc-secondary"
        :class="{
            'd-bc-warning': isUnsupportedBlock || isBroken
        }"
        ref="responseControls"
        :draggable="!editing"
        @dragstart="handleDragStart"
        @dragover.prevent="handleDragHover"
        @drop.prevent="handleDrop"
        :data-position="position"
        @mouseover="toggleIsHover(true)"
        @mouseleave="toggleIsHover(false)"
    >
        <template #content>
            <slot
                :editable="editing"
                :update-editable="updateEditable"
                :set-ref="setBlock"
                :handle-edit="handleEdit"
            />
            <div
                class="d-d-flex d-fw-wrap d-ps-absolute d-r4 d-t2 d-mt1 d-ba d-bc-moderate d-bar32 d-bs-sm d-bgc-primary"
                v-if="!hideControls"
            >
                <dt-button
                    circle
                    class="response-controls-edit"
                    :class="{ 'd-c-grabbing': dragging }"
                    importance="clear"
                    kind="muted"
                >
                    <template #icon>
                        <dt-icon name="grip-vertical" size="300" />
                    </template>
                </dt-button>
                <dt-button
                    circle
                    class="response-controls-edit"
                    importance="clear"
                    kind="muted"
                    @click="handleDelete"
                >
                    <template #icon>
                        <dt-icon name="trash" size="300" />
                    </template>
                </dt-button>
            </div>
        </template>
    </dt-card>
</template>
<script lang="ts">
import { defineComponent, inject, type PropType, toRaw } from 'vue';
import { DtButton, DtIcon, DtCard } from '@dialpad/dialtone/vue3';
import type {
    Block,
    IResponseBlock
} from '@/components/node-editor/responseBlockEditor';
import type { ResponseBlockEditor } from '@/components/node-editor/responseBlockEditor';
import { SUPPORTED_BLOCKS } from '@/utils/Constants';

export default defineComponent({
    props: {
        position: {
            type: Number as PropType<number>,
            required: true
        },
        dragging: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        draggedPosition: {
            type: Number as PropType<number>
        },
        block: {
            type: Object as PropType<Block>,
            required: true
        },
        isDragHover: {
            type: Boolean as PropType<boolean>
        },
        viewMode: {
            type: Boolean as PropType<boolean>
        }
    },
    components: { DtIcon, DtButton, DtCard },
    setup() {
        const orgId: string = inject('orgId')!;
        return {
            orgId
        };
    },
    computed: {
        hideControls(): boolean {
            return this.editing || !this.isHover;
        },
        /* v8 ignore next 3 */
        document(): ShadowRoot {
            return this.$store.getters[`${this.orgId}/document`];
        },
        blockData: {
            get(): IResponseBlock {
                return this.block.getBlockData();
            },
            /* v8 ignore next 7 */
            set(blockData: IResponseBlock) {
                this.block.setData(blockData);
            }
        },
        editing: {
            get(): boolean {
                return this.blockData.editing;
            },
            set(editing: boolean) {
                this.block.setEdit(editing);
            }
        },
        responseBlockEditor(): ResponseBlockEditor {
            return this.$store.getters[`${this.orgId}/responseBlockEditor`];
        },
        isUnsupportedBlock(): boolean {
            return !this.supportedBlocks.some(
                (supportedBlock: any) => supportedBlock === this.block.type
            );
        },
        isBroken(): boolean {
            return this.block?.data.choices_block?.choices.some(
                (choice: any) => choice.broken
            );
        }
    },
    methods: {
        /* v8 ignore next 16 */
        handleEdit() {
            this.editing = !this.editing;
            if (this.editing && this.blockRef) {
                this.$nextTick(() => {
                    this.blockRef?.scrollIntoView?.({
                        behavior: 'smooth'
                    });
                });
            }
        },
        handleDelete() {
            this.responseBlockEditor?.removeBlock(this.block);
        },
        setBlock(ref: any) {
            this.blockRef = toRaw(ref).$el;
        },
        /* v8 ignore next 30 */
        updateEditable(editable: boolean) {
            this.editing = editable;
        },
        handleDragStart(e: DragEvent) {
            if (e.dataTransfer) {
                e.dataTransfer.setData('text/plain', '');
                e.dataTransfer!.effectAllowed = 'move';
            }
            this.$emit('dragging', { dragging: true, position: this.position });
        },
        handleDrop() {
            this.$emit('dragging', { dragging: false, position: undefined });

            if (
                this.draggedPosition === undefined ||
                this.position === this.draggedPosition
            )
                return;

            this.responseBlockEditor?.moveBlock(
                this.block,
                this.draggedPosition
            );
        },
        handleDragHover() {
            document.documentElement.style.cursor = 'default';
            this.$emit('positionHover', this.position);
        },
        toggleIsHover(hover: boolean) {
            if (this.viewMode) return;
            this.isHover = hover;
        }
    },
    data() {
        return {
            blockRef: undefined as any,
            isHover: false,
            supportedBlocks: SUPPORTED_BLOCKS
        };
    }
});
</script>
