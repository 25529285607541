import { type NodeDetail, NodeStatus } from '@/open-api';

export enum RESPONSE_STATES {
    PUBLISHED = 'Published',
    DRAFT = 'Draft',
    ARCHIVED = 'Archived'
}

export enum NODE_TYPES {
    CHATTERING = 'chattering',
    RESPONSE = 'response',
    CONTENT = 'content',
    WORKFLOW = 'workflow',
    CHATTERFLOW = 'chatterflow'
}

export interface Response {
    id: string;
    title: string;
    updated_at?: number;
    created_at?: number;
    state?: RESPONSE_STATES | null;
    queries: number;
    choices: number;
    webexts: number;
    workflows: number;
    _rawNode: NodeDetail;
    labels?: string[];
}

export function transformNodeStatus(
    status?: NodeStatus | null
): RESPONSE_STATES | undefined | null {
    switch (status) {
        case NodeStatus.Published:
            return RESPONSE_STATES.PUBLISHED;
        case NodeStatus.Unpublished:
            return RESPONSE_STATES.DRAFT;
        case NodeStatus.Deleted:
            return RESPONSE_STATES.ARCHIVED;
        default:
            return undefined;
    }
}

export function transformNodeToResponse(data: NodeDetail): Response {
    return {
        id: data?.id || '',
        title: data?.title || '',
        updated_at: data?.updated_at,
        created_at: data?.created_at,
        state: transformNodeStatus(data?.status),
        queries: data.counters.queries || 0,
        choices: data.counters.choices || 0,
        webexts: data.counters.webexts || 0,
        workflows: data.counters.workflows || 0,
        _rawNode: data,
        labels: data?.labels
    };
}
