import store from '@/store';

export function featureIsEnabled(name?: string): boolean {
    const flags = store.getters.featureFlags;
    if (!flags) return true;

    if (name && name.length) {
        const feature =
            flags[
                `VITE_APP_FEATURE_${name.replaceAll('-', '_').toUpperCase()}`
            ];
        if (feature !== undefined) return feature;
        else return true;
    } else {
        return true;
    }
}

export const featureDirective = {
    beforeMount: (element: HTMLElement, binding: any) => {
        const featureEnabled = featureIsEnabled(binding?.value);
        if (!featureEnabled) {
            element.innerHTML = '';
            element.className = '';
            // @ts-ignore
            element.style = undefined;
        }
    },
    mounted(element: HTMLElement, binding: any) {
        const featureEnabled = featureIsEnabled(binding?.value);
        if (!featureEnabled) element.remove();
    }
};
