<template>
    <div class="d-mt12 d-d-flex d-fd-column d-ai-center d-ta-center">
        <spot-blank-space class="d-mb16 d-w164" />
        <!-- --------------------------------------------------------------- -->
        <!--                         WITHOUT FILTERS                         -->
        <!-- --------------------------------------------------------------- -->
        <div v-if="!hasFilters">
            <div class="d-mb4 d-headline-large">
                <slot v-if="$slots.title" name="title" />
                <span v-else>{{ $t(`No ${itemType}`) }}</span>
            </div>

            <slot v-if="$slots.subtitle" name="subtitle" />
        </div>
        <!-- --------------------------------------------------------------- -->
        <!--                         WITH FILTERS                            -->
        <!-- --------------------------------------------------------------- -->
        <div class="d-ta-center" v-else>
            <h3>
                {{ $t('No results found') }}
            </h3>
            <p>
                {{ $t('Try a different search or change the filters') }}
            </p>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
// @ts-ignore
import SpotBlankSpace from '@dialpad/dialtone/css/vue/spot/SpotBlankSpace.vue';
import { DtButton } from '@dialpad/dialtone/vue3';

export default defineComponent({
    props: {
        itemType: {
            type: String as PropType<string>
        },
        hasFilters: {
            type: Boolean as PropType<boolean>
        }
    },
    components: {
        DtButton,
        SpotBlankSpace
    }
});
</script>
