<template>
    <drawer-template @drawer-template-close="close">
        <template #header>
            <div class="d-d-flex d-ai-center">
                <div class="d-d-flex d-fd-column d-w90p">
                    <dt-skeleton
                        v-if="isFetching"
                        :arial-label="$t('Loading')"
                        :paragraphOption="{
                            rows: 1,
                            width: ['80%'],
                            rowClass: 'd-h12 d-mt12 d-mb8'
                        }"
                    />
                    <div class="d-d-flex d-ai-center" v-else>
                        <dt-link
                            class="d-d-flex d-ai-center d-fw-wrap d-headline--md d-td-none d-fc-primary"
                            :href="
                                isDownloadable
                                    ? 'javascript:void(0)'
                                    : documentData?.content?.url
                            "
                            @click="handleDownload"
                            :target="isDownloadable ? '_self' : '_blank'"
                            :disabled="isDownloading || undefined"
                            @mouseleave="handleMouseLeave"
                            @mouseover="handleMouseOver"
                        >
                            <div class="d-d-flex">
                                <div>
                                    {{ documentData?.title }}
                                    <dt-tooltip
                                        class="d-d-inline"
                                        placement="top"
                                        :message="$t('View source document')"
                                        v-if="true"
                                    >
                                        <template #anchor>
                                            <dt-icon
                                                class="d-ml4"
                                                name="external-link"
                                                size="200"
                                            />
                                        </template>
                                    </dt-tooltip>
                                </div>
                            </div>
                        </dt-link>
                    </div>
                    <div
                        class="d-d-flex d-ai-center d-pt4"
                        v-if="isFetching || documentData.labels.length"
                    >
                        <div class="d-w100p" v-if="isFetching">
                            <dt-skeleton
                                :arial-label="$t('Loading')"
                                :paragraphOption="{
                                    rows: 1,
                                    width: ['80%'],
                                    rowClass: 'd-h24'
                                }"
                            />
                        </div>
                        <div class="d-w100p" v-else>
                            <div class="d-w100p">
                                <div v-if="documentData.labels.length">
                                    <dt-chip
                                        :interactive="false"
                                        class="d-mr4 d-mb2 d-mt2"
                                        content-class="d-fs-100"
                                        v-for="label in documentData.labels"
                                        :key="label"
                                        hide-close
                                    >
                                        {{ label }}
                                    </dt-chip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-ml-auto d-mr2">
                    <dt-button
                        kind="muted"
                        importance="clear"
                        @click="handleEditQuestions"
                    >
                        {{ $t('Edit') }}
                    </dt-button>
                </div>
            </div>
        </template>
        <template #default>
            <dt-skeleton
                v-if="isFetching"
                :arial-label="$t('Loading')"
                :paragraphOption="{
                    rows: 10,
                    rowClass: 'd-h16'
                }"
            />
            <rich-text-editor
                v-if="!isFetching && markdown"
                :editable="false"
                :markdown="markdown"
            />
        </template>
    </drawer-template>
</template>

<script lang="ts">
import { defineComponent, inject, type PropType } from 'vue';
import {
    DtButton,
    DtInput,
    DtLink,
    DtIcon,
    DtChip,
    DtSkeleton,
    DtTooltip
} from '@dialpad/dialtone/vue3';
import DrawerTemplate from '@/components/drawer-template/DrawerTemplate.vue';
import { handleRequest } from '@/utils/Common';
import RichTextEditor from '@/components/rich-text-editor/RichTextEditor.vue';
import BaseCombobox from '@/components/base-combobox/BaseCombobox.vue';
import MenuPopover from '@/components/menu-popover/MenuPopover.vue';
import { ZONES_ABBR } from '@/utils/Constants';
import type { ApiService } from '@/services/Api.service';

import { type Knowledgebase, NodeType } from '@/open-api';

export default defineComponent({
    props: {
        documentId: {
            type: String as PropType<string>,
            required: true
        }
    },
    components: {
        DtButton,
        DtInput,
        DtLink,
        DtIcon,
        DtChip,
        DtSkeleton,
        DtTooltip,
        MenuPopover,
        DrawerTemplate,
        RichTextEditor,
        BaseCombobox
    },
    setup() {
        const orgId: string = inject('orgId')!;

        return {
            orgId
        };
    },
    async mounted() {
        await this.fetchAll();
    },
    watch: {
        documentId(newId) {
            if (!newId) return;
            this.fetchAll();
        }
    },
    computed: {
        /* v8 ignore next 12 */
        knowledgebase(): Knowledgebase {
            return this.$store.getters[`${this.orgId}/currentKnowledgebase`];
        },
        apiService(): ApiService | undefined {
            return this.$store.getters[`${this.orgId}/apiService`];
        },
        zone(): ZONES_ABBR | undefined {
            return this.$store.getters[`${this.orgId}/zone`];
        },
        authToken(): string {
            return this.$store.getters[`${this.orgId}/authToken`];
        },
        isDownloadable(): boolean {
            return this.documentData?.content?.source === 'uploads';
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        async fetchDocument() {
            return await handleRequest<string>(
                this.apiService?.knowledge.getNode(
                    this.authToken,
                    this.knowledgebase?.id,
                    this.documentId
                ),
                this.orgId
            );
        },
        async fetchMarkdown() {
            return await handleRequest<string>(
                this.apiService?.knowledge.getMarkdownContent(
                    this.authToken,
                    this.knowledgebase?.id,
                    this.documentId
                ),
                this.orgId
            );
        },
        async fetchAll() {
            this.isFetching = true;

            this.markdown = (await this.fetchMarkdown())?.data ?? '';
            this.documentData = (await this.fetchDocument())?.data ?? undefined;

            this.isFetching = false;
        },
        handleEditQuestions() {
            this.$router.push({
                name: 'node_editor',
                params: {
                    nodeId: this.documentData!.id
                },
                query: {
                    nodeType: NodeType.Content,
                    tab: 'documents'
                }
            });
        },
        handleMouseOver() {
            this.isHover = true;
        },
        handleMouseLeave() {
            this.isHover = false;
        },
        async handleDownload() {
            if (this.isDownloadable && !this.isDownloading) {
                this.isDownloading = true;
                const res = await this.apiService?.knowledge.getRawContent(
                    this.authToken,
                    this.knowledgebase.id!,
                    this.documentData?.id
                );
                if (res?.data && res.headers) {
                    // @ts-ignore
                    const contentType = res!.headers!.get('content-type');
                    const blob = new Blob([res.data], {
                        type: contentType
                    });
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = this.documentData?.title;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
                this.isDownloading = false;
            }
        }
    },
    data() {
        return {
            isFetching: true,
            markdown: '',
            documentData: undefined,
            isHover: false,
            isDownloading: false
        };
    }
});
</script>
