<template>
    <div v-if="!isEmbed" class="d-d-flex d-ai-center d-w100p d-jc-center">
        <video controls :autoplay="false">
            <source :src="source" type="video/mp4" />
        </video>
    </div>
    <div
        class="d-d-flex d-ai-center d-w100p d-jc-center d-ps-relative"
        style="padding-bottom: 56.25%"
        v-if="isEmbed"
    >
        <iframe
            class="d-baw0 d-ps-absolute d-t0 d-l0 d-w100p d-h100p"
            :src="validateYouTubeURL(source) || source"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import { validateYouTubeURL } from '@/utils/Common';

export default defineComponent({
    methods: { validateYouTubeURL },
    props: {
        source: {
            type: String as PropType<string>,
            required: true
        }
    },
    computed: {
        isEmbed(): boolean {
            return (
                !this.source.includes('assets') && !this.source.includes('blob')
            );
        }
    },
    data() {
        return {
            err: null as any
        };
    }
});
</script>
