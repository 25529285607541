<template>
    <Transition name="slide" v-bind="$attrs">
        <drawer-template
            v-if="showZendeskConnectPage"
            @drawer-template-close="handleClose"
            @back="handleBackClick({})"
            is-secondary-page
        >
            <template #header>
                <h3 class="d-d-flex d-jc-flex-start d-py8 d-my2 d-ml32 d-pl8">
                    {{
                        `${isConnected ? $t('Configure') : $t('Connect')}
                            Zendesk
                        `
                    }}
                </h3>
            </template>
            <template #default>
                <zendesk-logo class="d-mr8" />
                <p class="d-fc-black-600 d-fs-100 d-mb16 d-mt4">
                    {{
                        $t(
                            'Authorize Dialpad to capture Help Center articles and identify frequent questions from your customers.'
                        )
                    }}
                </p>
                <h4 class="d-fc-black-700 d-mb4 d-fw-semibold">
                    {{ `${$t('Zendesk subdomain')} (${$t('Required')})` }}
                </h4>
                <div class="d-d-flex d-ai-center d-mb16">
                    <dt-input
                        v-model="domain"
                        name="domain-input"
                        :disabled="isDomainDisabled"
                        :messages="hasError"
                        @change="handleOnChange"
                    />
                    <div class="d-ml4 d-as-flex-start d-pt4">.zendesk.com</div>
                </div>
                <div class="d-mt16 d-c-pointer" @click="handleToggleClick">
                    <b class="d-w100p d-d-flex d-ai-center">
                        <dt-icon
                            class="d-mr8"
                            :name="
                                showAdvancedSettings
                                    ? 'chevron-down'
                                    : 'chevron-right'
                            "
                        />
                        {{ $t('Advanced settings') }}
                    </b>
                </div>
                <div class="d-mt16">
                    <dt-input
                        v-if="showAdvancedSettings"
                        :label="$t('Zendesk locale')"
                        v-model="locale"
                        placeholder="i.e. en-US"
                        :description="
                            $t(
                                'Force import the locale that you would like to import from Zendesk. If not specified, we will import the same locale as the knowledgebase.'
                            )
                        "
                        name="country-code-input"
                        :disabled="islocaleDisabled"
                        @change="handleOnChange"
                    />
                </div>
            </template>
            <template #footer>
                <div class="d-d-flex d-py16">
                    <dt-button
                        class="d-mr16"
                        importance="clear"
                        @click="handleBackClick({})"
                        :disabled="isRequesting"
                    >
                        {{ $t('Cancel') }}
                    </dt-button>
                    <dt-button
                        v-if="!isConnected"
                        id="open-child-window"
                        importance="primary"
                        @click="handleConnectZendesk"
                        :disabled="isRequesting"
                    >
                        {{ $t('Connect') }}
                    </dt-button>
                    <dt-button
                        v-if="isConnected"
                        id="open-child-window"
                        kind="danger"
                        @click="handleShowConfirmationModal"
                        :disabled="isRequesting"
                    >
                        {{
                            isRequesting
                                ? `${$t('Disconnecting')}...`
                                : $t('Disconnect')
                        }}
                    </dt-button>
                </div>
            </template>
        </drawer-template>
    </Transition>
    <confirmation-modal
        type="Zendesk"
        v-model:show-prompt="showConfirmationModal"
        @confirm="handleDisconnectZendesk"
    />
</template>

<script lang="ts">
import { defineComponent, inject, type PropType } from 'vue';
import {
    DtButton,
    DtInput,
    DtIcon,
    DtNotice,
    VALIDATION_MESSAGE_TYPES
} from '@dialpad/dialtone/vue3';

import DrawerTemplate from '@/components/drawer-template/DrawerTemplate.vue';
import ConfirmationModal from '@/components/confirmation-modal/ConfirmationModal.vue';
import type {
    IExternalSource,
    INotice
} from '@/components/sources-drawer/Sources.types';
import type { ImportStatusType } from '@/components/import-status/ImportStatus.vue';

import ZendeskLogo from '@/assets/zendesk-logo.vue';

import { buildAuthUrl, handleRequest, isValidUrl } from '@/utils/Common';
import { NOTICE_KINDS } from '@/utils/Constants';

import type { RequestArgs } from '@/open-api/base';
import type { Knowledgebase, GetZendeskSettingsResponse } from '@/open-api';
import type { Integrations } from '@/store/AppModule';

export default defineComponent({
    props: {
        showZendeskConnectPage: {
            type: Boolean as PropType<boolean>,
            required: true
        },
        data: {
            type: Object as PropType<IExternalSource>
        },
        importStatus: {
            type: String as PropType<ImportStatusType>
        }
    },
    setup() {
        const orgId: string = inject('orgId')!;

        return {
            orgId
        };
    },
    components: {
        DtButton,
        DtInput,
        DtIcon,
        DtNotice,
        ZendeskLogo,
        DrawerTemplate,
        ConfirmationModal
    },
    beforeUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
        window.removeEventListener('message', this.handleOnMessage);
    },
    watch: {
        data(newData, oldData) {
            if (newData?.name !== oldData?.name) {
                if (this.isConnected) {
                    this.getZendeskSettings();
                } else {
                    this.domain = '';
                    this.locale = '';
                }
            }
        }
    },
    computed: {
        knowledgebase(): Knowledgebase {
            return this.$store.getters[`${this.orgId}/currentKnowledgebase`];
        },
        config(): Integrations {
            return this.$store.getters[`${this.orgId}/integrations`];
        },
        isDomainDisabled(): boolean {
            return this.isRequesting || this.isConnected;
        },
        islocaleDisabled(): boolean {
            return this.isRequesting || this.isConnected;
        },
        isConnected(): boolean {
            return this.data?.status === 'Connected';
        }
    },
    emits: ['back', 'connectClose', 'zendeskOnForceClosed'],
    methods: {
        handleClose() {
            this.$emit('connectClose');
        },
        handleBackClick(resp: INotice) {
            this.$emit(
                'back',
                Object.keys(resp).length
                    ? {
                          title: resp.title,
                          message: resp.message,
                          kind: NOTICE_KINDS.SUCCESS
                      }
                    : {}
            );
            this.clearData();
        },
        async getZendeskSettings() {
            this.isFetching = true;

            const res = await handleRequest<GetZendeskSettingsResponse>(
                this.$store.getters[
                    `${this.orgId}/apiService`
                ]?.ingestion.getZendeskSettings(
                    this.$store.getters[`${this.orgId}/authToken`],
                    this.knowledgebase?.id
                ),
                this.orgId
            );
            this.isFetching = false;

            if (res?.data) {
                this.domain = res.data.domain || '';
                this.locale = res.data.forced_locale || '';
                this.showAdvancedSettings = !!this.locale;
            }
        },
        async handleDisconnectZendesk() {
            this.isRequesting = true;

            const resKb = await handleRequest<RequestArgs>(
                this.$store.getters[
                    `${this.orgId}/apiService`
                ]?.ingestion.disconnectZendesk(
                    this.$store.getters[`${this.orgId}/authToken`],
                    this.knowledgebase?.id
                ),
                this.orgId
            );
            this.isRequesting = false;

            if (resKb.error) {
                this.showErrorMsg('Could not disconnect this Zendesk account');
            } else {
                this.showAdvancedSettings = false;
                this.handleBackClick({
                    title: `Zendesk ${this.$t(
                        'account disconnected successfully'
                    )}`
                });
            }
        },
        handleConnectZendesk() {
            const provider = 'zendesk';

            const url = buildAuthUrl(
                provider,
                {
                    domain: this.domain,
                    locale: this.locale,
                    kbId: this.knowledgebase?.id!
                },
                this.config?.[provider]
            );

            if (!url || !isValidUrl(url)) {
                this.hasError = [
                    {
                        message: 'Not a valid domain!',
                        type: VALIDATION_MESSAGE_TYPES.ERROR
                    }
                ];
                return;
            }
            /* v8 ignore next 30 */
            this.isRequesting = true;

            const params = `scrollbars=no,resizable=no,status=no,location=yes,toolbar=no,menubar=no,
width=600,height=300,left=1000,top=300`;

            this.oauthWindow = window.open(url, 'zen', params);

            window.addEventListener('message', this.handleOnMessage, false);

            const zendeskWindow = this.oauthWindow;

            this.interval = setInterval(() => {
                let sameOrigin;
                try {
                    sameOrigin =
                        zendeskWindow!.origin === window.location.origin;
                } catch (e) {
                    sameOrigin = false;
                }
                try {
                    if (zendeskWindow!.closed && !sameOrigin) {
                        clearInterval(this.interval);
                        this.$emit('zendeskOnForceClosed');
                    }
                } catch (e) {
                    console.log('err', e);
                }
            }, 500);
        },
        /* v8 ignore next 35 */
        async requestZendeskToken(code: string, locale: string) {
            const provider = 'zendesk';
            const redirectUrl = new URL(
                this.config?.[provider].redirectPath,
                this.config?.[provider].redirectOrigin
            );

            const resKb = await handleRequest<RequestArgs>(
                this.$store.getters[
                    `${this.orgId}/apiService`
                ]?.ingestion.authenticateZendesk(
                    this.$store.getters[`${this.orgId}/authToken`],
                    this.knowledgebase?.id,
                    {
                        authorize_code: code,
                        subdomain: this.domain,
                        forced_locale: locale,
                        redirect_uri: redirectUrl.toString()
                    }
                ),
                this.orgId,
                true
            );
            this.isRequesting = false;
            if (resKb.error) {
                this.showErrorMsg('Could not connect to Zendesk account');
            } else {
                this.handleBackClick({
                    title: `Zendesk ${this.$t(
                        'account connected successfully'
                    )}`,
                    message: this.$t(
                        'Your documents are now importing. View import status in the Configure sources sidebar.'
                    )
                });
            }
        },
        clearData() {
            this.oauthWindow = null;
            this.isRequesting = false;
        },
        handleOnChange() {
            this.hasError = [];
        },
        showErrorMsg(title: string) {
            this.$store.commit(`${this.orgId}/addNotification`, {
                kind: NOTICE_KINDS.ERROR,
                title: this.$t(title)
            });
        },
        handleOnMessage(event: MessageEvent) {
            if (event.origin !== window.location.origin) {
                this.isRequesting = false;
                this.showErrorMsg('Oops, this help center no longer exists');
                return;
            }

            if (event.data?.typeEvent === 'zendeskEvent') {
                const code = event.data?.code;
                const locale = event.data?.locale;
                if (code) {
                    this.requestZendeskToken(code, locale);
                }
            }
        },
        handleToggleClick() {
            this.showAdvancedSettings = !this.showAdvancedSettings;
        },
        handleShowConfirmationModal() {
            this.showConfirmationModal = true;
        }
    },
    data() {
        return {
            domain: '',
            locale: '',
            oauthWindow: null as Window | null,
            isRequesting: false,
            hasError: [] as { message: string; type: string }[],
            isFetching: false,
            interval: null as any,
            showAdvancedSettings: false as boolean | Event,
            showConfirmationModal: false
        };
    }
});
</script>
