import {
    type CRUDEvent,
    type NodeEventMessage,
    type messageType,
    Topic,
    ImportStatusEventMessage
} from '../../backend/pubsub/index';
import store from '@/store';
import type { LicenseType, NodeDetail, NodeType, Widget } from '@/open-api';

export type EventType = NodeType | LicenseType;

export class EventBusService {
    private readonly orgId: string;
    private readonly kbId?: string;
    private readonly topic: Topic;
    private readonly crudEvents: {
        [key: string]: (...args: any) => void;
    };
    private websocket?: WebSocket;
    private type: EventType;
    private idle = false;
    // (jnoronha): in minutes
    private idleTime = 2;
    private idleTimeoutInstance?: any;
    private fetchOnReconnect?: () => void;
    // (jnoronha): in seconds
    private readonly initialReconnectDelay = 2;
    // (jnoronha): in seconds
    private readonly maxReconnectDelay = 16;
    private currentReconnectDelay: number;
    private error = false;

    constructor(
        orgId: string,
        topic: Topic,
        type: EventType,
        kbId: string = undefined,
        crudEvents: {
            [key in CRUDEvent]?: (...args: any) => void;
        },
        fetchOnReconnect?: () => void
    ) {
        this.orgId = orgId;
        this.kbId = kbId;
        this.topic = topic;
        this.crudEvents = crudEvents;
        this.type = type;
        this.idleTime = this.idleTime * 1000 * 60;
        this.currentReconnectDelay = this.initialReconnectDelay;
        this.fetchOnReconnect = fetchOnReconnect;

        this.open();
        this.idleDetector();
    }

    handleMessageEvent(messageEvent: MessageEvent) {
        try {
            const messageData: messageType = JSON.parse(messageEvent.data);
            // If it's a node event and the KbId is different, we ignore the event
            if (
                this.topic === Topic.node_event &&
                this.kbId !== (messageData as NodeEventMessage)?.kare__kb_id
            )
                return;
            if (
                this.type === (<NodeDetail>messageData.after)?.type ||
                this.type === (<Widget>messageData.after)?.license_type
            ) {
                this.crudEvents[messageData.event]({
                    ...messageData.after,
                    ingestion_source: (messageData as ImportStatusEventMessage)
                        ?.ingestion_source
                });
            }
        } catch (e) {
            console.warn('failed to parse message data', e);
            return;
        }
    }

    open() {
        const zone = store.getters[`${this.orgId}/zone`];
        const authToken = store.getters[`${this.orgId}/authToken`];
        this.websocket = new WebSocket(
            `wss://console.${zone}.karehq.com/ws?topic=${this.topic}&access_token=${authToken?.replace('Bearer ', '')}`
        );

        this.websocket.onopen = () => {
            this.error = false;

            this.websocket!.onmessage = (message: MessageEvent) => {
                // If we receive a message back from the websocket this means the connection was successful
                if (this.currentReconnectDelay !== this.initialReconnectDelay)
                    this.currentReconnectDelay = this.initialReconnectDelay;

                this.handleMessageEvent(message);
            };
        };

        this.websocket.onclose = (e) => {
            if (!e.wasClean) {
                this.error = true;
                this.retryConnection();
            }
        };
    }

    retryConnection() {
        // (jnoronha): exponential backoff strategy
        setTimeout(() => {
            if (this.currentReconnectDelay < this.maxReconnectDelay) {
                this.currentReconnectDelay *= 2;
            }

            this.open();
        }, this.currentReconnectDelay * 1000);
    }

    close() {
        this.websocket?.close();
    }

    idleDetector() {
        this.idleTimeoutInstance = setTimeout(() => {
            this.idle = true;
            this.close();
        }, this.idleTime);
        store.commit(`${this.orgId}/addIdleDetectorFn`, {
            topic: this.topic,
            idleDetectorFn: () => {
                if (this.error) return;
                clearTimeout(this.idleTimeoutInstance);
                this.idleTimeoutInstance = setTimeout(() => {
                    this.idle = true;
                    this.close();
                }, this.idleTime);
                if (
                    this.websocket?.readyState === WebSocket.CLOSED ||
                    this.idle
                ) {
                    this.idle = false;
                    this.fetchOnReconnect?.();
                    this.open();

                    clearTimeout(this.idleTimeoutInstance);
                    this.idleTimeoutInstance = setTimeout(() => {
                        this.idle = true;
                        this.close();
                    }, this.idleTime);
                }
            }
        });
        this.bindEvents();
    }

    bindEvents() {
        const idleDetectorFns = store.getters[`${this.orgId}/idleDetectorFns`];
        document.onmousemove =
            document.onmousedown =
            document.onmouseup =
            document.onkeydown =
            document.onkeyup =
            document.onfocus =
                () =>
                    Object.keys(idleDetectorFns).forEach((key) =>
                        idleDetectorFns[key]?.()
                    );
    }

    destroy() {
        this.close();
        clearTimeout(this.idleTimeoutInstance);
        store.commit(`${this.orgId}/removeIdleDetectorFn`, this.topic);
        this.bindEvents();
    }
}
