/* tslint:disable */
/* eslint-disable */
/**
 * Mind
 * Kare API v4.0
 *
 * The version of the OpenAPI document: 4.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { ResponseNodeBlockAttachmentsBlock } from './response-node-block-attachments-block';
// May contain unused imports in some cases
// @ts-ignore
import { ResponseNodeBlockCallToActionBlock } from './response-node-block-call-to-action-block';
// May contain unused imports in some cases
// @ts-ignore
import { ResponseNodeBlockChoicesBlock } from './response-node-block-choices-block';
// May contain unused imports in some cases
// @ts-ignore
import { ResponseNodeBlockCxQuestionBlock } from './response-node-block-cx-question-block';
// May contain unused imports in some cases
// @ts-ignore
import { ResponseNodeBlockHandoverBlock } from './response-node-block-handover-block';
// May contain unused imports in some cases
// @ts-ignore
import { ResponseNodeBlockImageBlock } from './response-node-block-image-block';
// May contain unused imports in some cases
// @ts-ignore
import { ResponseNodeBlockQuoteBlock } from './response-node-block-quote-block';
// May contain unused imports in some cases
// @ts-ignore
import { ResponseNodeBlockTextBlock } from './response-node-block-text-block';
// May contain unused imports in some cases
// @ts-ignore
import { ResponseNodeBlockVideoBlock } from './response-node-block-video-block';
// May contain unused imports in some cases
// @ts-ignore
import { ResponseNodeBlockWebextBlock } from './response-node-block-webext-block';
// May contain unused imports in some cases
// @ts-ignore
import { ResponseNodeBlockWorkflowBlock } from './response-node-block-workflow-block';

/**
 * 
 * @export
 * @interface ResponseNodeBlock
 */
export interface ResponseNodeBlock {
    /**
     * 
     * @type {ResponseNodeBlockAttachmentsBlock}
     * @memberof ResponseNodeBlock
     */
    'attachments_block'?: ResponseNodeBlockAttachmentsBlock;
    /**
     * 
     * @type {ResponseNodeBlockCallToActionBlock}
     * @memberof ResponseNodeBlock
     */
    'call_to_action_block'?: ResponseNodeBlockCallToActionBlock;
    /**
     * 
     * @type {ResponseNodeBlockChoicesBlock}
     * @memberof ResponseNodeBlock
     */
    'choices_block'?: ResponseNodeBlockChoicesBlock;
    /**
     * 
     * @type {ResponseNodeBlockCxQuestionBlock}
     * @memberof ResponseNodeBlock
     */
    'cx_question_block'?: ResponseNodeBlockCxQuestionBlock;
    /**
     * 
     * @type {ResponseNodeBlockHandoverBlock}
     * @memberof ResponseNodeBlock
     */
    'handover_block'?: ResponseNodeBlockHandoverBlock;
    /**
     * 
     * @type {ResponseNodeBlockImageBlock}
     * @memberof ResponseNodeBlock
     */
    'image_block'?: ResponseNodeBlockImageBlock;
    /**
     * 
     * @type {ResponseNodeBlockQuoteBlock}
     * @memberof ResponseNodeBlock
     */
    'quote_block'?: ResponseNodeBlockQuoteBlock;
    /**
     * 
     * @type {ResponseNodeBlockTextBlock}
     * @memberof ResponseNodeBlock
     */
    'text_block'?: ResponseNodeBlockTextBlock;
    /**
     * 
     * @type {string}
     * @memberof ResponseNodeBlock
     */
    'type'?: ResponseNodeBlockTypeEnum;
    /**
     * 
     * @type {ResponseNodeBlockVideoBlock}
     * @memberof ResponseNodeBlock
     */
    'video_block'?: ResponseNodeBlockVideoBlock;
    /**
     * 
     * @type {ResponseNodeBlockWebextBlock}
     * @memberof ResponseNodeBlock
     */
    'webext_block'?: ResponseNodeBlockWebextBlock;
    /**
     * 
     * @type {ResponseNodeBlockWorkflowBlock}
     * @memberof ResponseNodeBlock
     */
    'workflow_block'?: ResponseNodeBlockWorkflowBlock;
}

export const ResponseNodeBlockTypeEnum = {
    Text: 'text',
    Quote: 'quote',
    Choices: 'choices',
    Video: 'video',
    Image: 'image',
    Attachments: 'attachments',
    Webext: 'webext',
    Workflow: 'workflow',
    Handover: 'handover',
    CallToAction: 'call_to_action',
    CxQuestion: 'cx_question'
} as const;

export type ResponseNodeBlockTypeEnum = typeof ResponseNodeBlockTypeEnum[keyof typeof ResponseNodeBlockTypeEnum];


