<template>
    <svg
        width="24"
        height="14"
        viewBox="0 0 24 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_594_87391)">
            <path
                d="M12.8951 11.3646C11.3511 11.3646 10.0996 10.1129 10.0996 8.56907C10.0996 7.02488 11.3511 5.77338 12.8951 5.77338C14.439 5.77338 15.6907 7.02488 15.6907 8.56907C15.6907 10.1129 14.439 11.3646 12.8951 11.3646ZM12.8951 5.69724C11.3117 5.69724 10.0234 6.98548 10.0234 8.56907C10.0234 10.1525 11.3117 11.4407 12.8951 11.4407C14.4786 11.4407 15.7669 10.1525 15.7669 8.56907C15.7669 6.98548 14.4786 5.69724 12.8951 5.69724Z"
                fill="white"
            />
            <path
                d="M12.8952 13.1537C11.1958 13.1537 9.64401 12.2201 8.84518 10.7175L8.77794 10.591L8.71071 10.7175C7.91188 12.2201 6.36 13.1537 4.6607 13.1537C3.44952 13.1537 2.30739 12.6855 1.44472 11.8356C0.58313 10.9867 0.0977985 9.85359 0.0780779 8.64494L0.0761445 8.59926V0.935679L0.0765908 0.915502C0.0864066 0.453152 0.470371 0.0769978 0.932504 0.0769978C1.39466 0.0769978 1.77869 0.453151 1.78854 0.915577L1.78898 0.93111V4.83976V4.99213L1.91087 4.9006C2.70905 4.30119 3.6599 3.98443 4.6607 3.98443C6.36003 3.98443 7.91188 4.91781 8.71071 6.42033L8.77794 6.5468L8.84518 6.42033C9.64394 4.91781 11.1958 3.98443 12.8952 3.98443C15.4231 3.98443 17.4797 6.04107 17.4797 8.56909C17.4797 11.097 15.4231 13.1537 12.8952 13.1537ZM12.8952 3.90829C11.1106 3.90829 9.56101 4.91157 8.77794 6.38461C7.99487 4.91157 6.44525 3.90829 4.6607 3.90829C3.61181 3.90829 2.64394 4.2549 1.86512 4.83976V0.913902H1.86468C1.85388 0.407922 1.4411 0.000853539 0.932504 0.000853539C0.423911 0.000853539 0.0111837 0.407922 0.00044616 0.913902H0V8.64622H0.00193336C0.0433667 11.1844 2.11256 13.2298 4.6607 13.2298C6.44525 13.2298 7.99487 12.2262 8.77794 10.7533C9.56101 12.2262 11.1106 13.2298 12.8952 13.2298C15.4692 13.2298 17.5559 11.1429 17.5559 8.56909C17.5559 5.99497 15.4692 3.90829 12.8952 3.90829Z"
                fill="white"
            />
            <path
                d="M4.66079 11.3646C3.1168 11.3646 1.86521 10.1129 1.86521 8.56907C1.86521 7.02488 3.1168 5.77338 4.66079 5.77338C6.20479 5.77338 7.45639 7.02488 7.45639 8.56907C7.45639 10.1129 6.20479 11.3646 4.66079 11.3646ZM4.66079 5.69724C3.07734 5.69724 1.78906 6.98548 1.78906 8.56907C1.78906 10.1525 3.07734 11.4407 4.66079 11.4407C6.24426 11.4407 7.53253 10.1525 7.53253 8.56907C7.53253 6.98548 6.24426 5.69724 4.66079 5.69724Z"
                fill="white"
            />
            <path
                d="M23.5821 12.9925C23.4327 13.1046 23.2553 13.1639 23.0689 13.1639C22.8037 13.1639 22.5577 13.0445 22.3941 12.836L22.3874 12.8275L20.3348 10.0837L20.2738 10.0023L20.2128 10.0837L18.1668 12.8189L18.1534 12.8359C17.9898 13.0443 17.7439 13.1639 17.4786 13.1639C17.2923 13.1639 17.1148 13.1044 16.9654 12.9922C16.5958 12.7146 16.5147 12.1833 16.7846 11.8077L16.7959 11.7918L19.1702 8.61796L19.2043 8.57251L19.1702 8.52683L16.7986 5.35662L16.7846 5.33721C16.5147 4.96174 16.5958 4.43032 16.9654 4.1527C17.1148 4.04039 17.2923 3.98107 17.4786 3.98107C17.7439 3.98115 17.9898 4.1007 18.1534 4.3091L18.1646 4.32311L20.2128 7.06119L20.2738 7.14284L20.3348 7.06119L22.3853 4.32014L22.3942 4.30895C22.5577 4.10054 22.8037 3.981 23.0689 3.981C23.2553 3.981 23.4327 4.04031 23.5821 4.15255C23.9516 4.4301 24.0328 4.96151 23.763 5.33713L23.7515 5.35335L21.3774 8.52683L21.3433 8.57251L21.3774 8.61796L23.7474 11.786L23.763 11.8077C24.0328 12.1834 23.9516 12.7149 23.5821 12.9925ZM23.8253 11.7631L21.4384 8.57251L23.8253 5.38183L23.8248 5.38152C24.12 4.97049 24.0345 4.39713 23.6278 4.09163C23.46 3.96562 23.2636 3.90485 23.0689 3.90485C22.7917 3.90485 22.5178 4.02805 22.3342 4.26189L22.3339 4.26174L20.2738 7.01568L18.2137 4.26174L18.2134 4.26212C18.0298 4.02828 17.7558 3.905 17.4786 3.90493C17.2838 3.90493 17.0875 3.96577 16.9197 4.09179C16.513 4.39735 16.4274 4.97065 16.7228 5.38167L16.7224 5.38183L19.1092 8.57251L16.7224 11.7631L16.7228 11.7633C16.4274 12.1743 16.513 12.7477 16.9197 13.0531C17.0875 13.1791 17.2839 13.24 17.4786 13.24C17.7558 13.24 18.0298 13.1167 18.2134 12.8828L18.2137 12.8831L20.2738 10.1293L22.3339 12.8831L22.3342 12.883C22.5177 13.1167 22.7918 13.24 23.0689 13.24C23.2637 13.24 23.46 13.1794 23.6278 13.0532C24.0345 12.7478 24.12 12.1744 23.8248 11.7634L23.8253 11.7631Z"
                fill="white"
            />
            <path
                d="M12.8952 11.3641C11.3512 11.3641 10.0997 10.1125 10.0997 8.56855C10.0997 7.02445 11.3512 5.77292 12.8952 5.77292C14.4391 5.77292 15.6908 7.02445 15.6908 8.56855C15.6908 10.1125 14.4391 11.3641 12.8952 11.3641ZM4.6607 11.3641C3.11672 11.3641 1.86512 10.1125 1.86512 8.56855C1.86512 7.02445 3.11672 5.77292 4.6607 5.77292C6.20471 5.77292 7.4563 7.02445 7.4563 8.56855C7.4563 10.1125 6.20471 11.3641 4.6607 11.3641ZM12.8952 3.90774C11.1106 3.90774 9.56101 4.9111 8.77794 6.38409C7.99487 4.9111 6.44525 3.90774 4.6607 3.90774C3.61181 3.90774 2.64394 4.25443 1.86512 4.83922V0.913432H1.86468C1.85388 0.407452 1.4411 0.000383377 0.932504 0.000383377C0.423911 0.000383377 0.0111837 0.407452 0.00044616 0.913432H0V8.64577H0.00193336C0.0433667 11.184 2.11256 13.2293 4.6607 13.2293C6.44525 13.2293 7.99487 12.2258 8.77794 10.7528C9.56101 12.2258 11.1106 13.2293 12.8952 13.2293C15.4692 13.2293 17.5559 11.1425 17.5559 8.56855C17.5559 5.99444 15.4692 3.90774 12.8952 3.90774Z"
                fill="#0F80CC"
            />
            <path
                d="M23.8253 11.7626L21.4384 8.57198L23.8253 5.38137L23.8248 5.38107C24.12 4.97004 24.0345 4.39667 23.6278 4.09111C23.2212 3.78577 22.6467 3.86336 22.3342 4.26144L22.3339 4.26121L20.2738 7.01518L18.2137 4.26121L18.2134 4.26167C17.9008 3.86343 17.3264 3.78584 16.9197 4.09133C16.513 4.39683 16.4274 4.97019 16.7228 5.38122L16.7224 5.38137L19.1092 8.57198L16.7224 11.7626L16.7228 11.7628C16.4274 12.1738 16.513 12.7471 16.9197 13.0526C17.3264 13.3581 17.9008 13.2805 18.2134 12.8823L18.2137 12.8827L20.2738 10.1288L22.3339 12.8827L22.3342 12.8825C22.6467 13.2806 23.2212 13.3582 23.6278 13.0529C24.0345 12.7474 24.12 12.174 23.8248 11.7629L23.8253 11.7626Z"
                fill="#0F80CC"
            />
        </g>
        <defs>
            <clipPath id="clip0_594_87391">
                <rect width="24" height="13.24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
