<template>
    <dt-icon class="loading" name="loading" :size="size" />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { DtIcon } from '@dialpad/dialtone/vue3';

export default defineComponent({
    components: {
        DtIcon
    },
    props: {
        size: {
            type: String as PropType<string>,
            default: '200'
        }
    }
});
</script>
<style lang="less">
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.loading {
    animation-name: spin;
    animation-duration: 800ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
</style>
