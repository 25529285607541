<template>
    <div class="d-w90p" v-if="uploadFile.message">
        <p class="d-fc-gold-500 d-fs-100 d-d-flex d-mb4">
            <dt-icon name="alert-triangle" size="200" class="d-mr4" />
            {{ uploadFile.message }}
        </p>
    </div>
    <div class="d-w90p" v-if="uploadFile?.file?.name">
        <div class="d-truncate d-mb2">
            {{ uploadFile?.file?.name }}
        </div>
        <dt-button
            v-if="isError && !uploadFile.override"
            link
            class="d-fc-red-400 d-mr8"
            @click="handleRemoveClick"
            aria-label="remove-uploaded-file-button"
        >
            {{ $t('Remove') }}
        </dt-button>
        <dt-button
            v-if="isError && !uploadFile.override"
            link
            class="d-ml-auto"
            @click="handleOverride"
        >
            {{ $t('Upload anyway') }}
        </dt-button>
    </div>
    <div
        class="d-d-flex d-ai-stretch d-jc-flex-end d-w10p d-h100p"
        @mouseover="toggleRemoveIcon(index)"
        @mouseleave="toggleRemoveIcon(null)"
    >
        <dt-icon name="loading" class="loading" v-show="isLoading" />
        <dt-icon
            name="check-circle"
            size="500"
            class="d-fc-green-400"
            v-if="isPublished"
        />
        <dt-icon
            name="alert-circle"
            size="500"
            class="d-fc-red-400"
            v-if="isError && !(showCloseIcon === index) && !uploadFile.override"
        />
        <dt-icon
            name="trash"
            size="300"
            class="d-c-pointer"
            v-if="
                !uploadFile.status ||
                (isError && showCloseIcon === index) ||
                (!isPublished && uploadFile.override && !isLoading)
            "
            @click="handleRemoveClick"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import { DtButton, DtIcon } from '@dialpad/dialtone/vue3';
import type { IUploadedFile } from './UploadFile.types';

export default defineComponent({
    props: {
        uploadFile: {
            type: Object as PropType<IUploadedFile>,
            required: true
        },
        index: {
            type: Number as PropType<number>,
            required: true
        }
    },
    components: {
        DtButton,
        DtIcon
    },
    computed: {
        isPublished(): boolean {
            return this.uploadFile.status === 'Published';
        },
        isLoading(): boolean {
            return this.uploadFile.status === 'Loading';
        },
        isError(): boolean {
            return this.uploadFile.status === 'Error';
        }
    },
    methods: {
        handleRemoveClick() {
            this.$emit('removeFile', this.uploadFile);
        },
        toggleRemoveIcon(index: null | number) {
            this.showCloseIcon = index;
        },
        handleOverride() {
            this.$emit('override', this.uploadFile);
        }
    },
    emits: ['removeFile', 'override'],
    data() {
        return {
            showCloseIcon: null as null | number
        };
    }
});
</script>
