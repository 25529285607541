<template>
    <div class="d-h100p">
        <component :is="drawer?.componentInstance" />
    </div>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import type { DrawerService } from '@/services/Drawer.service';

export default defineComponent({
    props: [],
    setup() {
        const orgId = inject('orgId');
        return {
            orgId
        };
    },
    computed: {
        drawerService(): DrawerService | undefined {
            return this.$store.getters[`${this.orgId}/drawerService`];
        },
        /* v8 ignore next 3 */
        drawer() {
            return this.drawerService?.getOpenDrawer();
        }
    }
});
</script>
