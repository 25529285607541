<template>
    <dt-card
        class="d-w100p"
        content-class="d-px16 d-py8"
        container-class="d-bs-none"
    >
        <template #content>
            <div class="d-fc-warning d-fw-semibold d-d-flex d-ai-center">
                <dt-icon name="alert-triangle" size="200" />
                <span class="d-ml8">{{ title }}</span>
            </div>
            <p>{{ $t('Unsupported content block') }}</p>
        </template>
    </dt-card>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import { DtCard, DtIcon } from '@dialpad/dialtone/vue3';
import type { Block } from '@/components/node-editor/responseBlockEditor';

export default defineComponent({
    props: {
        block: {
            type: Object as PropType<Block>,
            required: true
        }
    },
    components: {
        DtCard,
        DtIcon
    },
    computed: {
        title(): string {
            const title = this.block.type.split('-').join(' ');
            return title.charAt(0).toUpperCase() + title.slice(1);
        }
    }
});
</script>
