/* tslint:disable */
/* eslint-disable */
/**
 * Mind
 * Kare API v4.0
 *
 * The version of the OpenAPI document: 4.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * The license type that can be associated with organisation, application and knowledgebase. 
 * @export
 * @enum {string}
 */

export const LicenseType = {
    Aaa: 'AAA',
    Asa: 'ASA',
    Dss: 'DSS'
} as const;

export type LicenseType = typeof LicenseType[keyof typeof LicenseType];



