<template>
    <dt-modal
        :close-button-props="{
            ariaLabel: `${$t(`Close create ${type} prompt`)}`
        }"
        v-model:show="showModal"
    >
        <template #header>
            <div>{{ modalTitle }}</div>
        </template>
        <template #default>
            <dt-input
                v-model="title"
                aria-required="true"
                :placeholder="`${$t(`Type your ${type} title`)}`"
                :disabled="isSaving"
                :messages="validate"
                @keyup.enter="handleCreateResponse"
            />
        </template>
        <template #footer>
            <dt-button @click="handleCreateResponse">
                {{ $t('Create') }}
            </dt-button>
            <dt-button
                class="d-mr4"
                importance="clear"
                kind="muted"
                @click="handleCancel"
            >
                {{ $t('Cancel') }}
            </dt-button>
        </template>
    </dt-modal>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';

import {
    DtButton,
    DtIcon,
    DtInput,
    DtModal,
    VALIDATION_MESSAGE_TYPES
} from '@dialpad/dialtone/vue3';
import {
    type Knowledgebase,
    type NodeDetail,
    NodeStatus,
    NodeType,
    type PutNodeRequest
} from '@/open-api';
import { handleRequest } from '@/utils/Common';
import type { ApiService } from '@/services/Api.service';
import { MIN_RESPONSE_NAME_CHARS } from '@/utils/Constants';

export default defineComponent({
    props: {
        showPrompt: {
            type: Boolean as PropType<boolean>
        },
        orgId: {
            type: String as PropType<string>,
            required: true
        },
        type: {
            type: String as PropType<NodeType>,
            default: NodeType.Response
        }
    },
    components: {
        DtButton,
        DtIcon,
        DtInput,
        DtModal
    },
    watch: {
        showPrompt(newShow) {
            this.showModal = newShow;
        },
        showModal() {
            this.handleChangeShow();
        }
    },
    computed: {
        /* v8 ignore next 6 */
        knowledgebase(): Knowledgebase {
            return this.$store.getters[`${this.orgId}/currentKnowledgebase`];
        },
        apiService(): ApiService {
            return this.$store.getters[`${this.orgId}/apiService`];
        },
        canCreateResponse(): boolean {
            return this.title?.trim().length >= this.minCharacters;
        },
        modalTitle(): string {
            const typeText: string =
                this.type === NodeType.Chattering ? 'chatter' : this.type;
            const capitalized =
                typeText.charAt(0).toUpperCase() + typeText.slice(1);

            return `${this.$t(capitalized)} ${this.$t('title')}`;
        },
        isTypeResponse(): boolean {
            return this.type === NodeType.Response;
        }
    },
    methods: {
        async handleCreateResponse() {
            this.validate = undefined;
            if (this.canCreateResponse) {
                const body: PutNodeRequest = {
                    title: this.title,
                    type: this.type,
                    [this.type]: {
                        queries: [],
                        blocks: []
                    },
                    status: NodeStatus.Unpublished
                };
                this.isSaving = true;
                const res = await handleRequest<NodeDetail>(
                    this.apiService?.knowledge.postNode(
                        this.$store.getters[`${this.orgId}/authToken`],
                        this.knowledgebase.id!,
                        body
                    ),
                    this.orgId
                );
                this.isSaving = false;

                if (res.data) {
                    this.$router.push({
                        name: 'node_editor',
                        params: {
                            nodeId: res.data.id
                        },
                        query: {
                            nodeType: this.type,
                            notification: 'created successfully',
                            tab: this.isTypeResponse ? 'responses' : 'chatter'
                        }
                    });
                }
            } else {
                this.validate = [
                    {
                        message: `${this.$t(
                            this.isTypeResponse ? 'Response' : 'Chatter'
                        )} ${this.$t('should be at least')} ${
                            this.minCharacters
                        } ${this.$t('characters')}`,
                        type: VALIDATION_MESSAGE_TYPES.ERROR
                    }
                ];
            }
        },
        handleCancel() {
            this.showModal = false;
            this.handleChangeShow();
        },
        handleChangeShow() {
            this.$emit('update:showPrompt', this.showModal);
        }
    },
    data() {
        return {
            title: '',
            isSaving: false,
            showModal: this.showPrompt,
            minCharacters: MIN_RESPONSE_NAME_CHARS,
            validate: undefined as any
        };
    }
});
</script>
