<template>
    <dt-modal
        :close-button-props="{
            ariaLabel: `${$t('Close delete chatbot forever')}`
        }"
        v-model:show="showModal"
    >
        <template #header>
            <div>{{ $t('Delete chatbot forever') }}</div>
        </template>
        <template #default>
            <dt-input
                v-model="title"
                aria-required="true"
                :placeholder="`${$t('Please type the chatbot title in full')}`"
                :disabled="isDeleting"
                :messages="validate"
                @keyup.enter="handleDeleteChatbot"
            />
        </template>
        <template #footer>
            <dt-button @click="handleDeleteChatbot" kind="danger">
                {{ $t('Delete forever') }}
            </dt-button>
            <dt-button
                class="d-mr4"
                importance="clear"
                kind="muted"
                @click="handleHide"
            >
                {{ $t('Cancel') }}
            </dt-button>
        </template>
    </dt-modal>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';

import {
    DtButton,
    DtIcon,
    DtInput,
    DtModal,
    VALIDATION_MESSAGE_TYPES
} from '@dialpad/dialtone/vue3';
import type { Knowledgebase, Widget } from '@/open-api';
import { handleRequest } from '@/utils/Common';
import type { ApiService } from '@/services/Api.service';
import { WIDGET_DEFAULT_NAME } from '@/utils/Constants';

export default defineComponent({
    props: {
        showPrompt: {
            type: Boolean as PropType<boolean>
        },
        orgId: {
            type: String as PropType<string>,
            required: true
        },
        chatbot: {
            type: Object as PropType<Widget>
        }
    },
    components: {
        DtButton,
        DtIcon,
        DtInput,
        DtModal
    },
    watch: {
        showPrompt(newShow) {
            this.showModal = newShow;
        },
        showModal(newShow) {
            this.$emit('update:showPrompt', newShow);
        },
        chatbot(newChatbot) {
            this.chatbotToDelete = newChatbot;
        }
    },
    computed: {
        /* v8 ignore next 6 */
        knowledgebase(): Knowledgebase {
            return this.$store.getters[`${this.orgId}/currentKnowledgebase`];
        },
        apiService(): ApiService {
            return this.$store.getters[`${this.orgId}/apiService`];
        },
        isValid(): boolean {
            return (
                (this.title === WIDGET_DEFAULT_NAME &&
                    this.chatbotToDelete?.name === '') ||
                this.title === this.chatbotToDelete?.name
            );
        }
    },
    methods: {
        async handleDeleteChatbot() {
            this.validate = undefined;
            if (this.isValid && this.chatbotToDelete?.id) {
                this.isDeleting = true;
                const res = await handleRequest<any>(
                    this.apiService?.knowledge.deleteWidget(
                        this.$store.getters[`${this.orgId}/authToken`],
                        this.chatbotToDelete.id
                    ),
                    this.orgId
                );
                this.isDeleting = false;

                if (!res?.error) {
                    this.$emit('success');
                    this.title = '';
                }
            } else {
                this.validate = [
                    {
                        message: this.$t('Chatbot title does not match'),
                        type: VALIDATION_MESSAGE_TYPES.ERROR
                    }
                ];
            }
        },
        handleHide() {
            this.title = '';
            this.$emit('update:showPrompt', false);
            this.validate = undefined;
        }
    },
    data() {
        return {
            title: '',
            isDeleting: false,
            showModal: this.showPrompt,
            validate: undefined as any,
            chatbotToDelete: this.chatbot as Widget | undefined
        };
    }
});
</script>
