/* tslint:disable */
/* eslint-disable */
/**
 * Mind
 * Kare API v4.0
 *
 * The version of the OpenAPI document: 4.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Message types are identified using a universal resource identifier (URI)  * `meta/XXX` are meta messages used to keep the communication between the client and the server.  *  `meta/XXX` are used to drive the conversation.  *  `in/XXX` are used for sending data to the engine.  *  `out/XXX` are used to receive data from the engine.  Clients should be able to send `in` messages and receive `out` messages. 
 * @export
 * @enum {string}
 */

export const Uri = {
    MetaErr: 'meta/err',
    MetaConversation: 'meta/conversation',
    MetaBeacon: 'meta/beacon',
    InHi: 'in/hi',
    InBye: 'in/bye',
    InQuery: 'in/query',
    InClick: 'in/click',
    InMessage: 'in/message',
    InPull: 'in/pull',
    InCxAnswer: 'in/cx/answer',
    InCxFeedback: 'in/cx/feedback',
    OutCxQuestion: 'out/cx/question',
    OutText: 'out/text',
    OutResources: 'out/resources',
    OutBeacon: 'out/beacon',
    OutNotFound: 'out/not-found',
    OutBye: 'out/bye'
} as const;

export type Uri = typeof Uri[keyof typeof Uri];



