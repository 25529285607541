import {
    Schema,
    type MarkSpec,
    type DOMOutputSpec,
    Node,
    Mark
} from 'prosemirror-model';

import { tableNodes } from '@/components/rich-text-editor/tables';
/* v8 ignore next 300 */
const pDOM: DOMOutputSpec = ['p', { class: 'd-body-base d-fs-normal' }, 0],
    blockquoteDOM: DOMOutputSpec = [
        'blockquote',
        { class: 'd-mx0 d-pl8 d-bl1 d-blw1 d-bc-moderate' },
        0
    ],
    hrDOM: DOMOutputSpec = ['hr'],
    codeDOM: DOMOutputSpec = [
        'code',
        {
            class: 'd-bgc-secondary d-p4 d-bar8 d-ba d-baw1 d-bc-secondary d-fc-tertiary d-code-small d-fs-normal'
        },
        0
    ],
    codeBlockDOM: DOMOutputSpec = [
        'code',
        {
            class: 'd-bgc-secondary d-p4 d-bar8 d-ba d-baw1 d-bc-secondary d-fc-tertiary d-code-small d-fs-normal d-d-block'
        },
        0
    ],
    preDOM: DOMOutputSpec = ['pre', codeBlockDOM],
    olDOM = (node: Node): DOMOutputSpec => [
        'ol',
        {
            class: 'd-list-group d-lst-decimal',
            start: node.attrs.order == 1 ? null : node.attrs.order,
            'data-tight': node.attrs.tight ? 'true' : null
        },
        0
    ],
    ulDOM = (node: Node): DOMOutputSpec => [
        'ul',
        {
            class: 'd-list-group d-lst-circle',
            'data-tight': node.attrs.tight ? 'true' : null
        },
        0
    ],
    liDOM: DOMOutputSpec = ['li', { class: 'marker' }, 0],
    anchorDOM = (node: Mark): DOMOutputSpec => [
        'a',
        {
            ...node.attrs,
            target: '_blank',
            class: 'd-link d-td-underline d-wb-break-all'
        }
    ],
    sDOM: DOMOutputSpec = ['s', { class: 'd-body-base' }];

/// Document schema for the data model used by CommonMark.
export const schema = new Schema({
    nodes: {
        doc: {
            content: 'block+'
        },

        paragraph: {
            content: 'inline*',
            group: 'block',
            parseDOM: [{ tag: 'p' }],
            toDOM() {
                return pDOM;
            }
        },

        blockquote: {
            content: 'block+',
            group: 'block',
            parseDOM: [{ tag: 'blockquote' }],
            toDOM() {
                return blockquoteDOM;
            }
        },

        horizontal_rule: {
            group: 'block',
            parseDOM: [{ tag: 'hr' }],
            toDOM() {
                return ['div', hrDOM];
            }
        },

        heading: {
            attrs: { level: { default: 1 } },
            content: '(text | image)*',
            group: 'block',
            defining: true,
            parseDOM: [
                { tag: 'h1', attrs: { level: 1 } },
                { tag: 'h2', attrs: { level: 2 } },
                { tag: 'h3', attrs: { level: 3 } },
                { tag: 'h4', attrs: { level: 4 } },
                { tag: 'h5', attrs: { level: 5 } },
                { tag: 'h6', attrs: { level: 6 } }
            ],
            toDOM(node) {
                return ['h' + node.attrs.level, 0];
            }
        },

        code_block: {
            content: 'text*',
            marks: '',
            group: 'block',
            code: true,
            defining: true,
            parseDOM: [
                {
                    tag: 'pre',
                    preserveWhitespace: 'full'
                }
            ],
            toDOM() {
                return preDOM;
            }
        },

        ordered_list: {
            content: 'list_item+',
            group: 'block',
            attrs: { order: { default: 1 }, tight: { default: false } },
            parseDOM: [
                {
                    tag: 'ol',
                    getAttrs(dom) {
                        return {
                            order: (dom as HTMLElement).hasAttribute('start')
                                ? +(dom as HTMLElement).getAttribute('start')!
                                : 1,
                            tight: (dom as HTMLElement).hasAttribute(
                                'data-tight'
                            )
                        };
                    }
                }
            ],
            toDOM(node) {
                return olDOM(node);
            }
        },

        bullet_list: {
            content: 'list_item+',
            group: 'block',
            attrs: { tight: { default: false } },
            parseDOM: [
                {
                    tag: 'ul',
                    getAttrs: (dom) => ({
                        tight: (dom as HTMLElement).hasAttribute('data-tight')
                    })
                }
            ],
            toDOM(node) {
                return ulDOM(node);
            }
        },

        list_item: {
            content: 'paragraph block*',
            parseDOM: [{ tag: 'li' }],
            toDOM() {
                return liDOM;
            },
            defining: true
        },

        text: {
            group: 'inline'
        },

        /// An inline image (`<img>`) node. Supports `src`,
        /// `alt`, and `href` attributes. The latter two default to the empty
        /// string.
        image: {
            inline: true,
            attrs: {
                src: {},
                alt: { default: null },
                title: { default: null }
            },
            group: 'inline',
            draggable: true,
            parseDOM: [
                {
                    tag: 'img[src]',
                    getAttrs(dom: any) {
                        return {
                            src: dom.getAttribute('src'),
                            title: dom.getAttribute('title'),
                            alt: dom.getAttribute('alt')
                        };
                    }
                }
            ],
            toDOM(node) {
                return ['img', { ...node.attrs, class: 'd-wmx100p' }];
            }
        },

        hard_break: {
            inline: true,
            group: 'inline',
            selectable: false,
            parseDOM: [{ tag: 'br' }],
            toDOM() {
                return ['br'];
            }
        },
        ...tableNodes({
            tableGroup: 'block',
            cellContent: 'inline*',
            cellAttributes: {}
        })
    },

    marks: {
        em: {
            parseDOM: [
                { tag: 'i' },
                { tag: 'em' },
                { style: 'font-style=italic' },
                {
                    style: 'font-style=normal',
                    clearMark: (m) => m.type.name == 'em'
                }
            ],
            toDOM() {
                return ['em'];
            }
        },

        strong: {
            parseDOM: [
                { tag: 'strong' },
                {
                    tag: 'b',
                    getAttrs: (node: HTMLElement) =>
                        node.style.fontWeight != 'normal' && null
                },
                {
                    style: 'font-weight=400',
                    clearMark: (m) => m.type.name == 'strong'
                },
                {
                    style: 'font-weight',
                    getAttrs: (value: string) =>
                        /^(bold(er)?|[5-9]\d{2,})$/.test(value) && null
                }
            ],
            toDOM() {
                return ['strong'];
            }
        } as MarkSpec,

        link: {
            attrs: {
                href: {},
                title: { default: null }
            },
            inclusive: false,
            parseDOM: [
                {
                    tag: 'a[href]',
                    getAttrs(dom) {
                        return {
                            href: (dom as HTMLElement).getAttribute('href'),
                            title: (dom as HTMLElement).getAttribute('title')
                        };
                    }
                }
            ],
            toDOM(node) {
                return anchorDOM(node);
            }
        },

        code: {
            parseDOM: [{ tag: 'code' }],
            toDOM() {
                return codeDOM;
            }
        },

        // A strikethrough mark. Rendered as an `<s>` element. Has parse rules
        s: {
            parseDOM: [
                { tag: 's' },
                { style: 'text-decoration=line-through' },
                {
                    style: 'text-decoration=none',
                    clearMark: (m) => m.type.name == 's'
                }
            ],
            toDOM() {
                return sDOM;
            }
        } as MarkSpec
    }
});
