<template>
    <div
        class="d-bb d-mb8 d-t d-td300"
        :class="{ 'd-bc-subtle': !isHover, 'd-bc-transparent': isHover }"
    >
        <dt-card
            class="d-w100p h:d-bgc-primary d-c-pointer d-t d-td300 d-bar8"
            content-class="d-p8 d-d-flex d-ai-center"
            container-class="d-bs-none d-fc-secondary d-bgc-transparent d-ba d-bc-transparent h:d-bc-subtle"
        >
            <template #content>
                <div class="d-d-flex d-ai-center d-w100p d-jc-space-between">
                    <div class="d-d-flex d-fd-column d-truncate">
                        <div>
                            {{ title }}
                        </div>
                        <div class="d-d-flex">
                            <label-tag :labels="labelsArray" />
                        </div>
                    </div>
                    <dt-icon
                        name="chevron-right"
                        size="500"
                        class="d-o50 d-t d-td300 d-ml16"
                        :class="{ 'd-o100': isHover }"
                    />
                </div>
            </template>
        </dt-card>
    </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';

import { DtIcon, DtCard } from '@dialpad/dialtone/vue3';
import LabelTag from '@/components/label-tag/LabelTag.vue';
import type { Knowledgebase } from '@/open-api';

export default defineComponent({
    props: {
        title: {
            type: String as PropType<string>,
            required: true
        },
        labels: {
            type: Array as PropType<string[]>
        },
        isHover: {
            type: Boolean as PropType<boolean>
        }
    },
    components: {
        LabelTag,
        DtIcon,
        DtCard
    },
    computed: {
        authToken(): string {
            return this.$store.getters[`${this.orgId}/authToken`];
        },
        knowledgebase(): Knowledgebase {
            return this.$store.getters[`${this.orgId}/currentKnowledgebase`];
        },
        labelsArray(): string[] {
            const documentLabels = this.labels;
            const remainingLabels = documentLabels?.length
                ? documentLabels?.length - 3
                : 0;
            const labels = documentLabels?.slice(0, 3);

            if (remainingLabels > 0 && labels?.length) {
                labels.push(`+${remainingLabels}`);
            }
            return labels;
        }
    }
});
</script>
