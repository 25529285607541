<template>
    <dt-button importance="clear" @click="handleClick" size="xs">
        {{ $t(title) }}
    </dt-button>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import { DtButton } from '@dialpad/dialtone/vue3';

export default defineComponent({
    props: {
        title: {
            type: String as PropType<string>
        },
        onClick: {
            type: Function as PropType<any>,
            required: false
        }
    },
    components: {
        DtButton
    },
    methods: {
        /* v8 ignore next 5 */
        handleClick() {
            if (this.onClick) {
                this.onClick();
            }
        }
    }
});
</script>
