<template>
    <div class="d-d-flex d-fd-column d-jc-center d-ai-center d-mt128">
        <h2>{{ $t('Connecting') }}</h2>
        <dt-icon class="loading" name="loading" size="700" />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { DtIcon } from '@dialpad/dialtone/vue3';

export default defineComponent({
    components: { DtIcon },
    mounted() {
        const queryString = window.location.search;

        const params = new URLSearchParams(queryString);
        const code = params.get('code') as string;

        window.opener?.postMessage(
            {
                typeEvent: 'gDriveEvent',
                code
            },
            window.location.origin
        );

        window.self.close();
    }
});
</script>
