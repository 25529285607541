<template>
    <div class="editor-container d-d-flex d-fd-column d-fl-grow1">
        <div
            class="d-d-flex d-w100p d-fd-column editor-inner d-bgc-primary d-bar4 d-gg2"
            ref="richTextEditorWrapper"
        >
            <div
                ref="toolbar"
                class="markdown-editor-toolbar d-bgc-secondary d-fl-grow1 d-d-flex d-ai-center d-py4 d-px8 d-box-border d-fw-wrap d-bb d-bc-subtle d-btr4 d-gg8"
                v-if="shouldShowToolbar"
            >
                <toolbar
                    :editor="editorView"
                    :items="toolbarItems"
                    :update-toolbar="updateToolbar"
                    :schema="mySchema"
                    v-model:is-savable="isSavable"
                />
            </div>
            <div
                ref="editor"
                class="d-bgc-primary d-fl-grow1 d-d-flex d-fd-column d-h100p markdown-editor"
                :class="{
                    'd-btr4': !shouldShowToolbar,
                    'd-bbr4': !shouldShowFooter,
                    'd-hmn64': editable
                }"
            ></div>
            <div
                ref="footer"
                class="markdown-editor-footer d-bgc-primary d-h48 d-fl-grow1 d-bbr4 d-p8 d-d-flex d-ai-center d-jc-flex-end d-box-border d-gg8"
                v-if="shouldShowFooter"
            >
                <div
                    class="d-pl8 d-mr-auto d-label--sm d-d-flex d-ai-center d-fc-warning"
                    :class="{
                        'd-fc-critical': currentLength === maxlength
                    }"
                    v-if="currentLength >= maxlength - 100"
                >
                    <dt-icon name="alert-triangle" size="100" class="d-mr4" />
                    {{
                        currentLength === maxlength
                            ? $t('At the limit')
                            : $t('Near the limit')
                    }}
                </div>
                <dt-button
                    importance="clear"
                    size="sm"
                    :disabled="!isSavable"
                    @click="handleCancelEdit"
                >
                    {{ $t('Cancel') }}
                </dt-button>
                <dt-button size="sm" :disabled="!isSavable" @click="handleSave">
                    {{ $t('Save') }}
                </dt-button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, inject, type PropType, toRaw } from 'vue';

import { EditorState } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { Schema } from 'prosemirror-model';
import { baseKeymap } from 'prosemirror-commands';
import { dropCursor } from 'prosemirror-dropcursor';
import { gapCursor } from 'prosemirror-gapcursor';
import { keymap } from 'prosemirror-keymap';
import { history } from 'prosemirror-history';

import { schema } from './schema';
import { DtButton, DtIcon } from '@dialpad/dialtone/vue3';
import Toolbar, {
    toolbarItem
} from '@/components/rich-text-editor/Toolbar.vue';
import { buildKeymap } from './keymap';
import { menu } from './utils';
import { Subject } from 'rxjs';
import { buildInputRules } from '@/components/rich-text-editor/input-rules';
import { characterCount } from './utils';
import {
    markdownParser,
    markdownSerializer
} from '@/components/rich-text-editor/markdown';
import type { ResponseBlockEditor } from '@/components/node-editor/responseBlockEditor';
import type { Block } from '@/components/node-editor/responseBlockEditor';
import type { Knowledgebase } from '@/open-api';
import type { ApiService } from '@/services/Api.service';
import { TEXT_BLOCK_LIMIT } from '@/utils/Constants';

export default defineComponent({
    props: {
        markdown: {
            type: String as PropType<string>,
            default: ''
        },
        editable: {
            type: Boolean as PropType<boolean>,
            required: true
        },
        block: {
            type: Object as PropType<Block>
        }
    },
    components: { Toolbar, DtButton, DtIcon },
    updated() {
        if (this.editable) {
            this.editorView?.focus();
        }
    },
    mounted() {
        /* v8 ignore next 22 */
        const editor = this.$refs.editor as HTMLElement;
        if (editor) {
            this.editorView = new EditorView(editor, {
                editable: () => this.isEditable,
                state: EditorState.create({
                    schema,
                    doc: markdownParser.parse(this.content) || undefined,
                    plugins: [
                        buildInputRules(this.mySchema),
                        menu(this.updateToolbar as any),
                        history(),
                        keymap(buildKeymap(toRaw(this.mySchema))),
                        keymap(baseKeymap),
                        dropCursor(),
                        gapCursor(),
                        characterCount(this.handleCharCountUpdate)
                    ]
                }),
                attributes: {
                    class: 'd-bgc-primary d-fl-grow1 d-d-flex d-fd-column d-h100p d-py8 d-px12 d-mx4'
                },
                transformPastedHTML(html) {
                    return html.replace(/<img.*?>/g, '');
                }
            });
        }
    },
    watch: {
        isEditable(editable) {
            this.editorView?.setProps({
                editable: () => !!editable
            });
        }
    },
    setup() {
        const orgId: string = inject('orgId')!;
        return {
            orgId
        };
    },
    computed: {
        /* v8 ignore next 35 */
        toolbarItems(): string[] {
            return [
                toolbarItem.Bold,
                toolbarItem.Italic,
                toolbarItem.Strikethrough,
                toolbarItem.Link,
                toolbarItem.BulletList,
                toolbarItem.NumberList,
                toolbarItem.CodeBlock
                //toolbarItem.Code
            ];
        },
        content: {
            get(): string {
                return this.markdown;
            },
            set(content: string) {
                this.$emit('update:markdown', content);
            }
        },
        isEditable: {
            get(): boolean {
                return !!this.editable;
            },
            set(editable: boolean) {
                this.$emit('update:editable', editable);
            }
        },
        shouldShowToolbar(): boolean {
            return !!this.editorView && this.editable;
        },
        shouldShowFooter(): boolean {
            return this.editable;
        },
        responseBlockEditor(): ResponseBlockEditor | undefined {
            return this.$store.getters[`${this.orgId}/responseBlockEditor`];
        },
        authToken(): string {
            return this.$store.getters[`${this.orgId}/authToken`];
        },
        apiService(): ApiService {
            return this.$store.getters[`${this.orgId}/apiService`];
        },
        knowledgebase(): Knowledgebase {
            return this.$store.getters[`${this.orgId}/currentKnowledgebase`];
        }
    },
    methods: {
        handleSave(e: Event) {
            e.stopPropagation();
            if (this.editorView) {
                this.content = markdownSerializer.serialize(
                    this.editorView.state.doc
                );
                this.isEditable = false;
                this.$emit('save');
            }
        },
        handleCancelEdit(e: Event) {
            e.stopPropagation();
            if (
                this.block?.new &&
                this.editorView &&
                !this.block?.data?.dirty
            ) {
                this.handleRemove();
            } else {
                const previousText = this.block?.data?.text_block?.text;
                const { state } = this.editorView;
                const tr = state.tr;
                tr.replaceWith(0, state.doc.content.size, null);
                tr.insertText(previousText);
                const newState = state.apply(tr);
                this.editorView.updateState(newState);
                this.isEditable = false;
            }
        },
        handleRemove() {
            if (this.block) {
                this.responseBlockEditor?.removeBlock(this.block);
            }
        },
        handleCharCountUpdate(characterCount: number) {
            this.currentLength = characterCount;
        }
    },
    data() {
        return {
            editorView: undefined as EditorView | undefined,
            updateToolbar: new Subject<any>(),
            isSavable: true,
            mySchema: new Schema({
                nodes: schema.spec.nodes,
                marks: schema.spec.marks
            }),
            maxlength: TEXT_BLOCK_LIMIT,
            currentLength: 0
        };
    }
});
</script>

<style lang="less">
.markdown-editor {
    .d-list-group {
        padding: 0 0 0 var(--dt-size-400) !important;
    }
    .d-list-group.d-lst-circle {
        margin-left: 10px !important;
        li {
            list-style: disc;
        }
    }

    .d-list-group.d-lst-decimal {
        margin-left: 10px !important;
        li {
            list-style: auto;
        }
    }
}

blockquote {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
}
</style>
