<template>
    <dt-popover
        v-bind="$attrs"
        :append-to="appendTo"
        :modal="false"
        :placement="placement"
        padding="none"
        :max-height="maxHeight"
        v-model:open="open"
        :offset="[moveX, moveY]"
        content-width="anchor"
        class="filter-popover"
        :class="{
            'filter-popover-open': setOpenClass
        }"
        :dialog-class="dialogStyle"
        initial-focus-element="none"
        :aria-label="ariaLabel"
    >
        <template #anchor="{ attrs }">
            <dt-button
                v-bind="attrs"
                icon-position="right"
                :kind="hasFiltersSelected ? 'inverted' : 'muted'"
                :importance="hasFiltersSelected ? 'primary' : 'clear'"
                class="filter-popover-button d-bar8 d-td0 d-ba d-baw d-bc-default d-w100p"
                :class="{
                    'd-tt-capitalize': capitalize,
                    open,
                    'has-filters-selected': hasFiltersSelected,
                    'is-purple': !isNormalSelectStyle,
                    'd-wmn128': isLoading,
                    'd-bc-critical': showInvalidOption
                }"
                :style="{
                    borderWidth: '0.15rem !important'
                }"
                :size="sizeStyle"
                label-class="d-jc-flex-start"
                @click="toggleOpen"
                :disabled="isDisabled"
            >
                <template
                    v-if="
                        isLoading &&
                        (!isNormalSelectStyle || id === currentDropdown)
                    "
                >
                    <div class="d-w100p">
                        <dt-skeleton
                            arial-label="Loading"
                            :paragraphOption="{
                                rows: 1,
                                width: '100%'
                            }"
                        />
                    </div>
                </template>
                <template v-else>
                    <span class="d-fw-normal">{{ filterTitle }}</span>
                </template>

                <template #icon>
                    <dt-icon
                        name="chevron-down"
                        class="d-ml8"
                        v-if="!hasFiltersSelected || hideCheckboxes"
                        :size="sizeIcon"
                    />
                    <dt-icon
                        name="close"
                        class="d-ml8"
                        v-if="
                            !hideCheckboxes && hasFiltersSelected && !isDisabled
                        "
                        @click="clearFilters"
                        :size="sizeIcon"
                    />
                </template>
            </dt-button>
        </template>
        <template #content="{}">
            <div ref="content">
                <dt-list-item
                    v-if="useDefault"
                    key="default"
                    navigation-type="tab"
                    role="menuitem"
                    :selected="selectedOptions.includes('default')"
                    @click="hideCheckboxes && handleClick('default')"
                >
                    <div class="d-px4 d-py4">
                        <span v-if="hideCheckboxes" aria-label="default-span">
                            {{ $t('Use default') }}
                        </span>
                    </div>
                </dt-list-item>
                <div
                    v-if="withSearch"
                    class="d-popover__header d-ba d-baw0 d-fw-normal d-pt8 d-pb6 d-px12 d-bt d-btw1 d-bc-subtle"
                >
                    <div class="d-w100p">
                        <dt-input
                            v-model="search"
                            :placeholder="$t(searchPlaceholder)"
                            :icon-size="sizeStyle"
                            :size="sizeStyle"
                            type="text"
                        >
                            <template #leftIcon>
                                <dt-icon
                                    v-if="!search"
                                    name="search"
                                    class="d-fc-black-900"
                                    :size="sizeIcon"
                                />
                                <dt-icon
                                    v-else
                                    name="close"
                                    class="d-fc-black-900 d-c-pointer"
                                    @click="handleClearInput"
                                    :size="sizeIcon"
                                />
                            </template>
                        </dt-input>
                    </div>
                </div>
                <dt-list-item
                    v-for="option in filteredOptions"
                    :key="option.value"
                    navigation-type="tab"
                    role="menuitem"
                    :selected="
                        hideCheckboxes && selectedOptions.includes(option.value)
                    "
                    @click="hideCheckboxes && handleClick(option.value)"
                >
                    <div class="d-px4">
                        <dt-checkbox
                            v-if="!hideCheckboxes"
                            :disabled="isLoading"
                            :aria-label="`${option.value}-checkbox`"
                            :checked="selectedOptions.includes(option.value)"
                            :value="option.value"
                            :label="option.label"
                            @input="(e: any) => onChecked(option.value, !!e)"
                        />
                        <span
                            v-if="hideCheckboxes"
                            :aria-label="`${option.value}-span`"
                        >
                            {{
                                capitalize
                                    ? capitalizeString(option.label)
                                    : translateItem
                                      ? $t(option.label)
                                      : option.label
                            }}
                        </span>
                    </div>
                </dt-list-item>
                <template v-if="isEmpty">
                    <dt-list-item
                        navigation-type="tab"
                        role="menuitem"
                        :class="{
                            'd-tt-capitalize': capitalize
                        }"
                    >
                        <div class="d-px8">
                            <span class="d-d-inline-block d-w100p">
                                {{ $t(`No ${title} found`) }}
                            </span>
                        </div>
                    </dt-list-item>
                </template>
            </div>
        </template>
    </dt-popover>
</template>

<script lang="ts">
import { defineComponent, type PropType, reactive } from 'vue';
import {
    DtButton,
    DtCheckbox,
    DtInput,
    DtIcon,
    DtDropdown,
    DtListItem,
    DtPopover
} from '@dialpad/dialtone/vue3';
import { capitalizeString, type IFilter } from '@/utils/Common';
import { DtSkeleton } from '@dialpad/dialtone/vue3';

export default defineComponent({
    props: {
        id: {
            required: true,
            type: String as PropType<string>
        },
        options: {
            required: true,
            type: Array as PropType<IFilter[]>
        },
        selectedOptions: {
            required: true,
            type: Array as PropType<string[]>
        },
        title: {
            type: String as PropType<string>
        },
        totalHits: {
            type: Number as PropType<number>
        },
        fetch: {
            type: Function as PropType<any>,
            required: true
        },
        typeSelection: {
            type: String as PropType<'checkbox' | 'radio'>,
            default: 'checkbox'
        },
        withSearch: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        isFetching: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        closeOnSelect: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        capitalize: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        isDisabled: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        hideCheckboxes: {
            type: Boolean as PropType<boolean>
        },
        appendTo: {
            type: String as PropType<string>,
            default: 'parent'
        },
        moveX: {
            type: Number as PropType<number>,
            default: 0
        },
        moveY: {
            type: Number as PropType<number>,
            default: 2
        },
        dialogStyle: {
            type: String as PropType<string>,
            default: 'd-w264'
        },
        isNormalSelectStyle: {
            type: Boolean as PropType<boolean>
        },
        useDefault: {
            type: Boolean as PropType<boolean>
        },
        sizeStyle: {
            type: String as PropType<string>,
            default: 'sm'
        },
        sizeIcon: {
            type: String as PropType<string>,
            default: '300'
        },
        ariaLabel: {
            type: String as PropType<string>
        },
        searchPlaceholder: {
            type: String as PropType<string>,
            default: 'Search labels'
        },
        showInvalidOption: {
            type: Boolean as PropType<boolean>
        },
        translateItem: {
            type: Boolean as PropType<boolean>
        },
        placement: {
            type: String as PropType<string>,
            default: 'bottom-start'
        },
        rawSelectedOption: {
            type: Object as PropType<any>
        },
        maxHeight: {
            type: String as PropType<string>,
            default: '264px'
        }
    },
    components: {
        DtSkeleton,
        DtButton,
        DtCheckbox,
        DtInput,
        DtIcon,
        DtDropdown,
        DtListItem,
        DtPopover
    },
    setup(props) {
        const selectedCheckboxes = reactive(props.selectedOptions);
        return {
            selectedCheckboxes
        };
    },
    watch: {
        selectedCheckboxes: {
            handler(newCheckboxes) {
                this.$emit('update:selectedOptions', [...newCheckboxes]);
            },
            deep: true
        },
        isFetching(newFetching) {
            this.isLoading = newFetching;

            if (!newFetching) {
                this.currentDropdown = undefined;
            }
        },
        open(newOpen, oldOpen) {
            if (newOpen !== oldOpen) {
                if (!newOpen) {
                    this.handleClose();
                }
            }
        }
    },
    computed: {
        isEmpty(): boolean {
            return !this.filteredOptions.length;
        },
        hasFiltersSelected(): boolean {
            return !!this.selectedCheckboxes.length;
        },
        filteredOptions(): IFilter[] {
            if (this.search) {
                return this.options.filter((filter: IFilter) => {
                    return (
                        filter.label
                            .toLowerCase()
                            .includes(this.search.toLowerCase()) ||
                        filter.label
                            .toLowerCase()
                            .startsWith(this.search.toLowerCase())
                    );
                });
            }
            return this.options;
        },
        filterTitle(): string {
            if (!this.isNormalSelectStyle) {
                const numberOfSelectedOptions = this.selectedCheckboxes.length;

                const totalHits =
                    this.totalHits !== undefined ? ` (${this.totalHits})` : '';

                const itemsSelected =
                    this.selectedCheckboxes.length > 1 &&
                    this.typeSelection === 'checkbox'
                        ? ` • ${this.selectedCheckboxes.length}`
                        : '';

                switch (numberOfSelectedOptions) {
                    case 0:
                        return `${this.$t(
                            'All ' + this.title || ''
                        )} ${totalHits}${itemsSelected}`;
                    case 1:
                        return `${
                            this.options.find(
                                (option: any) =>
                                    option.value === this.selectedCheckboxes[0]
                            )?.label
                        }${totalHits}${itemsSelected}`;
                    default:
                        return `${capitalizeString(
                            this.$t(this.title || 'Filter')
                        )}${totalHits}${itemsSelected}`;
                }
            } else {
                if (this.selectedOptions?.[0] === 'default') {
                    return this.$t('Use default');
                }
                if (this.selectedOptions?.[0] === '') {
                    return this.$t('Please select one');
                }

                const findLabel = this.options.find(
                    (option: any) => option.value === this.selectedCheckboxes[0]
                )?.label as string;

                return findLabel
                    ? this.translateItem
                        ? this.$t(findLabel)
                        : findLabel
                    : this.rawSelectedOption?.title || this.selectedOptions[0];
            }
        }
    },
    methods: {
        capitalizeString,
        async onChecked(val: string, checked: boolean) {
            const indexOfSelectedFilter = this.selectedCheckboxes.indexOf(val);

            // Add filter if not present in selected filters
            if (indexOfSelectedFilter === -1 && checked) {
                if (this.typeSelection === 'radio') {
                    this.selectedCheckboxes.length = 0;
                    this.selectedCheckboxes.push(val);
                } else this.selectedCheckboxes.push(val);
            }
            // If is not checked and exists in selected filters, remove it
            else if (!checked && indexOfSelectedFilter !== -1) {
                this.selectedCheckboxes.splice(indexOfSelectedFilter, 1);
            }

            if (this.fetch) this.fetch(this.selectedCheckboxes);
            if (this.closeOnSelect) this.handleClose();
        },
        clearFilters(e: Event) {
            e.stopPropagation();
            this.selectedCheckboxes.length = 0;
            this.fetch();
        },
        toggleOpen() {
            if (this.open) {
                this.handleClose();
            } else {
                this.handleOpen();
            }
        },
        handleOpen() {
            this.open = true;
            this.currentDropdown = this.id;

            this.$nextTick(() => {
                this.popoverWidth = (this.$refs.content as HTMLDivElement)
                    ?.clientWidth
                    ? `${(this.$refs.content as HTMLDivElement)?.clientWidth}px`
                    : undefined;
                this.setOpenClass = true;
            });
        },
        handleClose() {
            this.open = false;
            this.popoverWidth = undefined;
            this.setOpenClass = false;
            this.search = '';
        },
        handleClick(option: any) {
            if (option !== this.selectedCheckboxes[0]) {
                this.selectedCheckboxes.length = 0;
                this.selectedCheckboxes.push(option);
                this.fetch(option);
            }
            if (this.closeOnSelect) this.handleClose();
        },
        handleClearInput() {
            this.search = '';
        }
    },
    data() {
        return {
            open: false,
            search: '',
            setOpenClass: false,
            popoverWidth: undefined as string | undefined,
            isLoading: this.isFetching,
            currentDropdown: undefined as any
        };
    }
});
</script>
<style lang="less" scoped>
.filter-popover-open [data-qa='dt-popover-anchor'] {
    position: relative !important;
    .tippy-box {
        z-index: 300 !important;
        width: v-bind(popoverWidth);
    }
}

.base-button__icon.d-btn__icon.d-btn__icon--right {
    svg {
        margin-left: 0 !important;
        color: var(--dt-color-foreground-tertiary);
        &:hover {
            color: var(--dt-color-foreground-primary);
        }
    }
}

.filter-popover {
    .filter-popover-button {
        background-color: var(--dt-inputs-color-background-default) !important;
        border-color: var(--dt-inputs-color-border-default) !important;
        color: var(--dt-color-foreground-primary) !important;
    }
    .filter-popover-button.has-filters-selected {
        &.is-purple {
            --bco: 100%;
            border: var(--dt-size-100) solid
                hsla(
                    var(--dt-color-purple-300-h) var(--dt-color-purple-300-s)
                        var(--dt-color-purple-300-l) / var(--bco)
                ) !important;
            --bgo: 100%;
            background-color: hsla(
                var(--dt-color-purple-100-h) var(--dt-color-purple-100-s)
                    var(--dt-color-purple-100-l) / var(--bgo)
            ) !important;
        }
        &.open,
        &:hover {
            color: var(--dt-color-foreground-primary) !important;
        }
        &.is-purple:hover {
            background-color: hsla(
                var(--dt-color-purple-200-h) var(--dt-color-purple-200-s)
                    var(--dt-color-purple-200-l) / 50%
            ) !important;
        }
    }
}

.dt-list-item--focusable {
    &:not(:disabled):hover {
        cursor: pointer;
        background-color: var(
            --dt-action-color-background-muted-hover
        ) !important;
        color: var(--dt-action-color-foreground-muted-hover) !important;
    }
}
</style>
