<template>
    <svg
        width="102"
        height="20"
        viewBox="0 0 102 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_325_75057)">
            <path
                d="M67.63 16.74C68.2912 16.7557 68.949 16.64 69.5653 16.3997C70.1815 16.1594 70.7439 15.7992 71.22 15.34L73 17.27C72.3094 17.9823 71.4753 18.5397 70.5529 18.9052C69.6306 19.2707 68.6411 19.4359 67.65 19.39C63.3 19.39 60.49 16.49 60.49 12.57C60.4697 11.673 60.6306 10.7812 60.963 9.94792C61.2954 9.1146 61.7925 8.35691 62.4246 7.72019C63.0566 7.08347 63.8106 6.5808 64.6415 6.24227C65.4723 5.90374 66.3629 5.7363 67.26 5.74998C71.58 5.74998 74.03 9.04998 73.81 13.63H63.62C63.7615 14.564 64.2567 15.4078 65.0032 15.9867C65.7497 16.5656 66.6902 16.8353 67.63 16.74ZM70.74 11.45C70.6935 10.5841 70.305 9.7721 69.6599 9.19263C69.0148 8.61316 68.1659 8.31365 67.3 8.35998C66.4085 8.30765 65.5306 8.59706 64.8451 9.16929C64.1595 9.74152 63.7178 10.5535 63.61 11.44L70.74 11.45Z"
                fill="#03363D"
            />
            <path
                d="M0 16.6L7.86 8.60001H0.18V6.07001H11.5V8.63001L3.64 16.63H11.64V19.15H0V16.6Z"
                fill="#03363D"
            />
            <path
                d="M21.0001 16.74C21.6614 16.7557 22.3191 16.64 22.9354 16.3997C23.5516 16.1594 24.1141 15.7992 24.5901 15.34L26.3701 17.27C25.6795 17.9823 24.8454 18.5397 23.923 18.9052C23.0007 19.2707 22.0112 19.4359 21.0201 19.39C16.6701 19.39 13.8601 16.49 13.8601 12.57C13.8398 11.673 14.0007 10.7812 14.3331 9.94792C14.6655 9.1146 15.1626 8.35691 15.7947 7.72019C16.4267 7.08347 17.1807 6.5808 18.0116 6.24227C18.8424 5.90374 19.733 5.7363 20.6301 5.74998C24.9501 5.74998 27.4001 9.04998 27.1801 13.63H17.0001C17.1416 14.5621 17.6354 15.4043 18.3796 15.983C19.1239 16.5617 20.0619 16.8326 21.0001 16.74ZM24.1101 11.45C24.0611 10.5859 23.6714 9.77642 23.0266 9.19908C22.3818 8.62174 21.5344 8.3236 20.6701 8.36998C19.7835 8.32267 18.9119 8.61309 18.2309 9.18275C17.55 9.75241 17.1101 10.559 17.0001 11.44L24.1101 11.45Z"
                fill="#03363D"
            />
            <path
                d="M44.1801 12.57C44.1422 11.6875 44.2837 10.8065 44.5962 9.98031C44.9086 9.15411 45.3854 8.39988 45.9977 7.76326C46.61 7.12664 47.3451 6.62086 48.1585 6.27653C48.972 5.9322 49.8468 5.75648 50.7301 5.76C51.5575 5.75388 52.3761 5.92987 53.1278 6.27551C53.8796 6.62115 54.5461 7.12796 55.0801 7.76V0H57.8601V19.12H55.0801V17.32C54.5541 17.9713 53.888 18.4956 53.1314 18.8539C52.3748 19.2123 51.5472 19.3955 50.7101 19.39C49.83 19.3825 48.9604 19.1989 48.1525 18.8498C47.3446 18.5008 46.6148 17.9935 46.0061 17.3578C45.3975 16.7221 44.9223 15.9709 44.6087 15.1486C44.2951 14.3263 44.1493 13.4495 44.1801 12.57ZM55.2301 12.57C55.2301 11.472 54.7939 10.419 54.0175 9.64258C53.2411 8.86618 52.1881 8.43 51.0901 8.43C49.9921 8.43 48.9391 8.86618 48.1627 9.64258C47.3863 10.419 46.9501 11.472 46.9501 12.57C46.9501 13.668 47.3863 14.721 48.1627 15.4974C48.9391 16.2738 49.9921 16.71 51.0901 16.71C52.1881 16.71 53.2411 16.2738 54.0175 15.4974C54.7939 14.721 55.2301 13.668 55.2301 12.57Z"
                fill="#03363D"
            />
            <path
                d="M75.55 16.35L78.06 15C78.3914 15.5934 78.8787 16.0849 79.4691 16.4215C80.0595 16.7581 80.7306 16.927 81.41 16.91C82.98 16.91 83.79 16.1 83.79 15.18C83.79 14.26 82.27 13.9 80.62 13.56C78.39 13.09 76.09 12.36 76.09 9.66001C76.09 7.59001 78.09 5.66001 81.15 5.66001C82.1722 5.59849 83.1931 5.7996 84.1156 6.2442C85.0381 6.68881 85.8314 7.3621 86.42 8.20001L84.09 9.56001C83.7688 9.08286 83.3311 8.69558 82.8184 8.43484C82.3056 8.1741 81.7349 8.04853 81.16 8.07001C79.67 8.07001 78.91 8.80001 78.91 9.64001C78.91 10.48 80.11 10.84 82 11.26C84.15 11.73 86.58 12.44 86.58 15.16C86.58 16.97 85.01 19.4 81.29 19.38C80.1454 19.464 79.0002 19.2244 77.9853 18.6886C76.9703 18.1529 76.1264 17.3425 75.55 16.35Z"
                fill="#03363D"
            />
            <path
                d="M93.8101 13.31L91.6101 15.72V19.13H88.8301V0H91.6101V12.52L97.5001 6H100.88L95.7701 11.61L101 19.12H97.8701L93.8101 13.31Z"
                fill="#03363D"
            />
            <path
                d="M35.9201 5.76001C32.6201 5.76001 29.8601 7.91001 29.8601 11.47V19.12H32.6901V11.82C32.6404 11.3678 32.6909 10.9102 32.8379 10.4797C32.985 10.0492 33.225 9.65627 33.5409 9.32891C33.8568 9.00155 34.2409 8.74773 34.6659 8.58547C35.0909 8.4232 35.5464 8.35647 36.0001 8.39001C38.1001 8.39001 39.1401 9.68001 39.1401 11.82V19.12H42.0001V11.47C42.0001 7.91001 39.2201 5.76001 35.9201 5.76001Z"
                fill="#03363D"
            />
        </g>
        <defs>
            <clipPath id="clip0_325_75057">
                <rect width="101.01" height="19.38" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
