<template>
    <div
        class="d-w100p d-h48 d-bgc-info-subtle d-bt d-btw1 d-bc-moderate d-d-flex d-ai-center d-jc-space-between d-px12"
    >
        <div class="d-d-flex d-ai-center d-fs-300">
            <dt-icon class="d-c-pointer" name="info" size="500" />
            <span class="d-ml12">This page is displaying mocked data.</span>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { DtIcon } from '@dialpad/dialtone/vue3';

export default defineComponent({
    components: {
        DtIcon
    }
});
</script>
