<template>
    <dt-popover
        v-bind="$attrs"
        append-to="body"
        :modal="false"
        :placement="placement"
        padding="none"
        max-height="464px"
        v-model:open="open"
        :offset="[moveX, moveY]"
        content-width="anchor"
        class="search-nodes"
        :class="{
            'search-nodes-open': setOpenClass
        }"
        :dialog-class="dialogStyle"
        initial-focus-element="none"
        :aria-label="ariaLabel"
    >
        <template #anchor="{ attrs }">
            <dt-button
                v-bind="attrs"
                icon-position="right"
                kind="muted"
                importance="clear"
                class="search-nodes-button d-bar8 d-td0 d-ba d-baw d-bc-default d-w100p"
                :class="{
                    'd-tt-capitalize': capitalize,
                    open,
                    'has-filters-selected': hasFiltersSelected,
                    'd-wmn128': isLoading,
                    'd-bc-critical': showInvalidOption
                }"
                :style="{
                    borderWidth: '0.15rem !important'
                }"
                size="xs"
                label-class="d-jc-flex-start"
                @click="toggleOpen"
                :disabled="isDisabled"
            >
                <template
                    v-if="
                        isLoading &&
                        (!isNormalSelectStyle || id === currentDropdown)
                    "
                >
                    <div class="d-w100p">
                        <dt-skeleton
                            arial-label="Loading"
                            :paragraphOption="{
                                rows: 1,
                                width: '100%'
                            }"
                        />
                    </div>
                </template>
                <template v-else>
                    <span class="d-fw-normal">{{ filterTitle }}</span>
                </template>

                <template #icon>
                    <dt-icon
                        name="chevron-down"
                        class="d-ml8"
                        :size="sizeIcon"
                    />
                </template>
            </dt-button>
        </template>
        <template #content="{}">
            <div ref="content">
                <dt-list-item
                    key="default"
                    navigation-type="tab"
                    role="menuitem"
                    :selected="selectedOptions.includes('default')"
                    @click="hideCheckboxes && handleClick('default')"
                >
                    <div class="d-px4 d-py4">
                        <span v-if="hideCheckboxes" aria-label="default-span">
                            {{ $t('Use default') }}
                        </span>
                    </div>
                </dt-list-item>
                <div
                    class="d-popover__header d-ba d-baw0 d-fw-normal d-pt8 d-pb6 d-px12 d-bt d-btw1 d-bc-subtle"
                >
                    <div class="d-w100p">
                        <dt-input
                            v-model="search"
                            :placeholder="$t('Search responses')"
                            icon-size="xs"
                            size="xs"
                            type="text"
                            @input="debounce(fetchResponses)"
                        >
                            <template #leftIcon>
                                <dt-icon
                                    name="search"
                                    class="d-fc-black-900"
                                    :size="sizeIcon"
                                />
                            </template>
                        </dt-input>
                    </div>
                </div>
                <template v-if="!isEmpty && !isFetchingNodes">
                    <dt-list-item
                        v-for="option in options"
                        :key="option.value"
                        navigation-type="tab"
                        role="menuitem"
                        :selected="
                            hideCheckboxes &&
                            selectedOptions.includes(option.value)
                        "
                        @click="hideCheckboxes && handleClick(option.value)"
                    >
                        <div class="d-px4">
                            <span :aria-label="`${option.value}-span`">
                                {{
                                    capitalize
                                        ? capitalizeString(option.label)
                                        : translateItem
                                          ? $t(option.label)
                                          : option.label
                                }}
                            </span>
                        </div>
                    </dt-list-item>
                </template>
                <template v-if="isEmpty && !isFetchingNodes">
                    <dt-list-item
                        navigation-type="tab"
                        role="menuitem"
                        :class="{
                            'd-tt-capitalize': capitalize
                        }"
                    >
                        <div class="d-px8">
                            <span class="d-d-inline-block d-w100p">
                                {{ $t('No response found') }}
                            </span>
                        </div>
                    </dt-list-item>
                </template>
                <template v-if="isFetchingNodes">
                    <dt-list-item
                        navigation-type="tab"
                        role="menuitem"
                        :class="{
                            'd-tt-capitalize': capitalize
                        }"
                    >
                        <div class="d-px8">
                            <dt-skeleton
                                arial-label="Loading"
                                :paragraphOption="{
                                    rows: 1,
                                    width: '100%'
                                }"
                            />
                        </div>
                    </dt-list-item>
                </template>
            </div>
        </template>
    </dt-popover>
</template>

<script lang="ts">
import { defineComponent, inject, type PropType, reactive } from 'vue';
import {
    DtButton,
    DtInput,
    DtIcon,
    DtDropdown,
    DtListItem,
    DtPopover
} from '@dialpad/dialtone/vue3';
import {
    capitalizeString,
    createDebounce,
    handleRequest,
    type IFilter
} from '@/utils/Common';
import { DtSkeleton } from '@dialpad/dialtone/vue3';
import {
    type Knowledgebase,
    type ListNodesResponse,
    type NodeDetail,
    NodeStatus
} from '@/open-api';
import type { ApiService } from '@/services/Api.service';
import { NODE_TYPES } from '@/utils/types/Response';

export default defineComponent({
    props: {
        id: {
            required: true,
            type: String as PropType<string>
        },
        responses: {
            required: true,
            type: Array as PropType<NodeDetail[] | undefined>
        },
        selectedOptions: {
            required: true,
            type: Array as PropType<string[]>
        },
        title: {
            type: String as PropType<string>
        },
        totalHits: {
            type: Number as PropType<number>
        },
        fetch: {
            type: Function as PropType<any>,
            required: true
        },
        typeSelection: {
            type: String as PropType<'checkbox' | 'radio'>,
            default: 'checkbox'
        },
        isFetching: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        closeOnSelect: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        capitalize: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        isDisabled: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        hideCheckboxes: {
            type: Boolean as PropType<boolean>
        },
        moveX: {
            type: Number as PropType<number>,
            default: 0
        },
        moveY: {
            type: Number as PropType<number>,
            default: 2
        },
        dialogStyle: {
            type: String as PropType<string>,
            default: 'd-w264'
        },
        isNormalSelectStyle: {
            type: Boolean as PropType<boolean>
        },
        sizeIcon: {
            type: String as PropType<string>,
            default: '300'
        },
        ariaLabel: {
            type: String as PropType<string>
        },
        showInvalidOption: {
            type: Boolean as PropType<boolean>
        },
        translateItem: {
            type: Boolean as PropType<boolean>
        },
        placement: {
            type: String as PropType<string>,
            default: 'bottom-start'
        }
    },
    components: {
        DtSkeleton,
        DtButton,
        DtInput,
        DtIcon,
        DtDropdown,
        DtListItem,
        DtPopover
    },
    setup(props) {
        const selectedCheckboxes = reactive(props.selectedOptions);
        const orgId: string = inject('orgId')!;

        return {
            debounce: createDebounce(),
            selectedCheckboxes,
            orgId
        };
    },
    mounted() {
        this.getDefaultOptions();
    },
    watch: {
        /* v8 ignore next 15 */
        selectedCheckboxes: {
            handler(newCheckboxes) {
                this.$emit('update:selectedOptions', [...newCheckboxes]);
            },
            deep: true
        },
        isFetching(newFetching) {
            this.isLoading = newFetching;

            if (!newFetching) {
                this.currentDropdown = undefined;
            }
        }
    },
    computed: {
        /* v8 ignore next 30 */
        currentKnowledgebase(): Knowledgebase {
            return this.$store.getters[`${this.orgId}/currentKnowledgebase`];
        },
        authToken(): string {
            return this.$store.getters[`${this.orgId}/authToken`];
        },
        apiService(): ApiService {
            return this.$store.getters[`${this.orgId}/apiService`];
        },
        isEmpty(): boolean {
            return !this.options.length;
        },
        hasFiltersSelected(): boolean {
            return !!this.selectedCheckboxes.length;
        },
        filterTitle(): string {
            if (this.selectedOptions?.[0] === 'default') {
                return this.$t('Use default');
            }
            if (this.selectedOptions?.[0] === '') {
                return this.$t('Please select one');
            }

            const findLabel = this.options.find(
                (option: any) => option.value === this.selectedCheckboxes[0]
            )?.label as string;

            return findLabel
                ? this.translateItem
                    ? this.$t(findLabel)
                    : findLabel
                : this.selectedOptions[0];
        }
    },
    methods: {
        /* v8 ignore next 100 */
        capitalizeString,
        async onChecked(val: string, checked: boolean) {
            const indexOfSelectedFilter = this.selectedCheckboxes.indexOf(val);

            // Add filter if not present in selected filters
            if (indexOfSelectedFilter === -1 && checked) {
                if (this.typeSelection === 'radio') {
                    this.selectedCheckboxes.length = 0;
                    this.selectedCheckboxes.push(val);
                } else this.selectedCheckboxes.push(val);
            }
            // If is not checked and exists in selected filters, remove it
            else if (!checked && indexOfSelectedFilter !== -1) {
                this.selectedCheckboxes.splice(indexOfSelectedFilter, 1);
            }

            if (this.fetch) this.fetch(this.selectedCheckboxes);
            if (this.closeOnSelect) this.handleClose();
        },
        toggleOpen() {
            if (this.open) {
                this.handleClose();
            } else {
                this.handleOpen();
            }
        },
        handleOpen() {
            this.open = true;
            this.currentDropdown = this.id;

            this.$nextTick(() => {
                this.popoverWidth = (this.$refs.content as HTMLDivElement)
                    ?.clientWidth
                    ? `${(this.$refs.content as HTMLDivElement)?.clientWidth}px`
                    : undefined;
                this.setOpenClass = true;
            });
        },
        handleClose() {
            this.open = false;
            this.popoverWidth = undefined;
            this.setOpenClass = false;
            this.search = '';
        },
        handleClick(option: any) {
            if (option !== this.selectedCheckboxes[0]) {
                this.selectedCheckboxes.length = 0;
                this.selectedCheckboxes.push(option);
                this.fetch(option);
            }
            if (this.closeOnSelect) this.handleClose();
        },
        async fetchResponses() {
            this.isFetchingNodes = true;
            if (this.search) {
                const res = await handleRequest<ListNodesResponse>(
                    this.apiService?.knowledge.listNodes(
                        this.authToken,
                        this.currentKnowledgebase?.id!,
                        NODE_TYPES.RESPONSE,
                        NodeStatus.Published,
                        undefined,
                        this.search ?? undefined
                    ),
                    this.orgId
                );

                if (res?.data) {
                    const newResponses = res.data.nodes;
                    this.options =
                        newResponses?.map((response) => ({
                            label: response.title || '',
                            value: response.id || ''
                        })) || [];
                }
            } else {
                this.getDefaultOptions();
            }
            this.isFetchingNodes = false;
        },
        getDefaultOptions() {
            this.options =
                this.responses?.map((response) => ({
                    label: response.title || '',
                    value: response.id || ''
                })) || [];
        }
    },
    data() {
        return {
            open: false,
            search: '',
            setOpenClass: false,
            popoverWidth: undefined as string | undefined,
            isLoading: this.isFetching,
            currentDropdown: undefined as any,
            isFetchingNodes: false,
            options: [] as IFilter[]
        };
    }
});
</script>
<style lang="less" scoped>
.search-nodes-open [data-qa='dt-popover-anchor'] {
    position: relative !important;
    .tippy-box {
        width: v-bind(popoverWidth);
    }
}

.base-button__icon.d-btn__icon.d-btn__icon--right {
    svg {
        margin-left: 0 !important;
        color: var(--dt-color-foreground-tertiary);
        &:hover {
            color: var(--dt-color-foreground-primary);
        }
    }
}

.search-nodes {
    .search-nodes-button {
        background-color: var(--dt-inputs-color-background-default) !important;
        border-color: var(--dt-inputs-color-border-default) !important;
        color: var(--dt-color-foreground-primary) !important;
    }
    .search-nodes-button.has-filters-selected {
        &.open,
        &:hover {
            color: var(--dt-color-foreground-primary) !important;
        }
    }
}

.dt-list-item--focusable {
    &:not(:disabled):hover {
        cursor: pointer;
        background-color: var(
            --dt-action-color-background-muted-hover
        ) !important;
        color: var(--dt-action-color-foreground-muted-hover) !important;
    }
}
</style>
