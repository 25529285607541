<template>
    <dt-card
        class="d-w100p"
        header-class="d-px16 d-py4 d-bgc-secondary d-bb d-bc-subtle d-btr4"
        content-class="d-px16 d-py8"
        :container-class="{
            'd-bs-sm': editable,
            'd-bs-none h:d-bs-sm': !editable
        }"
    >
        <template #header v-if="editable">
            <span
                class="d-fs-100 d-fw-bold d-h16 d-py4 d-my2 d-d-flex d-ai-center"
            >
                {{ $t('Add image') }}
            </span>
        </template>
        <template #content>
            <!-- --------------------------------------------------------------- -->
            <!--                            IMAGE BLOCK                          -->
            <!-- --------------------------------------------------------------- -->
            <div
                v-if="block?.data?.image_block?.url && !editable"
                class="d-d-flex d-jc-center d-fd-column d-ps-relative"
            >
                <div class="d-d-flex d-ai-center d-w100p d-jc-center">
                    <img
                        :draggable="!editable"
                        class="d-wmx100p d-w100p d-hmx100p"
                        :src="block?.data.image_block.url"
                        :alt="imageCaption"
                        aria-label="ariaLabel"
                        @load="onLoad"
                    />
                </div>
                <div
                    class="d-d-flex d-w100p d-fc-tertiary d-fs-100"
                    v-if="block?.data?.file"
                >
                    <p class="d-w90p d-mr8 d-ps-relative">
                        {{ tempImageName }}
                    </p>
                    <p class="d-ml-auto">
                        {{ tempImageSize }}
                    </p>
                </div>
                <p class="d-fs-100 d-mt4">
                    {{ imageCaption || caption }}
                </p>
            </div>
            <!-- --------------------------------------------------------------- -->
            <!--                             ADD IMAGE                           -->
            <!-- --------------------------------------------------------------- -->
            <div class="d-fs-100 d-w100p" v-else>
                <dt-tab-group
                    class="d-box-border"
                    size="sm"
                    @change="onTabClick"
                >
                    <template #tabs>
                        <dt-tab
                            v-for="uploadTabState in ['Upload', 'Embed']"
                            :selected="isTabActive(uploadTabState)"
                            :id="uploadTabState"
                            :panel-id="`${uploadTabState}-panel`"
                            :key="`${uploadTabState}-panel`"
                            tab-class="d-px12"
                        >
                            {{ uploadTabState }}
                        </dt-tab>
                    </template>
                    <dt-tab-panel id="Upload-panel" tab-id="Upload">
                        <div
                            class="d-ps-relative d-mt12 d-mb8 d-bar8"
                            :class="{ 'd-ba d-baw1 d-bc-subtle': hasImage }"
                        >
                            <drop-file
                                dropzone-classes="d-h100p d-hmn264"
                                v-if="!hasImage"
                                file-type-accepted="IMAGE"
                                hide-description
                                :upload-text="$t('images or drag and drop')"
                                upload-subtext="JPEG, PNG, GIF (max 5mb)"
                                v-model:dropzone-files="files"
                                v-model:selected-doc-labels="selectedTags"
                                :is-uploading="isUploading"
                            />
                            <div
                                class="d-d-flex d-ai-center d-w100p d-jc-center"
                            >
                                <img
                                    class="d-wmx100p d-w100p d-hmx100p d-mb8 d-btr4"
                                    v-if="hasImage"
                                    alt="tempImg"
                                    :src="imageUrl || createUrl(tempImage)"
                                />
                            </div>
                            <div
                                class="d-d-flex d-jc-space-between d-ai-center d-mb8 d-px12"
                                v-if="hasImage"
                            >
                                <div
                                    class="d-d-flex d-fd-column"
                                    v-if="tempImageName || tempImageSize"
                                >
                                    <p class="d-fs-100">
                                        {{ tempImageName }}
                                    </p>
                                    <p class="d-fs-100 d-fc-tertiary">
                                        {{ tempImageSize }}
                                    </p>
                                </div>
                                <dt-button
                                    class="d-mr8 d-ml-auto"
                                    size="xs"
                                    importance="clear"
                                    kind="danger"
                                    @click="handleReplaceImage"
                                >
                                    Remove
                                </dt-button>
                            </div>
                        </div>
                        <dt-input
                            size="xs"
                            v-model="imageCaption"
                            v-if="hasImage"
                        >
                            <template #labelSlot>
                                <div class="d-mb4 d-fw-semibold">
                                    {{ $t('Caption') }}
                                </div>
                            </template>
                        </dt-input>
                        <div class="d-d-flex d-mt8">
                            <dt-button
                                class="d-ml-auto d-mr8"
                                importance="clear"
                                size="xs"
                                @click="handleCancel"
                            >
                                {{ $t('Cancel') }}
                            </dt-button>
                            <dt-button
                                size="xs"
                                @click="handleAddImage"
                                :disabled="!hasImage"
                            >
                                {{ $t('Add') }}
                            </dt-button>
                        </div>
                    </dt-tab-panel>
                    <dt-tab-panel id="Embed-panel" tab-id="Embed">
                        <div class="d-mt12 d-mb4 d-d-flex d-fd-column">
                            <div
                                class="d-d-flex d-ai-center d-w100p d-jc-center"
                            >
                                <dt-image-viewer
                                    v-if="embedUrl && isImage"
                                    :image-button-class="
                                        isImage
                                            ? 'd-wmx100p d-w100p d-hmx100p d-mb8'
                                            : 'd-d-none'
                                    "
                                    :image-src="isImage ? embedUrl : ''"
                                    image-alt="embed url image"
                                    aria-label="embed image"
                                    close-aria-label="close embed image"
                                />
                            </div>
                            <dt-input
                                v-model="embedUrl"
                                :placeholder="$t('Paste your URL')"
                                :label="$t('Embed')"
                                :description="
                                    $t(
                                        'Image should be publicly-available. JPEG, PNG, GIF (max 5 mb)'
                                    )
                                "
                                size="xs"
                            />

                            <div class="d-my12">
                                <dt-input
                                    v-model="caption"
                                    v-if="isImage"
                                    :label="$t('Caption')"
                                    :placeholder="$t('Type your caption')"
                                    size="xs"
                                />
                            </div>
                            <div class="d-d-flex d-mt-auto">
                                <dt-button
                                    class="d-ml-auto d-mr8"
                                    importance="clear"
                                    size="xs"
                                    @click="handleCancel"
                                >
                                    {{ $t('Cancel') }}
                                </dt-button>
                                <dt-button
                                    size="xs"
                                    :disabled="!embedUrl && !caption"
                                    @click="handleAddImage"
                                >
                                    {{ $t('Add') }}
                                </dt-button>
                            </div>
                        </div>
                    </dt-tab-panel>
                </dt-tab-group>
            </div>
        </template>
    </dt-card>
</template>
<script lang="ts">
import { defineComponent, type PropType, inject } from 'vue';
import DropFile from '@/components/upload-drawer/DropFile.vue';
import {
    DtButton,
    DtTab,
    DtTabPanel,
    DtTabGroup,
    DtInput,
    DtCard,
    DtIcon,
    DtImageViewer
} from '@dialpad/dialtone/vue3';
import type { IUploadedFile } from '@/components/upload-drawer/UploadFile.types';
import type {
    Block,
    ResponseBlockEditor
} from '@/components/node-editor/responseBlockEditor';
import { checkIfImageExists } from '@/utils/Common';
import { DEFAULT_IMAGE_BLOCK_TAB } from '@/utils/Constants';

export default defineComponent({
    props: {
        block: {
            type: Object as PropType<Block>,
            required: true
        },
        editable: {
            type: Boolean as PropType<boolean>,
            required: true
        }
    },
    components: {
        DtButton,
        DtTab,
        DtTabPanel,
        DtTabGroup,
        DtInput,
        DtCard,
        DtIcon,
        DtImageViewer,
        DropFile
    },
    setup() {
        const orgId: string = inject('orgId')!;
        return {
            orgId
        };
    },
    computed: {
        /* v8 ignore next 36 */
        isEditable: {
            get(): boolean {
                return this.editable;
            },
            set(editable: boolean) {
                this.$emit('edit', editable);
            }
        },
        hasImage(): boolean {
            return !!this.files?.length || !!this.imageUrl;
        },
        tempImage: {
            get(): { file: File } | undefined {
                return this.files[0];
            },
            set(newTemp: { file: File }) {
                this.files[0] = newTemp;
            }
        },
        tempImageName(): string | undefined {
            return this.tempImage?.file?.name || this.block.data?.file?.name;
        },
        tempImageSize(): string {
            const imageSize =
                this.tempImage?.file?.size || this.block.data?.file?.size;
            if (!imageSize) {
                return '';
            }

            const size = parseFloat((imageSize / 1024 ** 2).toFixed(3));
            return size < 1 ? `${size * 1000}kb` : `${size}mb`;
        },
        responseBlockEditor(): ResponseBlockEditor | undefined {
            return this.$store.getters[`${this.orgId}/responseBlockEditor`];
        }
    },
    methods: {
        /* v8 ignore next 60 */
        handleReplaceImage() {
            this.imageUrl = '';
            this.imageCaption = '';
            this.files = [];
        },
        handleRemove() {
            this.responseBlockEditor?.removeBlock(this.block);
        },
        isTabActive(tabState: any): boolean {
            return tabState === this.activeUploadTab;
        },
        isUploadTab(): boolean {
            return this.activeUploadTab === 'Upload';
        },
        onTabClick(newTab: any) {
            this.activeUploadTab = newTab.selected.replace('-panel', '');
        },
        createUrl(file: any) {
            return URL.createObjectURL(file.file);
        },
        onLoad() {
            this.$emit('load');
        },
        handleAddImage(e: Event) {
            e.stopPropagation();
            if (this.tempImage?.file) {
                this.block.setFile(this.tempImage.file);
            }
            this.block?.setBlockData({
                text: this.isUploadTab() ? this.imageCaption : this.caption,
                url: this.isUploadTab()
                    ? this.imageUrl || this.createUrl(this.tempImage)
                    : this.embedUrl
            });
            this.resetData();
        },
        resetData() {
            this.files = [];
            this.isEditable = false;
            this.activeUploadTab = DEFAULT_IMAGE_BLOCK_TAB;
            this.imageUrl = this.block?.data?.image_block?.url || '';
        },
        handleCancel(e: Event) {
            e.stopPropagation();
            // If block has never been added then it will remove it.
            // If was added, even if it was temporary, it won't be removed.
            if (this.block.new && !this.block.data.dirty) {
                this.handleRemove();
            } else {
                this.resetData();
            }
        },
        isImage(): boolean {
            return checkIfImageExists(this.embedUrl, (exists: any) => exists);
        }
    },
    emits: ['edit', 'load'],
    data() {
        return {
            showAddImage: null as number | null,
            imageCaption: this.block?.data?.image_block?.text || '',
            imageUrl: this.block?.data?.image_block?.url || '',
            activeUploadTab: DEFAULT_IMAGE_BLOCK_TAB,
            isUploading: false,
            selectedTags: [] as string[],
            files: [] as IUploadedFile[] | any[],
            embedUrl: '',
            caption: ''
        };
    }
});
</script>
