<template>
    <dt-empty-state
        :header-text="$t('Access denied')"
        illustration-name="mind"
        body-text="&nbsp;"
    />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { DtEmptyState } from '@dialpad/dialtone/vue3';

export default defineComponent({
    components: { DtEmptyState }
});
</script>
