import { createStore, Store } from 'vuex';
import { toRaw } from 'vue';
import type { Organisation } from '@/open-api';

export interface StoreState {
    orgId?: string;
    lastCreatedKBM?: Organisation;
    featureFlags?: {
        [key: string]: boolean;
    };
}

export const FORCE_FECTH_INTERVAL_SECONDS = 4;
export const DEBOUNCE_INTERVAL_SECONDS = 2;

export const defaultState: StoreState = {};

export function getDefaultState() {
    return defaultState;
}

export function resetState(store: Store<StoreState>) {
    const state: any = store.state;
    const newState: { [x: string]: any } = {};
    const defaultState: any = getDefaultState();
    Object.keys(state).forEach((key: string) => {
        const isArray = toRaw(state[key]) instanceof Array;
        if (Object.keys(defaultState).includes(key)) {
            if (isArray) state[key].length = 0;
            newState[key] = defaultState[key];
        } else if (isArray) {
            state[key].length = 0;
            newState[key] = []; // or = initialState[key]
        } else {
            newState[key] = null; // or = initialState[key]
        }
    });

    store.replaceState(newState as StoreState);
}

export default createStore<StoreState>({
    mutations: {
        setLastCreatedKBM(state, kbm: Organisation) {
            state.lastCreatedKBM = kbm;
        },
        setOrgId(state, orgId: string) {
            state.orgId = orgId;
        },
        setFeatureFlags(state, flags: { [key: string]: boolean }) {
            state.featureFlags = flags;
        }
    },
    getters: {
        lastCreatedKBM(state) {
            return state.lastCreatedKBM;
        },
        orgId(state) {
            return state.orgId;
        },
        featureFlags(state) {
            return state.featureFlags;
        }
    }
});
