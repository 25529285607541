import { Subject } from 'rxjs';
import type { Organisation } from '@/open-api';

class UserCallbackService {
    private readonly $OnKBMReady: Subject<any> = new Subject<any>();
    OnKBMReady(cb: any) {
        this.$OnKBMReady.subscribe((org: Organisation | undefined) => cb(org));
    }
    TriggerOnKBMReady(org: Organisation | undefined) {
        this.$OnKBMReady.next(org);
    }
}

export const UserCallbackServiceInstance = new UserCallbackService();
