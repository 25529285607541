import { createDebounce } from '@/utils/Common';

export const onResizeDirective = {
    beforeMount: function (el: any, binding: any) {
        const debounce = createDebounce();
        const onResizeCallback = binding.value;
        window.addEventListener('resize', () => {
            const width = document.documentElement.clientWidth;
            const height = document.documentElement.clientHeight;
            debounce(onResizeCallback({ width, height }));
        });
    },
    unmounted: (el: any) => {
        document.removeEventListener('resize', el);
    }
};
