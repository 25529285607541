import { uuidv4 } from '@/utils/Common';
import type {
    ResponseNodeBlock,
    ResponseNodeBlockCallToActionBlock,
    ResponseNodeBlockChoicesBlock,
    ResponseNodeBlockVideoBlock,
    ResponseNodeBlockWebextBlock,
    ResponseNodeBlockWorkflowBlock
} from '@/open-api';
import {
    OpenTargetType,
    type ResponseNodeBlockImageBlock,
    type ResponseNodeBlockTextBlock,
    ResponseNodeBlockTypeEnum
} from '@/open-api';
import { reactive, type UnwrapNestedRefs } from 'vue';
import type { IChoice } from '@/components/node-editor/blocks/choices-block.vue';

export interface AllBlocks
    extends Partial<ResponseNodeBlockTextBlock>,
        Partial<ResponseNodeBlockImageBlock>,
        Partial<ResponseNodeBlockVideoBlock>,
        Partial<ResponseNodeBlockWebextBlock>,
        Partial<ResponseNodeBlockWorkflowBlock>,
        Partial<ResponseNodeBlockChoicesBlock> {}

export interface IResponseBlock extends ResponseNodeBlock {
    new?: boolean;
    dirty?: boolean;
    file?: File;
    id: string;
    editing: boolean;
    type: ResponseNodeBlockTypeEnum;
}

/* v8 ignore next 160 */
export class ResponseBlockEditor {
    public blocks: UnwrapNestedRefs<Block[]>;

    constructor(blocks: Block[] = []) {
        this.blocks = reactive(blocks);
    }

    addBlock(block: Block) {
        this.blocks.push(block);
    }

    findBlockIndex(blockToFind: Block): number {
        return this.blocks.findIndex((block) => block.id == blockToFind.id);
    }

    removeBlock(blockToRemove: Block) {
        const index = this.findBlockIndex(blockToRemove);
        if (index === -1) return;
        this.blocks.splice(index, 1);
    }

    moveBlock(blockToMove: Block, to: number) {
        const index = this.findBlockIndex(blockToMove);
        if (index === -1) return;
        this.blocks.splice(to, 0, this.blocks.splice(index, 1)[0]);
    }

    getBlocks() {
        return this.blocks;
    }
}

export class Block {
    private readonly rawData: ResponseNodeBlock;
    public readonly editing: boolean;
    public readonly hidden: boolean;
    public readonly new: boolean;
    public readonly unsupported: boolean;
    public readonly type: ResponseNodeBlockTypeEnum;
    public dirty: boolean;
    public data: UnwrapNestedRefs<IResponseBlock>;
    public id: string;
    public file?: File;
    constructor(
        editing = false,
        newBlock = false,
        dirty = false,
        hidden = false,
        blockData: ResponseNodeBlock,
        unsupported?: boolean
    ) {
        this.editing = editing;
        this.new = newBlock;
        this.dirty = dirty;
        this.hidden = hidden;
        this.id = uuidv4();
        this.rawData = blockData;
        this.type = blockData.type!;
        this.unsupported = unsupported || false;

        this.data = reactive({
            type: this.type,
            editing: this.editing,
            new: this.new,
            id: this.id,
            ...this.rawData
        });
    }

    getBlockData() {
        return this.data;
    }

    setEdit(editing: boolean) {
        this.data.editing = editing;
    }

    setNew(newBlock: boolean) {
        this.data.new = newBlock;
    }

    setFile(newFile: File) {
        this.data.file = newFile;
    }

    setDirty(dirty: boolean) {
        this.data.dirty = dirty;
    }

    setData(data: Partial<IResponseBlock>) {
        this.data = { ...this.data, ...data };
    }

    setBlockData(data: Partial<AllBlocks>) {
        this.data = {
            ...this.data,
            [`${this.data.type}_block` as keyof ResponseNodeBlock]: {
                ...(this.data[
                    this.data.type as keyof ResponseNodeBlock
                ] as ResponseNodeBlock),
                ...data
            }
        };

        if (JSON.stringify(this.data) !== JSON.stringify(this.rawData)) {
            this.setDirty(true);
        }
    }

    resetData() {
        this.data = reactive({
            type: this.type,
            editing: this.editing,
            new: this.new,
            id: this.id,
            dirty: false,
            ...this.rawData
        });
    }
}

export const EMPTY_TEXT_BLOCK: ResponseNodeBlock = {
    type: ResponseNodeBlockTypeEnum.Text,
    [`${ResponseNodeBlockTypeEnum.Text}_block`]: {
        text: ''
    } as ResponseNodeBlockTextBlock
};

export const EMPTY_IMAGE_BLOCK: ResponseNodeBlock = {
    type: ResponseNodeBlockTypeEnum.Image,
    [`${ResponseNodeBlockTypeEnum.Image}_block`]: {
        text: '',
        url: ''
    } as ResponseNodeBlockImageBlock
};

export const EMPTY_VIDEO_BLOCK: ResponseNodeBlock = {
    type: ResponseNodeBlockTypeEnum.Video,
    [`${ResponseNodeBlockTypeEnum.Video}_block`]: {
        text: '',
        url: ''
    } as ResponseNodeBlockImageBlock
};

export const EMPTY_WEBEXT_BLOCK: ResponseNodeBlock = {
    type: ResponseNodeBlockTypeEnum.Webext,
    [`${ResponseNodeBlockTypeEnum.Webext}_block`]: {
        call_to_action: '',
        target: OpenTargetType.Dialog,
        text: '',
        url: ''
    } as ResponseNodeBlockWebextBlock
};

export const EMPTY_CTA_BLOCK: ResponseNodeBlock = {
    type: ResponseNodeBlockTypeEnum.CallToAction,
    [`${ResponseNodeBlockTypeEnum.CallToAction}_block`]: {
        call_to_action: '',
        text: '',
        url: ''
    } as ResponseNodeBlockCallToActionBlock
};

export const EMPTY_WORKFLOW_BLOCK: ResponseNodeBlock = {
    type: ResponseNodeBlockTypeEnum.Workflow,
    [`${ResponseNodeBlockTypeEnum.Workflow}_block`]: {
        url: ''
    } as ResponseNodeBlockWorkflowBlock
};
export const EMPTY_CHOICE = (): IChoice =>
    ({
        uid: uuidv4(),
        node: '',
        text: '',
        title: ''
    }) as IChoice;

export const EMPTY_CHOICES_BLOCK = (): ResponseNodeBlock => ({
    type: ResponseNodeBlockTypeEnum.Choices,
    [`${ResponseNodeBlockTypeEnum.Choices}_block`]: {
        choices: [EMPTY_CHOICE()],
        text: ''
    } as ResponseNodeBlockChoicesBlock
});
