<template>
    <div
        :class="{
            'd-fl-grow1 d-h100p d-d-flex d-fd-column':
                isFetching || (tableData?.length && !hasError)
        }"
        class="d-ps-relative"
    >
        <div
            class="d-d-flex d-fd-column d-pt12 d-pb8 d-px16 d-t0 d-ps-sticky d-l0 d-zi-popover d-bgc-primary"
            ref="title"
        >
            <div class="d-d-flex d-ai-center d-t d-td300">
                <div class="d-d-flex d-fw-wrap d-w100p d-fd-column">
                    <div
                        class="d-headline-medium d-pr16 d-d-flex d-ai-center d-h24 d-mb2 d-gg16"
                    >
                        {{ title }}
                        <slot
                            v-if="$slots.selectedItemsActions"
                            name="selectedItemsActions"
                        />
                        <import-status v-if="hasImportStatus" />
                    </div>
                    <div
                        class="d-d-flex d-ai-center d-t d-td300 d-pt2 d-zi-navigation-fixed d-body-small"
                        v-if="hasDescription"
                    >
                        <p class="d-pr4">{{ titleDescription }}</p>
                        <dt-link
                            v-if="hasDescriptionLink"
                            :href="descriptionLink?.href"
                        >
                            {{ descriptionLink.title }}
                        </dt-link>
                    </div>
                </div>
                <div class="d-d-flex d-fl-grow1 d-fl-shrink0">
                    <slot v-if="$slots.actionButtons" name="actionButtons" />
                </div>
            </div>
        </div>
        <div
            class="d-d-flex d-fd-column d-px16 d-box-border d-fl-grow1 d-fl-shrink1 d-hmx100p"
        >
            <div
                class="d-d-flex d-ai-center d-fw-wrap d-pb4 d-ps-sticky d-l0 d-zi-dropdown d-bgc-primary"
                :style="`top: ${titleHeight}px`"
                ref="searchFiltersBar"
                v-if="showSearchFilterToolbar"
            >
                <div class="d-mr8 d-mb8" v-if="!hideSearch">
                    <dt-input
                        class="d-w332 d-fs-100"
                        v-model="search"
                        :placeholder="searchPlaceholder"
                        size="lg"
                        @input="debounce(onSearchInputChange)"
                    >
                        <template #leftIcon>
                            <dt-icon
                                v-if="!search"
                                name="search"
                                size="200"
                                class="d-fc-black-900"
                            />
                            <dt-icon
                                v-else
                                name="close"
                                size="200"
                                class="d-fc-black-900 d-c-pointer"
                                @click="handleClearInput"
                            />
                        </template>
                    </dt-input>
                </div>
                <div class="d-d-flex d-mb8">
                    <slot v-if="$slots.filters" name="filters" />
                </div>
            </div>
            <div
                class="d-d-flex d-fd-column d-w100p d-mb24"
                :class="{
                    ['d-fl-grow1']: shouldOverflowTable
                }"
                v-if="
                    isFetching ||
                    (tableData?.length && !hasError) ||
                    $slots.table
                "
            >
                <slot
                    v-if="$slots.table"
                    name="table"
                    :title-height="titleHeight"
                    :search-filters-bar-height="searchFiltersBarHeight"
                />
                <base-table
                    v-else
                    :is-fetching="isFetching"
                    :id="tableId"
                    :columns="columns"
                    :data="tableData"
                    :has-error="hasError"
                    v-model:selected-rows="selectedItemsNew"
                    @row-click="handleRowClick"
                    :active-row-id="activeRowId"
                    :highlight-updated-rows="highlightTableRows"
                    v-model:active-page="activePage"
                    v-model:hovered-row-id="hoveredRowId"
                    :total-hits="totalItems"
                    :row-count="itemsPerPage"
                    :item-type="itemType"
                    :has-active-filters="hasFilters"
                    v-model:sort="sortBy"
                    :hide-checkbox="hideCheckbox"
                    :fixed-header="tableFixedHeader"
                    :render-options="renderOptions"
                    :title-height="titleHeight"
                    :search-filters-bar-height="searchFiltersBarHeight"
                    :id-key="idKey"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, inject, type PropType } from 'vue';

import { NodeStatus, type Knowledgebase, NodeType } from '@/open-api';
import { convertLocaleToLanguage, createDebounce } from '@/utils/Common';
import ImportStatus from '@/components/import-status/ImportStatus.vue';

import {
    DtTabGroup,
    DtTab,
    DtTabPanel,
    DtButton,
    DtInput,
    DtIcon,
    DtBadge,
    DtLink
} from '@dialpad/dialtone/vue3';
import BaseTable from '@/components/base-table/BaseTable.vue';

import type {
    IBaseTableRow,
    IBaseTableColumn,
    IBaseSortableTableColumn
} from '@/components/base-table/BaseTable.types';
import { DEFAULT_ITEMS_PER_PAGE } from '@/utils/Constants';

export default defineComponent({
    components: {
        DtTabGroup,
        DtTab,
        DtTabPanel,
        DtButton,
        DtInput,
        DtIcon,
        DtBadge,
        DtLink,
        BaseTable,
        ImportStatus
    },
    props: {
        title: {
            type: String as PropType<string>,
            default: 'Title'
        },
        totalItems: {
            type: Number as PropType<number>,
            default: 0
        },
        searchPlaceholder: {
            type: String as PropType<string>,
            default: 'Search'
        },
        isFetching: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        hasLoaded: {
            type: Boolean as PropType<boolean>,
            default: true
        },
        tableId: {
            type: String as PropType<string>,
            default: 'table'
        },
        tableFixedHeader: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        columns: {
            type: Array as PropType<IBaseTableColumn<any>[]>,
            default() {
                return [];
            }
        },
        renderOptions: {
            type: Function as PropType<any>
        },
        tableData: {
            type: Array as PropType<any[]>,
            default() {
                return [];
            }
        },
        sort: {
            type: Object as PropType<IBaseSortableTableColumn>,
            default: () => ({ sort: 'title', asc: true })
        },
        hasFilters: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        highlightTableRows: {
            type: Array as PropType<string[]>
        },
        onSelectedItems: {
            type: Array as PropType<IBaseTableRow[]>,
            default: () => []
        },
        itemsPerPage: {
            type: Number as PropType<number>,
            default: DEFAULT_ITEMS_PER_PAGE
        },
        hasError: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        hideCheckbox: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        itemType: {
            type: String as PropType<
                | NodeType
                | 'chatbot'
                | 'automation'
                | 'workflow'
                | 'queries'
                | 'questions'
                | 'items'
                | 'messages'
            >,
            default: 'items'
        },
        hideSearch: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        activeRowId: {
            type: String as PropType<string>
        },
        titleDescription: {
            type: String as PropType<string>
        },
        descriptionLink: {
            type: Object as PropType<{ href: string; title: string }>
        },
        hasImportStatus: {
            type: Boolean as PropType<boolean>
        },
        onPageChange: {
            type: Number as PropType<number>
        },
        onHoverChange: {
            type: String as PropType<any>
        },
        // Use this as the row identifier
        idKey: {
            type: String as PropType<string>
        }
    },
    setup(props, { slots }) {
        const orgId: string = inject('orgId')!;
        const hasSlot = (name: string) => !!slots[name];
        return {
            debounce: createDebounce(),
            orgId,
            hasSlot
        };
    },
    mounted() {
        const titleRef = this.$refs.title;
        if (titleRef) {
            this.titleHeight = titleRef.clientHeight;
        }

        const searchFiltersBarRef = this.$refs.searchFiltersBar;
        if (searchFiltersBarRef) {
            this.searchFiltersBarHeight = searchFiltersBarRef.clientHeight;
        }
    },
    watch: {
        activePage(newPage, oldPage) {
            if (newPage !== oldPage) {
                this.$emit('update:onPageChange', newPage);
            }
        },
        hoveredRowId(newRow, oldRow) {
            if (newRow !== oldRow) {
                this.$emit('update:onHoverChange', newRow);
            }
        },
        onPageChange(newPage, oldPage) {
            if (newPage !== oldPage) {
                this.activePage = newPage;
            }
        },
        sortBy: {
            handler(newSort) {
                if (newSort) {
                    this.$emit('update:sort', newSort);
                }
            },
            deep: true
        }
    },
    methods: {
        convertLocaleToLanguage,
        handleClearInput() {
            this.search = '';
            this.activePage = 1;
            this.onSearchInputChange();
        },
        onSearchInputChange() {
            this.activePage = 1;
            this.$emit('update:search', this.search);
        },
        handleRowClick(rowData: any) {
            this.$emit('tableRowClick', rowData);
        }
    },
    computed: {
        knowledgebase(): Knowledgebase {
            return this.$store.getters[`${this.orgId}/currentKnowledgebase`];
        },
        selectedItemsNew: {
            get(): any {
                return this.onSelectedItems;
            },
            set(newSelected: any) {
                this.$emit('update:onSelectedItems', newSelected);
            }
        },
        showSearchFilterToolbar(): boolean {
            return !!(this.$slots.filters || !this.hideSearch);
        },
        hasDescription(): boolean {
            return !!this.titleDescription?.length;
        },
        hasDescriptionLink(): boolean {
            return (
                this.descriptionLink &&
                !!Object.keys(this.descriptionLink)?.length
            );
        },
        shouldOverflowTable(): boolean {
            return this.tableData?.length >= 15;
        }
    },
    data() {
        return {
            search: '',
            searchFiltersBarHeight: 0,
            titleHeight: 0,
            nodeStatus: NodeStatus,
            activePage: 1,
            hoveredRowId: undefined as any,
            sortBy: this.sort as IBaseSortableTableColumn
        };
    }
});
</script>
