<script lang="ts">
import { defineComponent, type PropType, type VNode } from 'vue';

export default defineComponent({
    props: {
        template: {
            required: true,
            type: null as unknown as PropType<VNode | null>
        }
    },
    render() {
        if (this.template) {
            return this.template;
        }
        return null;
    }
});
</script>
