/* tslint:disable */
/* eslint-disable */
/**
 * Mind
 * Kare API v4.0
 *
 * The version of the OpenAPI document: 4.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { InitResponse } from '../model';
// @ts-ignore
import { ModelError } from '../model';
// @ts-ignore
import { PostInMessageRequest } from '../model';
// @ts-ignore
import { PostInMessageResponse } from '../model';
// @ts-ignore
import { StartConversationResponse } from '../model';
// @ts-ignore
import { StoreMessageRequest } from '../model';
// @ts-ignore
import { TokenRequest } from '../model';
// @ts-ignore
import { TokenResponse } from '../model';
/**
 * DialogApi - axios parameter creator
 * @export
 */
export const DialogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Initialize a client given a valid app id. This is the same as calling: * /auth/app-token * /settings/client-config/public * /dialog/conversation: 
         * @summary Initialize a client
         * @param {string} appId Application ID
         * @param {string} [authorization] Authorization Bearer header
         * @param {string} [userId] The user ID, if known.
         * @param {string} [kareContentLocale] Content locale selector. It can be null as the server will assign one.  
         * @param {number} [kareDnt] \&#39;Disable message tracking. See https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/DNT\&#39; 
         * @param {string} [userAgent] \&#39;The user agent\&#39; 
         * @param {string} [acceptLanguage] \&#39;The browser accepted languages\&#39; 
         * @param {string} [kareMetaReferral] \&#39;The page from which the user is interacting\&#39; 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        init: async (appId: string, authorization?: string, userId?: string, kareContentLocale?: string, kareDnt?: number, userAgent?: string, acceptLanguage?: string, kareMetaReferral?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('init', 'appId', appId)
            const localVarPath = `/dialog/init`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (appId !== undefined) {
                localVarQueryParameter['app_id'] = appId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            if (kareContentLocale != null) {
                localVarHeaderParameter['Kare-Content-Locale'] = String(kareContentLocale);
            }

            if (kareDnt != null) {
                localVarHeaderParameter['kare-dnt'] = typeof kareDnt === 'string' 
                    ? kareDnt 
                    : JSON.stringify(kareDnt);
            }

            if (userAgent != null) {
                localVarHeaderParameter['User-Agent'] = String(userAgent);
            }

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }

            if (kareMetaReferral != null) {
                localVarHeaderParameter['kare-meta-referral'] = String(kareMetaReferral);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Syncronous interface for posting messages to the dialog on behalf of a user. Post a message to the dialog engine then syncrounously retrieve all the outbound messages that is has generated. Clients should then continue the conversaton by posting click events as the user interacts with the messages. This endoint is designed for those clients which cannot use a websocket. If they can clients should consider using the websocket which instead offers an asyncronous interface. 
         * @summary Post IN message
         * @param {string} authorization Authorization Bearer header
         * @param {PostInMessageRequest} body The query to be sent to the dialog engine.
         * @param {string} [kareContentLocale] Content locale selector. It cannot be omitted as the server should  have already suggested one from start conversation. 
         * @param {number} [kareDnt] \&#39;Disable message tracking. See https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/DNT\&#39; 
         * @param {string} [userAgent] \&#39;The user agent\&#39; 
         * @param {string} [acceptLanguage] \&#39;The browser accepted languages\&#39; 
         * @param {string} [kareMetaReferral] \&#39;The page from which the user is interacting\&#39; 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInMessage: async (authorization: string, body: PostInMessageRequest, kareContentLocale?: string, kareDnt?: number, userAgent?: string, acceptLanguage?: string, kareMetaReferral?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('postInMessage', 'authorization', authorization)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('postInMessage', 'body', body)
            const localVarPath = `/dialog/in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerBase required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            if (kareContentLocale != null) {
                localVarHeaderParameter['Kare-Content-Locale'] = String(kareContentLocale);
            }

            if (kareDnt != null) {
                localVarHeaderParameter['kare-dnt'] = typeof kareDnt === 'string' 
                    ? kareDnt 
                    : JSON.stringify(kareDnt);
            }

            if (userAgent != null) {
                localVarHeaderParameter['User-Agent'] = String(userAgent);
            }

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }

            if (kareMetaReferral != null) {
                localVarHeaderParameter['kare-meta-referral'] = String(kareMetaReferral);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Starts a new conversation and returns the conversation ID. In order to communicate with the dialog engine, clients need to first start a conversation. One they own a conversation ID clients can use either the websocket or the dialog endpoints to submit requests to the dialog engine. 
         * @summary Start conversation
         * @param {string} authorization Authorization Bearer header
         * @param {string} [kareContentLocale] Content locale selector. It can be null as the server will assign one.  
         * @param {number} [kareDnt] \&#39;Disable message tracking. See https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/DNT\&#39; 
         * @param {string} [userAgent] \&#39;The user agent\&#39; 
         * @param {string} [acceptLanguage] \&#39;The browser accepted languages\&#39; 
         * @param {string} [kareMetaReferral] \&#39;The page from which the user is interacting\&#39; 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startConversation: async (authorization: string, kareContentLocale?: string, kareDnt?: number, userAgent?: string, acceptLanguage?: string, kareMetaReferral?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('startConversation', 'authorization', authorization)
            const localVarPath = `/dialog/conversation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerBase required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            if (kareContentLocale != null) {
                localVarHeaderParameter['Kare-Content-Locale'] = String(kareContentLocale);
            }

            if (kareDnt != null) {
                localVarHeaderParameter['kare-dnt'] = typeof kareDnt === 'string' 
                    ? kareDnt 
                    : JSON.stringify(kareDnt);
            }

            if (userAgent != null) {
                localVarHeaderParameter['User-Agent'] = String(userAgent);
            }

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }

            if (kareMetaReferral != null) {
                localVarHeaderParameter['kare-meta-referral'] = String(kareMetaReferral);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Syncronous interface for storing messages to the dialog. It can be used for forcing conversation history by testing or evaluation frameworks. 
         * @summary Store message in conversation
         * @param {string} authorization Authorization Bearer header
         * @param {StoreMessageRequest} body The message to be stored.
         * @param {string} [kareContentLocale] Content locale selector. It cannot be omitted as the server should  have already suggested one from start conversation. 
         * @param {number} [kareDnt] \&#39;Disable message tracking. See https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/DNT\&#39; 
         * @param {string} [userAgent] \&#39;The user agent\&#39; 
         * @param {string} [acceptLanguage] \&#39;The browser accepted languages\&#39; 
         * @param {string} [kareMetaReferral] \&#39;The page from which the user is interacting\&#39; 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeMessage: async (authorization: string, body: StoreMessageRequest, kareContentLocale?: string, kareDnt?: number, userAgent?: string, acceptLanguage?: string, kareMetaReferral?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('storeMessage', 'authorization', authorization)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('storeMessage', 'body', body)
            const localVarPath = `/dialog/store`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerBase required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            if (kareContentLocale != null) {
                localVarHeaderParameter['Kare-Content-Locale'] = String(kareContentLocale);
            }

            if (kareDnt != null) {
                localVarHeaderParameter['kare-dnt'] = typeof kareDnt === 'string' 
                    ? kareDnt 
                    : JSON.stringify(kareDnt);
            }

            if (userAgent != null) {
                localVarHeaderParameter['User-Agent'] = String(userAgent);
            }

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }

            if (kareMetaReferral != null) {
                localVarHeaderParameter['kare-meta-referral'] = String(kareMetaReferral);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Exchanges an access token for a dialog token. 
         * @summary Exchanges an access token for a dialog token.
         * @param {TokenRequest} body Body of the token request.
         * @param {string} [authorization] Authorization Bearer header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        token: async (body: TokenRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('token', 'body', body)
            const localVarPath = `/dialog/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DialogApi - functional programming interface
 * @export
 */
export const DialogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DialogApiAxiosParamCreator(configuration)
    return {
        /**
         * Initialize a client given a valid app id. This is the same as calling: * /auth/app-token * /settings/client-config/public * /dialog/conversation: 
         * @summary Initialize a client
         * @param {string} appId Application ID
         * @param {string} [authorization] Authorization Bearer header
         * @param {string} [userId] The user ID, if known.
         * @param {string} [kareContentLocale] Content locale selector. It can be null as the server will assign one.  
         * @param {number} [kareDnt] \&#39;Disable message tracking. See https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/DNT\&#39; 
         * @param {string} [userAgent] \&#39;The user agent\&#39; 
         * @param {string} [acceptLanguage] \&#39;The browser accepted languages\&#39; 
         * @param {string} [kareMetaReferral] \&#39;The page from which the user is interacting\&#39; 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async init(appId: string, authorization?: string, userId?: string, kareContentLocale?: string, kareDnt?: number, userAgent?: string, acceptLanguage?: string, kareMetaReferral?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InitResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.init(appId, authorization, userId, kareContentLocale, kareDnt, userAgent, acceptLanguage, kareMetaReferral, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Syncronous interface for posting messages to the dialog on behalf of a user. Post a message to the dialog engine then syncrounously retrieve all the outbound messages that is has generated. Clients should then continue the conversaton by posting click events as the user interacts with the messages. This endoint is designed for those clients which cannot use a websocket. If they can clients should consider using the websocket which instead offers an asyncronous interface. 
         * @summary Post IN message
         * @param {string} authorization Authorization Bearer header
         * @param {PostInMessageRequest} body The query to be sent to the dialog engine.
         * @param {string} [kareContentLocale] Content locale selector. It cannot be omitted as the server should  have already suggested one from start conversation. 
         * @param {number} [kareDnt] \&#39;Disable message tracking. See https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/DNT\&#39; 
         * @param {string} [userAgent] \&#39;The user agent\&#39; 
         * @param {string} [acceptLanguage] \&#39;The browser accepted languages\&#39; 
         * @param {string} [kareMetaReferral] \&#39;The page from which the user is interacting\&#39; 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postInMessage(authorization: string, body: PostInMessageRequest, kareContentLocale?: string, kareDnt?: number, userAgent?: string, acceptLanguage?: string, kareMetaReferral?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostInMessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postInMessage(authorization, body, kareContentLocale, kareDnt, userAgent, acceptLanguage, kareMetaReferral, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Starts a new conversation and returns the conversation ID. In order to communicate with the dialog engine, clients need to first start a conversation. One they own a conversation ID clients can use either the websocket or the dialog endpoints to submit requests to the dialog engine. 
         * @summary Start conversation
         * @param {string} authorization Authorization Bearer header
         * @param {string} [kareContentLocale] Content locale selector. It can be null as the server will assign one.  
         * @param {number} [kareDnt] \&#39;Disable message tracking. See https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/DNT\&#39; 
         * @param {string} [userAgent] \&#39;The user agent\&#39; 
         * @param {string} [acceptLanguage] \&#39;The browser accepted languages\&#39; 
         * @param {string} [kareMetaReferral] \&#39;The page from which the user is interacting\&#39; 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startConversation(authorization: string, kareContentLocale?: string, kareDnt?: number, userAgent?: string, acceptLanguage?: string, kareMetaReferral?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartConversationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startConversation(authorization, kareContentLocale, kareDnt, userAgent, acceptLanguage, kareMetaReferral, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Syncronous interface for storing messages to the dialog. It can be used for forcing conversation history by testing or evaluation frameworks. 
         * @summary Store message in conversation
         * @param {string} authorization Authorization Bearer header
         * @param {StoreMessageRequest} body The message to be stored.
         * @param {string} [kareContentLocale] Content locale selector. It cannot be omitted as the server should  have already suggested one from start conversation. 
         * @param {number} [kareDnt] \&#39;Disable message tracking. See https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/DNT\&#39; 
         * @param {string} [userAgent] \&#39;The user agent\&#39; 
         * @param {string} [acceptLanguage] \&#39;The browser accepted languages\&#39; 
         * @param {string} [kareMetaReferral] \&#39;The page from which the user is interacting\&#39; 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeMessage(authorization: string, body: StoreMessageRequest, kareContentLocale?: string, kareDnt?: number, userAgent?: string, acceptLanguage?: string, kareMetaReferral?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeMessage(authorization, body, kareContentLocale, kareDnt, userAgent, acceptLanguage, kareMetaReferral, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Exchanges an access token for a dialog token. 
         * @summary Exchanges an access token for a dialog token.
         * @param {TokenRequest} body Body of the token request.
         * @param {string} [authorization] Authorization Bearer header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async token(body: TokenRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.token(body, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DialogApi - factory interface
 * @export
 */
export const DialogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DialogApiFp(configuration)
    return {
        /**
         * Initialize a client given a valid app id. This is the same as calling: * /auth/app-token * /settings/client-config/public * /dialog/conversation: 
         * @summary Initialize a client
         * @param {string} appId Application ID
         * @param {string} [authorization] Authorization Bearer header
         * @param {string} [userId] The user ID, if known.
         * @param {string} [kareContentLocale] Content locale selector. It can be null as the server will assign one.  
         * @param {number} [kareDnt] \&#39;Disable message tracking. See https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/DNT\&#39; 
         * @param {string} [userAgent] \&#39;The user agent\&#39; 
         * @param {string} [acceptLanguage] \&#39;The browser accepted languages\&#39; 
         * @param {string} [kareMetaReferral] \&#39;The page from which the user is interacting\&#39; 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        init(appId: string, authorization?: string, userId?: string, kareContentLocale?: string, kareDnt?: number, userAgent?: string, acceptLanguage?: string, kareMetaReferral?: string, options?: any): AxiosPromise<InitResponse> {
            return localVarFp.init(appId, authorization, userId, kareContentLocale, kareDnt, userAgent, acceptLanguage, kareMetaReferral, options).then((request) => request(axios, basePath));
        },
        /**
         * Syncronous interface for posting messages to the dialog on behalf of a user. Post a message to the dialog engine then syncrounously retrieve all the outbound messages that is has generated. Clients should then continue the conversaton by posting click events as the user interacts with the messages. This endoint is designed for those clients which cannot use a websocket. If they can clients should consider using the websocket which instead offers an asyncronous interface. 
         * @summary Post IN message
         * @param {string} authorization Authorization Bearer header
         * @param {PostInMessageRequest} body The query to be sent to the dialog engine.
         * @param {string} [kareContentLocale] Content locale selector. It cannot be omitted as the server should  have already suggested one from start conversation. 
         * @param {number} [kareDnt] \&#39;Disable message tracking. See https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/DNT\&#39; 
         * @param {string} [userAgent] \&#39;The user agent\&#39; 
         * @param {string} [acceptLanguage] \&#39;The browser accepted languages\&#39; 
         * @param {string} [kareMetaReferral] \&#39;The page from which the user is interacting\&#39; 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInMessage(authorization: string, body: PostInMessageRequest, kareContentLocale?: string, kareDnt?: number, userAgent?: string, acceptLanguage?: string, kareMetaReferral?: string, options?: any): AxiosPromise<PostInMessageResponse> {
            return localVarFp.postInMessage(authorization, body, kareContentLocale, kareDnt, userAgent, acceptLanguage, kareMetaReferral, options).then((request) => request(axios, basePath));
        },
        /**
         * Starts a new conversation and returns the conversation ID. In order to communicate with the dialog engine, clients need to first start a conversation. One they own a conversation ID clients can use either the websocket or the dialog endpoints to submit requests to the dialog engine. 
         * @summary Start conversation
         * @param {string} authorization Authorization Bearer header
         * @param {string} [kareContentLocale] Content locale selector. It can be null as the server will assign one.  
         * @param {number} [kareDnt] \&#39;Disable message tracking. See https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/DNT\&#39; 
         * @param {string} [userAgent] \&#39;The user agent\&#39; 
         * @param {string} [acceptLanguage] \&#39;The browser accepted languages\&#39; 
         * @param {string} [kareMetaReferral] \&#39;The page from which the user is interacting\&#39; 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startConversation(authorization: string, kareContentLocale?: string, kareDnt?: number, userAgent?: string, acceptLanguage?: string, kareMetaReferral?: string, options?: any): AxiosPromise<StartConversationResponse> {
            return localVarFp.startConversation(authorization, kareContentLocale, kareDnt, userAgent, acceptLanguage, kareMetaReferral, options).then((request) => request(axios, basePath));
        },
        /**
         * Syncronous interface for storing messages to the dialog. It can be used for forcing conversation history by testing or evaluation frameworks. 
         * @summary Store message in conversation
         * @param {string} authorization Authorization Bearer header
         * @param {StoreMessageRequest} body The message to be stored.
         * @param {string} [kareContentLocale] Content locale selector. It cannot be omitted as the server should  have already suggested one from start conversation. 
         * @param {number} [kareDnt] \&#39;Disable message tracking. See https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/DNT\&#39; 
         * @param {string} [userAgent] \&#39;The user agent\&#39; 
         * @param {string} [acceptLanguage] \&#39;The browser accepted languages\&#39; 
         * @param {string} [kareMetaReferral] \&#39;The page from which the user is interacting\&#39; 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeMessage(authorization: string, body: StoreMessageRequest, kareContentLocale?: string, kareDnt?: number, userAgent?: string, acceptLanguage?: string, kareMetaReferral?: string, options?: any): AxiosPromise<void> {
            return localVarFp.storeMessage(authorization, body, kareContentLocale, kareDnt, userAgent, acceptLanguage, kareMetaReferral, options).then((request) => request(axios, basePath));
        },
        /**
         * Exchanges an access token for a dialog token. 
         * @summary Exchanges an access token for a dialog token.
         * @param {TokenRequest} body Body of the token request.
         * @param {string} [authorization] Authorization Bearer header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        token(body: TokenRequest, authorization?: string, options?: any): AxiosPromise<TokenResponse> {
            return localVarFp.token(body, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DialogApi - object-oriented interface
 * @export
 * @class DialogApi
 * @extends {BaseAPI}
 */
export class DialogApi extends BaseAPI {
    /**
     * Initialize a client given a valid app id. This is the same as calling: * /auth/app-token * /settings/client-config/public * /dialog/conversation: 
     * @summary Initialize a client
     * @param {string} appId Application ID
     * @param {string} [authorization] Authorization Bearer header
     * @param {string} [userId] The user ID, if known.
     * @param {string} [kareContentLocale] Content locale selector. It can be null as the server will assign one.  
     * @param {number} [kareDnt] \&#39;Disable message tracking. See https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/DNT\&#39; 
     * @param {string} [userAgent] \&#39;The user agent\&#39; 
     * @param {string} [acceptLanguage] \&#39;The browser accepted languages\&#39; 
     * @param {string} [kareMetaReferral] \&#39;The page from which the user is interacting\&#39; 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DialogApi
     */
    public init(appId: string, authorization?: string, userId?: string, kareContentLocale?: string, kareDnt?: number, userAgent?: string, acceptLanguage?: string, kareMetaReferral?: string, options?: AxiosRequestConfig) {
        return DialogApiFp(this.configuration).init(appId, authorization, userId, kareContentLocale, kareDnt, userAgent, acceptLanguage, kareMetaReferral, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Syncronous interface for posting messages to the dialog on behalf of a user. Post a message to the dialog engine then syncrounously retrieve all the outbound messages that is has generated. Clients should then continue the conversaton by posting click events as the user interacts with the messages. This endoint is designed for those clients which cannot use a websocket. If they can clients should consider using the websocket which instead offers an asyncronous interface. 
     * @summary Post IN message
     * @param {string} authorization Authorization Bearer header
     * @param {PostInMessageRequest} body The query to be sent to the dialog engine.
     * @param {string} [kareContentLocale] Content locale selector. It cannot be omitted as the server should  have already suggested one from start conversation. 
     * @param {number} [kareDnt] \&#39;Disable message tracking. See https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/DNT\&#39; 
     * @param {string} [userAgent] \&#39;The user agent\&#39; 
     * @param {string} [acceptLanguage] \&#39;The browser accepted languages\&#39; 
     * @param {string} [kareMetaReferral] \&#39;The page from which the user is interacting\&#39; 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DialogApi
     */
    public postInMessage(authorization: string, body: PostInMessageRequest, kareContentLocale?: string, kareDnt?: number, userAgent?: string, acceptLanguage?: string, kareMetaReferral?: string, options?: AxiosRequestConfig) {
        return DialogApiFp(this.configuration).postInMessage(authorization, body, kareContentLocale, kareDnt, userAgent, acceptLanguage, kareMetaReferral, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Starts a new conversation and returns the conversation ID. In order to communicate with the dialog engine, clients need to first start a conversation. One they own a conversation ID clients can use either the websocket or the dialog endpoints to submit requests to the dialog engine. 
     * @summary Start conversation
     * @param {string} authorization Authorization Bearer header
     * @param {string} [kareContentLocale] Content locale selector. It can be null as the server will assign one.  
     * @param {number} [kareDnt] \&#39;Disable message tracking. See https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/DNT\&#39; 
     * @param {string} [userAgent] \&#39;The user agent\&#39; 
     * @param {string} [acceptLanguage] \&#39;The browser accepted languages\&#39; 
     * @param {string} [kareMetaReferral] \&#39;The page from which the user is interacting\&#39; 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DialogApi
     */
    public startConversation(authorization: string, kareContentLocale?: string, kareDnt?: number, userAgent?: string, acceptLanguage?: string, kareMetaReferral?: string, options?: AxiosRequestConfig) {
        return DialogApiFp(this.configuration).startConversation(authorization, kareContentLocale, kareDnt, userAgent, acceptLanguage, kareMetaReferral, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Syncronous interface for storing messages to the dialog. It can be used for forcing conversation history by testing or evaluation frameworks. 
     * @summary Store message in conversation
     * @param {string} authorization Authorization Bearer header
     * @param {StoreMessageRequest} body The message to be stored.
     * @param {string} [kareContentLocale] Content locale selector. It cannot be omitted as the server should  have already suggested one from start conversation. 
     * @param {number} [kareDnt] \&#39;Disable message tracking. See https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/DNT\&#39; 
     * @param {string} [userAgent] \&#39;The user agent\&#39; 
     * @param {string} [acceptLanguage] \&#39;The browser accepted languages\&#39; 
     * @param {string} [kareMetaReferral] \&#39;The page from which the user is interacting\&#39; 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DialogApi
     */
    public storeMessage(authorization: string, body: StoreMessageRequest, kareContentLocale?: string, kareDnt?: number, userAgent?: string, acceptLanguage?: string, kareMetaReferral?: string, options?: AxiosRequestConfig) {
        return DialogApiFp(this.configuration).storeMessage(authorization, body, kareContentLocale, kareDnt, userAgent, acceptLanguage, kareMetaReferral, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Exchanges an access token for a dialog token. 
     * @summary Exchanges an access token for a dialog token.
     * @param {TokenRequest} body Body of the token request.
     * @param {string} [authorization] Authorization Bearer header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DialogApi
     */
    public token(body: TokenRequest, authorization?: string, options?: AxiosRequestConfig) {
        return DialogApiFp(this.configuration).token(body, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
