/* tslint:disable */
/* eslint-disable */
/**
 * Mind
 * Kare API v4.0
 *
 * The version of the OpenAPI document: 4.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetAggregatedMetricByGroupResponse } from '../model';
// @ts-ignore
import { GetAggregatedMetricsResponse } from '../model';
// @ts-ignore
import { GetTimeseriesMetricsResponse } from '../model';
// @ts-ignore
import { ModelError } from '../model';
/**
 * AnalyticsApi - axios parameter creator
 * @export
 */
export const AnalyticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get an aggregated time series of traffic analysis for the specified period of time and metric. Response example for feedback metrics: {   from: 1584662400,   to: 1584489600,   \'counters\':{     \'helpful\': 150,     \'unhelpful\': 10,     \'total\': 160,   } } Use this endpoint to represent aggregated data given a time frame, for instance represent data in a pie chart. 
         * @summary GET Aggregated timeseries metrics
         * @param {string} authorization Authorization Bearer header.
         * @param {string} appId AppID/WidgetID
         * @param {'queries' | 'devices' | 'conversations' | 'feedbacks' | 'resolution_counters' | 'response_blocks_click_through' | 'external_links' | 'automations_clickthrough' | 'search_click_through' | 'beacons' | 'entry_urls' | 'exit_page' | 'cx_metrics'} metric 
         * @param {string} [kbId] Filter the aggregation by KbID(locale).
         * @param {number} [from] Lower date bound on unix timestamp.
         * @param {number} [to] Higher date bound on unix timestamp.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAggregatedMetrics: async (authorization: string, appId: string, metric: 'queries' | 'devices' | 'conversations' | 'feedbacks' | 'resolution_counters' | 'response_blocks_click_through' | 'external_links' | 'automations_clickthrough' | 'search_click_through' | 'beacons' | 'entry_urls' | 'exit_page' | 'cx_metrics', kbId?: string, from?: number, to?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getAggregatedMetrics', 'authorization', authorization)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAggregatedMetrics', 'appId', appId)
            // verify required parameter 'metric' is not null or undefined
            assertParamExists('getAggregatedMetrics', 'metric', metric)
            const localVarPath = `/analytics/{app_id}/aggregated/{metric}`
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"metric"}}`, encodeURIComponent(String(metric)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeAnalyticsRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (kbId !== undefined) {
                localVarQueryParameter['kb_id'] = kbId;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get an aggregated time series of traffic analysis for the specified period of time, metric and group Response example for feedback metrics: {   from: 1584662400,   to: 1584489600,   entries: {     \'node_123\': {       \'counters\':{         \'helpful\': 150,         \'unhelpful\': 10,         \'impressions\': 3000,       }     },     \'node_567\': {       \'counters\':{           \'helpful\': 150,           \'unhelpful\': 10,           \'impressions\': 50000,        }       }     }   } } Use this endpoint to represent aggregated data given a time frame. 
         * @summary GET Aggregated timeseries metrics with a group by field
         * @param {string} authorization Authorization Bearer header.
         * @param {string} appId AppID/WidgetID
         * @param {'queries' | 'devices' | 'conversations' | 'feedbacks' | 'resolution_counters' | 'response_blocks_click_through' | 'external_links' | 'automations_clickthrough' | 'search_click_through' | 'beacons' | 'entry_urls' | 'exit_page' | 'cx_metrics'} metric 
         * @param {Array<string>} groupBy 
         * @param {string} [kbId] Filter the aggregation by KbID(locale).
         * @param {number} [from] Lower date bound on unix timestamp.
         * @param {number} [to] Higher date bound on unix timestamp.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAggregatetByGroupMetric: async (authorization: string, appId: string, metric: 'queries' | 'devices' | 'conversations' | 'feedbacks' | 'resolution_counters' | 'response_blocks_click_through' | 'external_links' | 'automations_clickthrough' | 'search_click_through' | 'beacons' | 'entry_urls' | 'exit_page' | 'cx_metrics', groupBy: Array<string>, kbId?: string, from?: number, to?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getAggregatetByGroupMetric', 'authorization', authorization)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAggregatetByGroupMetric', 'appId', appId)
            // verify required parameter 'metric' is not null or undefined
            assertParamExists('getAggregatetByGroupMetric', 'metric', metric)
            // verify required parameter 'groupBy' is not null or undefined
            assertParamExists('getAggregatetByGroupMetric', 'groupBy', groupBy)
            const localVarPath = `/analytics/{app_id}/aggregated/{metric}/{group_by}`
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"metric"}}`, encodeURIComponent(String(metric)))
                .replace(`{${"group_by"}}`, encodeURIComponent(String(groupBy)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeAnalyticsRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (kbId !== undefined) {
                localVarQueryParameter['kb_id'] = kbId;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get an aggregated time series of traffic analysis for the specified period of time and metric. The time series can be resolved daily or hourly. 
         * @summary GET Timeseries metrics
         * @param {string} authorization Authorization Bearer header.
         * @param {string} appId AppID/WidgetID
         * @param {'queries' | 'devices' | 'conversations' | 'feedbacks' | 'resolution_counters' | 'response_blocks_click_through' | 'external_links' | 'automations_clickthrough' | 'search_click_through' | 'beacons' | 'entry_urls' | 'exit_page' | 'cx_metrics'} metric 
         * @param {'hourly' | 'daily'} resolution Timeseries resolution.
         * @param {string} [kbId] Filter the timeseries by KbID.
         * @param {number} [from] Lower date bound on unix timestamp.
         * @param {number} [to] Higher date bound on unix timestamp.
         * @param {'asc' | 'desc'} [sortBy] Higher date bound on unix timestamp.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeseriesMetrics: async (authorization: string, appId: string, metric: 'queries' | 'devices' | 'conversations' | 'feedbacks' | 'resolution_counters' | 'response_blocks_click_through' | 'external_links' | 'automations_clickthrough' | 'search_click_through' | 'beacons' | 'entry_urls' | 'exit_page' | 'cx_metrics', resolution: 'hourly' | 'daily', kbId?: string, from?: number, to?: number, sortBy?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getTimeseriesMetrics', 'authorization', authorization)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getTimeseriesMetrics', 'appId', appId)
            // verify required parameter 'metric' is not null or undefined
            assertParamExists('getTimeseriesMetrics', 'metric', metric)
            // verify required parameter 'resolution' is not null or undefined
            assertParamExists('getTimeseriesMetrics', 'resolution', resolution)
            const localVarPath = `/analytics/{app_id}/timeseries/{metric}/{resolution}`
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"metric"}}`, encodeURIComponent(String(metric)))
                .replace(`{${"resolution"}}`, encodeURIComponent(String(resolution)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeAnalyticsRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (kbId !== undefined) {
                localVarQueryParameter['kb_id'] = kbId;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalyticsApi - functional programming interface
 * @export
 */
export const AnalyticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnalyticsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get an aggregated time series of traffic analysis for the specified period of time and metric. Response example for feedback metrics: {   from: 1584662400,   to: 1584489600,   \'counters\':{     \'helpful\': 150,     \'unhelpful\': 10,     \'total\': 160,   } } Use this endpoint to represent aggregated data given a time frame, for instance represent data in a pie chart. 
         * @summary GET Aggregated timeseries metrics
         * @param {string} authorization Authorization Bearer header.
         * @param {string} appId AppID/WidgetID
         * @param {'queries' | 'devices' | 'conversations' | 'feedbacks' | 'resolution_counters' | 'response_blocks_click_through' | 'external_links' | 'automations_clickthrough' | 'search_click_through' | 'beacons' | 'entry_urls' | 'exit_page' | 'cx_metrics'} metric 
         * @param {string} [kbId] Filter the aggregation by KbID(locale).
         * @param {number} [from] Lower date bound on unix timestamp.
         * @param {number} [to] Higher date bound on unix timestamp.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAggregatedMetrics(authorization: string, appId: string, metric: 'queries' | 'devices' | 'conversations' | 'feedbacks' | 'resolution_counters' | 'response_blocks_click_through' | 'external_links' | 'automations_clickthrough' | 'search_click_through' | 'beacons' | 'entry_urls' | 'exit_page' | 'cx_metrics', kbId?: string, from?: number, to?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAggregatedMetricsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAggregatedMetrics(authorization, appId, metric, kbId, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get an aggregated time series of traffic analysis for the specified period of time, metric and group Response example for feedback metrics: {   from: 1584662400,   to: 1584489600,   entries: {     \'node_123\': {       \'counters\':{         \'helpful\': 150,         \'unhelpful\': 10,         \'impressions\': 3000,       }     },     \'node_567\': {       \'counters\':{           \'helpful\': 150,           \'unhelpful\': 10,           \'impressions\': 50000,        }       }     }   } } Use this endpoint to represent aggregated data given a time frame. 
         * @summary GET Aggregated timeseries metrics with a group by field
         * @param {string} authorization Authorization Bearer header.
         * @param {string} appId AppID/WidgetID
         * @param {'queries' | 'devices' | 'conversations' | 'feedbacks' | 'resolution_counters' | 'response_blocks_click_through' | 'external_links' | 'automations_clickthrough' | 'search_click_through' | 'beacons' | 'entry_urls' | 'exit_page' | 'cx_metrics'} metric 
         * @param {Array<string>} groupBy 
         * @param {string} [kbId] Filter the aggregation by KbID(locale).
         * @param {number} [from] Lower date bound on unix timestamp.
         * @param {number} [to] Higher date bound on unix timestamp.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAggregatetByGroupMetric(authorization: string, appId: string, metric: 'queries' | 'devices' | 'conversations' | 'feedbacks' | 'resolution_counters' | 'response_blocks_click_through' | 'external_links' | 'automations_clickthrough' | 'search_click_through' | 'beacons' | 'entry_urls' | 'exit_page' | 'cx_metrics', groupBy: Array<string>, kbId?: string, from?: number, to?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAggregatedMetricByGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAggregatetByGroupMetric(authorization, appId, metric, groupBy, kbId, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get an aggregated time series of traffic analysis for the specified period of time and metric. The time series can be resolved daily or hourly. 
         * @summary GET Timeseries metrics
         * @param {string} authorization Authorization Bearer header.
         * @param {string} appId AppID/WidgetID
         * @param {'queries' | 'devices' | 'conversations' | 'feedbacks' | 'resolution_counters' | 'response_blocks_click_through' | 'external_links' | 'automations_clickthrough' | 'search_click_through' | 'beacons' | 'entry_urls' | 'exit_page' | 'cx_metrics'} metric 
         * @param {'hourly' | 'daily'} resolution Timeseries resolution.
         * @param {string} [kbId] Filter the timeseries by KbID.
         * @param {number} [from] Lower date bound on unix timestamp.
         * @param {number} [to] Higher date bound on unix timestamp.
         * @param {'asc' | 'desc'} [sortBy] Higher date bound on unix timestamp.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTimeseriesMetrics(authorization: string, appId: string, metric: 'queries' | 'devices' | 'conversations' | 'feedbacks' | 'resolution_counters' | 'response_blocks_click_through' | 'external_links' | 'automations_clickthrough' | 'search_click_through' | 'beacons' | 'entry_urls' | 'exit_page' | 'cx_metrics', resolution: 'hourly' | 'daily', kbId?: string, from?: number, to?: number, sortBy?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTimeseriesMetricsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTimeseriesMetrics(authorization, appId, metric, resolution, kbId, from, to, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnalyticsApi - factory interface
 * @export
 */
export const AnalyticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnalyticsApiFp(configuration)
    return {
        /**
         * Get an aggregated time series of traffic analysis for the specified period of time and metric. Response example for feedback metrics: {   from: 1584662400,   to: 1584489600,   \'counters\':{     \'helpful\': 150,     \'unhelpful\': 10,     \'total\': 160,   } } Use this endpoint to represent aggregated data given a time frame, for instance represent data in a pie chart. 
         * @summary GET Aggregated timeseries metrics
         * @param {string} authorization Authorization Bearer header.
         * @param {string} appId AppID/WidgetID
         * @param {'queries' | 'devices' | 'conversations' | 'feedbacks' | 'resolution_counters' | 'response_blocks_click_through' | 'external_links' | 'automations_clickthrough' | 'search_click_through' | 'beacons' | 'entry_urls' | 'exit_page' | 'cx_metrics'} metric 
         * @param {string} [kbId] Filter the aggregation by KbID(locale).
         * @param {number} [from] Lower date bound on unix timestamp.
         * @param {number} [to] Higher date bound on unix timestamp.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAggregatedMetrics(authorization: string, appId: string, metric: 'queries' | 'devices' | 'conversations' | 'feedbacks' | 'resolution_counters' | 'response_blocks_click_through' | 'external_links' | 'automations_clickthrough' | 'search_click_through' | 'beacons' | 'entry_urls' | 'exit_page' | 'cx_metrics', kbId?: string, from?: number, to?: number, options?: any): AxiosPromise<GetAggregatedMetricsResponse> {
            return localVarFp.getAggregatedMetrics(authorization, appId, metric, kbId, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * Get an aggregated time series of traffic analysis for the specified period of time, metric and group Response example for feedback metrics: {   from: 1584662400,   to: 1584489600,   entries: {     \'node_123\': {       \'counters\':{         \'helpful\': 150,         \'unhelpful\': 10,         \'impressions\': 3000,       }     },     \'node_567\': {       \'counters\':{           \'helpful\': 150,           \'unhelpful\': 10,           \'impressions\': 50000,        }       }     }   } } Use this endpoint to represent aggregated data given a time frame. 
         * @summary GET Aggregated timeseries metrics with a group by field
         * @param {string} authorization Authorization Bearer header.
         * @param {string} appId AppID/WidgetID
         * @param {'queries' | 'devices' | 'conversations' | 'feedbacks' | 'resolution_counters' | 'response_blocks_click_through' | 'external_links' | 'automations_clickthrough' | 'search_click_through' | 'beacons' | 'entry_urls' | 'exit_page' | 'cx_metrics'} metric 
         * @param {Array<string>} groupBy 
         * @param {string} [kbId] Filter the aggregation by KbID(locale).
         * @param {number} [from] Lower date bound on unix timestamp.
         * @param {number} [to] Higher date bound on unix timestamp.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAggregatetByGroupMetric(authorization: string, appId: string, metric: 'queries' | 'devices' | 'conversations' | 'feedbacks' | 'resolution_counters' | 'response_blocks_click_through' | 'external_links' | 'automations_clickthrough' | 'search_click_through' | 'beacons' | 'entry_urls' | 'exit_page' | 'cx_metrics', groupBy: Array<string>, kbId?: string, from?: number, to?: number, options?: any): AxiosPromise<GetAggregatedMetricByGroupResponse> {
            return localVarFp.getAggregatetByGroupMetric(authorization, appId, metric, groupBy, kbId, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * Get an aggregated time series of traffic analysis for the specified period of time and metric. The time series can be resolved daily or hourly. 
         * @summary GET Timeseries metrics
         * @param {string} authorization Authorization Bearer header.
         * @param {string} appId AppID/WidgetID
         * @param {'queries' | 'devices' | 'conversations' | 'feedbacks' | 'resolution_counters' | 'response_blocks_click_through' | 'external_links' | 'automations_clickthrough' | 'search_click_through' | 'beacons' | 'entry_urls' | 'exit_page' | 'cx_metrics'} metric 
         * @param {'hourly' | 'daily'} resolution Timeseries resolution.
         * @param {string} [kbId] Filter the timeseries by KbID.
         * @param {number} [from] Lower date bound on unix timestamp.
         * @param {number} [to] Higher date bound on unix timestamp.
         * @param {'asc' | 'desc'} [sortBy] Higher date bound on unix timestamp.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeseriesMetrics(authorization: string, appId: string, metric: 'queries' | 'devices' | 'conversations' | 'feedbacks' | 'resolution_counters' | 'response_blocks_click_through' | 'external_links' | 'automations_clickthrough' | 'search_click_through' | 'beacons' | 'entry_urls' | 'exit_page' | 'cx_metrics', resolution: 'hourly' | 'daily', kbId?: string, from?: number, to?: number, sortBy?: 'asc' | 'desc', options?: any): AxiosPromise<GetTimeseriesMetricsResponse> {
            return localVarFp.getTimeseriesMetrics(authorization, appId, metric, resolution, kbId, from, to, sortBy, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnalyticsApi - object-oriented interface
 * @export
 * @class AnalyticsApi
 * @extends {BaseAPI}
 */
export class AnalyticsApi extends BaseAPI {
    /**
     * Get an aggregated time series of traffic analysis for the specified period of time and metric. Response example for feedback metrics: {   from: 1584662400,   to: 1584489600,   \'counters\':{     \'helpful\': 150,     \'unhelpful\': 10,     \'total\': 160,   } } Use this endpoint to represent aggregated data given a time frame, for instance represent data in a pie chart. 
     * @summary GET Aggregated timeseries metrics
     * @param {string} authorization Authorization Bearer header.
     * @param {string} appId AppID/WidgetID
     * @param {'queries' | 'devices' | 'conversations' | 'feedbacks' | 'resolution_counters' | 'response_blocks_click_through' | 'external_links' | 'automations_clickthrough' | 'search_click_through' | 'beacons' | 'entry_urls' | 'exit_page' | 'cx_metrics'} metric 
     * @param {string} [kbId] Filter the aggregation by KbID(locale).
     * @param {number} [from] Lower date bound on unix timestamp.
     * @param {number} [to] Higher date bound on unix timestamp.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getAggregatedMetrics(authorization: string, appId: string, metric: 'queries' | 'devices' | 'conversations' | 'feedbacks' | 'resolution_counters' | 'response_blocks_click_through' | 'external_links' | 'automations_clickthrough' | 'search_click_through' | 'beacons' | 'entry_urls' | 'exit_page' | 'cx_metrics', kbId?: string, from?: number, to?: number, options?: AxiosRequestConfig) {
        return AnalyticsApiFp(this.configuration).getAggregatedMetrics(authorization, appId, metric, kbId, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get an aggregated time series of traffic analysis for the specified period of time, metric and group Response example for feedback metrics: {   from: 1584662400,   to: 1584489600,   entries: {     \'node_123\': {       \'counters\':{         \'helpful\': 150,         \'unhelpful\': 10,         \'impressions\': 3000,       }     },     \'node_567\': {       \'counters\':{           \'helpful\': 150,           \'unhelpful\': 10,           \'impressions\': 50000,        }       }     }   } } Use this endpoint to represent aggregated data given a time frame. 
     * @summary GET Aggregated timeseries metrics with a group by field
     * @param {string} authorization Authorization Bearer header.
     * @param {string} appId AppID/WidgetID
     * @param {'queries' | 'devices' | 'conversations' | 'feedbacks' | 'resolution_counters' | 'response_blocks_click_through' | 'external_links' | 'automations_clickthrough' | 'search_click_through' | 'beacons' | 'entry_urls' | 'exit_page' | 'cx_metrics'} metric 
     * @param {Array<string>} groupBy 
     * @param {string} [kbId] Filter the aggregation by KbID(locale).
     * @param {number} [from] Lower date bound on unix timestamp.
     * @param {number} [to] Higher date bound on unix timestamp.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getAggregatetByGroupMetric(authorization: string, appId: string, metric: 'queries' | 'devices' | 'conversations' | 'feedbacks' | 'resolution_counters' | 'response_blocks_click_through' | 'external_links' | 'automations_clickthrough' | 'search_click_through' | 'beacons' | 'entry_urls' | 'exit_page' | 'cx_metrics', groupBy: Array<string>, kbId?: string, from?: number, to?: number, options?: AxiosRequestConfig) {
        return AnalyticsApiFp(this.configuration).getAggregatetByGroupMetric(authorization, appId, metric, groupBy, kbId, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get an aggregated time series of traffic analysis for the specified period of time and metric. The time series can be resolved daily or hourly. 
     * @summary GET Timeseries metrics
     * @param {string} authorization Authorization Bearer header.
     * @param {string} appId AppID/WidgetID
     * @param {'queries' | 'devices' | 'conversations' | 'feedbacks' | 'resolution_counters' | 'response_blocks_click_through' | 'external_links' | 'automations_clickthrough' | 'search_click_through' | 'beacons' | 'entry_urls' | 'exit_page' | 'cx_metrics'} metric 
     * @param {'hourly' | 'daily'} resolution Timeseries resolution.
     * @param {string} [kbId] Filter the timeseries by KbID.
     * @param {number} [from] Lower date bound on unix timestamp.
     * @param {number} [to] Higher date bound on unix timestamp.
     * @param {'asc' | 'desc'} [sortBy] Higher date bound on unix timestamp.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getTimeseriesMetrics(authorization: string, appId: string, metric: 'queries' | 'devices' | 'conversations' | 'feedbacks' | 'resolution_counters' | 'response_blocks_click_through' | 'external_links' | 'automations_clickthrough' | 'search_click_through' | 'beacons' | 'entry_urls' | 'exit_page' | 'cx_metrics', resolution: 'hourly' | 'daily', kbId?: string, from?: number, to?: number, sortBy?: 'asc' | 'desc', options?: AxiosRequestConfig) {
        return AnalyticsApiFp(this.configuration).getTimeseriesMetrics(authorization, appId, metric, resolution, kbId, from, to, sortBy, options).then((request) => request(this.axios, this.basePath));
    }
}
