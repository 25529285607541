import {
    type NodeDetail,
    NodeStatus,
    type ResponseNode,
    ResponseNodeBlockTypeEnum
} from '@/open-api';

export enum CHATTER_STATES {
    PUBLISHED = 'Published',
    DRAFT = 'Draft',
    ARCHIVED = 'Archived'
}

export interface Chatter {
    id: string;
    title: string;
    updated_at?: number;
    created_at?: number;
    state?: CHATTER_STATES | null;
    queries: number;
    options: number;
    documents: number;
    images: number;
    videos: number;
    automations: number;
    handovers: number;
    _rawNode: NodeDetail;
}

export function transformNodeStatus(
    status?: NodeStatus | null
): CHATTER_STATES | undefined | null {
    switch (status) {
        case NodeStatus.Published:
            return CHATTER_STATES.PUBLISHED;
        case NodeStatus.Unpublished:
            return CHATTER_STATES.DRAFT;
        case NodeStatus.Deleted:
            return CHATTER_STATES.ARCHIVED;
        default:
            return undefined;
    }
}

function fetchChatterCounters(node: NodeDetail): {
    [x: string]: number;
} {
    if (!node || !node?.type) return {};
    const chatterNode = node[node.type] as ResponseNode;
    const initialCounter = {
        queries: chatterNode.queries?.length || 0,
        automations: 0,
        videos: 0,
        documents: 0,
        handovers: 0,
        images: 0,
        options: 0
    };
    if (!chatterNode.blocks) return initialCounter;
    return chatterNode.blocks?.reduce(
        (blockCounter, currentBlock) => {
            switch (currentBlock.type) {
                case ResponseNodeBlockTypeEnum.CallToAction:
                    blockCounter.handovers++;
                    break;
                case ResponseNodeBlockTypeEnum.Image:
                    blockCounter.images++;
                    break;
                case ResponseNodeBlockTypeEnum.Video:
                    blockCounter.videos++;
                    break;
                case ResponseNodeBlockTypeEnum.Handover:
                    blockCounter.handovers++;
                    break;
                case ResponseNodeBlockTypeEnum.Webext:
                    blockCounter.automations++;
                    break;
                case ResponseNodeBlockTypeEnum.Attachments:
                    blockCounter.documents++;
                    break;
                case ResponseNodeBlockTypeEnum.Choices:
                    blockCounter.options++;
            }
            return blockCounter;
        },
        {
            ...initialCounter,
            queries: chatterNode.queries?.length || 0
        }
    );
}

export function transformNodeToChatter(data: NodeDetail): Chatter {
    const counters = fetchChatterCounters(data);

    return {
        id: data?.id || '',
        title: data?.title || '',
        updated_at: data?.updated_at,
        created_at: data?.created_at,
        state: transformNodeStatus(data?.status),
        queries: counters.queries,
        automations: counters.automations,
        videos: counters.videos,
        documents: counters.documents,
        handovers: counters.handovers,
        images: counters.images,
        options: counters.options,
        _rawNode: data
    };
}
