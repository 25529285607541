<template>
    <dt-card
        class="d-w100p"
        header-class="d-px16 d-py4 d-bgc-secondary d-bb d-bc-subtle d-btr4"
        content-class="d-px16 d-py8"
        :container-class="{
            'd-bs-sm': editable,
            'd-bs-none h:d-bs-sm': !editable
        }"
    >
        <template #header v-if="editable">
            <span
                class="d-fs-100 d-fw-bold d-h16 d-py4 d-my2 d-d-flex d-ai-center"
            >
                {{ cardTitle }}
            </span>
        </template>
        <template #content>
            <div v-if="editable">
                <template v-if="isFetching">
                    <div
                        class="d-d-flex d-w100p d-fd-column d-jc-center d-ai-center"
                    >
                        <dt-button loading importance="clear" />
                    </div>
                </template>
                <template v-else>
                    <div
                        v-if="hasAutomations"
                        class="d-d-flex d-fw-wrap d-w100p d-gg8"
                    >
                        <div class="d-w100p">
                            <dt-select-menu
                                size="xs"
                                :label="$t('Automation')"
                                name="webext-select"
                                @change="handleUrlChange"
                                class="d-tt-capitalize"
                                :value="url"
                            >
                                <option
                                    v-for="option in webextensions"
                                    :key="`webext-select-option-${option.name}`"
                                    :value="option.url"
                                >
                                    {{ option.name }}
                                </option>
                            </dt-select-menu>
                        </div>
                        <div class="d-w100p">
                            <dt-input
                                size="xs"
                                type="textarea"
                                :label="$t('Message text')"
                                v-model="text"
                            />
                        </div>
                        <div class="d-w100p">
                            <dt-input
                                size="xs"
                                :label="$t('Button text')"
                                v-model="callToAction"
                            />
                        </div>
                        <div class="d-w100p">
                            <dt-select-menu
                                size="xs"
                                :label="$t('Launch mode')"
                                name="webext-select"
                                class="d-tt-capitalize"
                                @change="handleTargetChange"
                                :value="target"
                            >
                                <option
                                    v-for="targetKey in Object.keys(
                                        OpenTargetType
                                    )"
                                    :key="`webext-launch-select-option-${targetKey}`"
                                    :value="
                                        OpenTargetType[
                                            targetKey as keyof typeof OpenTargetType
                                        ]
                                    "
                                >
                                    {{
                                        OpenTargetType[
                                            targetKey as keyof typeof OpenTargetType
                                        ]
                                    }}
                                </option>
                            </dt-select-menu>
                        </div>
                    </div>
                    <div
                        class="d-d-flex d-jc-center d-fw-wrap d-fd-column d-gg12"
                        v-else
                    >
                        <div
                            class="d-d-flex d-ai-center d-fw-wrap d-fs-100 d-fd-column d-gg4"
                        >
                            <span class="d-fw-bold d-w100p d-ta-center">
                                {{ $t('No automations found') }}
                            </span>
                            <span class="d-ta-center">
                                {{
                                    $t(
                                        'Please set up an environment to sync automations'
                                    )
                                }}
                            </span>
                        </div>

                        <div class="d-d-flex d-jc-center">
                            <dt-button size="xs">
                                {{ $t('Contact us to add automations') }}
                            </dt-button>
                        </div>
                    </div>
                </template>
            </div>
            <div v-else class="d-d-flex d-fw-wrap d-gg8">
                <p class="d-w100p" v-if="text?.length">
                    {{ text }}
                </p>
                <dt-button size="sm">{{ callToAction }}</dt-button>
            </div>
        </template>
        <template #footer v-if="editable">
            <div class="d-d-flex d-jc-flex-end d-w100p d-mt8 d-gg8">
                <dt-button importance="clear" @click="handleCancel" size="xs">
                    {{ $t('Cancel') }}
                </dt-button>
                <dt-button
                    @click="handleSave"
                    size="xs"
                    :disabled="!shouldSave"
                >
                    {{ $t('Add') }}
                </dt-button>
            </div>
        </template>
    </dt-card>
</template>
<script lang="ts">
import { defineComponent, inject, type PropType } from 'vue';
import type { Block } from '@/components/node-editor/responseBlockEditor';
import DropFile from '@/components/upload-drawer/DropFile.vue';
import {
    DtButton,
    DtCard,
    DtInput,
    DtSelectMenu
} from '@dialpad/dialtone/vue3';
import type { Organisation, WebExtensionDetail } from '@/open-api';
import IframeLoader from '@/components/iframe-loader/IframeLoader.vue';
import { handleRequest } from '@/utils/Common';
import type { ApiService } from '@/services/Api.service';
import { OpenTargetType } from '@/open-api';
import type { ResponseBlockEditor } from '@/components/node-editor/responseBlockEditor';

export default defineComponent({
    props: {
        block: {
            type: Object as PropType<Block>,
            required: true
        },
        editable: {
            type: Boolean as PropType<boolean>,
            required: true
        }
    },
    setup() {
        const orgId: string = inject('orgId')!;
        return {
            orgId
        };
    },
    mounted() {
        /* v8 ignore next 100*/
        this.fetchWebExtensions();
    },
    components: {
        DtSelectMenu,
        DtInput,
        IframeLoader,
        DtButton,
        DtCard,
        DropFile
    },
    computed: {
        /* v8 ignore next 40 */
        isEditable: {
            get(): boolean {
                return this.editable;
            },
            set(editable: boolean) {
                this.$emit('edit', editable);
            }
        },
        currentOrganisation(): Organisation {
            return this.$store.getters[`${this.orgId}/currentOrg`];
        },
        authToken(): string {
            return this.$store.getters[`${this.orgId}/authToken`];
        },
        apiService(): ApiService {
            return this.$store.getters[`${this.orgId}/apiService`];
        },
        responseBlockEditor(): ResponseBlockEditor | undefined {
            return this.$store.getters[`${this.orgId}/responseBlockEditor`];
        },
        shouldSave(): boolean {
            return (
                !!this.url?.length &&
                !!this.callToAction?.length &&
                !!this.target?.length
            );
        },
        hasAutomations(): boolean {
            return !!this.webextensions.length;
        },
        cardTitle(): string {
            return this.block.new
                ? this.$t('Add automation')
                : this.$t('Edit automation');
        }
    },
    methods: {
        resetData() {
            this.url = this.block?.data?.webext_block?.url;
            this.target = this.block?.data?.webext_block?.target;
            this.callToAction = this.block?.data?.webext_block?.call_to_action;
            this.text = this.block?.data?.webext_block?.text;
        },
        handleRemove() {
            this.responseBlockEditor?.removeBlock(this.block);
        },
        handleCancel(e: Event) {
            e.stopPropagation();
            // If block has never been added then it will remove it.
            // If was added, even if it was temporary, it won't be removed.
            if (this.block.new && !this.block.data.dirty) {
                this.handleRemove();
            } else {
                this.resetData();
                this.isEditable = false;
            }
        },
        handleSave(e: Event) {
            e.stopPropagation();
            this.block?.setBlockData({
                url: this.url,
                target: this.target,
                call_to_action: this.callToAction,
                text: this.text
            });
            this.isEditable = false;
        },
        handleTargetChange(newTarget: any) {
            this.target = newTarget;
        },
        handleUrlChange(newUrl: any) {
            this.url = newUrl;
        },
        async fetchWebExtensions() {
            this.isFetching = true;
            const resWebext = await handleRequest(
                this.apiService.knowledge.listWebExt(this.authToken),
                this.orgId
            );

            this.isFetching = false;
            this.webextensionsError = !!resWebext.error;
            if (!resWebext.error) {
                this.webextensions = resWebext.data || [];
            }
        }
    },
    data() {
        return {
            url: this.block?.data?.webext_block?.url,
            target: this.block?.data?.webext_block?.target,
            callToAction: this.block?.data?.webext_block?.call_to_action,
            text: this.block?.data?.webext_block?.text,
            webextensions: [] as WebExtensionDetail[],
            webextensionsError: false,
            isFetching: false,
            OpenTargetType: OpenTargetType
        };
    }
});
</script>
