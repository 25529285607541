<template>
    <chatbots-default-view :app-type="APP_TYPES.SELF_SERVICE" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import ChatbotsDefaultView from '@/views/ChatbotsDefaultView.vue';
import { APP_TYPES } from '@/utils/types/App';

export default defineComponent({
    components: {
        ChatbotsDefaultView
    },
    data() {
        return {
            APP_TYPES
        };
    }
});
</script>
