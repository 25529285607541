import {
    ActivityApi,
    AnalyticsApi,
    AudienceApi,
    DialogApi,
    IamApi,
    ImproveApi,
    IngestionApi,
    KnowledgeApi,
    SettingsApi
} from '@/open-api';

export class ApiService {
    public readonly activity: ActivityApi;
    public readonly analytics: AnalyticsApi;
    public readonly audience: AudienceApi;
    public readonly dialog: DialogApi;
    public readonly iam: IamApi;
    public readonly improve: ImproveApi;
    public readonly ingestion: IngestionApi;
    public readonly knowledge: KnowledgeApi;
    public readonly settings: SettingsApi;
    constructor(base_path: string | undefined = undefined) {
        this.activity = new ActivityApi(undefined, base_path);
        this.analytics = new AnalyticsApi(undefined, base_path);
        this.audience = new AudienceApi(undefined, base_path);
        this.dialog = new DialogApi(undefined, base_path);
        this.iam = new IamApi(undefined, base_path);
        this.improve = new ImproveApi(undefined, base_path);
        this.ingestion = new IngestionApi(undefined, base_path);
        this.knowledge = new KnowledgeApi(undefined, base_path);
        this.settings = new SettingsApi(undefined, base_path);
    }
}
