<template>
    <div class="d-d-flex d-fd-column d-gg16 d-bgc-primary">
        <table class="datatable-table" :id="id">
            <thead>
                <tr class="data-grid-row">
                    <th
                        scope="col"
                        class="datatable-cell-size d-fw-bold d-ps-sticky d-zi-navigation-fixed d-l0 d-c-grab d-wmx264 d-bgc-primary d-w32"
                        :style="{
                            top: `${fixedHeightOffset}px`
                        }"
                        v-if="!hideCheckbox"
                    >
                        <div
                            class="d-d-flex d-ai-center d-jc-center d-w100p d-px16 d-h100p d-bc-subtle d-ba d-baw1 d-br"
                            :style="{
                                ['border-top-left-radius']: '1.2rem'
                            }"
                        >
                            <dt-checkbox
                                aria-label="checkbox-toggle-all"
                                input-class="d-fs-200"
                                :checked="allChecked"
                                v-model="allChecked"
                                @input="handleToggleAllRows"
                                :disabled="isFetching"
                            />
                        </div>
                    </th>
                    <th
                        :key="column.key"
                        v-for="(column, columnIndex) in renderColumns"
                        scope="col"
                        class="datatable-cell-size d-fw-bold d-ps-sticky d-zi-navigation-fixed d-c-grab d-wmx264 d-bgc-primary"
                        :class="{
                            'd-w100p': columnIndex === 0 && !column.width
                        }"
                        :style="{
                            top: `${fixedHeightOffset}px`
                        }"
                    >
                        <div
                            class="d-px16 d-d-flex d-ai-center d-gg4 d-h100p d-bc-subtle d-ba d-baw1"
                            :class="{
                                'd-blw0':
                                    (columnIndex === 0 && !hideCheckbox) ||
                                    columnIndex > 0
                            }"
                            :style="{
                                ['border-top-left-radius']:
                                    columnIndex === 0 && hideCheckbox
                                        ? '1.2rem'
                                        : undefined,
                                ['border-top-right-radius']:
                                    columnIndex === renderColumns?.length - 1
                                        ? '1.2rem'
                                        : undefined
                            }"
                        >
                            <span class="fs-100" style="margin-left: -5px">
                                <base-table-header
                                    :checkbox="!hideCheckbox"
                                    :enable-all-checked="columnIndex === 0"
                                    :column-data="column"
                                    :is-disabled="isFetching"
                                    v-model:sort-by="sortBy"
                                    @toggle-all-rows="handleToggleAllRows"
                                />
                            </span>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    :key="row.id"
                    v-for="(row, rowIndex) in filteredRows"
                    class="datatable-cell-size data-grid-row d-bar12"
                    :class="{
                        ['d-bgc-moderate']: isRowActive(row.id),
                        ['h:d-bgc-secondary d-c-pointer']: hasRowClickListener
                    }"
                    :style="rowStyle"
                    @click="handleRowClick(row)"
                    @mouseleave="onMouseLeaveRow"
                    @mouseover="onMouseEnterRow(row.id)"
                >
                    <template v-if="!hideCheckbox">
                        <td
                            class="d-bc-subtle d-baw1 d-fs-200 d-bb d-br d-bl d-w32"
                            :style="{
                                ['border-bottom-left-radius']: belongsToLastRow(
                                    rowIndex
                                )
                                    ? '1.2rem'
                                    : undefined
                            }"
                        >
                            <div
                                class="d-d-flex d-ai-center d-jc-center d-w100p"
                            >
                                <dt-checkbox
                                    aria-label="checkbox-toggle"
                                    input-class="d-fs-200"
                                    :checked="isChecked(row)"
                                    @input="toggleCheckbox(row)"
                                    @click="handleCheckboxClick($event)"
                                    :disabled="isFetching"
                                />
                            </div>
                        </td>
                    </template>

                    <td
                        :key="`${row.id}-${column.key}`"
                        v-for="(column, columnIndex) in renderColumns"
                        class="d-bc-subtle d-baw1 d-fs-200 d-ps-relative d-bb d-br d-ps-sticky d-l0 d-zi-navigation d-wmx264"
                        :class="{
                            'd-bl': columnIndex === 0 && hideCheckbox,
                            ['row-options d-w32 d-px16 d-py12']:
                                column.type === 'options',
                            ['active']: focusedRow && focusedRow === rowIndex,
                            'd-w100p': columnIndex === 0 && !column.width
                        }"
                        :style="{
                            width: `${column.width || 'auto'}`,
                            ['border-bottom-left-radius']:
                                belongsToLastRow(rowIndex) &&
                                columnIndex === 0 &&
                                hideCheckbox
                                    ? '1.2rem'
                                    : undefined,
                            ['border-bottom-right-radius']:
                                belongsToLastRow(rowIndex) &&
                                columnIndex === renderColumns?.length - 1
                                    ? '1.2rem'
                                    : undefined
                        }"
                    >
                        <template v-if="isFetching">
                            <template v-if="column.type === 'options'">
                                <menu-popover
                                    :id="`${column.key}-row-menu`"
                                    :loading="isFetching"
                                />
                            </template>
                            <template v-else-if="column.type === 'datetime'">
                                <div
                                    class="d-w100p d-py12 d-px16 d-gg4 d-d-flex d-fd-column"
                                >
                                    <dt-skeleton
                                        arial-label="Loading"
                                        :paragraphOption="{
                                            rows: 1,
                                            rowClass: 'd-h12',
                                            width: ['80%', '80%']
                                        }"
                                    />
                                    <dt-skeleton
                                        arial-label="Loading"
                                        :paragraphOption="{
                                            rows: 1,
                                            rowClass: 'd-h12',
                                            width: ['40%', '40%']
                                        }"
                                    />
                                </div>
                            </template>
                            <template v-else>
                                <div class="d-w100p d-py12 d-px16">
                                    <dt-skeleton
                                        arial-label="Loading"
                                        :paragraphOption="{
                                            rows: 2,
                                            rowClass: 'd-h12',
                                            width: ['80%', '80%'],
                                            contentClass:
                                                'd-d-flex d-py6 d-fd-column'
                                        }"
                                    />
                                </div>
                            </template>
                        </template>
                        <template v-else-if="column.template">
                            <template-renderer
                                :template="column.template!(row?.data)"
                            />
                        </template>
                        <template
                            v-else-if="
                                column.type === 'options' && renderOptions
                            "
                        >
                            <menu-popover
                                :id="`${column.key}-row-${row.id}-menu`"
                                :options="renderOptions(row.data.status)"
                                :row-data="row.data"
                                @click="handleOptionsClick($event, rowIndex)"
                                :focused="focusedRow === rowIndex"
                            />
                        </template>
                        <template v-else-if="column.type === 'datetime'">
                            <div
                                class="d-d-flex d-ai-center d-fw-wrap d-px16 d-py12"
                            >
                                <span
                                    class="d-w100p d-truncate d-fc-black-900 d-d-flex d-fw-wrap d-fl-grow1"
                                >
                                    {{
                                        column.key
                                            ? getColumnDate(
                                                  row?.data[column.key]
                                              )
                                            : ''
                                    }}
                                </span>
                                <span class="d-fs-100 d-fc-black-600">
                                    {{
                                        column.key
                                            ? getColumnHour(
                                                  row?.data[column.key]
                                              )
                                            : ''
                                    }}
                                </span>
                            </div>
                        </template>
                        <!-- Renders as a string-->
                        <template v-else>
                            <span
                                class="d-py12 d-px16 d-d-flex d-ai-center d-truncate d-to-ellipsis"
                            >
                                {{ row.data[column.key!] }}
                            </span>
                        </template>
                    </td>
                </tr>
                <tr
                    v-if="isFetchingMore"
                    class="datatable-cell-size data-grid-row"
                    :style="rowStyle"
                >
                    <td
                        :key="`is-fetching-more-${column.key}`"
                        v-for="(column, columnIndex) in renderColumns"
                        class="d-bc-subtle d-baw1 d-fs-200 d-ps-relative d-bb d-br d-ps-sticky d-l0 d-zi-navigation d-wmx264"
                        :class="{
                            ['d-bbw0']: true,
                            ['d-brw0']: belongsToLastColumn(columnIndex),
                            ['row-options d-w32 d-px16']:
                                column.type === 'options',
                            'd-w100p': columnIndex === 0 && !column.width
                        }"
                        :style="{
                            width: `${column.width || 'auto'}`
                        }"
                    >
                        <template v-if="column.type === 'options'">
                            <menu-popover
                                :id="`${column.key}-row-menu`"
                                :loading="isFetching"
                            />
                        </template>
                        <template v-else-if="column.type === 'datetime'">
                            <div
                                class="d-w100p d-py12 d-px16 d-gg4 d-d-flex d-fd-column"
                            >
                                <dt-skeleton
                                    arial-label="Loading"
                                    :paragraphOption="{
                                        rows: 1,
                                        rowClass: 'd-h12',
                                        width: ['80%', '80%']
                                    }"
                                />
                                <dt-skeleton
                                    arial-label="Loading"
                                    :paragraphOption="{
                                        rows: 1,
                                        rowClass: 'd-h12',
                                        width: ['40%', '40%']
                                    }"
                                />
                            </div>
                        </template>
                        <template v-else>
                            <div class="d-w100p d-py12 d-px16">
                                <dt-skeleton
                                    arial-label="Loading"
                                    :paragraphOption="{
                                        rows: 1,
                                        rowClass: 'd-h24',
                                        width: ['80%', '80%'],
                                        contentClass:
                                            'd-d-flex d-ai-center d-h24 d-pt4'
                                    }"
                                />
                            </div>
                        </template>
                    </td>
                </tr>
            </tbody>
        </table>
        <!-- --------------------------------------------------------------- -->
        <!--                            ERROR                                -->
        <!-- --------------------------------------------------------------- -->
        <template v-if="hasError">
            <div class="d-d-flex d-ai-center d-fd-column d-w100p">
                <h3 class="d-mt48">
                    {{ $t('An error occurred fetching data') }}
                </h3>
                <p class="d-mt8 d-mb48">
                    {{ $t('Please try again later') }}
                </p>
            </div>
        </template>

        <template v-else>
            <dt-pagination
                v-if="shouldShowPagination"
                class="d-jc-center d-mt-auto d-mb16"
                :total-pages="totalPages"
                :aria-label="$t('pagination')"
                :prev-aria-label="$t('previous')"
                :next-aria-label="$t('next')"
                :page-number-aria-label="getPageNumberAriaLabel"
                :active-page="activePage"
                @change="changePage"
            />
        </template>
    </div>
</template>

<script lang="ts">
import {
    DtButton,
    DtPagination,
    DtSkeleton,
    DtCheckbox,
    DtIcon
} from '@dialpad/dialtone/vue3';
import MenuPopover from '@/components/menu-popover/MenuPopover.vue';
import { CSSProperties, defineComponent, inject, type PropType } from 'vue';
import TemplateRenderer from '@/components/template-renderer/TemplateRenderer.vue';
import {
    getFormattedDate,
    getFormattedHour,
    calculatePagesCount
} from '@/utils/Common';
import type {
    IBaseTableRow,
    IBaseTableColumn,
    IBaseSortableTableColumn,
    IBaseTableFilters
} from '@/components/base-table/BaseTable.types';
import BaseTableHeader from '@/components/base-table/BaseTableHeader.vue';
import type { NodeType } from '@/open-api';
import EmptyState from '@/components/empty-state/EmptyState.vue';
import { DrawerService } from '@/services/Drawer.service';
import { DEFAULT_ITEMS_PER_PAGE } from '@/utils/Constants';

export default defineComponent({
    inheritAttrs: false,
    components: {
        BaseTableHeader,
        MenuPopover,
        DtButton,
        DtPagination,
        DtSkeleton,
        DtCheckbox,
        DtIcon,
        TemplateRenderer,
        EmptyState
    },
    props: {
        activePage: {
            type: Number,
            default: 1
        },
        hoveredRowId: {
            type: String as PropType<any>
        },
        filters: {
            type: Array as PropType<IBaseTableFilters<any>[]>,
            default: () => []
        },
        id: {
            type: String as PropType<string>
        },
        class: {
            type: String as PropType<string>,
            default: ''
        },
        columns: {
            type: Array as PropType<IBaseTableColumn<any>[]>,
            required: true
        },
        data: {
            type: Object as PropType<any>,
            default: () => {}
        },
        rowClass: {
            type: String as PropType<string>,
            default: ''
        },
        isFetching: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        hasError: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        selectedRows: {
            type: Array as PropType<IBaseTableRow[]>,
            default: () => []
        },
        activeRowId: {
            type: String as PropType<string>
        },
        highlightUpdatedRows: {
            type: Array as PropType<string[]>
        },
        totalHits: {
            type: Number as PropType<number>,
            default: 0
        },
        rowCount: {
            type: Number as PropType<number>,
            default: 5
        },
        hideCheckbox: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        hasActiveFilters: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        itemType: {
            type: String as PropType<
                | NodeType
                | 'chatbot'
                | 'automation'
                | 'workflow'
                | 'queries'
                | 'questions'
                | 'items'
                | 'messages'
            >,
            required: true
        },
        sort: {
            type: Object as PropType<IBaseSortableTableColumn>,
            default: () => ({ key: undefined, asc: undefined })
        },
        pagination: {
            type: Boolean as PropType<boolean>,
            default: () => true
        },
        fixedHeader: {
            type: Boolean as PropType<boolean>,
            default: () => false
        },
        renderOptions: {
            type: Function as PropType<any>
        },
        titleHeight: {
            type: Number as PropType<number>,
            default: 0
        },
        searchFiltersBarHeight: {
            type: Number as PropType<number>,
            default: 0
        },
        // Use this as the row identifier
        idKey: {
            type: String as PropType<string>,
            default: 'id'
        },
        isFetchingMore: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        rowHeight: {
            type: Number as PropType<number>
        }
    },
    setup() {
        const orgId: string = inject('orgId')!;
        return {
            orgId
        };
    },
    computed: {
        checkboxes: {
            get(): IBaseTableRow[] {
                return this.selectedRows;
            },
            set(value: IBaseTableRow[]) {
                this.$emit('update:selectedRows', value);
            }
        },
        sortBy: {
            get(): IBaseSortableTableColumn {
                return this.sort;
            },
            set(value: IBaseSortableTableColumn) {
                this.$emit('update:sort', value);
            }
        },
        /* v8 ignore next 12 */
        drawerService(): DrawerService | undefined {
            return this.$store.getters[`${this.orgId}/drawerService`];
        },
        rows(): IBaseTableRow[] | undefined {
            if (!this.data?.length) return;
            return this.data?.map((rowData: IBaseTableRow) => {
                return {
                    id: rowData[this.idKey],
                    data: rowData
                };
            });
        },
        filteredRows(): IBaseTableRow[] | undefined {
            return this.isFetching
                ? ([...Array(DEFAULT_ITEMS_PER_PAGE).keys()] as any[])
                : this.rows || [];
        },
        renderColumns(): IBaseTableColumn<any>[] {
            return this.renderOptions
                ? [
                      ...this.columns,
                      {
                          type: 'options'
                      }
                  ]
                : this.columns;
        },
        shouldShowPagination(): boolean {
            return (
                this.pagination &&
                !!this.filteredRows?.length &&
                this.totalPages > 1
            );
        },
        totalPages(): number {
            return this.totalHits && this.rowCount
                ? calculatePagesCount(this.rowCount, this.totalHits)
                : 0;
        },
        allChecked(): boolean {
            if (!this.rows?.length) {
                return false;
            }

            return this.rows.every((row) =>
                this.checkboxes.find((rc) => row.id === rc.id)
            );
        },
        shouldOverflowTable(): boolean {
            return this.data?.length >= DEFAULT_ITEMS_PER_PAGE;
        },
        hasRowClickListener(): boolean {
            return !!this.$attrs?.onRowClick;
        },
        fixedHeightOffset(): number {
            return this.titleHeight + this.searchFiltersBarHeight;
        },
        rowStyle(): CSSProperties {
            if (!this.rowHeight) return {};
            return {
                height: `${this.rowHeight}px !important`,
                maxHeight: `${this.rowHeight}px !important`
            };
        }
    },
    methods: {
        /* v8 ignore next 100 */
        belongsToLastRow(rowIndex: number | undefined) {
            if (this.filteredRows?.length !== 1 && !rowIndex) return false;
            return this.filteredRows?.length === rowIndex + 1;
        },
        belongsToLastColumn(columnIndex: number | undefined) {
            if (!columnIndex) return false;
            return this.renderColumns?.length === columnIndex + 1;
        },
        onScroll(e: any) {
            if (!this.$refs.grid) return;
            const scrollTop = (this.$refs.grid as HTMLElement).scrollTop;
            const scrollLeft = (this.$refs.grid as HTMLElement).scrollLeft;
            if (scrollTop !== this.scrollY) {
                this.scrollY = scrollTop;
                this.$emit('scrollY', e);
            } else if (scrollLeft !== this.scrollX) {
                this.scrollX = scrollLeft;
                this.$emit('scrollX', e);
            }
        },
        getPageNumberAriaLabel(page: number) {
            return page === this.totalPages
                ? `Last page ${page}`
                : `Page ${page}`;
        },
        changePage(page: any) {
            this.currentPage = page;
            this.$emit('update:activePage', page);
        },
        handleToggleAllRows() {
            if (this.allChecked) {
                this.checkboxes = [];
            } else {
                this.checkboxes = [...(this.rows || [])];
            }
        },
        isChecked(row: IBaseTableRow) {
            return !!this.checkboxes.find((checkbox) => checkbox.id === row.id);
        },
        toggleCheckbox(row: IBaseTableRow) {
            const isChecked = this.isChecked(row);
            if (isChecked) {
                const index = this.checkboxes.findIndex(
                    (checkbox: IBaseTableRow) => checkbox.id === row.id
                );
                this.checkboxes.splice(index, 1);
            } else {
                this.checkboxes.push(row);
            }
        },
        handleCheckboxClick(e: Event) {
            e.stopPropagation();
        },
        getColumnDate(date: number) {
            return getFormattedDate(date);
        },
        getColumnHour(date: number) {
            return getFormattedHour(date);
        },
        onMouseEnterRow(rowIndex: any) {
            this.$emit('update:hoveredRowId', rowIndex);
        },
        onMouseLeaveRow() {
            this.$emit('update:hoveredRowId', undefined);
        },
        handleOptionsClick(event: any, rowIndex: number) {
            event.stopPropagation();
            this.setFocusedRowByIndex(rowIndex);
        },
        setFocusedRowByIndex(index: number) {
            this.focusedRow = index;
        },
        handleRowClick(rowData: any) {
            this.$emit('rowClick', rowData);
        },
        isRowActive(rowId?: string) {
            return this.activeRowId?.length && this.activeRowId === rowId;
        }
    },
    data() {
        return {
            focusedRow: -1,
            currentPage: this.activePage,
            hoveredRow: this.hoveredRowId,
            scrollY: 0,
            scrollX: 0
        };
    }
});
</script>

<style lang="less">
.datatable-cell-size {
    height: 56px !important;
}
.datatable-table {
    border-collapse: separate !important;
    border-spacing: 0 !important;
}
</style>
