import type { StoreState } from '@/store/index';
import type { Store } from 'vuex';
import { STORE_STORAGE_KEY } from '@/utils/Constants';

export default (
    store: Store<StoreState>,
    moduleId: string,
    persistKeys: string[]
) => {
    initialize(store, moduleId, persistKeys);

    store.subscribe((mutation) => {
        if (mutation.type.includes('/')) {
            const moduleKey = mutation.type.split('/')[0];
            // @ts-ignore
            const registeredModules = store._modules.root._children;
            if (
                moduleKey === moduleId &&
                registeredModules &&
                registeredModules[moduleKey]
            )
                localStorage.setItem(
                    `${STORE_STORAGE_KEY}__${moduleKey}`,
                    JSON.stringify(registeredModules[moduleKey].state)
                );
        }
    });
};

function initialize(
    store: Store<StoreState>,
    moduleId: string,
    persistKeys: string[]
) {
    const persistedState = JSON.parse(
        localStorage.getItem(`${STORE_STORAGE_KEY}__${moduleId}`) || '{}'
    );
    const filteredPersistedState = Object.keys(persistedState)
        .filter((key) => persistKeys.includes(key))
        .reduce((acc, curr) => {
            return {
                ...acc,
                [curr]: persistedState[curr]
            };
        }, {});
    store.commit(`${moduleId}/CLEAN_STATE`, filteredPersistedState);
}
