<template>
    <div class="d-ps-relative d-w100p d-h100p" v-if="iframeLoading">
        <div
            class="d-ps-absolute d-d-flex d-jc-center d-ai-center d-w100p d-h100p"
        >
            <dt-button loading kind="inverted" />
        </div>
    </div>
    <div class="d-ps-relative d-w100p d-h100p" v-if="iframeError">
        <div
            class="d-ps-absolute d-d-flex d-jc-center d-ai-center d-w100p d-h100p"
        >
            <div class="d-d-flex d-ai-center d-mx32">
                <dt-icon class="d-fc-critical" name="alert-circle" size="600" />
                <span class="d-p8">{{
                    errorMessage ||
                    $t('An error has occurred trying to load the merge client')
                }}</span>
            </div>
        </div>
    </div>
    <iframe
        v-show="!iframeLoading && !iframeError"
        class="d-baw0 d-w100p d-h100p"
        ref="iframeRef"
        :class="iframeClass"
        v-bind="$attrs"
        :src="src"
        referrerpolicy="no-referrer"
        @load="onIframeLoad"
        @error="onIframeError"
    ></iframe>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import { DtButton, DtIcon } from '@dialpad/dialtone/vue3';

export default defineComponent({
    components: {
        DtButton,
        DtIcon
    },
    props: {
        src: {
            type: String as PropType<string>
        },
        errorMessage: {
            type: String as PropType<string>
        },
        iframeClass: {
            type: String as PropType<string>,
            default: ''
        }
    },
    mounted() {
        window.addEventListener(
            'message',
            (e) => {
                this.$emit('message', e);
            },
            false
        );
    },
    methods: {
        onIframeLoad() {
            this.iframeLoading = false;
        },
        onIframeError() {
            this.iframeError = true;
            this.iframeLoading = false;
        }
    },
    data: function () {
        return {
            iframeLoading: true,
            iframeError: false
        };
    }
});
</script>
