<template>
    <div class="d-p24">
        <h1 class="d-gc12 d-fc-black-800 d-fw-bold d-mb24">
            {{ $t('Engagement') }}
        </h1>
        <dt-tab-group size="sm" selected="entry-page-panel">
            <template #tabs>
                <dt-tab id="entry-page" panel-id="entry-page-panel">{{
                    $t('Entry pages')
                }}</dt-tab>
                <dt-tab id="tooltip" panel-id="tooltip-panel">{{
                    $t('Tooltips')
                }}</dt-tab>
            </template>

            <dt-tab-panel
                id="entry-page-panel"
                tab-id="entry-page"
                class="d-mt16"
            >
                <div
                    class="d-card d-gc12 d-fs-100 d-d-flex d-jc-center d-fc-black-800 d-mb16"
                >
                    <div class="d-card__header">
                        <p class="d-fs-200 d-fw-bold">
                            {{ $t('Traffic sources') }}
                        </p>
                    </div>
                    <div
                        class="d-card__content d-d-flex d-ai-center d-jc-center d-fd-column d-h332"
                    >
                        <dt-icon name="bar-chart" size="800" />
                        <p class="d-fw-bold">{{ $t('No activity') }}</p>
                    </div>
                </div>
                <div
                    class="d-card d-gc12 d-fs-100 d-d-flex d-jc-center d-fc-black-800"
                >
                    <div class="d-card__header">
                        <p class="d-fs-200 d-fw-bold">
                            {{ $t('Show traffic from') }}
                        </p>
                    </div>
                    <div
                        class="d-card__content d-d-flex d-ai-center d-jc-center d-fd-column d-h332"
                    >
                        <dt-icon name="bar-chart" size="800" />
                        <p class="d-fw-bold">{{ $t('No activity') }}</p>
                    </div>
                </div>
            </dt-tab-panel>
            <dt-tab-panel id="tooltip-panel" tab-id="tooltip" class="d-mt16">
                <div
                    class="d-card d-gc12 d-fs-100 d-d-flex d-jc-center d-fc-black-800"
                >
                    <div class="d-card__header">
                        <p class="d-fs-200 d-fw-bold">
                            {{ $t('Tooltips on') }}
                        </p>
                    </div>
                    <div
                        class="d-card__content d-d-flex d-ai-center d-jc-center d-fd-column d-h332"
                    >
                        <dt-icon name="bar-chart" size="800" />
                        <p class="d-fw-bold">{{ $t('No activity') }}</p>
                    </div>
                </div>
            </dt-tab-panel>
        </dt-tab-group>
    </div>
</template>

<script lang="ts">
import { DtTabGroup, DtTab, DtTabPanel, DtIcon } from '@dialpad/dialtone/vue3';
import { defineComponent } from 'vue';

export default defineComponent({
    components: { DtTabGroup, DtTab, DtTabPanel, DtIcon }
});
</script>
