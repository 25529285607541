<template>
    <div class="d-d-flex d-fd-column">
        <default-template-view
            :title="$t('Responses')"
            :title-description="
                $t(
                    'A response is a custom piece of content that the chatbot will respond with.'
                )
            "
            :search-placeholder="$t('Search responses')"
            :table-data="tableData"
            :render-options="renderOptions"
            :columns="columns"
            :is-fetching="isFetching"
            :has-loaded="hasLoaded"
            :total-items="totalHits"
            @table-row-click="handleOpenResponse"
            v-model:sort="sortBy"
            v-model:search="search"
            v-model:on-selected-items="selectedResponses"
            v-model:labels="tags"
            :has-filters="hasFilters"
            v-model:on-page-change="activePage"
            :has-error="hasError && tagsError"
            table-fixed-header
            :item-type="NodeType.Response"
        >
            <template #selectedItemsActions>
                <div class="d-d-flex d-ml8" v-if="selectedResponses?.length">
                    <dt-button
                        class="d-mr8"
                        kind="danger"
                        importance="outlined"
                        size="xs"
                        v-if="
                            selectedResponses.length &&
                            selectedStatus[0] !== nodeStatus.Deleted
                        "
                        @click="handleUpdateStatus(nodeStatus.Deleted)"
                    >
                        {{ $t('Archive') }}
                    </dt-button>
                    <dt-button
                        class="d-mr8"
                        importance="outlined"
                        size="xs"
                        v-if="
                            selectedResponses.length &&
                            selectedStatus[0] === nodeStatus.Unpublished
                        "
                        @click="handleUpdateStatus(nodeStatus.Published)"
                    >
                        {{ $t('Publish selected') }}
                    </dt-button>
                    <dt-button
                        class="d-mr8"
                        importance="outlined"
                        size="xs"
                        v-if="
                            selectedResponses.length &&
                            selectedStatus[0] !== nodeStatus.Unpublished
                        "
                        @click="handleUpdateStatus(nodeStatus.Unpublished)"
                    >
                        {{ $t('Revert to draft') }}
                    </dt-button>
                </div>
            </template>
            <template #actionButtons>
                <dt-button
                    v-feature="'node_editor'"
                    class="d-truncate"
                    size="xs"
                    @click="handleShowPrompt"
                >
                    {{ $t('Create response') }}
                </dt-button>
            </template>
            <template #filters>
                <filter-popover
                    id="responseStatusPopover"
                    type="radio"
                    class="d-mr8"
                    :options="statusFilters"
                    title="status"
                    v-model:selected-options="statusSelectedFilters"
                    :fetch="fetchResponses"
                    :total-hits="totalHits"
                    :is-fetching="isFetching"
                    capitalize
                    hide-checkboxes
                    close-on-select
                    dialog-style="d-w164"
                />
                <filter-popover
                    id="labelPopover"
                    :fetch="fetchResponses"
                    :options="tags"
                    v-model:selected-options="labelsSelectedFilters"
                    title="labels"
                    :is-fetching="isFetching"
                    with-search
                />
            </template>
        </default-template-view>
        <response-title-modal
            :org-id="orgId"
            v-model:show-prompt="showPrompt"
        />
        <empty-state
            v-if="!nodes?.length && !isFetching && !hasError"
            :item-type="NodeType.Response"
            :has-filters="hasFilters"
        >
            <template #subtitle>
                <dt-button link @click="handleShowPrompt">
                    {{ $t('Create one') }}
                </dt-button>
            </template>
        </empty-state>
    </div>
</template>

<script lang="tsx">
import { defineComponent, h, inject } from 'vue';
import { useRoute } from 'vue-router';

import {
    type NodeStatus as NodeStatusType,
    type Knowledgebase,
    type ListTagsOKBody,
    NodeStatus,
    type NodeDetail,
    NodeType
} from '@/open-api';

import type { ApiService } from '@/services/Api.service';

import { DtButton, DtInput } from '@dialpad/dialtone/vue3';

import {
    type Response,
    RESPONSE_STATES,
    transformNodeToResponse
} from '@/utils/types/Response';
import {
    capitalizeString,
    handleRequest,
    type IFilter,
    replaceQueryFilterParams
} from '@/utils/Common';
import type { INotification } from '@/utils/types/Notification';
import { DEFAULT_ITEMS_PER_PAGE, NOTICE_KINDS } from '@/utils/Constants';

import DefaultTemplateView from '@/views/DefaultTemplateView.vue';

import LabelTag from '@/components/label-tag/LabelTag.vue';
import FilterPopover from '@/components/filter-popover/FilterPopover.vue';
import type {
    IBaseTableColumn,
    IBaseTableRow
} from '@/components/base-table/BaseTable.types';
import ResponseTitleModal from '@/components/node-editor/ResponseTitleModal.vue';
import { POPOVER_TYPES } from '@/components/menu-popover/MenuPopover.types';
import EmptyState from '@/components/empty-state/EmptyState.vue';
import { EventBusService } from '@/services/EventBus.service';
import { Topic } from '../../backend/pubsub/index';
import type { fetchPayload } from '@/store/ResponsesModule';

export default defineComponent({
    components: {
        FilterPopover,
        DefaultTemplateView,
        ResponseTitleModal,
        EmptyState,
        DtButton,
        DtInput
    },
    setup() {
        const orgId: string = inject('orgId')!;
        const $route = useRoute();
        const selectedStatus = $route.query.status
            ? [$route.query.status?.toString()]
            : [NodeStatus.Published];
        const selectedLabels = $route.query.labels
            ? $route.query.labels?.toString().split(',')
            : [];

        return {
            orgId,
            selectedStatus,
            selectedLabels
        };
    },
    async mounted() {
        await this.fetchResponses();
        await this.fetchTags();
        const notificationMsg = this.$route.query.notification as string;

        if (notificationMsg) {
            this.$store.commit(`${this.orgId}/addNotification`, {
                kind: NOTICE_KINDS.SUCCESS,
                title: `${this.$t('Response')} ${this.$t(notificationMsg)}`,
                message:
                    notificationMsg === 'published'
                        ? this.$t(
                              'Your chatbot will prioritize this response over document-based results. You can also use this response as a default message.'
                          )
                        : ''
            } as INotification);
            this.$router.replace({ query: { notification: null } });
        }

        this.nodeEventBus = new EventBusService(
            this.orgId,
            Topic.node_event,
            NodeType.Response,
            this.knowledgebase.id,
            {
                created: this.handleCreateNodeEvent,
                updated: this.handleUpdateNodeEvent,
                deleted: this.handleDeleteNodeEvent
            },
            this.fetchResponses
        );
    },
    unmounted() {
        this.nodeEventBus?.destroy();
    },
    watch: {
        activePage(newPage) {
            if (newPage) {
                this.fetchResponses();
            }
        },
        search(newSearch: string) {
            this.search = newSearch;
            this.fetchResponses();
        },
        sortBy: {
            handler(newSort) {
                if (newSort) {
                    this.fetchResponses();
                }
            }
        }
    },
    computed: {
        NodeType() {
            return NodeType;
        },
        knowledgebase(): Knowledgebase {
            return this.$store.getters[`${this.orgId}/currentKnowledgebase`];
        },
        hasFilters(): boolean {
            return (
                !!this.labelsSelectedFilters?.length || !!this.search?.length
            );
        },
        tableData(): Response[] {
            return (
                this?.nodes?.map((node) => transformNodeToResponse(node)) || []
            );
        },
        apiService(): ApiService {
            return this.$store.getters[`${this.orgId}/apiService`];
        },
        statusSelectedFilters: {
            get(): string[] {
                return this.selectedStatus;
            },
            set(status: string[]) {
                this.selectedStatus.length = 0;
                if (status[0]) this.selectedStatus.push(status[0]);
                this.onStatusFilterChange();
            }
        },
        labelsSelectedFilters: {
            get(): string[] {
                return this.selectedLabels;
            },
            set(labels: string[]) {
                this.selectedLabels.length = 0;
                if (labels.length && labels[0])
                    this.selectedLabels.push(...labels);
                this.onLabelsFilterChange();
            }
        },
        nodes(): NodeDetail[] {
            return this.$store.getters[`${this.orgId}/responses/list`];
        },
        isFetching(): boolean {
            return this.$store.getters[`${this.orgId}/responses/fetching`];
        },
        hasError(): boolean {
            return this.$store.getters[`${this.orgId}/responses/error`];
        },
        totalHits(): number {
            return this.$store.getters[`${this.orgId}/responses/totalHits`];
        }
    },
    methods: {
        async fetchResponses() {
            const offset = (this.activePage - 1) * this.itemsPerPage;

            await this.$store.dispatch(`${this.orgId}/responses/fetch`, {
                kbId: this.knowledgebase?.id,
                status: this.statusSelectedFilters[0],
                search: this.search,
                labels: this.selectedLabels,
                sort: this.sortBy.sort,
                order: this.sortBy.asc ? 'asc' : 'desc',
                limit: this.itemsPerPage,
                offset
            } as fetchPayload);
        },
        async fetchTags() {
            this.isFetchingTags = true;
            const res = await handleRequest<ListTagsOKBody>(
                this.$store.getters[
                    `${this.orgId}/apiService`
                ]?.knowledge.listTags(
                    this.$store.getters[`${this.orgId}/authToken`],
                    this.knowledgebase?.id
                ),
                this.orgId,
                true
            );
            this.isFetchingTags = false;
            this.tagsError = !!res.error;

            if (res?.data) {
                this.tags =
                    res.data?.tags?.map((tag: string) => ({
                        label: tag,
                        value: tag
                    })) || [];
            }
        },
        async bulkUpdateStatus(nodesArr: NodeDetail[]) {
            await this.$store.dispatch(`${this.orgId}/responses/bulkUpdate`, {
                kbId: this.knowledgebase?.id,
                nodesArray: nodesArr
            });
            this.selectedResponses = [];
        },
        handleUpdateStatus(status: NodeStatusType) {
            const nodesArr = this.selectedResponses.map((doc: any) => ({
                id: doc.data.id,
                status
            }));

            this.bulkUpdateStatus(nodesArr);
        },
        handleShowPrompt() {
            this.showPrompt = true;
        },
        handleOpenResponse(rowData: IBaseTableRow<NodeDetail>) {
            this.$router.push({
                name: 'node_editor',
                params: {
                    nodeId: rowData.data.id
                },
                query: {
                    nodeType: NodeType.Response
                }
            });
        },
        onStatusFilterChange() {
            replaceQueryFilterParams(
                'status',
                this.statusSelectedFilters,
                this.$route,
                this.$router
            );
        },
        onLabelsFilterChange() {
            replaceQueryFilterParams(
                'labels',
                this.labelsSelectedFilters,
                this.$route,
                this.$router
            );
        },
        updateResponse(response: Response, status: NodeStatusType) {
            const nodesArr = [
                {
                    id: response.id,
                    status
                }
            ];

            this.bulkUpdateStatus(nodesArr);
        },
        renderOptions() {
            switch (this.selectedStatus[0]) {
                case this.nodeStatus.Published:
                    return [
                        {
                            title: 'Revert to draft',
                            action: (rowData: Response) => {
                                this.updateResponse(
                                    rowData,
                                    this.nodeStatus.Unpublished
                                );
                            }
                        },
                        {
                            title: 'Archive',
                            kind: POPOVER_TYPES.DANGER,
                            action: (rowData: Response) => {
                                this.updateResponse(
                                    rowData,
                                    this.nodeStatus.Deleted
                                );
                            }
                        }
                    ];

                case this.nodeStatus.Deleted:
                    return [
                        {
                            title: 'Publish',
                            action: (rowData: Response) => {
                                this.updateResponse(
                                    rowData,
                                    this.nodeStatus.Published
                                );
                            }
                        },
                        {
                            title: 'Revert to draft',
                            action: (rowData: Response) => {
                                this.updateResponse(
                                    rowData,
                                    this.nodeStatus.Unpublished
                                );
                            }
                        }
                    ];

                case this.nodeStatus.Unpublished:
                    return [
                        {
                            title: 'Publish',
                            action: (rowData: Response) => {
                                this.updateResponse(
                                    rowData,
                                    this.nodeStatus.Published
                                );
                            }
                        },
                        {
                            title: 'Archive',
                            kind: POPOVER_TYPES.DANGER,
                            action: (rowData: Response) => {
                                this.updateResponse(
                                    rowData,
                                    this.nodeStatus.Deleted
                                );
                            }
                        }
                    ];
                default:
                    return [];
            }
        },
        handleCreateNodeEvent(message: NodeDetail) {
            this.$store.dispatch(
                `${this.orgId}/responses/handleResponseCreate`,
                message
            );
        },
        handleUpdateNodeEvent(message: NodeDetail) {
            this.$store.dispatch(
                `${this.orgId}/responses/handleResponseUpdate`,
                message
            );
        },
        handleDeleteNodeEvent(message: NodeDetail) {
            this.$store.dispatch(
                `${this.orgId}/responses/handleResponseDelete`,
                message
            );
        }
    },
    data() {
        return {
            search: '' as string | undefined,
            hasLoaded: false,
            isFetchingTags: false,
            tagsError: false,
            activePage: 1,
            itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
            sortBy: { sort: 'title', asc: true },
            markdown: '',
            activeRowId: '',
            RESPONSE_STATES,
            selectedResponses: [] as IBaseTableRow[],
            tags: [] as IFilter[],
            nodeStatus: NodeStatus,
            statusFilters: [
                ...Object.values(NodeStatus).map((status) => ({
                    label:
                        status === NodeStatus.Deleted
                            ? this.$t('archived')
                            : this.$t(capitalizeString(status)),
                    value: status
                }))
            ] as IFilter[],
            columns: [
                {
                    key: 'title',
                    type: 'string',
                    title: 'Title',
                    fixed: 'left',
                    template: (response: Response) => {
                        const labels = response.labels;

                        return h(
                            <div className="d-py12 d-px16">
                                <p className="d-fc-black-900 d-truncate d-to-ellipsis">
                                    {response.title}
                                </p>
                                <LabelTag labels={labels}></LabelTag>
                            </div>
                        );
                    },
                    sort: 'title'
                },
                {
                    key: 'queries',
                    type: 'string',
                    title: 'Questions',
                    width: '13rem',
                    sort: 'queries_count'
                },
                {
                    key:
                        this.$store.getters[`${this.orgId}/currentChatbot`]
                            .license_type === 'DSS'
                            ? 'workflows'
                            : 'automations',
                    type: 'string',
                    title:
                        this.$store.getters[`${this.orgId}/currentChatbot`]
                            .license_type === 'DSS'
                            ? 'Workflows'
                            : 'Automations',
                    width: '13rem',
                    sort:
                        this.$store.getters[`${this.orgId}/currentChatbot`]
                            .license_type === 'DSS'
                            ? 'workflows_count'
                            : 'webexts_count',
                    template: (response: Response) => {
                        const isDSS =
                            this.$store.getters[`${this.orgId}/currentChatbot`]
                                .license_type === 'DSS';
                        return h(
                            <div className="d-py12 d-px16 d-d-flex d-jc-flex-end">
                                {isDSS ? response.workflows : response.webexts}
                            </div>
                        );
                    }
                },
                {
                    key: 'updated_at',
                    type: 'datetime',
                    title: 'Last updated',
                    width: '13rem',
                    sort: 'updated_at'
                }
            ] as IBaseTableColumn<Response>[],
            showPrompt: false,
            nodeEventBus: undefined as EventBusService | undefined
        };
    }
});
</script>
