<template>
    <aside class="d-ps-relative d-wmn216 d-bgc-secondary nav">
        <div
            class="d-d-flex d-jc-center d-ai-center d-fs-200 d-h48 d-bgc-purple-300 d-by d-byw1 d-bc-subtle"
        >
            <span class="d-fc-neutral-white">{{ orgName }}</span>
        </div>
        <ul class="d-ls-reset">
            <li
                v-for="(item, index) in navigationItems"
                :key="`nav-item-${index}`"
                class="d-d-flex d-h32 nav-item"
                :class="{
                    disabled: !!item.disabled,
                    ['h:d-bgc-moderate']: !item.disabled,
                    ['is__active d-bgc-moderate']: isVisible(index),
                    ['d-mb8']: isLastNavItem(index),
                    ['d-bb d-bbw1 d-bc-black-200']: !isLastNavItem(index)
                }"
            >
                <div class="d-d-flex d-ai-center d-jc-flex-end d-wmn24">
                    <dt-icon size="200" v-if="item?.icon" :name="item?.icon" />
                </div>
                <span
                    v-if="item?.disabled"
                    class="d-td-none d-fs-200 d-pl12 d-pr8 d-d-flex d-ai-center d-w100p sidebar-link d-c-default d-fc-primary"
                    :class="{
                        ['d-fc-purple-400 d-bgc-moderate']: isVisible(index)
                    }"
                >
                    {{ item.text }}
                </span>
                <router-link
                    v-else
                    :to="item.href"
                    class="d-td-none d-fs-200 d-pl12 d-pr8 d-d-flex d-ai-center d-w100p sidebar-link d-fc-primary"
                    :class="{
                        ['d-fc-purple-400']: isVisible(index)
                    }"
                    @click="open(item, index)"
                >
                    {{ item.text }}
                </router-link>
            </li>
        </ul>
    </aside>
</template>

<script lang="ts">
import { defineComponent, inject, type Ref, Transition } from 'vue';
import { type RouteLocationNormalizedLoaded, RouterLink } from 'vue-router';
import { DtIcon } from '@dialpad/dialtone/vue3';
import { NAVIGATION_ITEMS } from '@/utils/Constants';

export interface NavigationItem {
    text: string;
    href: string;
    name?: string;
    disabled?: boolean;
    limited?: boolean;
    icon?: string;
    soon?: boolean;
    mocked?: boolean;
    query?: {
        [x: string]: any;
    };
}

export default defineComponent({
    components: {
        RouterLink,
        Transition,
        DtIcon
    },
    watch: {
        '$router.currentRoute': {
            handler(oldRoute, $newRoute: Ref<RouteLocationNormalizedLoaded>) {
                const currentRoute = $newRoute?.value;
                if (!currentRoute) return;
                const licenseType =
                    currentRoute.path.startsWith('/self-service') ||
                    currentRoute.path === '' ||
                    currentRoute.name === '/'
                        ? '/self-service'
                        : currentRoute.path.startsWith('/agent-assist')
                          ? '/agent-assist'
                          : currentRoute.path.startsWith('/sales-assist')
                            ? '/sales-assist'
                            : currentRoute.path;

                this.navigationItems.forEach(
                    (nav: { text: string; href: string }, index: number) => {
                        if (nav.href.includes(licenseType)) {
                            this.active = index;
                            this.$forceUpdate();
                        }
                    }
                );
            },
            immediate: true,
            deep: true
        }
    },

    setup() {
        const orgId: string = inject('orgId')!;

        const active = -1;
        return {
            orgId,
            active
        };
    },
    methods: {
        open(navigationItem: NavigationItem, index: number): void {
            if (this.isVisible(index)) return;
            else this.active = index;
        },
        start(el: any): void {
            el.style.height = el.scrollHeight + 'px';
        },
        end(el: any): void {
            el.style.height = '';
        },
        isVisible(index: number) {
            return this.active === index;
        },
        isLastNavItem(index: number): boolean {
            return this.navigationItems?.length === index + 1;
        }
    },
    computed: {
        orgName(): string {
            return this.$store?.getters[`${this.orgId}/orgName`];
        },
        orgLicenses(): any[] {
            return this.$store?.getters[`${this.orgId}/orgLicenses`];
        },
        navigationItems(): any[] {
            return Object.keys(NAVIGATION_ITEMS)
                .filter((key: any) => this.orgLicenses?.includes(key))
                .reduce((acc: any, key: any) => {
                    acc.push(NAVIGATION_ITEMS[key]);

                    if (key === 'AAA') {
                        acc.push({
                            text: 'Automations',
                            href: '/apps-integrations'
                        });
                    }
                    return acc;
                }, []);
        }
    }
});
</script>

<style lang="less" scoped>
.overlay {
    -webkit-box-shadow: inset -8px 0px 7px -12px #616161;
    box-shadow: inset -8px 0px 7px -12px #616161;
}
</style>
