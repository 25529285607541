import {
    type ContentNode,
    type ListNodesResponse,
    type NodeDetail,
    NodeStatus
} from '@/open-api';

export enum DOCUMENT_STATES {
    PUBLISHED = 'Published',
    DRAFT = 'Draft',
    ARCHIVED = 'Archived'
}

export enum NODE_TYPES {
    CHATTERING = 'chattering',
    RESPONSE = 'response',
    CONTENT = 'content',
    WORKFLOW = 'workflow',
    CHATTERFLOW = 'chatterflow'
}

export interface Document {
    id: string;
    title: string;
    updated_at?: number;
    status?: DOCUMENT_STATES;
    labels?: string[];
    content?: ContentNode;
}

export function transformNodeStatus(status?: NodeStatus | null) {
    switch (status) {
        case NodeStatus.Published:
            return DOCUMENT_STATES.PUBLISHED;
        case NodeStatus.Unpublished:
            return DOCUMENT_STATES.DRAFT;
        case NodeStatus.Deleted:
            return DOCUMENT_STATES.ARCHIVED;
        default:
            return undefined;
    }
}

export function transformDocumentState(status?: DOCUMENT_STATES) {
    switch (status?.toString()) {
        case DOCUMENT_STATES.PUBLISHED:
            return NodeStatus.Published;
        case DOCUMENT_STATES.DRAFT:
            return NodeStatus.Unpublished;
        case DOCUMENT_STATES.ARCHIVED:
            return NodeStatus.Deleted;
        default:
            return undefined;
    }
}
export function transformDocumentData(data: ListNodesResponse): Document[] {
    return (
        data?.nodes?.map((node: NodeDetail) => ({
            id: node.id || '',
            title: node?.title || '',
            updated_at: node?.updated_at,
            status: transformNodeStatus(node?.status),
            labels: node?.labels,
            content: node?.content
        })) || []
    );
}
