<template>
    <div class="d-d-flex d-w100p d-jc-space-between d-mb16 d-ai-flex-start">
        <div class="d-d-flex d-fd-column">
            <span class="d-label-base">
                {{ $t('Use Generative Ai Assistance') }}
            </span>
            <div class="d-description">
                {{
                    $t(
                        'Allow the chatbot to respond with natural sounding responses using information in your knowledge base. Only available in English.'
                    )
                }}
            </div>
        </div>
        <dt-toggle
            :checked="isGenerative"
            :disabled="shouldDisableGenAi"
            aria-label="Use Generative Ai Assistance"
            @change="onGenerativeAiToggle"
        />
    </div>
</template>
<script lang="ts">
import {
    DtCheckbox,
    DtToggle,
    VALIDATION_MESSAGE_TYPES
} from '@dialpad/dialtone/vue3';
import { defineComponent, inject, type PropType } from 'vue';

export default defineComponent({
    props: {
        generative: {
            type: Boolean as PropType<boolean>,
            required: true
        },
        isSaving: {
            type: Boolean as PropType<boolean>
        }
    },
    components: {
        DtCheckbox,
        DtToggle
    },
    setup() {
        const orgId = inject('orgId') as string;
        return {
            orgId
        };
    },
    mounted() {
        if (this.baaEnabled) {
            // Make sure that we are sanity checking the gen AI is disabled when handling a BAA customer
            this.isGenerative = false;
        }
    },
    computed: {
        VALIDATION_MESSAGE_TYPES() {
            return VALIDATION_MESSAGE_TYPES;
        },
        // This is done so that the function coverage isn't affected by computed props
        /* v8 ignore next 90 */
        isGenerative: {
            get(): boolean {
                return this.generative;
            },
            set(isGenerative: boolean) {
                this.$emit('update:generative', isGenerative);
            }
        },
        baaEnabled(): boolean {
            return this.$store.getters[`${this.orgId}/baaEnabled`];
        },
        shouldDisableGenAi(): boolean {
            return this.baaEnabled;
        }
    },
    methods: {
        onGenerativeAiToggle(isGenerative: any) {
            this.isGenerative = isGenerative;
        }
    }
});
</script>
