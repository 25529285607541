import {
    MONTH_NAMES,
    DATA_KEYS,
    LOCALES,
    KNOWLEDGE_ROUTES,
    NOTICE_KINDS,
    WEEK_DAYS,
    KNOWLEDGE_SUB_ROUTES
} from '@/utils/Constants';
import type { AxiosError, AxiosResponse } from 'axios';
import store from '@/store';
import { featureIsEnabled } from '@/directives/feature.directive';
import type { ApiService } from '@/services/Api.service';
import type {
    RouteLocationNormalized,
    Router,
    RouteRecordName
} from 'vue-router';
import type { Knowledgebase, Widget } from '@/open-api';
import { LicenseType } from '@/open-api';
import { APP_TYPES } from '@/utils/types/App';

export interface IFilter {
    label: string;
    value: string;
}

export interface IQuestion {
    text: string;
    status?: string;
    count?: number;
    id?: string;
    muted?: boolean;
    selected?: boolean;
}

export function uuidv4() {
    // @ts-ignore
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
            c ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
    );
}

export function getDateFromUnixTimestamp(timestamp: number) {
    return new Date(timestamp * 1000);
}

// [DD]-[MM]
// e.g.: 22-01
export function getGraphsFormattedDate(timestamp: number) {
    const date = getDateFromUnixTimestamp(timestamp);
    const day = new Date(date).getDate();
    const month = new Date(date).getMonth() + 1;
    return `${day < 10 ? 0 : ''}${day}-${month < 10 ? 0 : ''}${month}`;
}

// [Month] [Day], [Year]
// e.g.: December 5, 2022
export function getFormattedDate(timestamp: number) {
    const date = new Date(getDateFromUnixTimestamp(timestamp));
    return `${MONTH_NAMES[new Date(date).getUTCMonth()]} ${new Date(
        date
    ).getUTCDate()}, ${new Date(date).getFullYear()}`;
}

export function getFullDateFromTimestamp(timestamp: number, $translate: any) {
    const date = new Date(getDateFromUnixTimestamp(timestamp));
    return `${$translate(WEEK_DAYS[date.getDay()])}, ${$translate(MONTH_NAMES[new Date(date).getUTCMonth()])} ${new Date(
        date
    ).getUTCDate()}, ${new Date(date).getFullYear()}`;
}

// [hh]:[mm]
// e.g.: 17:21
export function getFormattedHour(timestamp: number) {
    const date = new Date(getDateFromUnixTimestamp(timestamp));
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesStr = minutes < 10 ? '0' + minutes : minutes;

    return `${hours}:${minutesStr} ${ampm}`;
}

export function getLocaleDate(
    timestamp: Date,
    locale: string,
    options: Intl.DateTimeFormatOptions,
    resolution = 'daily' as 'daily' | 'hourly'
) {
    return resolution === 'daily'
        ? new Date(timestamp).toLocaleDateString(locale, options)
        : new Date(timestamp).toLocaleTimeString(locale, options);
}

export function getFormattedDateByString(timestamp: string) {
    const date = new Date(timestamp);
    return `${MONTH_NAMES[new Date(date).getUTCMonth()]} ${new Date(
        date
    ).getUTCDate()} ${new Date(date).getFullYear()}`;
}

export function getFormattedHourByString(timestamp: string) {
    const date = new Date(timestamp);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesStr = minutes < 10 ? '0' + minutes : minutes;

    return `${hours}:${minutesStr} ${ampm}`;
}

/**
 *
 * @param {Array} originalArray
 * @param {Array} newArray
 * @return {Array} Array with items not duplicated
 */
export function unionByName(originalArray: any[], newArray: any[]) {
    return newArray.reduce((acc, val) => {
        if (originalArray.some((item) => item.file.name === val.file.name))
            return acc;
        else return [...acc, val];
    }, originalArray);
}

export interface Response<T> {
    data?: T;
    error: boolean | AxiosError;
}

export async function handleRequest<T>(
    request: Promise<AxiosResponse<T>>,
    orgId: string,
    silent = false,
    stall: number | undefined = undefined
): Promise<Response<T>> {
    return new Promise<Response<T>>((resolve) => {
        request
            ?.then((response) => {
                if (stall) {
                    setTimeout(() => {
                        resolve({ data: response.data, error: false });
                    }, stall);
                } else {
                    resolve({ data: response.data, error: false });
                }
            })
            .catch((error: AxiosError<T>) => {
                console.error('DX CONSOLE NETWORK ERROR:', error);
                if (!silent) {
                    if (error.response?.status === 401) {
                        store.commit(`${orgId}/addNotification`, {
                            message: 'You are not authenticated',
                            kind: NOTICE_KINDS.ERROR
                        });
                    } else {
                        store.commit(`${orgId}/addNotification`, {
                            message: error.message,
                            kind: NOTICE_KINDS.ERROR
                        });
                    }
                }
                resolve({
                    error
                });
            });
    });
}

export function requestWaterfall(
    promises: ((previousResponse?: any) => Promise<any>)[]
) {
    return promises.reduce(
        async (responses, currentPromise, currentIndex) => {
            const allResponses = await responses;
            const previousResponse = currentIndex
                ? allResponses[currentIndex - 1]
                : undefined;
            const response = await currentPromise(previousResponse);
            allResponses.push(response);
            return allResponses;
        },
        Promise.resolve([] as any)
    );
}

export function requestParallel<T>(promises: Promise<T | any>[]) {
    return Promise.all<T | any>(promises);
}

export function isObject(value: any): boolean {
    return typeof value === 'object' && !Array.isArray(value) && value !== null;
}

export const getLabelFromDataKeys: any = (dataKey: string) => {
    switch (dataKey) {
        case DATA_KEYS.P1:
            return 'P@1';
        case DATA_KEYS.P5:
            return 'P@5';
        case DATA_KEYS.RESPONSE:
            return 'Responses';
        case DATA_KEYS.CHATTERING:
            return 'Chattering';
        case DATA_KEYS.QUERIES:
            return 'Queries';
        case DATA_KEYS.CONTENT:
            return 'Content';
        case DATA_KEYS.P_PERCEIVED:
            return 'Perceived';
        case DATA_KEYS.HELPFUL:
            return 'Helpful';
        case DATA_KEYS.ENGAGED:
            return 'Engaged';
        case DATA_KEYS.LIBRARY:
            return 'Library';
        case DATA_KEYS.RESPONSES:
            return 'Responses';
        case DATA_KEYS.REGISTERED:
            return 'Registered';
        case DATA_KEYS.VISITOR:
            return 'Visitor';
        case DATA_KEYS.UNHELPFUL:
            return 'Unhelpful';
        case DATA_KEYS.TOTAL:
            return 'Total';
        case DATA_KEYS.BOUNCED:
            return 'Bounced';
        case DATA_KEYS.RESOLVED:
            return 'Resolved';
        case DATA_KEYS.HANDED:
            return 'Handed over';
        case DATA_KEYS.ANSWERED:
            return 'Answered';
        case DATA_KEYS.UNCERTAIN:
            return 'Uncertain';
        case DATA_KEYS.ESCALATED:
            return 'Escalated';
        case DATA_KEYS.MISSING:
            return 'Missing';
        case DATA_KEYS.UNRESOLVED:
            return 'Unresolved';
        case DATA_KEYS.GREATLY_DISSATISFIED:
            return 'Greatly Dissatisfied';
        case DATA_KEYS.DISSATISFIED:
            return 'Dissatisfied';
        case DATA_KEYS.MODERATELY_DISSATISFIED:
            return 'Moderately Dissatisfied';
        case DATA_KEYS.NEUTRAL:
            return 'Neutral';
        case DATA_KEYS.MODERATELY_SATISFIED:
            return 'Moderately Satisfied';
        case DATA_KEYS.SATISFIED:
            return 'Satisfied';
        case DATA_KEYS.GREATLY_SATISFIED:
            return 'Greatly Satisfied';
        case DATA_KEYS.DETRACTORS:
            return 'Detractors';
        case DATA_KEYS.PROMOTERS:
            return 'Promoters';
        case DATA_KEYS.EMPTY:
            return 'Empty';
        default:
            return 'N/A';
    }
};

export const getChartColor: any = (
    key: string,
    darkMode: boolean
): { hex: string; rgba: string } => {
    switch (key) {
        case DATA_KEYS.P_PERCEIVED:
        case DATA_KEYS.HELPFUL:
        case DATA_KEYS.ENGAGED:
        case DATA_KEYS.REGISTERED:
        case DATA_KEYS.IMPROVE_ANSWER:
        case DATA_KEYS.ANDROID:
        case DATA_KEYS.OPENED:
        case DATA_KEYS.GREATLY_SATISFIED:
        case DATA_KEYS.RESOLVED:
        case DATA_KEYS.DEFLECTED:
        case DATA_KEYS.ANSWERED:
        case DATA_KEYS.POSITIVE:
        case DATA_KEYS.SATISFIED:
        case DATA_KEYS.PROMOTERS:
            return darkMode
                ? { hex: '#72BB75', rgba: 'rgba(114, 187, 117, 0.5)' }
                : { hex: '#3D7E40', rgba: 'rgba(61, 126, 64, 0.5)' };
        case DATA_KEYS.VISITOR:
        case DATA_KEYS.LIBRARY:
        case DATA_KEYS.CONTENT:
        case DATA_KEYS.DISAMBIGUATION:
        case DATA_KEYS.WINDOWS:
        case DATA_KEYS.P1:
        case DATA_KEYS.UNHELPFUL:
        case DATA_KEYS.MISSING_KNOWLEDGE:
        case DATA_KEYS.LINUX:
        case DATA_KEYS.DISSATISFIED:
        case DATA_KEYS.DETRACTORS:
        case DATA_KEYS.GREATLY_DISSATISFIED:
        case DATA_KEYS.NEGATIVE:
        case DATA_KEYS.MODERATELY_DISSATISFIED:
        case DATA_KEYS.MODERATELY_SATISFIED:
        case DATA_KEYS.ESCALATED:
            return darkMode
                ? { hex: '#C93671', rgba: 'rgba(201, 54, 113, 0.5)' }
                : { hex: '#922752', rgba: 'rgba(146, 39, 82, 0.5)' };
        case DATA_KEYS.BOUNCED:
        case DATA_KEYS.NOT_OPENED:
        case DATA_KEYS.EMPTY:
        case DATA_KEYS.NODATA:
            return darkMode
                ? { hex: '#E3E5E8', rgba: 'rgba(227, 229, 232, 0.5)' }
                : { hex: '#6A7381', rgba: 'rgba(106, 115, 129, 0.5)' };
        case DATA_KEYS.HANDED:
        case DATA_KEYS.UNCERTAIN:
        case DATA_KEYS.CHATTERING:
        case DATA_KEYS.AUTO_ANSWER:
        case DATA_KEYS.P5:
        case DATA_KEYS.NEUTRAL:
        case DATA_KEYS.MISSING:
        case DATA_KEYS.OSX:
        case DATA_KEYS.UNRESOLVED:
            return darkMode
                ? { hex: '#F0C166', rgba: 'rgba(240, 193, 102, 0.5)' }
                : { hex: '#F4B73F', rgba: 'rgba(244, 183, 63, 0.5)' };
        default:
            return darkMode
                ? { hex: '#68A1F8', rgba: 'rgba(104, 161, 248, 0.5)' }
                : { hex: '#4B8FF7', rgba: 'rgba(75, 143, 247, 0.5)' };
    }
};

export const roundDownNearest5 = (num: number) => {
    return Math.round(num / 5) * 5;
};

export const roundUpNearest5 = (num: number) => {
    return Math.ceil(num / 5) * 5;
};

function roundDownNearest10(num: number) {
    return Math.round(num / 10) * 10;
}

function roundUpNearest10(num: number) {
    return Math.ceil(num / 10) * 10;
}

function roundDownNearest100(num: number) {
    return Math.round(num / 100) * 100;
}

function roundUpNearest100(num: number) {
    return Math.ceil(num / 100) * 100;
}

export function getRoundNearestMinNumber(num: number) {
    switch (true) {
        case num < 10:
            return roundDownNearest5(num);
        case num < 100:
            return roundDownNearest10(num);
        case num < 1000:
            return roundDownNearest100(num);
        default:
            return roundDownNearest5(num);
    }
}

export function getRoundNearestMaxNumber(num: number) {
    switch (true) {
        case num < 10:
            return roundUpNearest5(num);
        case num < 100:
            return roundUpNearest10(num);
        case num < 1000:
            return roundUpNearest100(num);
        default:
            return roundUpNearest5(num);
    }
}

export function returnKeyValueIfExists(key: string, value: any) {
    if (value) return { [key]: value };
    else return {};
}

export function fieldsChanged<T, X>(
    original: T,
    edited: T,
    exclude: string[] = []
): X[] {
    // @ts-ignore
    return Object.keys(original).filter((key) => {
        if (exclude.includes(key)) return false;
        return original[key as keyof T] !== edited[key as keyof T];
    });
}

export function appendQueryParams(obj: any, oauthUrl: URL) {
    Object.keys(obj).forEach((key) =>
        oauthUrl.searchParams.append(key, obj[key])
    );
}

export const isValidUrl = (urlString: string) => {
    const urlPattern = new RegExp(
        '^(https?:\\/\\/)?' + // validate protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
            '(\\#[-a-z\\d_]*)?$',
        'i'
    ); // validate fragment locator
    return urlPattern.test(urlString);
};
export const buildAuthUrl = (
    provider: string,
    values: { domain: string; locale: string; kbId: string },
    config: any
) => {
    //TODO hack for tests
    if (!config?.redirectOrigin) {
        return;
    }
    const redirectUrl = new URL(config.redirectPath, config.redirectOrigin);
    const completeUrl = new URL(
        window.location.pathname + config.completePath,
        window.location.origin
    );

    appendQueryParams(
        {
            domain: values.domain,
            locale: values.locale,
            kbId: values.kbId
        },
        completeUrl
    );

    const oauthUrl = new URL(
        config.authorizeUrl,
        `https://${values.domain}.zendesk.com`
    );

    appendQueryParams(
        {
            client_id: config.clientId,
            scope: config.scope,
            redirect_uri: redirectUrl.toString(),
            response_type: config.responseType,
            state: btoa(completeUrl.toString())
        },
        oauthUrl
    );

    return oauthUrl.toString();
};

export const buildBoxAuthUrl = ({ clientId, zone }) => {
    const oauthUrl = new URL(`https://account.box.com/api/oauth2/authorize`);

    appendQueryParams(
        {
            client_id: clientId,
            response_type: 'code'
        },
        oauthUrl
    );

    return oauthUrl.toString();
};

export function createDebounce() {
    let timeout: any = null;
    return function (fnc: any, delayMs?: number) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            if (fnc) fnc();
        }, delayMs || 500);
    };
}

export function composeKnowledgeUrl(href: string, route: any) {
    if (!route || !route.params?.id) return '';
    return `/knowledgebase/${route.params?.id}${href}`;
}

export const calculatePagesCount = (pageSize: number, totalCount: number) => {
    // we suppose that if we have 0 items we want 1 empty page
    return totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize);
};

export function convertLocaleToLanguage(locale?: string) {
    if (!locale) return 'Unknown language';
    return LOCALES[locale as keyof typeof LOCALES] || locale;
}

export function convertLanguageToLocale(language?: string) {
    if (!language) return;
    return Object.keys(LOCALES).find((localeKey) => {
        if (LOCALES[localeKey as keyof typeof LOCALES] === language)
            return localeKey;
    });
}

export function convertToBoolean(value: any): boolean {
    return String(value).toLowerCase() === 'true';
}

export function getKey<T>(value: string, enumerable: { [x: string]: T }) {
    let enumKey = undefined;
    Object.keys(enumerable).forEach((key) => {
        if (enumerable[key] === value) {
            enumKey = key;
        }
    });
    return enumKey;
}

export function getNodeStatus(obj: any, str: string) {
    const objKey = Object.keys(obj).find((k: any) => obj[k] === str);

    return objKey ? obj[objKey] : '';
}

export function areArrayOfObjectsEqual(
    first_array_of_objects: any,
    second_array_of_objects: any
) {
    return (
        first_array_of_objects.length === second_array_of_objects.length &&
        first_array_of_objects.every((element_1: any) =>
            second_array_of_objects.some((element_2: any) =>
                Object.keys(element_1).every(
                    (key) => element_1[key] === element_2[key]
                )
            )
        )
    );
}

export function checkIfImageExists(url: string, callback: any) {
    if (!url) {
        return callback(false);
    }
    const img = new Image();
    img.src = url;

    if (img.complete) {
        return callback(true);
    } else {
        img.onload = () => {
            return callback(true);
        };

        img.onerror = () => {
            return callback(false);
        };
    }
}

export function validateYouTubeURL(url: string) {
    if (url != undefined || url != '') {
        const regExp =
            /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
        const match = url.match(regExp);
        if (match && match[2].length == 11) {
            // Do anything for being valid
            // if need to change the url to embed url then use below line
            return 'https://www.youtube.com/embed/' + match[2] + '?autoplay=0';
        } else {
            // Do anything for not being valid
        }
    }
}

// As VIMEO tends to change/update its URL pattern, it is better to use oEmbed API to check the validity and get video information
export async function validateVimeoURL(url: string) {
    const vimeoPromise = fetch(`https://vimeo.com/api/oembed.json?url=${url}`);

    const response = await vimeoPromise;
    if (!response.ok) {
        // Invalid URL
        return null;
    } else {
        // valid URL
        const vimeoURL = new URL(response.url);
        const urlParam = new URLSearchParams(vimeoURL.search).get('url');
        if (urlParam) {
            const vimeoPathname = getPathname(urlParam);
            const vimeoId = vimeoPathname?.substring(
                vimeoPathname.lastIndexOf('/') + 1
            );
            return `https://player.vimeo.com/video/${vimeoId}`;
        }
    }
}

export function getPathname(url: string) {
    try {
        const website = new URL(url);
        return website.pathname;
    } catch (error) {
        if (error instanceof TypeError) {
            return '';
        }
    }
}

export const calcRatio = (number: number, total: number) => {
    return !number
        ? 0
        : total === 0
          ? 0
          : parseFloat(((number / total) * 100).toFixed(1));
};

// Returns percentage with 1 decimal, e.g.: 63.8%
export const getPercentage = (val: number, total: number): string => {
    if (val === 0 || total === 0) {
        return '0%';
    }
    const percentage = (val / total) * 100;
    return Math.round(percentage * 10) / 10 + '%';
};

export const getArrTotal = (arr: any[]) => {
    return arr.reduce((acc, currentVal) => {
        acc += currentVal;
        return acc;
    }, 0);
};

export function getLastWeek(datetime: Date): { to: number; from: number } {
    const dayOfTheWeek = datetime.getDay();
    // This represents last sunday
    const sundayDate = new Date(
        datetime.setDate(datetime.getDate() - dayOfTheWeek)
    );
    const to = sundayDate.setUTCHours(23, 59, 59);
    const from = datetime.setDate(datetime.getDate() - 7);
    return {
        to,
        from
    };
}
export function getLastMonth(datetime: Date): { to: number; from: number } {
    // Rewind to last day of previous month
    const lastDayOfMonth = new Date(datetime.setDate(0));
    const to = lastDayOfMonth.setUTCHours(23, 59, 59);
    // Set this to the first day of previous month
    const from = datetime.setDate(1);
    return {
        to,
        from
    };
}

export function getFromToDates(dateTemplate: string) {
    const currentDateTime = new Date();
    let fromDate: number,
        toDate = currentDateTime.getTime();

    // Make sure all FROM dates are at the beginning of the day
    currentDateTime.setUTCHours(0, 0, 0, 0);
    switch (dateTemplate) {
        case 'today':
            fromDate = currentDateTime.getTime();
            break;
        case 'yesterday':
            fromDate = currentDateTime.setDate(currentDateTime.getDate() - 1);
            toDate = currentDateTime.setUTCHours(23, 59, 59);
            break;
        case 'last_week': {
            const { from, to } = getLastWeek(currentDateTime);
            fromDate = from;
            toDate = to;
            break;
        }
        case 'last_month': {
            const { from, to } = getLastMonth(currentDateTime);
            fromDate = from;
            toDate = to;
            break;
        }
        default:
            fromDate = currentDateTime.setDate(
                currentDateTime.getDate() - parseInt(dateTemplate)
            );
            break;
    }

    return {
        fromDate,
        toDate
    };
}

// If automations are configured and at least 1 is active
export function automationsEnabled(
    apiService: ApiService,
    authToken: string
): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
        try {
            apiService.knowledge
                .listWebExt(authToken)
                .then((res) => {
                    if (res.data?.length) resolve(true);
                    else resolve(false);
                })
                .catch(() => resolve(false));
        } catch (e) {
            resolve(false);
        }
    });
}

export async function fetchChatbot(
    chatbotId: string,
    apiService: ApiService,
    authToken: string,
    orgId: string
): Promise<Widget | undefined> {
    const res = await handleRequest(
        apiService.knowledge.getWidget(authToken, chatbotId),
        orgId,
        true
    );
    if (res.data && res.data.id) {
        return res.data;
    }
    return;
}

export async function fetchKb(
    kbId: string,
    apiService: ApiService,
    authToken: string,
    orgId: string
): Promise<Knowledgebase | undefined> {
    const res = await handleRequest(
        apiService.knowledge.getKnowledgebase(authToken, kbId),
        orgId,
        true
    );
    if (res.data && res.data.id) {
        return res.data;
    }
    return;
}

export async function fetchKbs(
    chatbot: Widget,
    apiService: ApiService,
    authToken: string,
    orgId: string
): Promise<Knowledgebase[]> {
    const res = await handleRequest(
        apiService.knowledge.listKnowledgebases(authToken, chatbot.id!),
        orgId,
        true
    );
    if (res.data && res.data.knowledgebases) {
        return res.data.knowledgebases;
    }
    return [];
}

export function enabledLocales() {
    return Object.keys(LOCALES).filter((key: string) =>
        featureIsEnabled(`LOCALE_${key.replaceAll('-', '_')}`)
    );
}

export function isOdd(num: number) {
    return num % 2 !== 0;
}

export function isKnowledgeRoute(routeName?: RouteRecordName): boolean {
    return (
        (!!routeName &&
            KNOWLEDGE_ROUTES.some((route) => route.name === routeName)) ||
        routeName === KNOWLEDGE_SUB_ROUTES.NODE_EDITOR ||
        routeName === KNOWLEDGE_SUB_ROUTES.OPTIMIZED_WIZARD
    );
}

export function replaceQueryFilterParams(
    key: string,
    filterValues: string[],
    $route: RouteLocationNormalized,
    $router: Router
) {
    if (!$router || !$route) return;
    if (filterValues.length && filterValues[0]) {
        $router.replace({
            query: {
                ...$route.query,
                [key]: filterValues.join(',')
            }
        });
    } else {
        const query = Object.assign({}, $route.query);
        delete query[key];
        $router.replace({
            query
        });
    }
}

export function capitalizeString(str: string) {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function convertLicenseTypeToRouteName(
    licenseType: LicenseType
): string {
    switch (licenseType?.toString()) {
        case LicenseType.Aaa:
            return 'agent_assist';
        case LicenseType.Asa:
            return 'sales_assist';
        case LicenseType.Dss:
            return 'self_service';
        default:
            return 'agent_assist';
    }
}

export function convertAppTypeToLicenseType(
    appType: APP_TYPES
): LicenseType | undefined {
    switch (appType?.toString()) {
        case APP_TYPES.SELF_SERVICE:
            return LicenseType.Dss;
        case APP_TYPES.SALES_ASSIST:
            return LicenseType.Asa;
        case APP_TYPES.AGENT_ASSIST:
            return LicenseType.Aaa;
        default:
            return;
    }
}

export function validateOrgLicense(
    orgLicenses: LicenseType[],
    currentLicense: LicenseType
) {
    return orgLicenses.includes(currentLicense);
}

export function updatePathParams(
    $router: Router,
    newParams: { [x: string]: any }
) {
    // Retrieve current params
    const currentParams = $router.currentRoute.params;

    // Create new params object
    const mergedParams = { ...currentParams, ...newParams };

    // When router is not supplied path or name,
    // it simply tries to update current route with new params or query
    // Almost everything is optional.
    $router.replace({ params: mergedParams });
}
