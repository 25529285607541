import { Subject } from 'rxjs';

export interface Drawer {
    id: string;
    name: string;
    componentInstance: any;
    open?: boolean;
    width?: string;
}

export class DrawerService {
    private readonly $OnDrawerOpen: Subject<any> = new Subject<any>();
    private readonly $OnDrawerClose: Subject<any> = new Subject<any>();
    private readonly _DrawerList: Drawer[];
    readonly _DefaultDrawerWidth: string;
    private _InTransition: boolean;
    private _DrawerToBeOpened: Drawer | undefined;

    constructor() {
        this._DrawerList = [];
        this._DefaultDrawerWidth = '400px';
        this._InTransition = false;
    }

    toggleDrawer(drawer: Drawer) {
        this.addDrawer(drawer);

        const newDrawer: Drawer | undefined = this._DrawerList.find(
            (d: Drawer) => d.id === drawer.id
        );

        const openedDrawer = this.getOpenDrawer();
        const isDifferentDrawer =
            openedDrawer && openedDrawer?.name !== newDrawer?.name;

        if (isDifferentDrawer) {
            this._DrawerToBeOpened = newDrawer;
            this.closeDrawer();
        } else if (newDrawer) {
            if (!openedDrawer) {
                this.openDrawer(newDrawer);
            } else if (openedDrawer?.id === newDrawer?.id) {
                newDrawer.open
                    ? this.closeDrawer()
                    : this.openDrawer(newDrawer);
            } else {
                this.changeOpenedDrawer(newDrawer, openedDrawer);
            }
        }
    }

    getOpenDrawer(): Drawer | undefined {
        return this._DrawerList.find((d: Drawer) => d.open);
    }

    changeOpenedDrawer(newD: Drawer, oldD: Drawer): void {
        this.openDrawer(newD);
        oldD.open = false;
    }

    openDrawer(drawer: Drawer) {
        drawer.open = true;
    }
    closeDrawer() {
        const openedDrawer = this.getOpenDrawer();
        if (openedDrawer) {
            this.TriggerOnDrawerClose(openedDrawer);
            openedDrawer.open = false;
        }
    }

    addDrawer(drawer: Drawer) {
        if (!this._DrawerList.some((d: Drawer) => d.id === drawer.id)) {
            if (!drawer.width) {
                drawer.width = this._DefaultDrawerWidth;
            }
            this._DrawerList.push(drawer);
        }
    }
    removeDrawer(drawer: Drawer) {
        const i = this._DrawerList.findIndex((d: Drawer) => d.id === drawer.id);
        if (i > -1) {
            this._DrawerList.splice(i, 1);
        }
    }
    OnDrawerClose(callback: any) {
        this.$OnDrawerClose.subscribe((data: any | undefined) =>
            callback(data)
        );
    }

    TriggerOnDrawerClose(drawer: Drawer) {
        this.$OnDrawerClose.next({ drawer: drawer });
    }

    OnDrawerOpen(callback: any) {
        this.$OnDrawerOpen.subscribe((data: any | undefined) => callback(data));
    }

    TriggerOnDrawerOpen(data: any | undefined) {
        this.$OnDrawerOpen.next(data);
    }

    setInTransition() {
        this._InTransition = true;
    }

    getInTransition() {
        return this._InTransition;
    }

    setOffTransition() {
        this._InTransition = false;

        // If there is a new drawer to be opened
        if (this._DrawerToBeOpened) {
            this.openDrawer(this._DrawerToBeOpened);
            this._DrawerToBeOpened = undefined;
        }
    }
}
