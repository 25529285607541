<template>
    <div class="d-h100p" ref="nodeEditorWrapper" v-on-resize="calculateWidth">
        <dt-root-layout
            fixed
            class="d-h100p d-ps-relative"
            :sidebar-width="`min(${minSidebarWidth}px, auto)`"
            sidebar-class="d-h100p d-ps-relative"
            content-class="d-bgc-black-100"
        >
            <template #header>
                <div
                    class="d-d-flex d-ai-center d-w100p d-bb d-bbw1 d-bc-subtle header-wrapper"
                    :style="{
                        ['min-height']: headerHeight
                    }"
                    ref="nodeHeader"
                >
                    <div class="d-d-flex d-fd-column d-w100p d-mr16 d-h100p">
                        <div
                            class="d-w100p d-h100p d-px16 d-py8"
                            v-if="isNodeLoading && !state"
                        >
                            <dt-skeleton
                                :arial-label="$t('Loading')"
                                :paragraphOption="{
                                    rows: 2,
                                    width: ['30%', '20%'],
                                    rowClass: 'd-h16 d-my8 d-py8'
                                }"
                            />
                        </div>
                        <div
                            class="d-h100p d-px16 d-py8 title-wrapper"
                            ref="responseLabels"
                            v-else
                        >
                            <div
                                class="d-fs-200 d-d-flex d-ai-center d-fw-bold"
                                :class="{ 'd-c-pointer': !isTypeDocument }"
                                style="height: 31px"
                                @click="handleEditTitle"
                                v-if="!showTitleInput"
                            >
                                {{ titleModel }}
                                <dt-badge
                                    class="d-ml8"
                                    :type="isPublished || isDraft || isArchived"
                                    v-if="state"
                                >
                                    <span class="d-tt-capitalize">
                                        {{
                                            isDraft
                                                ? $t('draft')
                                                : isArchived
                                                  ? $t('archived')
                                                  : $t(state)
                                        }}
                                    </span>
                                </dt-badge>
                            </div>
                            <dt-input
                                v-if="showTitleInput"
                                @focusout="handleClickOutside"
                                @input="handleTitleChange"
                                maxlength="75"
                                v-focus-input
                                name="titleInput"
                                v-model="titleModel"
                                :messages="validate"
                                messages-class="d-ps-absolute d-t8 d-l72"
                                ref="titleInput"
                                @keyup.enter="handleClickOutside"
                                input-wrapper-class="d-bgc-transparent d-baw0 d-ol-none d-fw-bold"
                                input-class="d-fc-primary d-pl0"
                            />
                            <div
                                v-if="isTypeResponse || isTypeDocument"
                                :class="{
                                    'd-d-flex': !showLabelsComponent
                                }"
                            >
                                <div
                                    v-if="
                                        selectedLabels?.length &&
                                        !showLabelsComponent
                                    "
                                    class="d-p2 d-box-border"
                                    @click="handleShowLabelsComponent"
                                >
                                    <dt-chip
                                        size="xs"
                                        class="d-mr4"
                                        content-class="d-fs-100"
                                        v-for="label in selectedLabels"
                                        :key="label"
                                        @close="handleRemoveChip(label)"
                                        :hide-close="!isDocumentUploaded"
                                        :interactive="isDocumentUploaded"
                                    >
                                        {{ label }}
                                    </dt-chip>
                                </div>
                                <dt-tooltip
                                    class="d-h24"
                                    style="margin-left: -4px"
                                    v-if="!showLabelsComponent"
                                    placement="bottom-start"
                                    :message="
                                        $t(
                                            'Used when searching responses in the admin, never shown to users.'
                                        )
                                    "
                                >
                                    <template
                                        #anchor
                                        v-if="
                                            isTypeResponse || isDocumentUploaded
                                        "
                                    >
                                        <dt-button
                                            importance="clear"
                                            kind="muted"
                                            size="xs"
                                            @click="handleShowLabelsComponent"
                                        >
                                            <span
                                                v-if="!selectedLabels?.length"
                                            >
                                                {{ $t('Edit labels') }}
                                            </span>
                                            <template #icon>
                                                <dt-icon
                                                    name="edit-2"
                                                    size="100"
                                                />
                                            </template>
                                        </dt-button>
                                    </template>
                                </dt-tooltip>

                                <base-combobox
                                    :style="{
                                        marginLeft: '-4px'
                                    }"
                                    class="no-border"
                                    v-if="showLabelsComponent"
                                    size="sm"
                                    data-qa="labels-input"
                                    :item-list="tags"
                                    v-model:selected-items="selectedLabels"
                                    is-focused
                                />
                            </div>
                        </div>
                    </div>
                    <div class="d-ml-auto d-mr16 d-d-flex">
                        <dt-button
                            class="d-mr4"
                            importance="clear"
                            size="sm"
                            @click="handleCancel"
                            :disabled="isLoading || isInEditMode"
                        >
                            {{ $t('Cancel') }}
                        </dt-button>
                        <dt-button
                            class="d-mr4 d-truncate"
                            importance="outlined"
                            size="sm"
                            @click="handleSave(NodeStatus.Unpublished)"
                            :disabled="isLoading || isInEditMode"
                        >
                            {{ $t('Save as draft') }}
                        </dt-button>
                        <dt-button
                            size="sm"
                            @click="handleSave(NodeStatus.Published)"
                            :disabled="isLoading || isInEditMode"
                            v-if="isTypeDocument || hasBlocks"
                        >
                            {{ $t('Publish') }}
                        </dt-button>
                        <dt-tooltip
                            class="d-h24"
                            placement="bottom-end"
                            :message="`${$t(
                                isTypeResponse ? 'Responses' : 'Chatter'
                            )} ${$t('can not be published with no content')}`"
                            v-else
                        >
                            <template #anchor>
                                <dt-button
                                    size="sm"
                                    class="d-truncate d-bgc-black-300 d-bc-transparent d-c-not-allowed"
                                    kind="muted"
                                    importance="outlined"
                                    label-class="d-fc-black-500"
                                >
                                    {{ $t('Publish') }}
                                </dt-button>
                            </template>
                        </dt-tooltip>
                    </div>
                </div>
            </template>
            <template #sidebar>
                <node-questions
                    :node-id="nodeId"
                    :org-id="orgId"
                    v-model:questions="questions"
                    :max-height="sidebarHeight"
                    :min-width="minSidebarWidth"
                    :max-width="maxSidebarWidth"
                    :is-updating="isLoading"
                    :is-dirty="isQuestionDirty"
                    @save="handleSave"
                    v-model:is-saved="isSaved"
                />
            </template>
            <template #default>
                <div class="d-d-flex d-fd-column d-h100p" v-if="isTypeDocument">
                    <main
                        class="d-mx16 d-my12 d-baw1 d-ba d-bc-subtle d-bar16 d-px2 d-py4 d-bgc-primary d-pb64 d-fl1 d-of-auto"
                    >
                        <rich-text-editor
                            v-if="!isDocumentLoading && markdown"
                            :editable="false"
                            :markdown="markdown"
                        />
                    </main>
                </div>
                <div class="d-d-flex d-fd-column d-h100p" v-else>
                    <div
                        class="d-w100p d-of-y-auto d-pt12 d-px16 d-box-border"
                        ref="scrollableWrapper"
                    >
                        <div
                            class="d-d-flex d-fd-column"
                            ref="blockRendererWrapper"
                        >
                            <div class="d-fs-100 d-d-flex d-fd-column">
                                <span class="d-headline--sm">
                                    {{ $t('Response') }}
                                </span>
                                <span class="d-body--sm-compact">
                                    {{
                                        $t(
                                            'The message that the chatbot will reply with when a question is recognized'
                                        )
                                    }}
                                </span>
                            </div>
                            <response-block-renderer
                                v-if="!isNodeLoading"
                                :raw-blocks="blocks"
                                :workflows="workflows"
                                :workflows-error="workflowsError"
                                :fetching-workflows="isFetchingWorkflows"
                                @mountedBlock="onMountedBlock"
                            />
                        </div>
                    </div>
                    <div
                        v-if="isNodeLoading"
                        class="d-w100p d-h100p d-d-flex d-fd-column d-jc-center d-ai-center"
                    >
                        <dt-button importance="clear" loading />
                    </div>
                    <!-- --------------------------------------------------------------- -->
                    <!--                          ACTION BUTTONS                         -->
                    <!-- --------------------------------------------------------------- -->
                    <div
                        class="d-w100p d-fl-shrink0 d-py8 d-px16 d-box-border"
                        v-if="!isNodeLoading"
                    >
                        <div
                            class="d-fl-grow1 d-bgc-black-100 d-d-flex d-fw-wrap d-gg8"
                        >
                            <div class="d-d-flex" v-if="isWorkflowEnabled">
                                <dt-tooltip
                                    :message="
                                        $t(
                                            'A response can contain a workflow or content but not both at the same time.'
                                        )
                                    "
                                    :show="
                                        showTooltip ===
                                            ResponseNodeBlockTypeEnum.Workflow &&
                                        showHoverTooltip
                                    "
                                    @mouseenter="showButtonTooltip"
                                    @mouseleave="hideButtonTooltip"
                                >
                                    <template #anchor>
                                        <dt-button
                                            class="d-as-flex-start"
                                            kind="muted"
                                            importance="outlined"
                                            size="xs"
                                            :disabled="
                                                isLoading || isInEditMode
                                            "
                                            @click="
                                                handleAddBlock(
                                                    ResponseNodeBlockTypeEnum.Workflow
                                                )
                                            "
                                        >
                                            <template #icon>
                                                <dt-icon
                                                    name="expert-node"
                                                    size="200"
                                                />
                                            </template>
                                            {{ $t('Add workflow') }}
                                        </dt-button>
                                    </template>
                                </dt-tooltip>
                                <div
                                    class="d-body-compact-small d-d-flex d-ml8 d-mb1 d-ai-center"
                                >
                                    {{ $t('or') }}
                                </div>
                            </div>
                            <dt-tooltip
                                v-for="activeNodeBlock in activeNodeBlocks"
                                :key="activeNodeBlock.type"
                                :message="
                                    $t(
                                        'A response can contain a workflow or content but not both at the same time.'
                                    )
                                "
                                :show="
                                    showTooltip === activeNodeBlock.type &&
                                    showHoverTooltip
                                "
                                @mouseenter="showButtonTooltip"
                                @mouseleave="hideButtonTooltip"
                            >
                                <template #anchor>
                                    <dt-button
                                        v-if="
                                            activeNodeBlock.type !==
                                                ResponseNodeBlockTypeEnum.Webext ||
                                            (isAiAssist &&
                                                hasAutomationsEnabled)
                                        "
                                        class="d-as-flex-start"
                                        label-class="d-d-flex d-ai-center"
                                        kind="muted"
                                        importance="outlined"
                                        size="xs"
                                        :disabled="
                                            isLoading ||
                                            !featureIsEnabled(
                                                `${activeNodeBlock.type}_block`
                                            ) ||
                                            isInEditMode
                                        "
                                        @click="
                                            handleAddBlock(activeNodeBlock.type)
                                        "
                                    >
                                        <template #icon>
                                            <dt-icon
                                                :name="activeNodeBlock.icon"
                                                size="200"
                                            />
                                        </template>
                                        <span class="d-pt1">
                                            {{
                                                $t(
                                                    'Add ' +
                                                        activeNodeBlock.type
                                                )
                                            }}
                                        </span>
                                    </dt-button>
                                </template>
                            </dt-tooltip>
                        </div>
                    </div>
                </div>
            </template>
        </dt-root-layout>
        <dt-modal
            :close-button-props="{ ariaLabel: `${$t('Close exit prompt')}` }"
            v-model:show="showPrompt"
        >
            <template #header>
                <div>{{ $t('Unsaved changes') }}</div>
            </template>
            <template #default>
                <p>{{ $t('Are you sure you want to leave this page?') }}</p>
                <p>{{ $t('Changes you made will not be saved.') }}</p>
            </template>
            <template #footer>
                <dt-button kind="danger" @click="() => handleExit(state)">
                    {{ $t('Leave page') }}
                </dt-button>
                <dt-button
                    class="d-mr4"
                    importance="clear"
                    kind="muted"
                    @click="handleCancelExit"
                >
                    {{ $t('Cancel') }}
                </dt-button>
            </template>
        </dt-modal>
        <dt-modal
            :close-button-props="{ ariaLabel: `${$t('Close exit prompt')}` }"
            v-model:show="showOptimizePrompt"
        >
            <template #header>
                <div>{{ $t('Optimize questions') }}</div>
            </template>
            <template #default>
                <p>
                    {{
                        `${$t('This action will remove')} ${numberRemovedQuestions} ${numberRemovedQuestions > 1 ? $t('questions from') : $t('question from')} "${titleModel}". ${$t('Are you sure you want to proceed?')}`
                    }}
                </p>
            </template>
            <template #footer>
                <dt-button @click="handleProceedOptimize">
                    {{ $t('Yes') }}
                </dt-button>
                <dt-button
                    class="d-mr4"
                    importance="clear"
                    kind="muted"
                    @click="handleCancelOptimize"
                >
                    {{ $t('No, cancel') }}
                </dt-button>
            </template>
        </dt-modal>
    </div>
</template>

<script lang="ts">
import { defineComponent, inject, toRaw } from 'vue';
import { type RouteRecordName, useRoute } from 'vue-router';

import {
    DtRootLayout,
    DtButton,
    DtIcon,
    DtTooltip,
    DtChip,
    DtInput,
    DtSkeleton,
    DtBadge,
    DtModal,
    VALIDATION_MESSAGE_TYPES
} from '@dialpad/dialtone/vue3';

import type {
    AssetDetail,
    Knowledgebase,
    NodeDetail,
    ResponseNodeBlock,
    ListTagsOKBody,
    ResponseNodeBlockChoicesBlockChoicesItems,
    PatchNodeRequest
} from '@/open-api';

import {
    LicenseType,
    NodeStatus,
    NodeType,
    ResponseNodeBlockTypeEnum
} from '@/open-api';

import BaseCombobox from '@/components/base-combobox/BaseCombobox.vue';
import NodeQuestions from '@/components/node-editor/NodeQuestions.vue';
import ResponseBlockRenderer from '@/components/node-editor/ResponseBlockRenderer.vue';
import RichTextEditor from '@/components/rich-text-editor/RichTextEditor.vue';
import {
    Block,
    EMPTY_CHOICES_BLOCK,
    EMPTY_CTA_BLOCK,
    EMPTY_IMAGE_BLOCK,
    EMPTY_TEXT_BLOCK,
    EMPTY_VIDEO_BLOCK,
    EMPTY_WEBEXT_BLOCK,
    EMPTY_WORKFLOW_BLOCK,
    ResponseBlockEditor
} from '@/components/node-editor/responseBlockEditor';

import type { ApiService } from '@/services/Api.service';

import { featureIsEnabled } from '@/directives/feature.directive';

import {
    automationsEnabled,
    handleRequest,
    type IQuestion
} from '@/utils/Common';
import { getWorkflows, type KoopidWorkflow } from '@/utils/koopid';
import type { INotification } from '@/utils/types/Notification';
import { NOTICE_KINDS } from '@/utils/Constants';

export default defineComponent({
    components: {
        ResponseBlockRenderer,
        BaseCombobox,
        NodeQuestions,
        RichTextEditor,
        DtSkeleton,
        DtButton,
        DtIcon,
        DtRootLayout,
        DtTooltip,
        DtChip,
        DtInput,
        DtBadge,
        DtModal
    },
    setup() {
        const orgId: string = inject('orgId')!;

        const route = useRoute();
        const nodeId = route?.params?.nodeId as string | undefined;
        const nodeType = (route?.query?.nodeType ||
            NodeType.Response) as NodeType;
        const tab = (route?.query?.tab || 'responses') as RouteRecordName;
        const subTab = route?.query?.subTab as string | undefined;

        return {
            orgId,
            nodeId,
            nodeType,
            tab,
            subTab
        };
    },
    mounted() {
        const nodeEditorWrapper = this.$refs.nodeEditorWrapper as HTMLElement;
        const nodeHeader = this.$refs.nodeHeader as HTMLElement;

        this.sidebarHeight = `${
            nodeEditorWrapper.clientHeight - nodeHeader.clientHeight - 5
        }px`;

        this.calculateWidth();

        this.fetchAll();

        const notificationMsg = this.$route.query.notification as string;

        if (notificationMsg) {
            this.$store.commit(`${this.orgId}/addNotification`, {
                kind: NOTICE_KINDS.SUCCESS,
                message: `${this.$t(
                    this.isTypeResponse
                        ? 'Response'
                        : this.isTypeDocument
                          ? 'Document'
                          : 'Chatter'
                )} ${this.$t(notificationMsg)}`
            } as INotification);
            this.$router.replace({ query: { notification: null } });
        }
    },
    watch: {
        selectedLabels(newLabels) {
            // If it has labels then we have to calculate sidebarHeight with a responseLabels height
            if (newLabels.length > 0) {
                const nodeEditorWrapper = this.$refs
                    .nodeEditorWrapper as HTMLElement;
                const responseLabels = this.$refs.responseLabels as HTMLElement;

                if (nodeEditorWrapper && responseLabels) {
                    this.sidebarHeight = `${
                        nodeEditorWrapper?.clientHeight -
                        responseLabels?.clientHeight
                    }px`;
                }
            }
        }
    },
    computed: {
        NodeStatus() {
            return NodeStatus;
        },
        ResponseNodeBlockTypeEnum() {
            return ResponseNodeBlockTypeEnum;
        },
        activeNodeBlocks() {
            return [
                { type: ResponseNodeBlockTypeEnum.Text, icon: 'type' },
                { type: ResponseNodeBlockTypeEnum.Image, icon: 'image' },
                { type: ResponseNodeBlockTypeEnum.Video, icon: 'video' },
                {
                    type: ResponseNodeBlockTypeEnum.Choices,
                    icon: 'multi-line-dept'
                },
                {
                    type: ResponseNodeBlockTypeEnum.Webext,
                    icon: 'lightning-bolt'
                },
                {
                    type: ResponseNodeBlockTypeEnum.CallToAction,
                    icon: 'inspect'
                }
            ];
        },
        knowledgebase(): Knowledgebase {
            return this.$store.getters[`${this.orgId}/currentKnowledgebase`];
        },
        tags(): string[] {
            return this.labels?.tags || [];
        },
        isPublished(): string {
            return this.state === NodeStatus.Published ? 'success' : '';
        },
        isDraft(): string {
            return this.state === NodeStatus.Unpublished ? 'warning' : '';
        },
        isArchived(): string {
            return this.state === NodeStatus.Deleted ? 'critical' : '';
        },
        isQuestionDirty(): boolean {
            const prevQueries = this.rawData?.queries;
            const nextQueries = this.questions.map((q: IQuestion) => q.text);

            return JSON.stringify(nextQueries) !== JSON.stringify(prevQueries);
        },
        isDirty(): boolean {
            return (
                this.isTitleDirty() ||
                this.isSelectedLabelsDirty() ||
                this.isQuestionDirty ||
                this.isBlocksDirty()
            );
        },
        isLimited(): boolean {
            return this.$store.getters[`${this.orgId}/limited`];
        },
        responseBlockEditor(): ResponseBlockEditor | undefined {
            return this.$store.getters[`${this.orgId}/responseBlockEditor`];
        },
        koopid(): string {
            return this.$store.getters[`${this.orgId}/koopid`];
        },
        authToken(): string {
            return this.$store.getters[`${this.orgId}/authToken`];
        },
        apiService(): ApiService {
            return this.$store.getters[`${this.orgId}/apiService`];
        },
        isInEditMode(): boolean {
            if (this.isTypeDocument) {
                return false;
            }

            return !!this.responseBlockEditor
                ?.getBlocks()
                ?.find((block: any) => block.data.editing === true);
        },
        isSelfService(): boolean {
            return this.knowledgebase.license_type === LicenseType.Dss;
        },
        isAiAssist(): boolean {
            return this.knowledgebase.license_type === LicenseType.Aaa;
        },
        companyId(): string {
            return this.$store.getters[`${this.orgId}/companyId`];
        },
        isTypeResponse(): boolean {
            return this.nodeType === NodeType.Response;
        },
        isTypeDocument(): boolean {
            return this.nodeType === NodeType.Content;
        },
        isDocumentUploaded(): boolean {
            return (
                this.isTypeDocument &&
                this.rawData?.content?.source === 'uploads'
            );
        },
        headerHeight(): string {
            return this.isTypeResponse || this.isTypeDocument ? '75px' : '48px';
        },
        hasBlocks(): boolean {
            return !!this.responseBlockEditor?.getBlocks().length;
        },
        isLoading(): boolean {
            return (
                this.isFetchingWorkflows ||
                this.isComboboxFetching ||
                this.isUpdating
            );
        },
        isWorkflowEnabled(): boolean {
            return (
                this.isSelfService &&
                featureIsEnabled('workflows_block') &&
                !this.isLimited
            );
        },
        hasWorkflowBlock(): boolean {
            return (
                this.isWorkflowEnabled &&
                this.responseBlockEditor
                    ?.getBlocks()
                    .some(
                        (block: ResponseNodeBlock) =>
                            block.type === ResponseNodeBlockTypeEnum.Workflow
                    )
            );
        },
        disableWorkflowBlock(): boolean {
            return this.responseBlockEditor
                ?.getBlocks()
                .some(
                    (block: ResponseNodeBlock) =>
                        block.type !== ResponseNodeBlockTypeEnum.Workflow
                );
        }
    },
    methods: {
        featureIsEnabled,
        async fetchAll() {
            if (!this.isSelfService) {
                this.hasAutomationsEnabled = await automationsEnabled(
                    this.apiService,
                    this.authToken
                );
            }

            if (this.nodeId) await this.getNodeData();

            if (!this.tags?.length) await this.fetchTags();

            if (
                this.blocks.some(
                    (block: Block) =>
                        block.type === ResponseNodeBlockTypeEnum.Workflow
                )
            ) {
                await this.fetchWorkflows();
            }

            if (this.isTypeDocument) {
                await this.loadDocument();
            }

            this.calculateWidth();
        },
        async getNodeData() {
            this.isNodeLoading = true;
            const res = await handleRequest<NodeDetail>(
                this.apiService?.knowledge.getNode(
                    this.authToken,
                    this.knowledgebase?.id,
                    this.nodeId
                ),
                this.orgId
            );
            this.isNodeLoading = false;
            this.rawData = res.data as NodeDetail;

            if (this.rawData) {
                this.questions = Array.from(this.rawData?.queries || []).map(
                    (q: string) => ({ text: q, status: 'keep' })
                );
                this.titleModel = this.rawData.title as string;
                this.state = this.rawData.status!;

                this.selectedLabels = this.rawData.labels as string[];
                this.blocks =
                    this.rawData.response?.blocks ||
                    this.rawData.chattering?.blocks ||
                    [];
            }
        },
        async fetchTags() {
            this.isComboboxFetching = true;
            this.labels = { tags: [] };
            const res = await handleRequest<ListTagsOKBody>(
                this.apiService?.knowledge.listTags(
                    this.authToken,
                    this.knowledgebase?.id
                ),
                this.orgId,
                true
            );
            this.isComboboxFetching = false;
            this.hasComboboxError = !!res.error;
            if (res?.data) this.labels = res.data;
        },
        handleShowLabelsComponent() {
            if (this.showTitleInput) {
                this.showTitleInput = false;
            }

            if (!this.isRemoving) {
                this.showLabelsComponent = true;
            }
            this.isRemoving = false;
        },
        handleRemoveChip(label: string) {
            this.isRemoving = true;
            const index = this.selectedLabels.findIndex(
                (selectedItem: string) => selectedItem === label
            );
            // This returns a new array without the selectedIndex item without mutating the array
            this.selectedLabels = this.selectedLabels
                ?.slice(0, index)
                .concat(
                    this.selectedLabels?.slice(
                        index + 1,
                        this.selectedLabels?.length
                    )
                );
        },
        handleEditTitle() {
            if (this.isTypeDocument) {
                return;
            }
            if (this.showLabelsComponent) {
                this.showLabelsComponent = false;
            }
            this.showTitleInput = true;
        },
        handleTitleChange() {
            this.validate = [];
        },
        handleClickOutside() {
            this.titleModel = this.titleModel.trim();
            if (this.titleModel.length < this.minCharacters) {
                this.validate = [
                    {
                        message: this.$t(
                            'Title should be at least 5 characters'
                        ),
                        type: VALIDATION_MESSAGE_TYPES.ERROR
                    }
                ];
            } else {
                this.showTitleInput = false;
            }
        },
        async handleSave(status?: NodeStatus, useOptimize?: boolean) {
            this.statusToBeSaved = status || NodeStatus.Unpublished;
            // First upload new images/videos before saving
            const blocksSettled = await Promise.allSettled(
                this.responseBlockEditor?.getBlocks().map((block: Block) => {
                    return new Promise<ResponseNodeBlock>((resolve, reject) => {
                        if (
                            !(
                                block.type === 'image' || block.type === 'video'
                            ) ||
                            !block.data.file
                        ) {
                            resolve(block.data);
                        } else {
                            this.uploadPictures(
                                block.data.file,
                                block.type === ResponseNodeBlockTypeEnum.Image
                                    ? 'pictures'
                                    : 'videos'
                            )
                                .then((res: any) => {
                                    if (res.error) {
                                        reject(res.error);
                                    }

                                    if (
                                        block.type ===
                                        ResponseNodeBlockTypeEnum.Image
                                    ) {
                                        resolve({
                                            ...block.data,
                                            image_block: {
                                                ...block.data.image_block,
                                                url: res.data?.url!
                                            }
                                        });
                                    } else {
                                        resolve({
                                            ...block.data,
                                            video_block: {
                                                ...block.data.video_block,
                                                url: res.data?.url!
                                            }
                                        });
                                    }
                                })
                                .catch((err: any) => {
                                    reject(err);
                                });
                        }
                    });
                }) || []
            );

            const blocksToSave = blocksSettled
                .filter(
                    (block: PromiseSettledResult<ResponseNodeBlock>) =>
                        block.status === 'fulfilled'
                )
                .map(
                    (block: any) =>
                        (block as PromiseFulfilledResult<ResponseNodeBlock>)
                            .value
                );

            // Need to clear title in Choices blocks as it is read only field
            const blocksToSaveNormalized = blocksToSave.reduce(
                (acc: ResponseNodeBlock[], block: ResponseNodeBlock) => {
                    if (block.type === 'choices') {
                        const choicesArr: ResponseNodeBlockChoicesBlockChoicesItems[] =
                            block.choices_block!.choices.map(
                                (
                                    choice: ResponseNodeBlockChoicesBlockChoicesItems
                                ) => ({
                                    ...choice,
                                    title: ''
                                })
                            );

                        acc.push({
                            ...block,
                            choices_block: {
                                choices: choicesArr,
                                text: block.choices_block.text
                            }
                        });
                    } else {
                        acc.push(block);
                    }
                    return acc;
                },
                []
            );

            if (
                !useOptimize &&
                this.questions.find((q: IQuestion) => q.status === 'remove')
            ) {
                this.numberRemovedQuestions = this.questions.filter(
                    (q: IQuestion) => q.status === 'remove'
                ).length;
                this.showOptimizePrompt = true;
                return;
            }

            const payloadQuestions =
                this.questions
                    .filter((q: IQuestion) => q.status !== 'remove')
                    .map((q: IQuestion) => q.text) || [];

            const body: PatchNodeRequest = {
                title: this.titleModel,
                labels:
                    this.isTypeResponse || this.isTypeDocument
                        ? this.selectedLabels
                        : null,
                queries: payloadQuestions,
                [this.nodeType]: {
                    blocks: blocksToSaveNormalized,
                    external_id: this.isTypeDocument
                        ? this.rawData.content.external_id
                        : undefined,
                    mime_type: this.isTypeDocument
                        ? this.rawData.content.mime_type
                        : undefined,
                    source: this.isTypeDocument
                        ? this.rawData.content.source
                        : undefined
                },
                status: this.statusToBeSaved
            };
            this.isUpdating = true;

            let res: any;
            if (this.nodeId) {
                res = await handleRequest<NodeDetail>(
                    this.apiService?.knowledge.patchNode(
                        this.authToken,
                        this.knowledgebase.id!,
                        this.nodeId!,
                        body
                    ),
                    this.orgId
                );
            } else {
                res = await handleRequest<NodeDetail>(
                    this.apiService?.knowledge.postNode(
                        this.authToken,
                        this.knowledgebase.id!,
                        body
                    ),
                    this.orgId
                );
            }
            this.isUpdating = false;
            this.statusToBeSaved = undefined;

            if (res.data) {
                this.rawData = res.data;
                // If no status then we just want to optimize questions and dont leave node editor view
                if (!status) {
                    this.isSaved = true;
                } else {
                    this.handleExit(status);
                }
            }
        },
        async uploadPictures(file: File, type: 'pictures' | 'videos') {
            return await handleRequest<AssetDetail>(
                this.apiService?.knowledge.postAsset(
                    this.authToken,
                    this.knowledgebase.id!,
                    type,
                    file
                ),
                this.orgId
            );
        },
        handleCancel() {
            if (this.isDirty) {
                this.showPrompt = true;
            } else {
                this.handleExit(this.state);
            }
        },
        handleExit(selectedStatus?: string) {
            this.$router.push({
                name: this.tab,
                query: {
                    status: selectedStatus || NodeStatus.Published,
                    notification:
                        this.state === selectedStatus ? '' : selectedStatus,
                    subTab: this.subTab
                }
            });
        },
        handleCancelExit() {
            this.showPrompt = false;
        },
        handleCancelOptimize() {
            this.showOptimizePrompt = false;
        },
        handleProceedOptimize() {
            this.showOptimizePrompt = false;
            this.handleSave(this.statusToBeSaved, true);
        },
        isTitleDirty() {
            return this.titleModel !== this.rawData?.title;
        },
        isSelectedLabelsDirty() {
            return (
                JSON.stringify(this.selectedLabels) !==
                JSON.stringify(this.rawData?.labels)
            );
        },
        isBlocksDirty() {
            return !!this.responseBlockEditor
                ?.getBlocks()
                ?.find((block: any) => block.data.dirty === true);
        },
        handleAddBlock(blockType: ResponseNodeBlockTypeEnum) {
            if (
                this.hasWorkflowBlock ||
                // Not allowed to add Workflow if other blocks were already added
                (blockType === ResponseNodeBlockTypeEnum.Workflow &&
                    this.disableWorkflowBlock)
            ) {
                this.showTooltip = blockType;
                return;
            }
            this.showTooltip = '';
            let block: Block;

            switch (blockType.toString()) {
                case ResponseNodeBlockTypeEnum.Choices: {
                    block = new Block(
                        true,
                        true,
                        false,
                        !featureIsEnabled(
                            `${ResponseNodeBlockTypeEnum.Choices}_block`
                        ),
                        EMPTY_CHOICES_BLOCK()
                    );
                    break;
                }
                case ResponseNodeBlockTypeEnum.Text: {
                    block = new Block(
                        true,
                        true,
                        false,
                        !featureIsEnabled(
                            `${ResponseNodeBlockTypeEnum.Text}_block`
                        ),
                        EMPTY_TEXT_BLOCK
                    );
                    break;
                }
                case ResponseNodeBlockTypeEnum.Image: {
                    block = new Block(
                        true,
                        true,
                        false,
                        !featureIsEnabled(
                            `${ResponseNodeBlockTypeEnum.Image}_block`
                        ),
                        EMPTY_IMAGE_BLOCK
                    );
                    break;
                }
                case ResponseNodeBlockTypeEnum.Workflow: {
                    block = new Block(
                        true,
                        true,
                        false,
                        !featureIsEnabled(
                            `${ResponseNodeBlockTypeEnum.Workflow}_block`
                        ),
                        EMPTY_WORKFLOW_BLOCK
                    );
                    break;
                }
                case ResponseNodeBlockTypeEnum.Video: {
                    block = new Block(
                        true,
                        true,
                        false,
                        !featureIsEnabled(
                            `${ResponseNodeBlockTypeEnum.Video}_block`
                        ),
                        EMPTY_VIDEO_BLOCK
                    );
                    break;
                }
                case ResponseNodeBlockTypeEnum.Webext: {
                    block = new Block(
                        true,
                        true,
                        false,
                        !featureIsEnabled(
                            `${ResponseNodeBlockTypeEnum.Webext}_block`
                        ),
                        EMPTY_WEBEXT_BLOCK
                    );
                    break;
                }
                case ResponseNodeBlockTypeEnum.CallToAction: {
                    block = new Block(
                        true,
                        true,
                        false,
                        !featureIsEnabled(
                            `${ResponseNodeBlockTypeEnum.CallToAction}_block`
                        ),
                        EMPTY_CTA_BLOCK
                    );
                    break;
                }
                default:
                    return;
            }

            if (block) this.responseBlockEditor?.addBlock(block);
        },
        scrollBlockIntoView(block: any) {
            const rawBlock: HTMLElement = toRaw(block).$el;
            rawBlock.scrollIntoView({
                behavior: 'smooth'
            });
        },
        async onMountedBlock(blockRef: any) {
            const block: Block = toRaw(blockRef).block;
            if (
                block.type === ResponseNodeBlockTypeEnum.Workflow &&
                block.new
            ) {
                await this.fetchWorkflows();
            }
            this.scrollBlockIntoView(blockRef);
        },
        async fetchWorkflows() {
            if (this.isLimited) return;
            this.isFetchingWorkflows = true;
            const resWorkflows = await handleRequest(
                getWorkflows(this.companyId, this.authToken, this.koopid),
                this.orgId
            );

            this.isFetchingWorkflows = false;
            this.workflowsError = !!resWorkflows.error;
            if (!resWorkflows.error) {
                this.workflows = resWorkflows.data?.entity || [];
            }
        },
        calculateWidth() {
            const nodeEditorWrapper = this.$refs
                .nodeEditorWrapper as HTMLElement;
            const editorWidth = nodeEditorWrapper?.clientWidth;
            this.minSidebarWidth = `${editorWidth * 0.4}px`;
            this.maxSidebarWidth = `${editorWidth * 0.5}px`;
        },
        async loadDocument() {
            this.isDocumentLoading = true;

            const markdownData = await handleRequest<string>(
                this.apiService?.knowledge.getMarkdownContent(
                    this.authToken,
                    this.knowledgebase?.id,
                    this.nodeId
                ),
                this.orgId
            );
            this.isDocumentLoading = false;
            this.markdown = markdownData?.data ?? '';
        },
        showButtonTooltip() {
            this.showHoverTooltip = true;
        },
        hideButtonTooltip() {
            this.showHoverTooltip = false;
            this.showTooltip = '';
        }
    },
    data() {
        return {
            sidebarHeight: '',
            minSidebarWidth: '',
            maxSidebarWidth: '',
            isComboboxFetching: false,
            hasComboboxError: false,
            labels: { tags: [] } as ListTagsOKBody,
            showLabelsComponent: false,
            isRemoving: false,
            showTitleInput: false,
            showPrompt: false,
            showOptimizePrompt: false,
            state: undefined as NodeStatus | undefined,
            isNodeLoading: false,
            isUpdating: false,
            rawData: null as null | NodeDetail,
            titleModel: '',
            selectedLabels: [] as string[],
            questions: [] as IQuestion[],
            blocks: [] as ResponseNodeBlock[],
            isFetchingWorkflows: false,
            workflowsError: false,
            workflows: [] as KoopidWorkflow[],
            validate: undefined as any,
            minCharacters: 5,
            hasAutomationsEnabled: false,
            showTooltip: '',
            isDocumentLoading: false,
            markdown: '',
            numberRemovedQuestions: null as null | number,
            statusToBeSaved: undefined as any,
            isSaved: false,
            showHoverTooltip: false
        };
    }
});
</script>
