<template>
    <div class="d-d-flex d-fd-column">
        <default-template-view
            :title="$t('Documents')"
            :title-description="
                $t('Your imported documents will appear on this page.')
            "
            :search-placeholder="$t('Search documents')"
            :table-data="documents"
            :render-options="renderOptions"
            :columns="columns"
            :is-fetching="isFetching"
            :has-loaded="hasLoaded"
            :total-items="totalHits"
            @table-row-click="handleOpenDocument"
            v-model:sort="sortBy"
            v-model:search="search"
            v-model:on-selected-items="selectedDocuments"
            v-model:labels="tags"
            :has-filters="hasFilters"
            :highlight-table-rows="uploadedDocNames"
            v-model:on-page-change="activePage"
            :has-error="hasError && tagsError"
            table-fixed-header
            :item-type="NodeType.Content"
            :active-row-id="activeRowId"
            has-import-status
        >
            <template #selectedItemsActions>
                <div class="d-d-flex d-ml8" v-if="selectedDocuments?.length">
                    <dt-button
                        class="d-mr8"
                        kind="danger"
                        importance="outlined"
                        size="xs"
                        v-if="
                            showArchive &&
                            selectedStatus[0] !== nodeStatus.Deleted
                        "
                        @click="handleUpdateStatus(nodeStatus.Deleted)"
                    >
                        {{ $t('Archive') }}
                    </dt-button>
                    <dt-tooltip
                        class="d-mr8"
                        placement="bottom"
                        :message="$t('You can only archive uploaded documents')"
                        content-class="d-fw-normal"
                        v-if="
                            !showArchive &&
                            selectedStatus[0] !== nodeStatus.Deleted
                        "
                    >
                        <template #anchor>
                            <dt-button
                                class="d-truncate d-bgc-black-300 d-bc-transparent d-c-not-allowed"
                                kind="muted"
                                importance="outlined"
                                size="xs"
                                label-class="d-fc-black-500"
                            >
                                {{ $t('Archive') }}
                            </dt-button>
                        </template>
                    </dt-tooltip>
                    <dt-button
                        class="d-mr8"
                        importance="outlined"
                        size="xs"
                        v-if="selectedStatus[0] === nodeStatus.Unpublished"
                        @click="handleUpdateStatus(nodeStatus.Published)"
                    >
                        {{ $t('Publish selected') }}
                    </dt-button>
                    <dt-button
                        class="d-mr8"
                        importance="outlined"
                        size="xs"
                        v-if="selectedStatus[0] !== nodeStatus.Unpublished"
                        @click="handleUpdateStatus(nodeStatus.Unpublished)"
                    >
                        {{ $t('Revert to draft') }}
                    </dt-button>
                </div>
            </template>
            <template #actionButtons>
                <sidebar-toggle
                    importance="clear"
                    :sidebar-component="upload"
                    drawer-name="upload"
                    anchor-title="Upload"
                />
                <sidebar-toggle
                    class="d-ml8"
                    :sidebar-component="drawer"
                    drawer-name="sources"
                    anchor-title="Configure sources"
                    width="520px"
                />
            </template>
            <template #filters>
                <filter-popover
                    id="documentStatusPopover"
                    type="radio"
                    class="d-mr8"
                    :options="statusFilters"
                    title="status"
                    v-model:selected-options="statusSelectedFilters"
                    :fetch="fetchDocuments"
                    :total-hits="totalHits"
                    :is-fetching="isFetching"
                    capitalize
                    hide-checkboxes
                    close-on-select
                    dialog-style="d-w164"
                />
                <filter-popover
                    id="sourcePopover"
                    class="d-mr8"
                    :options="sources"
                    v-model:selected-options="sourcesSelectedFilters"
                    :fetch="fetchDocuments"
                    title="sources"
                    :is-fetching="isFetching"
                    dialog-style="d-w100p"
                    hide-checkboxes
                    close-on-select
                />
                <filter-popover
                    id="labelPopover"
                    :options="tags"
                    v-model:selected-options="labelsSelectedFilters"
                    :fetch="fetchDocuments"
                    title="labels"
                    :is-fetching="isFetching"
                    with-search
                />
            </template>
        </default-template-view>
        <empty-state
            v-if="!documents?.length && !isFetching && !hasError"
            :item-type="NodeType.Content"
            :has-filters="hasFilters"
        >
            <template #subtitle>
                <sidebar-toggle
                    is-link
                    importance="clear"
                    :sidebar-component="drawer"
                    drawer-name="sources"
                    anchor-title="Connect a content source"
                    width="520px"
                />
                <span>{{ ` ${$t('or')} ` }}</span>
                <sidebar-toggle
                    is-link
                    importance="clear"
                    :sidebar-component="upload"
                    drawer-name="upload"
                    anchor-title="upload documents"
                />
            </template>
        </empty-state>
    </div>
</template>

<script lang="tsx">
import { defineComponent, h, inject, markRaw } from 'vue';

import {
    type Knowledgebase,
    type NodeStatus as NodeStatusType,
    type ListTagsOKBody,
    NodeStatus,
    NodeType,
    type NodeDetail,
    type AuthorizedServicesResponse
} from '@/open-api';

import { DtButton, DtChip, DtTooltip } from '@dialpad/dialtone/vue3';

import DefaultTemplateView from '@/views/DefaultTemplateView.vue';
import { type Document, DOCUMENT_STATES } from '@/utils/types/Document';
import {
    capitalizeString,
    handleRequest,
    type IFilter,
    replaceQueryFilterParams,
    uuidv4
} from '@/utils/Common';
import {
    DEFAULT_ITEMS_PER_PAGE,
    NOTICE_KINDS,
    SOURCES
} from '@/utils/Constants';
import type { INotification } from '@/utils/types/Notification';

import LabelTag from '@/components/label-tag/LabelTag.vue';
import SourcesDrawer from '@/components/sources-drawer/SourcesDrawer.vue';
import UploadDrawer from '@/components/upload-drawer/UploadDrawer.vue';
import DocumentDrawer from '@/components/document-drawer/DocumentDrawer.vue';
import FilterPopover from '@/components/filter-popover/FilterPopover.vue';
import SidebarToggle from '@/components/sidebar-drawer/SidebarToggle.vue';

import type { IBaseTableColumn } from '@/components/base-table/BaseTable.types';
import { POPOVER_TYPES } from '@/components/menu-popover/MenuPopover.types';
import type { IBaseTableRow } from '@/components/base-table/BaseTable.types';
import type { DrawerService, Drawer } from '@/services/Drawer.service';
import { useRoute } from 'vue-router';
import type { ApiService } from '@/services/Api.service';
import EmptyState from '@/components/empty-state/EmptyState.vue';
import type { fetchPayload } from '@/store/DocumentsModule';
import { EventBusService } from '@/services/EventBus.service';
import { Topic } from '../../backend/pubsub';

export default defineComponent({
    components: {
        EmptyState,
        DtTooltip,
        FilterPopover,
        DtChip,
        DocumentDrawer,
        DefaultTemplateView,
        DtButton,
        SidebarToggle
    },
    setup() {
        const orgId: string = inject('orgId')!;
        const $route = useRoute();
        const selectedStatus = $route.query.status
            ? [$route.query.status?.toString()]
            : [NodeStatus.Published];
        const selectedLabels = $route.query.labels
            ? $route.query.labels?.toString().split(',')
            : [];
        const selectedSources = $route.query.sources
            ? $route.query.sources?.toString().split(',')
            : ['all'];
        return {
            orgId,
            selectedStatus,
            selectedLabels,
            selectedSources
        };
    },
    async mounted() {
        await this.fetchDocuments();
        await this.fetchTags();
        await this.getAuthorizedServices();

        this.nodeEventBus = new EventBusService(
            this.orgId,
            Topic.node_event,
            NodeType.Content,
            this.knowledgebase.id,
            {
                created: this.handleCreateNodeEvent,
                updated: this.handleUpdateNodeEvent,
                deleted: this.handleDeleteNodeEvent
            },
            this.fetchDocuments
        );
    },
    unmounted() {
        this.nodeEventBus?.destroy();
        this.closeDocumentDrawer();
    },
    watch: {
        activePage(newPage) {
            if (newPage) {
                this.fetchDocuments();
            }
        },
        search(newSearch: string) {
            this.search = newSearch;
            this.fetchDocuments();
        },
        sortBy: {
            handler(newSort) {
                if (newSort) {
                    this.fetchDocuments();
                }
            }
        },
        documents(newDocuments) {
            if (!this.activeRowId) return;

            if (
                !newDocuments.find(
                    (document: NodeDetail) => this.activeRowId === document.id
                )
            ) {
                this.drawerService?.closeDrawer();
                this.closeDocumentDrawer();

                if (!this.changeStatusClicked) {
                    this.$store.commit(`${this.orgId}/addNotification`, {
                        kind: 'error',
                        title: `${this.$t('This document is no longer in this page')}`
                    } as INotification);
                }
                this.changeStatusClicked = false;
                return;
            }
        }
    },
    computed: {
        NodeType() {
            return NodeType;
        },
        knowledgebase(): Knowledgebase {
            return this.$store.getters[`${this.orgId}/currentKnowledgebase`];
        },
        apiService(): ApiService | undefined {
            return this.$store.getters[`${this.orgId}/apiService`];
        },
        authToken(): string {
            return this.$store.getters[`${this.orgId}/authToken`];
        },
        hasFilters(): boolean {
            return (
                !!this.selectedLabels?.length ||
                !!this.selectedSources?.length ||
                !!this.search?.length
            );
        },
        showArchive(): boolean {
            // Check if the selected document are only uploads
            return !this.selectedDocuments.some(
                (doc: IBaseTableRow) => doc.data.content.source !== 'uploads'
            );
        },
        drawerService(): DrawerService | undefined {
            return this.$store.getters[`${this.orgId}/drawerService`];
        },
        maxContentWidth(): string {
            return this.$store.getters[`${this.orgId}/maxContentWidth`];
        },
        statusSelectedFilters: {
            get(): string[] {
                return this.selectedStatus;
            },
            set(status: string[]) {
                this.selectedStatus.length = 0;
                this.selectedDocuments.length = 0;
                if (status[0]) this.selectedStatus.push(status[0]);
                this.onStatusFilterChange();
            }
        },
        labelsSelectedFilters: {
            get(): string[] {
                return this.selectedLabels;
            },
            set(labels: string[]) {
                this.selectedLabels.length = 0;
                if (labels.length && labels[0])
                    this.selectedLabels.push(...labels);
                this.onLabelsFilterChange();
            }
        },
        sourcesSelectedFilters: {
            get(): string[] {
                return this.selectedSources;
            },
            set(sources: string[]) {
                this.selectedSources.length = 0;
                if (sources.length && sources[0])
                    this.selectedSources.push(...sources);
                this.onSourcesFilterChange();
            }
        },
        documents(): NodeDetail[] {
            return this.$store.getters[`${this.orgId}/documents/list`];
        },
        isFetching(): boolean {
            return this.$store.getters[`${this.orgId}/documents/fetching`];
        },
        hasError(): boolean {
            return this.$store.getters[`${this.orgId}/documents/error`];
        },
        totalHits(): number {
            return this.$store.getters[`${this.orgId}/documents/totalHits`];
        }
    },
    methods: {
        async fetchDocuments() {
            this.uploadedDocNames = [];

            const offset = (this.activePage - 1) * this.itemsPerPage;

            await this.$store.dispatch(`${this.orgId}/documents/fetch`, {
                kbId: this.knowledgebase?.id,
                status: this.statusSelectedFilters[0],
                source:
                    this.sourcesSelectedFilters[0] !== 'all'
                        ? this.sourcesSelectedFilters[0]
                        : undefined,
                labels: this.labelsSelectedFilters,
                sort: this.sortBy.sort,
                order: this.sortBy.asc ? 'asc' : 'desc',
                search: this.search ?? undefined,
                limit: this.itemsPerPage,
                offset
            } as fetchPayload);

            this.hasLoaded = true;
        },
        async fetchTags() {
            this.isFetchingTags = true;
            const res = await handleRequest<ListTagsOKBody>(
                this.apiService.knowledge.listTags(
                    this.authToken,
                    this.knowledgebase?.id
                ),
                this.orgId,
                true
            );
            this.isFetchingTags = false;
            this.tagsError = !!res.error;

            if (res?.data) {
                this.tags =
                    res.data?.tags?.map((tag: string) => ({
                        label: tag,
                        value: tag
                    })) || [];
            }
        },
        closeDocumentDrawer() {
            this.markdown = '';
            this.activeRowId = undefined;
            this.documentData = undefined;
        },
        onCloseUploadDrawer(uploadedFiles: any) {
            if (uploadedFiles?.length) {
                this.fetchDocuments();
                this.fetchTags();
                this.uploadedDocNames = uploadedFiles.map(
                    (f: any) => f.file.name
                );
                this.$store.commit(`${this.orgId}/addNotification`, {
                    kind: NOTICE_KINDS.SUCCESS,
                    message: `${uploadedFiles.length} ${
                        uploadedFiles.length === 1
                            ? this.$t('document')
                            : this.$t('documents')
                    } ${this.$t('uploaded')}`
                } as INotification);
            }
        },
        async handleOpenDocument(rowData: any) {
            const currentOpenDrawer = this.drawerService.getOpenDrawer();
            if (
                currentOpenDrawer?.name === 'document' &&
                this.activeRowId === rowData.id
            ) {
                // This means the document drawer is open
                return;
            }
            this.activeRowId = rowData?.id;

            const drawer: Drawer = {
                id: uuidv4(),
                name: 'document',
                componentInstance: this.documentDrawer,
                width:
                    currentOpenDrawer?.name === 'document'
                        ? this.maxContentWidth
                        : `calc(${this.maxContentWidth} / 2)`
            };
            this.drawerService?.toggleDrawer(drawer);
        },
        async bulkUpdateStatus(nodesArr: any) {
            await this.$store.dispatch(`${this.orgId}/documents/bulkUpdate`, {
                kbId: this.knowledgebase?.id,
                nodesArray: nodesArr
            });
            this.selectedDocuments = [];
        },
        handleUpdateStatus(status: NodeStatusType) {
            const nodesArr = this.selectedDocuments.map((doc: any) => ({
                id: doc.data.id,
                status
            }));

            this.bulkUpdateStatus(nodesArr);
        },
        updateDocument(document: Document, status: NodeStatusType) {
            const nodesArr = [
                {
                    id: document.id,
                    status
                }
            ];
            this.changeStatusClicked = true;

            this.bulkUpdateStatus(nodesArr);
        },
        renderOptions() {
            switch (this.selectedStatus[0]) {
                case this.nodeStatus.Published:
                    return [
                        {
                            title: 'Revert to draft',
                            action: (rowData: Document) => {
                                this.updateDocument(
                                    rowData,
                                    this.nodeStatus.Unpublished
                                );
                            }
                        },
                        {
                            title: 'Archive',
                            kind: POPOVER_TYPES.DANGER,
                            disabled: (rowData: Document) => {
                                return rowData?.content?.source !== 'uploads';
                            },
                            action: (rowData: Document) => {
                                this.updateDocument(
                                    rowData,
                                    this.nodeStatus.Deleted
                                );
                            }
                        }
                    ];

                case this.nodeStatus.Deleted:
                    return [
                        {
                            title: 'Publish',
                            action: (rowData: Document) => {
                                this.updateDocument(
                                    rowData,
                                    this.nodeStatus.Published
                                );
                            }
                        },
                        {
                            title: 'Revert to draft',
                            action: (rowData: Document) => {
                                this.updateDocument(
                                    rowData,
                                    this.nodeStatus.Unpublished
                                );
                            }
                        }
                    ];

                case this.nodeStatus.Unpublished:
                    return [
                        {
                            title: 'Publish',
                            action: (rowData: Document) => {
                                this.updateDocument(
                                    rowData,
                                    this.nodeStatus.Published
                                );
                            }
                        },
                        {
                            title: 'Archive',
                            kind: POPOVER_TYPES.DANGER,
                            disabled: (rowData: Document) => {
                                return rowData?.content?.source !== 'uploads';
                            },
                            action: (rowData: Document) => {
                                this.updateDocument(
                                    rowData,
                                    this.nodeStatus.Deleted
                                );
                            }
                        }
                    ];
                default:
                    return [];
            }
        },
        onStatusFilterChange() {
            replaceQueryFilterParams(
                'status',
                this.statusSelectedFilters,
                this.$route,
                this.$router
            );
            this.activePage = 1;
        },
        onLabelsFilterChange() {
            replaceQueryFilterParams(
                'labels',
                this.labelsSelectedFilters,
                this.$route,
                this.$router
            );
            this.activePage = 1;
        },
        onSourcesFilterChange() {
            replaceQueryFilterParams(
                'sources',
                this.sourcesSelectedFilters,
                this.$route,
                this.$router
            );
            this.activePage = 1;
        },
        handleCreateNodeEvent(message: NodeDetail) {
            this.$store.dispatch(
                `${this.orgId}/documents/handleDocumentCreate`,
                message
            );
        },
        handleUpdateNodeEvent(message: NodeDetail) {
            this.$store.dispatch(
                `${this.orgId}/documents/handleDocumentUpdate`,
                message
            );
        },
        handleDeleteNodeEvent(message: NodeDetail) {
            this.$store.dispatch(
                `${this.orgId}/documents/handleDocumentDelete`,
                message
            );
        },
        async getAuthorizedServices() {
            if (!this.apiService) return;

            this.isFetchingServices = true;
            const resKb = await handleRequest<AuthorizedServicesResponse>(
                this.apiService.ingestion.getAuthorizedServices(
                    this.authToken,
                    this.knowledgebase.id!
                ),
                this.orgId
            );
            this.isFetchingServices = false;

            if (resKb.data) {
                const data = resKb.data as AuthorizedServicesResponse;

                // Filter sources for the active services
                this.sources = SOURCES.filter((src: IFilter) => {
                    if (
                        src.value === 'zendesk' ||
                        src.value === 'document360' ||
                        src.value === 'box' ||
                        src.value === 'gdrive'
                    ) {
                        return !!Object.keys(data).find(
                            (service: string) => service === src.value
                        );
                    }

                    return true;
                });
            }
        }
    },
    data() {
        return {
            changeStatusClicked: false,
            search: '' as string | undefined,
            hasLoaded: false,
            isFetchingTags: false,
            tagsError: false,
            activePage: 1,
            itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
            sortBy: { sort: 'title', asc: true },
            uploadedDocNames: [] as string[],
            documentData: undefined as Document | undefined,
            markdown: '',
            isDocumentLoading: false,
            activeRowId: '',
            DOCUMENT_STATES,
            sources: SOURCES as IFilter[],
            selectedDocuments: [] as IBaseTableRow[],
            tags: [] as IFilter[],
            nodeStatus: NodeStatus,
            drawer: markRaw(SourcesDrawer),
            isFetchingServices: false,
            documentDrawer: () =>
                h(DocumentDrawer, {
                    documentId: this.activeRowId,
                    onFetchDocs: this.fetchDocuments,
                    onClose: this.closeDocumentDrawer
                }),
            upload: h(UploadDrawer, { onClose: this.onCloseUploadDrawer }),
            statusFilters: [
                ...Object.values(NodeStatus).map((status) => ({
                    label:
                        status === NodeStatus.Deleted
                            ? this.$t('archived')
                            : this.$t(capitalizeString(status)),
                    value: status
                }))
            ] as IFilter[],
            columns: [
                {
                    key: 'title',
                    type: 'string',
                    title: 'Title',
                    template: (document: Document) => {
                        return h(
                            <div className="d-py12 d-px16">
                                <p className="d-fc-black-900 d-truncate d-to-ellipsis">
                                    {document.title}
                                </p>
                                <LabelTag labels={document.labels}></LabelTag>
                            </div>
                        );
                    },
                    sort: 'title'
                },
                {
                    key: 'updated_at',
                    type: 'datetime',
                    title: 'Last updated',
                    width: '15rem',
                    sort: 'updated_at'
                },
                {
                    key: 'source_name',
                    type: 'string',
                    title: 'Source',
                    width: '10rem',
                    template: (document: Document) => {
                        return h(
                            <div className="d-d-flex d-px16">
                                <p className="d-fc-black-900 d-tt-capitalize">
                                    {document.content?.source}
                                </p>
                            </div>
                        );
                    },
                    sort: 'source_name'
                }
            ] as IBaseTableColumn<Document>[],
            nodeEventBus: undefined as EventBusService | undefined
        };
    }
});
</script>
