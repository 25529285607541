<template>
    <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10.5 1.75V4.08333M11.0833 9.91667V12.25M9.33333 2.91667L11.6667 2.91667M9.91667 11.0833H12.25M7.10937 5.72396L5.83333 2.91667L4.55729 5.72396L1.75 7L4.55729 8.27604L5.83333 11.0833L7.10937 8.27604L9.91667 7L7.10937 5.72396Z"
            stroke="url(#paint0_linear_12271_3295)"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <defs>
            <linearGradient
                id="paint0_linear_12271_3295"
                x1="1.75"
                y1="1.75"
                x2="12.25"
                y2="12.25"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.1" stop-color="#F9008E" />
                <stop offset="0.9" stop-color="#7C52FF" />
            </linearGradient>
        </defs>
    </svg>
</template>
