import {
    createWebHashHistory,
    type RouteRecordRaw,
    type RouterOptions
} from 'vue-router';

import EngagementView from '../views/EngagementView.vue';
import ExpandView from '../views/ExpandView.vue';
import ConsolidateView from '../views/ConsolidateView.vue';
import DocumentsView from '../views/DocumentsView.vue';
import ResponsesView from '../views/ResponsesView.vue';
import AutomationsView from '../views/AutomationsView.vue';
import ChatteringView from '../views/ChatteringView.vue';
import AgentAssistView from '../views/AgentAssistView.vue';
import SelfServiceView from '../views/SelfServiceView.vue';
import ImproveView from '@/views/ImproveView.vue';
import HistoryView from '@/views/HistoryView.vue';
import NodeEditorView from '@/views/NodeEditorView.vue';
import { featureIsEnabled } from '@/directives/feature.directive';
import StaticMessagesView from '@/views/StaticMessagesView.vue';
import SalesAssistView from '@/views/SalesAssistView.vue';
import OptimizeWizardView from '@/views/OptimizeWizardView.vue';
import NotFoundView from '@/views/NotFoundView.vue';
import AccessDeniedView from '@/views/AccessDeniedView.vue';

const baseUrl = window.location.origin + window.location.pathname + '/';

const router = {
    history: createWebHashHistory(baseUrl),
    routes: [
        {
            path: '/self-service',
            name: 'self_service',
            component: SelfServiceView,
            props: true
        },
        {
            path: '/agent-assist',
            name: 'agent_assist',
            component: AgentAssistView,
            props: true
        },
        {
            path: '/sales-assist',
            name: 'sales_assist',
            component: SalesAssistView,
            props: true
        },
        {
            path: '/:licenseType/knowledgebase/:appId/:kbId/engagement',
            name: 'engagement',
            component: EngagementView
        },
        {
            path: '/:licenseType/knowledgebase/:appId/:kbId/expand',
            name: 'expand',
            component: ExpandView
        },
        {
            path: '/:licenseType/knowledgebase/:appId/:kbId/consolidate',
            name: 'consolidate',
            component: ConsolidateView
        },
        {
            path: '/:licenseType/knowledgebase/:appId/:kbId/documents',
            name: 'documents',
            component: DocumentsView
        },
        {
            path: '/:licenseType/knowledgebase/:appId/:kbId/responses',
            name: 'responses',
            component: ResponsesView
        },
        {
            path: '/:licenseType/knowledgebase/:appId/:kbId/node-editor/:nodeId?/:nodeType?/:tab?/:subTab?/:clusterId?',
            name: 'node_editor',
            component: NodeEditorView
        },
        {
            path: '/:licenseType/knowledgebase/:appId/:kbId/optimize-wizard/:clusterId?/:nodeType?/:subTab?',
            name: 'optimize_wizard',
            component: OptimizeWizardView
        },
        {
            path: '/:licenseType/knowledgebase/:appId/:kbId/improve/:tab?/:subTab?',
            name: 'improve',
            component: ImproveView
        },
        {
            path: '/:licenseType/knowledgebase/:appId/:kbId/history/:conversationId?',
            name: 'history',
            component: HistoryView
        },
        {
            path: '/:licenseType/knowledgebase/:appId/:kbId/chatter',
            name: 'chatter',
            component: ChatteringView
        },
        {
            path: '/:licenseType/knowledgebase/:appId/:kbId/default-messages',
            name: 'default_messages',
            component: StaticMessagesView
        },
        {
            path: '/apps-integrations',
            name: 'apps_integrations',
            component: AutomationsView
        },
        {
            path: '/not-found',
            name: 'not_found',
            component: NotFoundView
        },
        {
            path: '/access-denied',
            name: 'access_denied',
            component: AccessDeniedView
        },
        {
            path: '/:pathMatch(.*)*',
            redirect: '/self-service'
        } as RouteRecordRaw
    ].filter((route: any) => {
        if (route.name?.length) {
            return featureIsEnabled(route.name);
        }
        return true;
    })
} as RouterOptions;

export default router;
