<template>
    <dt-card
        class="d-w100p"
        header-class="d-px16 d-py4 d-bgc-secondary d-bb d-bc-subtle d-btr4"
        content-class="d-px16 d-py8"
        :container-class="{
            'd-bs-sm': editable,
            'd-bs-none h:d-bs-sm': !editable
        }"
    >
        <template #header v-if="editable">
            <span
                class="d-fs-100 d-fw-bold d-h16 d-py4 d-my2 d-d-flex d-ai-center"
            >
                {{ cardTitle }}
            </span>
        </template>
        <template #content>
            <div v-if="editable">
                <template v-if="fetchingWorkflows">
                    <div
                        class="d-d-flex d-w100p d-fd-column d-jc-center d-ai-center d-mt16"
                    >
                        <dt-button loading importance="clear" />
                    </div>
                </template>
                <template v-else>
                    <div
                        v-if="hasWorkflows"
                        class="d-d-flex d-fw-wrap d-w100p d-gg8"
                    >
                        <div class="d-w100p">
                            <dt-popover
                                ref="workflowSelect"
                                v-bind="$attrs"
                                :modal="false"
                                placement="bottom-start"
                                padding="small"
                                :offset="[-250, -100]"
                                class="workflows-block-popover"
                                initial-focus-element="none"
                            >
                                <template #anchor="{ attrs }">
                                    <button
                                        class="d-w100p d-bgc-transparent d-ba-none"
                                        v-bind="attrs"
                                    >
                                        <dt-input
                                            :label="$t('Workflow')"
                                            icon-position="right"
                                            kind="muted"
                                            importance="clear"
                                            class="d-w100p"
                                            size="sm"
                                            label-class="d-jc-flex-start"
                                            readonly="readonly"
                                            :value="workflowName"
                                        >
                                        </dt-input>
                                    </button>
                                </template>
                                <template #content="{}">
                                    <div
                                        ref="content"
                                        class="workflow-block-popover-content"
                                        :style="{ width: popoverWidth }"
                                    >
                                        <div
                                            class="d-popover__header d-ba d-baw0 d-fw-normal d-py8 d-px8 d-box-border"
                                        >
                                            <div class="d-w100p">
                                                <dt-input
                                                    v-model="searchWorkflows"
                                                    :placeholder="
                                                        $t('Search workflows')
                                                    "
                                                    icon-size="sm"
                                                    size="sm"
                                                    type="text"
                                                >
                                                    <template #rightIcon>
                                                        <dt-icon
                                                            name="search"
                                                            size="300"
                                                            class="d-fc-black-900"
                                                        />
                                                    </template>
                                                </dt-input>
                                            </div>
                                        </div>
                                        <ul class="d-hmx216 d-ls-reset">
                                            <template
                                                v-if="filteredWorkflows?.length"
                                            >
                                                <dt-list-item
                                                    v-for="option in filteredWorkflows"
                                                    :key="option.path"
                                                    navigation-type="tab"
                                                    role="menuitem"
                                                    class="d-px4 d-wb-break-all d-to-ellipsis"
                                                    @click="
                                                        handleUrlChange(
                                                            option.path
                                                        )
                                                    "
                                                >
                                                    <div class="d-px4">
                                                        {{ option.name }}
                                                    </div>
                                                </dt-list-item>
                                            </template>
                                            <template v-else>
                                                <dt-list-item
                                                    navigation-type="tab"
                                                    role="menuitem"
                                                    class="d-px4 d-wb-break-all d-to-ellipsis h:d-bgc-secondary d-c-default"
                                                >
                                                    <div class="d-ta-center">
                                                        {{
                                                            $t(
                                                                'No workflows found with current filter'
                                                            )
                                                        }}
                                                    </div>
                                                </dt-list-item>
                                            </template>
                                        </ul>
                                    </div>
                                </template>
                            </dt-popover>
                        </div>
                    </div>
                    <div
                        class="d-d-flex d-jc-center d-fw-wrap d-fd-column d-gg12"
                        v-else
                    >
                        <div
                            class="d-d-flex d-ai-center d-fw-wrap d-fs-100 d-fd-column d-gg4"
                        >
                            <span class="d-fw-bold d-w100p d-ta-center">
                                {{ $t('No workflows found') }}
                            </span>
                            <span class="d-ta-center">
                                {{
                                    $t(
                                        'Please create workflows on the global workflows page to start adding.'
                                    )
                                }}
                            </span>
                        </div>

                        <div class="d-d-flex d-jc-center">
                            <dt-button size="xs">
                                {{ $t('Workflows') }}
                            </dt-button>
                        </div>
                    </div>
                </template>
            </div>
            <div v-else class="d-d-flex d-fw-wrap d-gg2">
                <div class="d-d-flex d-fw-wrap d-gg8">
                    <dt-icon name="expert-node" />
                    <span class="d-fw-bold">
                        {{ $t('Workflow') }}
                    </span>
                </div>
                <div class="d-w100p" v-if="fetchingWorkflows">
                    <dt-skeleton
                        arial-label="Loading"
                        :paragraphOption="{
                            rows: 1,
                            rowClass: 'd-h16 d-mt8 d-mb8 d-pb2'
                        }"
                    />
                </div>
                <div
                    class="d-w100p d-fc-warning d-fw-semibold d-d-flex d-ai-center d-mt8"
                    v-else-if="isLimited"
                >
                    <dt-icon name="alert-triangle" size="200" />
                    <span class="d-ml8">{{
                        $t('Cannot load workflow in limited state')
                    }}</span>
                </div>
                <div
                    class="d-w100p d-fc-warning d-fw-semibold d-d-flex d-ai-center d-mt8"
                    v-else-if="workflowsError"
                >
                    <dt-icon name="alert-triangle" size="200" />
                    <span class="d-ml8">{{
                        $t('There was an error fetching workflows')
                    }}</span>
                </div>
                <p class="d-w100p" v-else>
                    {{ workflowName }}
                </p>
            </div>
        </template>
        <template #footer v-if="editable">
            <div class="d-d-flex d-jc-flex-end d-w100p d-mt8 d-gg8">
                <dt-button importance="clear" @click="handleCancel" size="xs">
                    {{ $t('Cancel') }}
                </dt-button>
                <dt-button
                    @click="handleSave"
                    size="xs"
                    :disabled="!shouldSave"
                >
                    {{ $t('Add') }}
                </dt-button>
            </div>
        </template>
    </dt-card>
</template>
<script lang="ts">
import { defineComponent, inject, type PropType, toRaw } from 'vue';
import type { Block } from '@/components/node-editor/responseBlockEditor';
import DropFile from '@/components/upload-drawer/DropFile.vue';
import {
    DtButton,
    DtCard,
    DtIcon,
    DtInput,
    DtListItem,
    DtPopover,
    DtSelectMenu,
    DtSkeleton
} from '@dialpad/dialtone/vue3';
import type { Organisation } from '@/open-api';
import IframeLoader from '@/components/iframe-loader/IframeLoader.vue';
import type { ApiService } from '@/services/Api.service';
import type { ResponseBlockEditor } from '@/components/node-editor/responseBlockEditor';
import type { KoopidWorkflow } from '@/utils/koopid';

export default defineComponent({
    props: {
        block: {
            type: Object as PropType<Block>,
            required: true
        },
        editable: {
            type: Boolean as PropType<boolean>,
            required: true
        },
        workflows: {
            type: Array as PropType<KoopidWorkflow[]>,
            required: true
        },
        workflowsError: {
            type: Boolean as PropType<boolean>,
            required: true
        },
        fetchingWorkflows: {
            type: Boolean as PropType<boolean>,
            required: true
        }
    },
    setup() {
        const orgId: string = inject('orgId')!;
        return {
            orgId
        };
    },
    mounted() {
        const el = // @ts-ignore
            toRaw(this.$refs.workflowSelect)?.$el as HTMLDivElement;
        this.popoverWidth = (el as HTMLDivElement)?.clientWidth
            ? `${(el as HTMLDivElement)?.clientWidth}px`
            : undefined;
    },
    components: {
        DtPopover,
        DtListItem,
        DtSkeleton,
        DtSelectMenu,
        DtIcon,
        DtInput,
        IframeLoader,
        DtButton,
        DtCard,
        DropFile
    },
    computed: {
        /* v8 ignore next 40 */
        isEditable: {
            get(): boolean {
                return this.editable;
            },
            set(editable: boolean) {
                this.$emit('edit', editable);
            }
        },
        currentOrganisation(): Organisation {
            return this.$store.getters[`${this.orgId}/currentOrg`];
        },
        isLimited(): boolean {
            return this.$store.getters[`${this.orgId}/limited`];
        },
        authToken(): string {
            return this.$store.getters[`${this.orgId}/authToken`];
        },
        apiService(): ApiService {
            return this.$store.getters[`${this.orgId}/apiService`];
        },
        responseBlockEditor(): ResponseBlockEditor | undefined {
            return this.$store.getters[`${this.orgId}/responseBlockEditor`];
        },
        shouldSave(): boolean {
            return !!this.url?.length;
        },
        cardTitle(): string {
            return this.block.new
                ? this.$t('Add workflow')
                : this.$t('Edit workflow');
        },
        hasWorkflows(): boolean {
            return !!this.workflows?.length;
        },
        companyId(): string {
            return this.$store.getters[`${this.orgId}/companyId`];
        },
        bodyRef(): string {
            return this.$store.getters[`${this.orgId}/bodyRef`];
        },
        workflowName(): string {
            return (
                this.workflows.find(
                    (workflow: KoopidWorkflow) => workflow.path === this.url
                )?.name || ''
            );
        },
        filteredWorkflows(): KoopidWorkflow[] {
            return this.workflows.filter((workflow: KoopidWorkflow) =>
                workflow.name.includes(this.searchWorkflows)
            );
        }
    },
    methods: {
        resetData() {
            this.url = this.block?.data?.workflow_block?.url;
        },
        handleRemove() {
            this.responseBlockEditor?.removeBlock(this.block);
        },
        handleCancel(e: Event) {
            e.stopPropagation();
            // If block has never been added then it will remove it.
            // If was added, even if it was temporary, it won't be removed.
            if (this.block.new && !this.block.data.dirty) {
                this.handleRemove();
            } else {
                this.resetData();
                this.isEditable = false;
            }
        },
        handleSave(e: Event) {
            e.stopPropagation();
            this.block?.setBlockData({
                url: this.url
            });
            this.isEditable = false;
        },
        handleUrlChange(newUrl: any) {
            this.url = newUrl;

            this.handleClose();
        },
        handleClose() {
            const e = new Event('dt-popover-close');
            window.dispatchEvent(e);

            this.searchWorkflows = '';
        }
    },
    data() {
        return {
            url: this.block?.data?.workflow_block?.url,
            searchWorkflows: '',
            popoverWidth: undefined as string | undefined
        };
    }
});
</script>

<style lang="less">
.workflows-block-popover-open [data-qa='dt-popover-anchor'] {
    position: relative !important;
}
</style>
