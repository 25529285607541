/* tslint:disable */
/* eslint-disable */
/**
 * Mind
 * Kare API v4.0
 *
 * The version of the OpenAPI document: 4.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const WidgetVertical = {
    Unspecified: 'unspecified',
    AccountingAndAuditing: 'accounting_and_auditing',
    Agriculture: 'agriculture',
    Airlines: 'airlines',
    AmusementAndRecreation: 'amusement_and_recreation',
    ArtsAndCulture: 'arts_and_culture',
    Banking: 'banking',
    BusinessServices: 'business_services',
    ChemicalIndustry: 'chemical_industry',
    Construction: 'construction',
    ConsumerDiscretionary: 'consumer_discretionary',
    ConsumerServices: 'consumer_services',
    ConsumerStaples: 'consumer_staples',
    Ecommerce: 'ecommerce',
    Education: 'education',
    Energy: 'energy',
    Engineering: 'engineering',
    Entertainment: 'entertainment',
    Environment: 'environment',
    Fashion: 'fashion',
    Forestry: 'forestry',
    Government: 'government',
    Healthcare: 'healthcare',
    HeavyIndustry: 'heavy_industry',
    Hotels: 'hotels',
    InformationTechnology: 'information_technology',
    Infrastructure: 'infrastructure',
    Insurance: 'insurance',
    Law: 'law',
    LifeSciences: 'life_sciences',
    Logistics: 'logistics',
    Manufacturing: 'manufacturing',
    Media: 'media',
    Mining: 'mining',
    NonProfits: 'non_profits',
    ProfessionalServices: 'professional_services',
    Publishing: 'publishing',
    RealEstate: 'real_estate',
    Restaurants: 'restaurants',
    Retail: 'retail',
    ScienceAndTechnology: 'science_and_technology',
    Space: 'space',
    Telecom: 'telecom',
    Tourism: 'tourism',
    Transportation: 'transportation',
    Utilities: 'utilities',
    Wholesale: 'wholesale'
} as const;

export type WidgetVertical = typeof WidgetVertical[keyof typeof WidgetVertical];



