<template>
    <rich-text-editor
        :class="{ 'h:d-bs-sm': !editable, 'd-bs-sm': editable }"
        v-model:editable="isEditable"
        v-model:markdown="content"
        :block="block"
        @save="handleSave"
    />
</template>
<script lang="ts">
import RichTextEditor from '@/components/rich-text-editor/RichTextEditor.vue';
import { defineComponent, type PropType } from 'vue';
import type { Block } from '@/components/node-editor/responseBlockEditor';
export default defineComponent({
    props: {
        block: {
            type: Object as PropType<Block>,
            required: true
        },
        editable: {
            type: Boolean as PropType<boolean>,
            required: true
        }
    },
    components: {
        RichTextEditor
    },
    computed: {
        /* v8 ignore next 6 */
        isEditable: {
            get(): boolean {
                return this.editable;
            },
            set(editable: boolean) {
                this.$emit('edit', editable);
            }
        }
    },
    methods: {
        handleSave() {
            /* v8 ignore next 4 */
            this.block?.setBlockData({
                text: this.content
            });
        }
    },
    data() {
        return {
            content: this.block?.data.text_block?.text || ''
        };
    }
});
</script>
