/* tslint:disable */
/* eslint-disable */
/**
 * Mind
 * Kare API v4.0
 *
 * The version of the OpenAPI document: 4.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { App } from '../model';
// @ts-ignore
import { AppConfigPublic } from '../model';
// @ts-ignore
import { CreateAppRequest } from '../model';
// @ts-ignore
import { ListAppsResponse } from '../model';
// @ts-ignore
import { ModelError } from '../model';
// @ts-ignore
import { Organisation } from '../model';
// @ts-ignore
import { PatchAppRequest } from '../model';
// @ts-ignore
import { PatchMeRequest } from '../model';
// @ts-ignore
import { PatchUserRequest } from '../model';
// @ts-ignore
import { PostUserRequest } from '../model';
// @ts-ignore
import { User } from '../model';
/**
 * IamApi - axios parameter creator
 * @export
 */
export const IamApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Deletes an application key. 
         * @summary Delete a app key
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId app ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApp: async (authorization: string, appId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('deleteApp', 'authorization', authorization)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('deleteApp', 'appId', appId)
            const localVarPath = `/iam/apps/{app_id}`
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerIAMAppsWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication bearerSettings required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the user. 
         * @summary Delete user
         * @param {string} authorization Authorization Bearer header
         * @param {string} userId user ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (authorization: string, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('deleteUser', 'authorization', authorization)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUser', 'userId', userId)
            const localVarPath = `/iam/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerIAMUsersWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get App key information. 
         * @summary Get a app key
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId app ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApp: async (authorization: string, appId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getApp', 'authorization', authorization)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getApp', 'appId', appId)
            const localVarPath = `/iam/apps/{app_id}`
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerSettings required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the details of the logged user. 
         * @summary Get application configuration
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId app ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppConfig: async (authorization: string, appId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getAppConfig', 'authorization', authorization)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppConfig', 'appId', appId)
            const localVarPath = `/iam/apps/{app_id}/config`
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerIAMAppsRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication bearerKnowledgeGraphRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication bearerSettings required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return public configuration of an application. This can than be used to bootstrap clients. 
         * @summary Get application configuration
         * @param {string} appId app ID
         * @param {boolean} [context] Optionally fetch the context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppConfigPublic: async (appId: string, context?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppConfigPublic', 'appId', appId)
            const localVarPath = `/iam/apps/{app_id}/public-config`
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (context !== undefined) {
                localVarQueryParameter['context'] = context;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the details of the logged user. 
         * @summary Get me
         * @param {string} authorization Authorization Bearer header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe: async (authorization: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getMe', 'authorization', authorization)
            const localVarPath = `/iam/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerBase required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to get organisation details
         * @summary Get organisation
         * @param {string} authorization Authorization Bearer header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisation: async (authorization: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getOrganisation', 'authorization', authorization)
            const localVarPath = `/iam/org`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerBase required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the details of a user. 
         * @summary Get user
         * @param {string} authorization Authorization Bearer header.
         * @param {string} userId user ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (authorization: string, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getUser', 'authorization', authorization)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUser', 'userId', userId)
            const localVarPath = `/iam/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerIAMUsersRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all applications in this domain. 
         * @summary List app
         * @param {string} authorization Authorization Bearer header
         * @param {'widget' | 'consumer'} [type] Application type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listApps: async (authorization: string, type?: 'widget' | 'consumer', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('listApps', 'authorization', authorization)
            const localVarPath = `/iam/apps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerIAMAppsRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication bearerSettings required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the list of all users belonging to this organisation. 
         * @summary List users
         * @param {string} authorization Authorization Bearer header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers: async (authorization: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('listUsers', 'authorization', authorization)
            const localVarPath = `/iam/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerIAMUsersRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update app key information. 
         * @summary Updade a app key
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId app ID
         * @param {PatchAppRequest} [body] Patch application request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchApp: async (authorization: string, appId: string, body?: PatchAppRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('patchApp', 'authorization', authorization)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('patchApp', 'appId', appId)
            const localVarPath = `/iam/apps/{app_id}`
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerIAMAppsWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication bearerSettings required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update app configuration. 
         * @summary Updade application configuration
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId app ID
         * @param {object} config JSON object with the application configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAppConfig: async (authorization: string, appId: string, config: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('patchAppConfig', 'authorization', authorization)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('patchAppConfig', 'appId', appId)
            // verify required parameter 'config' is not null or undefined
            assertParamExists('patchAppConfig', 'config', config)
            const localVarPath = `/iam/apps/{app_id}/config`
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerIAMAppsWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication bearerSettings required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(config, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the logged user information. 
         * @summary Update me
         * @param {string} authorization Authorization Bearer header
         * @param {PatchMeRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchMe: async (authorization: string, body: PatchMeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('patchMe', 'authorization', authorization)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('patchMe', 'body', body)
            const localVarPath = `/iam/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerBase required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Allow administrators to update the details of the other users. 
         * @summary Update user
         * @param {string} authorization Authorization Bearer header
         * @param {string} userId user ID
         * @param {PatchUserRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUser: async (authorization: string, userId: string, body: PatchUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('patchUser', 'authorization', authorization)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('patchUser', 'userId', userId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('patchUser', 'body', body)
            const localVarPath = `/iam/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerIAMUsersWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new application for this domain. 
         * @summary Create a new app
         * @param {string} authorization Authorization Bearer header
         * @param {CreateAppRequest} [body] Create application request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApp: async (authorization: string, body?: CreateAppRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('postApp', 'authorization', authorization)
            const localVarPath = `/iam/apps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerIAMAppsWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication bearerSettings required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new user in the organisation. 
         * @summary Create user
         * @param {string} authorization Authorization Bearer header
         * @param {PostUserRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUser: async (authorization: string, body?: PostUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('postUser', 'authorization', authorization)
            const localVarPath = `/iam/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerIAMUsersWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IamApi - functional programming interface
 * @export
 */
export const IamApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IamApiAxiosParamCreator(configuration)
    return {
        /**
         * Deletes an application key. 
         * @summary Delete a app key
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId app ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApp(authorization: string, appId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApp(authorization, appId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes the user. 
         * @summary Delete user
         * @param {string} authorization Authorization Bearer header
         * @param {string} userId user ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(authorization: string, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(authorization, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get App key information. 
         * @summary Get a app key
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId app ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApp(authorization: string, appId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<App>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApp(authorization, appId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the details of the logged user. 
         * @summary Get application configuration
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId app ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppConfig(authorization: string, appId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppConfig(authorization, appId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return public configuration of an application. This can than be used to bootstrap clients. 
         * @summary Get application configuration
         * @param {string} appId app ID
         * @param {boolean} [context] Optionally fetch the context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppConfigPublic(appId: string, context?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppConfigPublic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppConfigPublic(appId, context, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the details of the logged user. 
         * @summary Get me
         * @param {string} authorization Authorization Bearer header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMe(authorization: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMe(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to get organisation details
         * @summary Get organisation
         * @param {string} authorization Authorization Bearer header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganisation(authorization: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organisation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganisation(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the details of a user. 
         * @summary Get user
         * @param {string} authorization Authorization Bearer header.
         * @param {string} userId user ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(authorization: string, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(authorization, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List all applications in this domain. 
         * @summary List app
         * @param {string} authorization Authorization Bearer header
         * @param {'widget' | 'consumer'} [type] Application type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listApps(authorization: string, type?: 'widget' | 'consumer', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAppsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listApps(authorization, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the list of all users belonging to this organisation. 
         * @summary List users
         * @param {string} authorization Authorization Bearer header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUsers(authorization: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUsers(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update app key information. 
         * @summary Updade a app key
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId app ID
         * @param {PatchAppRequest} [body] Patch application request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchApp(authorization: string, appId: string, body?: PatchAppRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<App>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchApp(authorization, appId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update app configuration. 
         * @summary Updade application configuration
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId app ID
         * @param {object} config JSON object with the application configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchAppConfig(authorization: string, appId: string, config: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchAppConfig(authorization, appId, config, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the logged user information. 
         * @summary Update me
         * @param {string} authorization Authorization Bearer header
         * @param {PatchMeRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchMe(authorization: string, body: PatchMeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchMe(authorization, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Allow administrators to update the details of the other users. 
         * @summary Update user
         * @param {string} authorization Authorization Bearer header
         * @param {string} userId user ID
         * @param {PatchUserRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchUser(authorization: string, userId: string, body: PatchUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchUser(authorization, userId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new application for this domain. 
         * @summary Create a new app
         * @param {string} authorization Authorization Bearer header
         * @param {CreateAppRequest} [body] Create application request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApp(authorization: string, body?: CreateAppRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<App>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApp(authorization, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new user in the organisation. 
         * @summary Create user
         * @param {string} authorization Authorization Bearer header
         * @param {PostUserRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUser(authorization: string, body?: PostUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUser(authorization, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IamApi - factory interface
 * @export
 */
export const IamApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IamApiFp(configuration)
    return {
        /**
         * Deletes an application key. 
         * @summary Delete a app key
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId app ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApp(authorization: string, appId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteApp(authorization, appId, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the user. 
         * @summary Delete user
         * @param {string} authorization Authorization Bearer header
         * @param {string} userId user ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(authorization: string, userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(authorization, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get App key information. 
         * @summary Get a app key
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId app ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApp(authorization: string, appId: string, options?: any): AxiosPromise<App> {
            return localVarFp.getApp(authorization, appId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the details of the logged user. 
         * @summary Get application configuration
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId app ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppConfig(authorization: string, appId: string, options?: any): AxiosPromise<object> {
            return localVarFp.getAppConfig(authorization, appId, options).then((request) => request(axios, basePath));
        },
        /**
         * Return public configuration of an application. This can than be used to bootstrap clients. 
         * @summary Get application configuration
         * @param {string} appId app ID
         * @param {boolean} [context] Optionally fetch the context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppConfigPublic(appId: string, context?: boolean, options?: any): AxiosPromise<AppConfigPublic> {
            return localVarFp.getAppConfigPublic(appId, context, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the details of the logged user. 
         * @summary Get me
         * @param {string} authorization Authorization Bearer header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe(authorization: string, options?: any): AxiosPromise<User> {
            return localVarFp.getMe(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to get organisation details
         * @summary Get organisation
         * @param {string} authorization Authorization Bearer header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisation(authorization: string, options?: any): AxiosPromise<Organisation> {
            return localVarFp.getOrganisation(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the details of a user. 
         * @summary Get user
         * @param {string} authorization Authorization Bearer header.
         * @param {string} userId user ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(authorization: string, userId: string, options?: any): AxiosPromise<User> {
            return localVarFp.getUser(authorization, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * List all applications in this domain. 
         * @summary List app
         * @param {string} authorization Authorization Bearer header
         * @param {'widget' | 'consumer'} [type] Application type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listApps(authorization: string, type?: 'widget' | 'consumer', options?: any): AxiosPromise<ListAppsResponse> {
            return localVarFp.listApps(authorization, type, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the list of all users belonging to this organisation. 
         * @summary List users
         * @param {string} authorization Authorization Bearer header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers(authorization: string, options?: any): AxiosPromise<Array<User>> {
            return localVarFp.listUsers(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * Update app key information. 
         * @summary Updade a app key
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId app ID
         * @param {PatchAppRequest} [body] Patch application request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchApp(authorization: string, appId: string, body?: PatchAppRequest, options?: any): AxiosPromise<App> {
            return localVarFp.patchApp(authorization, appId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Update app configuration. 
         * @summary Updade application configuration
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId app ID
         * @param {object} config JSON object with the application configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAppConfig(authorization: string, appId: string, config: object, options?: any): AxiosPromise<void> {
            return localVarFp.patchAppConfig(authorization, appId, config, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the logged user information. 
         * @summary Update me
         * @param {string} authorization Authorization Bearer header
         * @param {PatchMeRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchMe(authorization: string, body: PatchMeRequest, options?: any): AxiosPromise<User> {
            return localVarFp.patchMe(authorization, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Allow administrators to update the details of the other users. 
         * @summary Update user
         * @param {string} authorization Authorization Bearer header
         * @param {string} userId user ID
         * @param {PatchUserRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUser(authorization: string, userId: string, body: PatchUserRequest, options?: any): AxiosPromise<User> {
            return localVarFp.patchUser(authorization, userId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new application for this domain. 
         * @summary Create a new app
         * @param {string} authorization Authorization Bearer header
         * @param {CreateAppRequest} [body] Create application request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApp(authorization: string, body?: CreateAppRequest, options?: any): AxiosPromise<App> {
            return localVarFp.postApp(authorization, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new user in the organisation. 
         * @summary Create user
         * @param {string} authorization Authorization Bearer header
         * @param {PostUserRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUser(authorization: string, body?: PostUserRequest, options?: any): AxiosPromise<User> {
            return localVarFp.postUser(authorization, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IamApi - object-oriented interface
 * @export
 * @class IamApi
 * @extends {BaseAPI}
 */
export class IamApi extends BaseAPI {
    /**
     * Deletes an application key. 
     * @summary Delete a app key
     * @param {string} authorization Authorization Bearer header
     * @param {string} appId app ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IamApi
     */
    public deleteApp(authorization: string, appId: string, options?: AxiosRequestConfig) {
        return IamApiFp(this.configuration).deleteApp(authorization, appId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the user. 
     * @summary Delete user
     * @param {string} authorization Authorization Bearer header
     * @param {string} userId user ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IamApi
     */
    public deleteUser(authorization: string, userId: string, options?: AxiosRequestConfig) {
        return IamApiFp(this.configuration).deleteUser(authorization, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get App key information. 
     * @summary Get a app key
     * @param {string} authorization Authorization Bearer header
     * @param {string} appId app ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IamApi
     */
    public getApp(authorization: string, appId: string, options?: AxiosRequestConfig) {
        return IamApiFp(this.configuration).getApp(authorization, appId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the details of the logged user. 
     * @summary Get application configuration
     * @param {string} authorization Authorization Bearer header
     * @param {string} appId app ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IamApi
     */
    public getAppConfig(authorization: string, appId: string, options?: AxiosRequestConfig) {
        return IamApiFp(this.configuration).getAppConfig(authorization, appId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return public configuration of an application. This can than be used to bootstrap clients. 
     * @summary Get application configuration
     * @param {string} appId app ID
     * @param {boolean} [context] Optionally fetch the context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IamApi
     */
    public getAppConfigPublic(appId: string, context?: boolean, options?: AxiosRequestConfig) {
        return IamApiFp(this.configuration).getAppConfigPublic(appId, context, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the details of the logged user. 
     * @summary Get me
     * @param {string} authorization Authorization Bearer header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IamApi
     */
    public getMe(authorization: string, options?: AxiosRequestConfig) {
        return IamApiFp(this.configuration).getMe(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to get organisation details
     * @summary Get organisation
     * @param {string} authorization Authorization Bearer header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IamApi
     */
    public getOrganisation(authorization: string, options?: AxiosRequestConfig) {
        return IamApiFp(this.configuration).getOrganisation(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the details of a user. 
     * @summary Get user
     * @param {string} authorization Authorization Bearer header.
     * @param {string} userId user ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IamApi
     */
    public getUser(authorization: string, userId: string, options?: AxiosRequestConfig) {
        return IamApiFp(this.configuration).getUser(authorization, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all applications in this domain. 
     * @summary List app
     * @param {string} authorization Authorization Bearer header
     * @param {'widget' | 'consumer'} [type] Application type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IamApi
     */
    public listApps(authorization: string, type?: 'widget' | 'consumer', options?: AxiosRequestConfig) {
        return IamApiFp(this.configuration).listApps(authorization, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the list of all users belonging to this organisation. 
     * @summary List users
     * @param {string} authorization Authorization Bearer header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IamApi
     */
    public listUsers(authorization: string, options?: AxiosRequestConfig) {
        return IamApiFp(this.configuration).listUsers(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update app key information. 
     * @summary Updade a app key
     * @param {string} authorization Authorization Bearer header
     * @param {string} appId app ID
     * @param {PatchAppRequest} [body] Patch application request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IamApi
     */
    public patchApp(authorization: string, appId: string, body?: PatchAppRequest, options?: AxiosRequestConfig) {
        return IamApiFp(this.configuration).patchApp(authorization, appId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update app configuration. 
     * @summary Updade application configuration
     * @param {string} authorization Authorization Bearer header
     * @param {string} appId app ID
     * @param {object} config JSON object with the application configuration.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IamApi
     */
    public patchAppConfig(authorization: string, appId: string, config: object, options?: AxiosRequestConfig) {
        return IamApiFp(this.configuration).patchAppConfig(authorization, appId, config, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the logged user information. 
     * @summary Update me
     * @param {string} authorization Authorization Bearer header
     * @param {PatchMeRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IamApi
     */
    public patchMe(authorization: string, body: PatchMeRequest, options?: AxiosRequestConfig) {
        return IamApiFp(this.configuration).patchMe(authorization, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Allow administrators to update the details of the other users. 
     * @summary Update user
     * @param {string} authorization Authorization Bearer header
     * @param {string} userId user ID
     * @param {PatchUserRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IamApi
     */
    public patchUser(authorization: string, userId: string, body: PatchUserRequest, options?: AxiosRequestConfig) {
        return IamApiFp(this.configuration).patchUser(authorization, userId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new application for this domain. 
     * @summary Create a new app
     * @param {string} authorization Authorization Bearer header
     * @param {CreateAppRequest} [body] Create application request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IamApi
     */
    public postApp(authorization: string, body?: CreateAppRequest, options?: AxiosRequestConfig) {
        return IamApiFp(this.configuration).postApp(authorization, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new user in the organisation. 
     * @summary Create user
     * @param {string} authorization Authorization Bearer header
     * @param {PostUserRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IamApi
     */
    public postUser(authorization: string, body?: PostUserRequest, options?: AxiosRequestConfig) {
        return IamApiFp(this.configuration).postUser(authorization, body, options).then((request) => request(this.axios, this.basePath));
    }
}
