<template>
    <Transition name="slide" v-bind="$attrs">
        <drawer-template
            v-if="showBoxConnectPage"
            @drawer-template-close="handleClose"
            @back="handleBackClick({})"
            is-secondary-page
        >
            <template #header>
                <h3 class="d-d-flex d-jc-flex-start d-py8 d-my2 d-ml32 d-pl8">
                    {{
                        `${isConnected ? $t('Configure') : $t('Connect')} ${$t(
                            'Box account'
                        )}`
                    }}
                </h3>
            </template>
            <template #default>
                <dt-notice
                    hide-close
                    class="d-mb16"
                    kind="info"
                    :title="$t('Connect your Box account')"
                >
                    {{ $t('Refer to') }}
                    <a
                        href="https://developer.karehq.com/integrations/box/oauth"
                        target="_blank"
                        class="d-td-underline d-fc-black-900"
                    >
                        {{ $t('Box integration documentation') }}
                    </a>
                    {{ $t('for more details') }}
                </dt-notice>
                <div class="d-mb16">
                    <dt-tooltip
                        placement="top-end"
                        :message="$t('copied!')"
                        :offset="[0, -76]"
                        :show="copied"
                    >
                        <template #anchor>
                            <dt-input
                                class="d-truncate"
                                :label="$t('Box configuration URL')"
                                disabled
                                :value="configUrl"
                                :description="
                                    $t(
                                        'Copy and paste this URL in Oauth 2.0 Redirect URI and CORS Domains fields on Box app configuration\n'
                                    )
                                "
                            >
                                <template #rightIcon>
                                    <dt-icon
                                        class="d-c-pointer"
                                        name="copy"
                                        size="300"
                                        @click="copyConfigUrl"
                                    />
                                </template>
                            </dt-input>
                        </template>
                    </dt-tooltip>
                </div>
                <div class="d-mb16">
                    <dt-input
                        :label="$t('Client ID')"
                        v-model="clientId"
                        :disabled="isConnected"
                    />
                </div>
                <div class="d-mb16" v-if="!isConnected">
                    <dt-input
                        :label="$t('Client secret')"
                        :type="showClientSecret ? 'text' : 'password'"
                        v-model="clientSecret"
                    >
                        <template #rightIcon>
                            <dt-icon
                                class="d-c-pointer"
                                v-if="showClientSecret"
                                name="eye-off"
                                @click="toggleShowClientSecret"
                                size="300"
                            />
                            <dt-icon
                                class="d-c-pointer"
                                v-else
                                name="eye"
                                size="300"
                                @click="toggleShowClientSecret"
                            />
                        </template>
                    </dt-input>
                </div>
                <div class="d-mb16">
                    <dt-input
                        :label="$t('Folder URL')"
                        v-model="folderUrl"
                        input-class="d-pl0"
                        :disabled="isConnected"
                    >
                        <template #leftIcon>
                            <span class="d-fc-black-500">
                                https://app.box.com/folder/
                            </span>
                        </template>
                    </dt-input>
                </div>
                <div class="d-mt16">
                    <h4
                        class="d-fc-black-700 d-fw-semibold d-mb4"
                        v-if="!isConnected || selectedLabels.length"
                    >
                        {{ $t('Labels') }}
                    </h4>
                    <base-combobox
                        v-if="!isConnected"
                        v-model:selected-items="selectedLabels"
                        :item-list="tags"
                        :is-fetching="isComboboxFetching"
                        :has-error="hasComboboxError"
                    />
                    <div
                        v-if="isConnected && selectedLabels.length"
                        class="base-input__input d-input d-h32"
                    >
                        <dt-chip
                            :interactive="false"
                            :hide-close="true"
                            v-for="label in selectedLabels"
                            :key="label"
                            class="d-mr4"
                        >
                            {{ label }}
                        </dt-chip>
                    </div>
                </div>
                <div class="d-mt16">
                    <dt-checkbox
                        :disabled="isConnected"
                        :label="$t('Allow downloading files')"
                        aria-label="allow-downloading-files-checkbox"
                        :checked="allowDownloadingFiles"
                        @input="handleAllowDownloadingFiles"
                    />
                    <dt-checkbox
                        :disabled="isConnected"
                        :label="$t('Allow public access to files')"
                        aria-label="allow-public-access-checkbox"
                        :checked="allowPublicAccess"
                        @input="handleAllowPublicAccess"
                    />
                    <dt-checkbox
                        :disabled="isConnected"
                        :label="$t('Import web links')"
                        aria-label="import-web-links-checkbox"
                        :checked="importWebLinks"
                        @input="handleImportWebLinks"
                    />
                    <div class="d-my16" v-if="showCustomUserAgent">
                        <dt-input
                            :disabled="isConnected"
                            :description="
                                $t(
                                    'Certain websites only allow crawling from non search engines. Changing the user agent allows you to crawl these websites.'
                                )
                            "
                            :label="$t('Custom user agent')"
                            v-model="customUserAgent"
                        />
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="d-d-flex d-py16">
                    <dt-button
                        class="d-mr16"
                        importance="clear"
                        @click="handleBackClick({})"
                        :disabled="isRequesting"
                    >
                        {{ $t('Cancel') }}
                    </dt-button>
                    <dt-button
                        v-if="!isConnected"
                        @click="handleConnectBox"
                        :disabled="isRequesting || !(clientId && clientSecret)"
                    >
                        {{ $t('Connect') }}
                    </dt-button>
                    <dt-button
                        v-if="isConnected"
                        id="open-child-window"
                        kind="danger"
                        @click="handleShowConfirmationModal"
                        :disabled="isRequesting"
                    >
                        {{ $t('Disconnect') }}
                    </dt-button>
                </div>
            </template>
        </drawer-template>
    </Transition>
    <confirmation-modal
        type="Box"
        v-model:show-prompt="showConfirmationModal"
        @confirm="handleDisconnectBox"
    />
</template>

<script lang="ts">
import { defineComponent, inject, type PropType } from 'vue';
import {
    DtButton,
    DtInput,
    DtIcon,
    DtNotice,
    DtCheckbox,
    DtChip,
    DtTooltip
} from '@dialpad/dialtone/vue3';
import DrawerTemplate from '@/components/drawer-template/DrawerTemplate.vue';
import BaseCombobox from '@/components/base-combobox/BaseCombobox.vue';
import ConfirmationModal from '@/components/confirmation-modal/ConfirmationModal.vue';
import type { IExternalSource } from '@/components/sources-drawer/Sources.types';
import type { INotice } from '@/components/sources-drawer/Sources.types';

import type {
    Knowledgebase,
    ListTagsOKBody,
    GetClientResponse
} from '@/open-api';
import type { RequestArgs } from '@/open-api/base';

import { handleRequest } from '@/utils/Common';
import { NOTICE_KINDS } from '@/utils/Constants';

function initialState() {
    return {
        selectedLabels: [] as string[],
        allowDownloadingFiles: false,
        allowPublicAccess: false,
        importWebLinks: false,
        hasComboboxError: false,
        isComboboxFetching: false,
        clientId: '',
        clientSecret: '',
        folderUrl: '0',
        customUserAgent: '',
        showClientSecret: false,
        showCustomUserAgent: false,
        isFetching: false,
        isRequesting: false,
        oauthWindow: null as Window | null,
        labels: { tags: [] } as ListTagsOKBody
    };
}

export default defineComponent({
    props: {
        showBoxConnectPage: {
            type: Boolean as PropType<boolean>,
            required: true
        },
        data: {
            type: Object as PropType<IExternalSource>
        }
    },
    setup() {
        const orgId: string = inject('orgId')!;

        return {
            orgId
        };
    },
    components: {
        DtButton,
        DtInput,
        DtIcon,
        DtNotice,
        DtCheckbox,
        DtChip,
        DtTooltip,
        ConfirmationModal,
        BaseCombobox,
        DrawerTemplate
    },
    watch: {
        data(newData, oldData) {
            if (newData?.name !== oldData?.name) {
                if (this.isConnected) {
                    this.getBoxSettings();
                }
            }
        },
        showBoxConnectPage(isOpen) {
            if (isOpen) {
                this.fetchTags();
            }
        }
    },
    emits: ['back', 'connectClose', 'update:labels'],
    computed: {
        /* v8 ignore next 10 */
        knowledgebase(): Knowledgebase {
            return this.$store.getters[`${this.orgId}/currentKnowledgebase`];
        },
        tags(): string[] {
            return this.labels?.tags || [];
        },
        isConnected(): boolean {
            return this.data?.status === 'Connected';
        },
        configUrl(): string {
            return (
                window.location.origin +
                window.location.pathname +
                '#/box/auth/callback'
            );
        }
    },
    methods: {
        /* v8 ignore next 220 */
        async fetchTags() {
            this.isComboboxFetching = true;
            this.labels = { tags: [] };
            const res = await handleRequest<ListTagsOKBody>(
                this.$store.getters[
                    `${this.orgId}/apiService`
                ]?.knowledge.listTags(
                    this.$store.getters[`${this.orgId}/authToken`],
                    this.knowledgebase?.id
                ),
                this.orgId
            );
            this.isComboboxFetching = false;
            this.hasComboboxError = !!res.error;
            if (res?.data) this.labels = res.data;
        },
        handleClose() {
            this.$emit('connectClose');
        },
        handleBackClick(resp: INotice) {
            this.$emit(
                'back',
                Object.keys(resp).length
                    ? {
                          title: resp.title,
                          message: resp.message,
                          kind: NOTICE_KINDS.SUCCESS
                      }
                    : {}
            );
            this.resetValues();
        },
        async handleConnectBox() {
            this.isRequesting = true;

            const resKb = await handleRequest<RequestArgs>(
                this.$store.getters[
                    `${this.orgId}/apiService`
                ]?.ingestion.createBoxClient(
                    this.$store.getters[`${this.orgId}/authToken`],
                    this.knowledgebase?.id,
                    {
                        allow_download: this.allowDownloadingFiles,
                        allow_public: this.allowPublicAccess,
                        client_id: this.clientId,
                        client_secret: this.clientSecret,
                        folder_id: this.folderUrl,
                        import_boxnotes: false,
                        import_weblinks: this.importWebLinks,
                        labels: this.selectedLabels,
                        user_agent: this.customUserAgent
                    }
                ),
                this.orgId
            );

            if (resKb.error) {
                this.showErrorMsg('Could not connect this Box account');
            } else {
                this.startAuth();
            }
        },
        startAuth() {
            const params = `scrollbars=no,resizable=no,status=no,location=yes,toolbar=no,menubar=no,
width=600,height=600`;

            this.oauthWindow = window.open(
                `https://account.box.com/api/oauth2/authorize?client_id=${this.clientId}&response_type=code`,
                'box',
                params
            );

            if (this.oauthWindow) {
                const timer = setInterval(() => {
                    try {
                        if (this.oauthWindow!.closed) {
                            clearInterval(timer);
                            this.isRequesting = false;
                        }
                    } catch (e) {
                        return;
                    }
                }, 500);
            } else {
                this.showErrorMsg('Oops, something blocked the popup');
                return;
            }

            window.addEventListener(
                'message',
                (event: MessageEvent) => {
                    if (event.origin !== window.location.origin) {
                        this.isRequesting = false;
                        this.showErrorMsg(
                            'Oops, this help center no longer exists'
                        );
                        return;
                    }

                    if (event.data?.typeEvent === 'boxEvent') {
                        const code = event.data?.code;
                        if (code) {
                            this.authenticateBox(code);
                        }
                    }
                },
                false
            );
        },
        async authenticateBox(code: string) {
            const resKb = await handleRequest<RequestArgs>(
                this.$store.getters[
                    `${this.orgId}/apiService`
                ]?.ingestion.authenticateBoxClient(
                    this.$store.getters[`${this.orgId}/authToken`],
                    this.knowledgebase?.id,
                    code
                ),
                this.orgId
            );
            this.isRequesting = false;
            if (resKb.error) {
                this.showErrorMsg('Could not connect to Box account');
            } else {
                this.handleBackClick({
                    title: `Box ${this.$t('account connected successfully')}`,
                    message: this.$t(
                        'Your documents are now importing. View import status in the Configure sources sidebar.'
                    )
                });
            }
        },
        async getBoxSettings() {
            this.isFetching = true;

            const res = await handleRequest<GetClientResponse>(
                this.$store.getters[
                    `${this.orgId}/apiService`
                ]?.ingestion.getBoxClient(
                    this.$store.getters[`${this.orgId}/authToken`],
                    this.knowledgebase?.id
                ),
                this.orgId
            );
            this.isFetching = false;

            if (res?.data) {
                this.selectedLabels = res.data.labels || [];
                this.clientId = res.data.client_id;
                this.folderUrl = res.data.folder_id || '0';
                this.allowPublicAccess = !!res.data.allow_public;
                this.allowDownloadingFiles = !!res.data.allow_download;
                this.importWebLinks = !!res.data.import_weblinks;
                this.showCustomUserAgent = !!res.data.import_weblinks;
                this.customUserAgent = res.data.user_agent || '';
            }
        },
        async handleDisconnectBox() {
            this.isRequesting = true;

            const resKb = await handleRequest(
                this.$store.getters[
                    `${this.orgId}/apiService`
                ]?.ingestion.disconnectBoxClient(
                    this.$store.getters[`${this.orgId}/authToken`],
                    this.knowledgebase?.id
                ),
                this.orgId
            );
            this.isRequesting = false;

            if (resKb.error) {
                this.showErrorMsg('Could not disconnect this Box account');
            } else {
                this.handleBackClick({
                    title: `Box ${this.$t('account disconnected successfully')}`
                });
            }
        },
        handleAllowDownloadingFiles(checked: any) {
            this.allowDownloadingFiles = !!checked;
        },
        handleAllowPublicAccess(checked: any) {
            this.allowPublicAccess = !!checked;
        },
        handleImportWebLinks(checked: any) {
            this.importWebLinks = !!checked;
            this.showCustomUserAgent = !!checked;
        },
        toggleShowClientSecret() {
            this.showClientSecret = !this.showClientSecret;
        },
        resetValues() {
            Object.assign(this.$data, initialState());
        },
        showErrorMsg(title: string) {
            this.$store.commit(`${this.orgId}/addNotification`, {
                kind: NOTICE_KINDS.ERROR,
                title: this.$t(title)
            });
        },
        handleShowConfirmationModal() {
            this.showConfirmationModal = true;
        },
        copyConfigUrl() {
            if (this.copied) return;
            this.copied = true;
            navigator.clipboard.writeText(this.configUrl);
            setTimeout(() => {
                this.copied = false;
            }, 3000);
        }
    },
    data() {
        return {
            ...initialState(),
            showConfirmationModal: false,
            copied: false
        };
    }
});
</script>
