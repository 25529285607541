<template>
    <dt-card
        class="d-w100p"
        header-class="d-px16 d-py4 d-bgc-black-100 d-bb d-bc-subtle d-btr4"
        content-class="d-px16 d-py8"
        :container-class="{
            'd-bs-sm': editable,
            'd-bs-none h:d-bs-sm': !editable
        }"
    >
        <template #header v-if="editable">
            <span
                class="d-fs-100 d-fw-bold d-h16 d-py4 d-my2 d-d-flex d-ai-center"
            >
                {{ $t('Add video') }}
            </span>
        </template>
        <template #content>
            <!-- --------------------------------------------------------------- -->
            <!--                            VIDEO BLOCK                          -->
            <!-- --------------------------------------------------------------- -->
            <div
                v-if="block?.data?.video_block?.url && !editable"
                class="d-d-flex d-jc-center d-fd-column d-ps-relative"
            >
                <video-player :source="block.data.video_block.url" />
                <div
                    class="d-d-flex d-w100p d-fc-tertiary d-fs-100"
                    v-if="block?.data?.file"
                >
                    <p class="d-w90p d-mr8 d-ps-relative">
                        {{ tempVideoName }}
                    </p>
                    <p class="d-ml-auto">
                        {{ tempVideoSize }}
                    </p>
                </div>
                <p class="d-fs-100 d-mt4">
                    {{ videoCaption || embedCaption }}
                </p>
            </div>
            <!-- --------------------------------------------------------------- -->
            <!--                             ADD VIDEO                           -->
            <!-- --------------------------------------------------------------- -->
            <div class="d-fs-100 d-w100p" v-else>
                <dt-tab-group
                    class="d-box-border"
                    size="sm"
                    @change="onTabClick"
                >
                    <template #tabs>
                        <dt-tab
                            v-for="uploadTabState in ['Embed', 'Upload']"
                            :selected="isTabActive(uploadTabState)"
                            :id="uploadTabState"
                            :panel-id="`${uploadTabState}-panel`"
                            :key="`${uploadTabState}-panel`"
                            tab-class="d-px12"
                        >
                            {{ uploadTabState }}
                        </dt-tab>
                    </template>
                    <dt-tab-panel id="Upload-panel" tab-id="Upload">
                        <div
                            class="d-ps-relative d-mt12 d-mb8 d-bar8"
                            :class="{ 'd-ba d-baw1 d-bc-subtle': hasVideo }"
                        >
                            <drop-file
                                dropzone-classes="d-h100p d-hmn264"
                                v-if="!hasVideo"
                                file-type-accepted="VIDEO"
                                hide-description
                                :upload-text="$t('videos or drag and drop')"
                                upload-subtext="MPG, MP4, MOV (max 100mb)"
                                v-model:dropzone-files="files"
                                v-model:selected-doc-labels="selectedTags"
                                :is-uploading="isUploading"
                            />
                            <video-player
                                v-if="hasVideo"
                                :source="videoUrl || createUrl(tempVideo)"
                            />
                            <div
                                class="d-d-flex d-jc-space-between d-ai-center d-my8 d-px12"
                                v-if="hasVideo"
                            >
                                <div
                                    class="d-d-flex d-fd-column"
                                    v-if="tempVideoName || tempVideoSize"
                                >
                                    <p class="d-fs-100">
                                        {{ tempVideoName }}
                                    </p>
                                    <p class="d-fs-100 d-fc-tertiary">
                                        {{ tempVideoSize }}
                                    </p>
                                </div>
                                <dt-button
                                    class="d-mr8 d-ml-auto"
                                    size="xs"
                                    importance="clear"
                                    kind="danger"
                                    @click="handleReplaceVideo"
                                >
                                    Remove
                                </dt-button>
                            </div>
                        </div>
                        <dt-input
                            size="xs"
                            v-model="videoCaption"
                            v-if="hasVideo"
                        >
                            <template #labelSlot>
                                <div class="d-mb4 d-fw-semibold">
                                    {{ $t('Caption') }}
                                </div>
                            </template>
                        </dt-input>
                        <div class="d-d-flex d-mt8">
                            <dt-button
                                class="d-ml-auto d-mr8"
                                importance="clear"
                                size="xs"
                                @click="handleCancel"
                            >
                                {{ $t('Cancel') }}
                            </dt-button>
                            <dt-button
                                size="xs"
                                @click="handleAddVideo"
                                :disabled="!hasVideo || !videoCaption"
                            >
                                {{ $t('Add') }}
                            </dt-button>
                        </div>
                    </dt-tab-panel>
                    <dt-tab-panel id="Embed-panel" tab-id="Embed">
                        <div
                            class="d-mb4 d-d-flex d-fd-column d-ba d-baw1 d-bc-transparent"
                        >
                            <div class="d-mt12" v-if="showEmbedVideo">
                                <video-player :source="internalEmbedUrl" />
                            </div>
                            <div class="d-mt12">
                                <dt-input
                                    v-model="embedUrl"
                                    :placeholder="$t('Paste your URL')"
                                    label="URL"
                                    :description="
                                        $t(
                                            'Link to your video hosted on YouTube or Vimeo, or provide a direct link to a video file on a public URL'
                                        )
                                    "
                                    @input="debounce(validateUrl)"
                                    :messages="validate"
                                    size="xs"
                                />
                            </div>

                            <div class="d-my12">
                                <dt-input
                                    v-model="embedCaption"
                                    v-if="showEmbedVideo"
                                    :label="$t('Caption')"
                                    :placeholder="$t('Type your caption')"
                                    size="xs"
                                />
                            </div>
                            <div class="d-d-flex d-mt-auto">
                                <dt-button
                                    class="d-ml-auto d-mr8"
                                    importance="clear"
                                    size="xs"
                                    @click="handleCancel"
                                >
                                    {{ $t('Cancel') }}
                                </dt-button>
                                <dt-button
                                    size="xs"
                                    :disabled="
                                        !showEmbedVideo || validate?.length > 0
                                    "
                                    @click="handleAddVideo"
                                >
                                    {{ $t('Add') }}
                                </dt-button>
                            </div>
                        </div>
                    </dt-tab-panel>
                </dt-tab-group>
            </div>
        </template>
    </dt-card>
</template>

<script lang="ts">
import { defineComponent, type PropType, inject } from 'vue';
import DropFile from '@/components/upload-drawer/DropFile.vue';
import VideoPlayer from '@/components/node-editor/blocks/video-player.vue';
import {
    DtButton,
    DtTab,
    DtTabPanel,
    DtTabGroup,
    DtInput,
    DtCard,
    DtIcon,
    VALIDATION_MESSAGE_TYPES
} from '@dialpad/dialtone/vue3';
import type { IUploadedFile } from '@/components/upload-drawer/UploadFile.types';
import type {
    Block,
    ResponseBlockEditor
} from '@/components/node-editor/responseBlockEditor';
import {
    createDebounce,
    getPathname,
    validateVimeoURL,
    validateYouTubeURL
} from '@/utils/Common';
import {
    ACCEPTED_VIDEO_FORMATS,
    DEFAULT_VIDEO_BLOCK_TAB
} from '@/utils/Constants';

export default defineComponent({
    props: {
        block: {
            type: Object as PropType<Block>,
            required: true
        },
        editable: {
            type: Boolean as PropType<boolean>,
            required: true
        }
    },
    components: {
        DtButton,
        DtTab,
        DtTabPanel,
        DtTabGroup,
        DtInput,
        DtCard,
        DtIcon,
        DropFile,
        VideoPlayer
    },
    setup() {
        const orgId: string = inject('orgId')!;
        return {
            debounce: createDebounce(),
            orgId
        };
    },
    computed: {
        /* v8 ignore next 50 */
        isEditable: {
            get(): boolean {
                return this.editable;
            },
            set(editable: boolean) {
                this.$emit('edit', editable);
            }
        },
        hasVideo(): boolean {
            return !!this.files?.length || !!this.videoUrl;
        },
        tempVideo: {
            get(): { file: File } | undefined {
                return this.files[0];
            },
            set(newTemp: { file: File }) {
                this.files[0] = newTemp;
            }
        },
        tempVideoName(): string | undefined {
            return this.tempVideo?.file?.name || this.block.data?.file?.name;
        },
        tempVideoSize(): string {
            const videoSize =
                this.tempVideo?.file?.size || this.block.data?.file?.size;

            if (!videoSize) {
                return '';
            }

            const size = parseFloat((videoSize / 1024 ** 2).toFixed(3));
            return size < 1 ? `${size * 1000}kb` : `${size}mb`;
        },
        responseBlockEditor(): ResponseBlockEditor | undefined {
            return this.$store.getters[`${this.orgId}/responseBlockEditor`];
        }
    },
    methods: {
        handleReplaceVideo() {
            this.videoUrl = '';
            this.videoCaption = '';
            this.files = [];
        },
        handleRemove() {
            this.responseBlockEditor?.removeBlock(this.block);
        },
        isTabActive(tabState: any): boolean {
            return tabState === this.activeUploadTab;
        },
        isUploadTab(): boolean {
            return this.activeUploadTab === 'Upload';
        },
        onTabClick(newTab: any) {
            this.activeUploadTab = newTab.selected.replace('-panel', '');
        },
        createUrl(file: any) {
            return URL.createObjectURL(file.file);
        },
        onLoad() {
            this.$emit('load');
        },
        handleAddVideo(e: Event) {
            e.stopPropagation();
            if (this.tempVideo?.file) {
                this.block.setFile(this.tempVideo.file);
            }
            this.block?.setBlockData({
                text: this.isUploadTab()
                    ? this.videoCaption
                    : this.embedCaption,
                url: this.isUploadTab()
                    ? this.videoUrl || this.createUrl(this.tempVideo)
                    : this.internalEmbedUrl
            });
            this.resetData();
        },
        resetData() {
            this.files = [];
            this.isEditable = false;
            this.activeUploadTab = DEFAULT_VIDEO_BLOCK_TAB;
            this.videoUrl = this.block?.data?.video_block?.url || '';
        },
        handleCancel(e: Event) {
            e.stopPropagation();
            // If block has never been added then it will remove it.
            // If was added, even if it was temporary, it won't be removed.
            if (this.block.new && !this.block.data.dirty) {
                this.handleRemove();
            } else {
                this.resetData();
            }
        },
        /* v8 ignore next 50 */
        async validateUrl() {
            this.validate = undefined;
            this.showEmbedVideo = false;

            if (!this.embedUrl) {
                return;
            }

            let errorMessage: string,
                isPublicUrl = false,
                publicVideoPathname: string,
                vimeoEmbedUrl: string;

            const youtubeEmbedUrl = validateYouTubeURL(this.embedUrl);

            if (!youtubeEmbedUrl) {
                publicVideoPathname = getPathname(this.embedUrl);
            }

            if (!youtubeEmbedUrl && !publicVideoPathname) {
                vimeoEmbedUrl = await validateVimeoURL(this.embedUrl);
            }

            if (publicVideoPathname) {
                const extension = publicVideoPathname.split('.')[1];
                isPublicUrl = ACCEPTED_VIDEO_FORMATS.some((accepted: string) =>
                    accepted.includes(extension)
                );
            }

            if (vimeoEmbedUrl || youtubeEmbedUrl || isPublicUrl) {
                this.internalEmbedUrl =
                    vimeoEmbedUrl || youtubeEmbedUrl || this.embedUrl || '';
                this.showEmbedVideo = true;
                return;
            } else {
                errorMessage = this.$t('Not a valid video URL');
            }

            this.validate = [
                {
                    message: errorMessage,
                    type: VALIDATION_MESSAGE_TYPES.ERROR
                }
            ];
        }
    },
    emits: ['edit', 'load'],
    data() {
        return {
            showAddVideo: null as number | null,
            videoCaption: '',
            videoUrl: '',
            activeUploadTab: DEFAULT_VIDEO_BLOCK_TAB,
            isUploading: false,
            selectedTags: [] as string[],
            files: [] as IUploadedFile[] | any[],
            embedUrl: this.block?.data?.video_block?.url || '',
            internalEmbedUrl: this.block?.data?.video_block?.url || '',
            embedCaption: this.block?.data?.video_block?.text || '',
            validate: undefined as any,
            showEmbedVideo: !!this.block?.data?.video_block?.url
        };
    }
});
</script>
