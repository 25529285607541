import type { Router } from 'vue-router';
import type { DIALPAD_LOCALES, ZONES_ABBR } from '@/utils/Constants';
import type { Organisation } from '@/open-api';
import type { ApiService } from '@/services/Api.service';

export interface DXConsoleConfiguration {
    orgName: string;
    authToken?: string;
    verbose?: boolean;
    showSidebarMenu?: boolean;
    zone: ZONES_ABBR;
    bodyStyle?: string;
    organisationDetails?: Organisation;
    apiService?: ApiService;
    limited?: boolean;
    darkMode?: boolean;
    locale: DIALPAD_LOCALES;
    currentPath?: string;
}

export const defaultDXConsoleConfiguration = {};

/**
 * @name Digital Experience SDK
 * @constructor
 * @param {Object} Configuration - An object that will contain all the initial configurations
 */
class DialpadDX {
    #_config: DXConsoleConfiguration;
    #router: Router;
    constructor(router: Router, config: DXConsoleConfiguration) {
        this.#_config = config;
        this.#router = router;
    }

    log(message: string) {
        if (this.#_config.verbose) {
            /* eslint-disable no-console */
            console.log(
                '%c[DX SDK]:',
                'color:  #7C52FF; background-color: #FFF;',
                ` ${message}`
            );
        }
    }

    /**
     * @name Push route
     * @type Action
     * @param {String} route - A string that will contain the pathname to route to.
     * @param {Function} callback - After the route has been entered callback is fired.
     **/
    PushRoute(route: string, callback?: any) {
        this.log(`Pushing route ${route}`);
        this.#router.push(route).then((res) => {
            if (callback) callback(res);
        });
    }
}
export const SDKInstance = DialpadDX;
