/* tslint:disable */
/* eslint-disable */
/**
 * Mind
 * Kare API v4.0
 *
 * The version of the OpenAPI document: 4.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AssetDetail } from '../model';
// @ts-ignore
import { GetNodeStatsResponse } from '../model';
// @ts-ignore
import { GetSubgraphResponse } from '../model';
// @ts-ignore
import { Knowledgebase } from '../model';
// @ts-ignore
import { ListEdgesInboundResponse } from '../model';
// @ts-ignore
import { ListEdgesOutboundResponse } from '../model';
// @ts-ignore
import { ListKnowledgebasesResponse } from '../model';
// @ts-ignore
import { ListNodesResponse } from '../model';
// @ts-ignore
import { ListTagsOKBody } from '../model';
// @ts-ignore
import { ListWidgetsResponse } from '../model';
// @ts-ignore
import { ModelError } from '../model';
// @ts-ignore
import { NodeDetail } from '../model';
// @ts-ignore
import { PatchNodeRequest } from '../model';
// @ts-ignore
import { PatchNodesRequest } from '../model';
// @ts-ignore
import { PatchNodesResponse } from '../model';
// @ts-ignore
import { PostKnowledgebaseRequest } from '../model';
// @ts-ignore
import { PostWidgetRequest } from '../model';
// @ts-ignore
import { PutNodeRequest } from '../model';
// @ts-ignore
import { WebExtensionDetail } from '../model';
// @ts-ignore
import { Widget } from '../model';
/**
 * KnowledgeApi - axios parameter creator
 * @export
 */
export const KnowledgeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Deletes a knowledgebase. 
         * @summary Delete knowledgebase
         * @param {string} authorization Authorization Bearer header.
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteKnowledgebase: async (authorization: string, kbId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('deleteKnowledgebase', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('deleteKnowledgebase', 'kbId', kbId)
            const localVarPath = `/kbm/{kb_id}`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeGraphWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a widget. 
         * @summary Delete widget
         * @param {string} authorization Authorization Bearer header.
         * @param {string} appId Widget application ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWidget: async (authorization: string, appId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('deleteWidget', 'authorization', authorization)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('deleteWidget', 'appId', appId)
            const localVarPath = `/widgets/{app_id}`
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeGraphRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the details of a knowledgebase. 
         * @summary Get knowledgebase
         * @param {string} authorization Authorization Bearer header.
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKnowledgebase: async (authorization: string, kbId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getKnowledgebase', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('getKnowledgebase', 'kbId', kbId)
            const localVarPath = `/kbm/{kb_id}`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeGraphRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Download the markdown representation of this content node.  
         * @summary Download markdown content
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} nodeId Node ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarkdownContent: async (authorization: string, kbId: string, nodeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getMarkdownContent', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('getMarkdownContent', 'kbId', kbId)
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('getMarkdownContent', 'nodeId', nodeId)
            const localVarPath = `/kbm/{kb_id}/nodes/{node_id}/content/md`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)))
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeGraphRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to get a node
         * @summary Get node
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} nodeId Node ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNode: async (authorization: string, kbId: string, nodeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getNode', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('getNode', 'kbId', kbId)
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('getNode', 'nodeId', nodeId)
            const localVarPath = `/kbm/{kb_id}/nodes/{node_id}`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)))
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeGraphRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Count nodes in the organisation grouping by responses, chattering and content.
         * @summary Get node stats
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodeStats: async (authorization: string, kbId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getNodeStats', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('getNodeStats', 'kbId', kbId)
            const localVarPath = `/kbm/{kb_id}/nodes/stats`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeGraphRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Download the public version of this content node. Client applications should use this method to serve  the original version of a content node to the end user.  The content is only gonna be accessible if the node is in state `published`.  
         * @summary Download content
         * @param {string} token Authorization Token Query
         * @param {string} kbId Knowledgebase ID
         * @param {string} nodeId Node ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicNodeContent: async (token: string, kbId: string, nodeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('getPublicNodeContent', 'token', token)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('getPublicNodeContent', 'kbId', kbId)
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('getPublicNodeContent', 'nodeId', nodeId)
            const localVarPath = `/kbm/{kb_id}/nodes/{node_id}/content/public`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)))
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenDialog required
            await setApiKeyToObject(localVarQueryParameter, "token", configuration)

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable authenticated users  the raw version of a content  node. Client applications serving the end user should not use this. 
         * @summary Download raw content
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} nodeId Node ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRawContent: async (authorization: string, kbId: string, nodeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getRawContent', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('getRawContent', 'kbId', kbId)
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('getRawContent', 'nodeId', nodeId)
            const localVarPath = `/kbm/{kb_id}/nodes/{node_id}/content/raw`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)))
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeGraphRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a subgraph from a given node
         * @summary Get subgraph
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} nodeId Node ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubgraph: async (authorization: string, kbId: string, nodeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getSubgraph', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('getSubgraph', 'kbId', kbId)
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('getSubgraph', 'nodeId', nodeId)
            const localVarPath = `/kbm/{kb_id}/nodes/{node_id}/subgraph`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)))
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeGraphRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the details of a widget. 
         * @summary Get widget
         * @param {string} authorization Authorization Bearer header.
         * @param {string} appId Widget application ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWidget: async (authorization: string, appId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getWidget', 'authorization', authorization)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getWidget', 'appId', appId)
            const localVarPath = `/widgets/{app_id}`
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeGraphRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to get a node\'s inbound edges. Will only return edges referencing published nodes.
         * @summary List inbound edges
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} nodeId Node ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEdgesInbound: async (authorization: string, kbId: string, nodeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('listEdgesInbound', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('listEdgesInbound', 'kbId', kbId)
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('listEdgesInbound', 'nodeId', nodeId)
            const localVarPath = `/kbm/{kb_id}/nodes/{node_id}/edges/inbound`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)))
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeGraphRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to get a node\'s outbound edges. This only returns published nodes.
         * @summary List outbound edges
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} nodeId Node ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEdgesOutbound: async (authorization: string, kbId: string, nodeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('listEdgesOutbound', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('listEdgesOutbound', 'kbId', kbId)
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('listEdgesOutbound', 'nodeId', nodeId)
            const localVarPath = `/kbm/{kb_id}/nodes/{node_id}/edges/outbound`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)))
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeGraphRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * \'List all knowledgebases matching the given filters.\' 
         * @summary List Knowledgebases
         * @param {string} authorization Authorization Bearer header
         * @param {string} [appId] Filter knowledgebases by app ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listKnowledgebases: async (authorization: string, appId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('listKnowledgebases', 'authorization', authorization)
            const localVarPath = `/kbm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeGraphRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (appId !== undefined) {
                localVarQueryParameter['app_id'] = appId;
            }

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * \'List all nodes matching the given filters.\' 
         * @summary List nodes
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {'chattering' | 'response' | 'content'} [type] Filter by node type
         * @param {'published' | 'unpublished' | 'deleted'} [status] Filter by status
         * @param {string} [sourceName] Filter by content source name
         * @param {string} [title] Filter content by node title
         * @param {string} [labels] Filter content by labels. Multiple labels are operated as a logical AND.
         * @param {'source_name' | 'title' | 'node_type' | 'node_status' | 'created_at' | 'updated_at' | 'queries_count' | 'webexts_count' | 'workflows_count' | 'choices_count'} [sortBy] Sort content by field
         * @param {'asc' | 'desc'} [order] Sort order
         * @param {number} [limit] 
         * @param {number} [offset] The query offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNodes: async (authorization: string, kbId: string, type?: 'chattering' | 'response' | 'content', status?: 'published' | 'unpublished' | 'deleted', sourceName?: string, title?: string, labels?: string, sortBy?: 'source_name' | 'title' | 'node_type' | 'node_status' | 'created_at' | 'updated_at' | 'queries_count' | 'webexts_count' | 'workflows_count' | 'choices_count', order?: 'asc' | 'desc', limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('listNodes', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('listNodes', 'kbId', kbId)
            const localVarPath = `/kbm/{kb_id}/nodes`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeGraphRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (sourceName !== undefined) {
                localVarQueryParameter['source_name'] = sourceName;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (labels !== undefined) {
                localVarQueryParameter['labels'] = labels;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to list all tags for an organization.
         * @summary List tags
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {'published' | 'unpublished' | 'deleted'} [status] Filter by status
         * @param {'chattering' | 'response' | 'content'} [type] Filter by node type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTags: async (authorization: string, kbId: string, status?: 'published' | 'unpublished' | 'deleted', type?: 'chattering' | 'response' | 'content', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('listTags', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('listTags', 'kbId', kbId)
            const localVarPath = `/kbm/{kb_id}/tags`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeGraphRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the registered web extensions in this organisation. 
         * @summary Get client registered web extensions.
         * @param {string} authorization Authorization Bearer header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWebExt: async (authorization: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('listWebExt', 'authorization', authorization)
            const localVarPath = `/kbm/web-extensions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeGraphRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * \'List all widgets.\' 
         * @summary List Widgets
         * @param {string} authorization Authorization Bearer header
         * @param {'AAA' | 'ASA' | 'DSS'} [licenseType] The license type that can be associated with organisation, application and knowledgebase. 
         * @param {'published' | 'unpublished' | 'archived'} [status] Filter widget by their status
         * @param {string} [name] Filter widgets by their name
         * @param {'name' | 'license_type' | 'created_at' | 'updated_at'} [sortBy] Sort content by field
         * @param {'asc' | 'desc'} [order] Sort order
         * @param {number} [limit] 
         * @param {number} [offset] The query offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWidgets: async (authorization: string, licenseType?: 'AAA' | 'ASA' | 'DSS', status?: 'published' | 'unpublished' | 'archived', name?: string, sortBy?: 'name' | 'license_type' | 'created_at' | 'updated_at', order?: 'asc' | 'desc', limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('listWidgets', 'authorization', authorization)
            const localVarPath = `/widgets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeGraphRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (licenseType !== undefined) {
                localVarQueryParameter['license_type'] = licenseType;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Allow administrators to update knowledgebase settings. 
         * @summary Update knowledgebase
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {Knowledgebase} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchKnowledgebase: async (authorization: string, kbId: string, body: Knowledgebase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('patchKnowledgebase', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('patchKnowledgebase', 'kbId', kbId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('patchKnowledgebase', 'body', body)
            const localVarPath = `/kbm/{kb_id}`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeGraphWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to edit an existing node
         * @summary Patch node
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} nodeId Node ID
         * @param {PatchNodeRequest} [body] Body containing all the node information all all fields to patch.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNode: async (authorization: string, kbId: string, nodeId: string, body?: PatchNodeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('patchNode', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('patchNode', 'kbId', kbId)
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('patchNode', 'nodeId', nodeId)
            const localVarPath = `/kbm/{kb_id}/nodes/{node_id}`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)))
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeGraphWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to patch a list of nodes.
         * @summary Patch nodes
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {PatchNodesRequest} [body] Body with a list of nodes formed with ID and changes to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNodes: async (authorization: string, kbId: string, body?: PatchNodesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('patchNodes', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('patchNodes', 'kbId', kbId)
            const localVarPath = `/kbm/{kb_id}/nodes/bulk`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeGraphWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Allow administrators to update widget settings. 
         * @summary Update widget
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId Widget application ID
         * @param {Widget} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchWidget: async (authorization: string, appId: string, body: Widget, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('patchWidget', 'authorization', authorization)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('patchWidget', 'appId', appId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('patchWidget', 'body', body)
            const localVarPath = `/widgets/{app_id}`
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeGraphWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Uploads an asset to our CDN. 
         * @summary Upload asset
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} name asset name.
         * @param {File} asset The content to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAsset: async (authorization: string, kbId: string, name: string, asset: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('postAsset', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('postAsset', 'kbId', kbId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('postAsset', 'name', name)
            // verify required parameter 'asset' is not null or undefined
            assertParamExists('postAsset', 'asset', asset)
            const localVarPath = `/kbm/{kb_id}/assets/{name}`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerKnowledgeGraphWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


            if (asset !== undefined) { 
                localVarFormParams.append('asset', asset as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Allow administrators to create a new knowledgebase. 
         * @summary Create knowledgebase
         * @param {string} authorization Authorization Bearer header
         * @param {PostKnowledgebaseRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postKnowledgebase: async (authorization: string, body: PostKnowledgebaseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('postKnowledgebase', 'authorization', authorization)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('postKnowledgebase', 'body', body)
            const localVarPath = `/kbm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeGraphWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new node of type response or chattering.
         * @summary Post node
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {PutNodeRequest} [body] Create node request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNode: async (authorization: string, kbId: string, body?: PutNodeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('postNode', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('postNode', 'kbId', kbId)
            const localVarPath = `/kbm/{kb_id}/nodes`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeGraphWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * \'Updates the raw content of an existing content node.\' 
         * @summary Update the content of a node
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} nodeId The ID of the content node to update
         * @param {File} content The content to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUpdateContent: async (authorization: string, kbId: string, nodeId: string, content: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('postUpdateContent', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('postUpdateContent', 'kbId', kbId)
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('postUpdateContent', 'nodeId', nodeId)
            // verify required parameter 'content' is not null or undefined
            assertParamExists('postUpdateContent', 'content', content)
            const localVarPath = `/kbm/{kb_id}/nodes/{node_id}/content`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)))
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerKnowledgeGraphWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


            if (content !== undefined) { 
                localVarFormParams.append('content', content as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Allow administrators to create a new widget. 
         * @summary Create widget
         * @param {string} authorization Authorization Bearer header
         * @param {PostWidgetRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWidget: async (authorization: string, body: PostWidgetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('postWidget', 'authorization', authorization)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('postWidget', 'body', body)
            const localVarPath = `/widgets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeGraphWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to edit an existing node
         * @summary Put node
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} nodeId Node ID
         * @param {PutNodeRequest} [body] Body containing all the node information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putNode: async (authorization: string, kbId: string, nodeId: string, body?: PutNodeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('putNode', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('putNode', 'kbId', kbId)
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('putNode', 'nodeId', nodeId)
            const localVarPath = `/kbm/{kb_id}/nodes/{node_id}`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)))
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeGraphWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KnowledgeApi - functional programming interface
 * @export
 */
export const KnowledgeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KnowledgeApiAxiosParamCreator(configuration)
    return {
        /**
         * Deletes a knowledgebase. 
         * @summary Delete knowledgebase
         * @param {string} authorization Authorization Bearer header.
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteKnowledgebase(authorization: string, kbId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteKnowledgebase(authorization, kbId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes a widget. 
         * @summary Delete widget
         * @param {string} authorization Authorization Bearer header.
         * @param {string} appId Widget application ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWidget(authorization: string, appId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWidget(authorization, appId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the details of a knowledgebase. 
         * @summary Get knowledgebase
         * @param {string} authorization Authorization Bearer header.
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKnowledgebase(authorization: string, kbId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Knowledgebase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKnowledgebase(authorization, kbId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Download the markdown representation of this content node.  
         * @summary Download markdown content
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} nodeId Node ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMarkdownContent(authorization: string, kbId: string, nodeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMarkdownContent(authorization, kbId, nodeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to get a node
         * @summary Get node
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} nodeId Node ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNode(authorization: string, kbId: string, nodeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNode(authorization, kbId, nodeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Count nodes in the organisation grouping by responses, chattering and content.
         * @summary Get node stats
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNodeStats(authorization: string, kbId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNodeStatsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNodeStats(authorization, kbId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Download the public version of this content node. Client applications should use this method to serve  the original version of a content node to the end user.  The content is only gonna be accessible if the node is in state `published`.  
         * @summary Download content
         * @param {string} token Authorization Token Query
         * @param {string} kbId Knowledgebase ID
         * @param {string} nodeId Node ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicNodeContent(token: string, kbId: string, nodeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicNodeContent(token, kbId, nodeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable authenticated users  the raw version of a content  node. Client applications serving the end user should not use this. 
         * @summary Download raw content
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} nodeId Node ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRawContent(authorization: string, kbId: string, nodeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRawContent(authorization, kbId, nodeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a subgraph from a given node
         * @summary Get subgraph
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} nodeId Node ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubgraph(authorization: string, kbId: string, nodeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSubgraphResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubgraph(authorization, kbId, nodeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the details of a widget. 
         * @summary Get widget
         * @param {string} authorization Authorization Bearer header.
         * @param {string} appId Widget application ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWidget(authorization: string, appId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Widget>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWidget(authorization, appId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to get a node\'s inbound edges. Will only return edges referencing published nodes.
         * @summary List inbound edges
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} nodeId Node ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEdgesInbound(authorization: string, kbId: string, nodeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListEdgesInboundResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEdgesInbound(authorization, kbId, nodeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to get a node\'s outbound edges. This only returns published nodes.
         * @summary List outbound edges
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} nodeId Node ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEdgesOutbound(authorization: string, kbId: string, nodeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListEdgesOutboundResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEdgesOutbound(authorization, kbId, nodeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * \'List all knowledgebases matching the given filters.\' 
         * @summary List Knowledgebases
         * @param {string} authorization Authorization Bearer header
         * @param {string} [appId] Filter knowledgebases by app ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listKnowledgebases(authorization: string, appId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListKnowledgebasesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listKnowledgebases(authorization, appId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * \'List all nodes matching the given filters.\' 
         * @summary List nodes
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {'chattering' | 'response' | 'content'} [type] Filter by node type
         * @param {'published' | 'unpublished' | 'deleted'} [status] Filter by status
         * @param {string} [sourceName] Filter by content source name
         * @param {string} [title] Filter content by node title
         * @param {string} [labels] Filter content by labels. Multiple labels are operated as a logical AND.
         * @param {'source_name' | 'title' | 'node_type' | 'node_status' | 'created_at' | 'updated_at' | 'queries_count' | 'webexts_count' | 'workflows_count' | 'choices_count'} [sortBy] Sort content by field
         * @param {'asc' | 'desc'} [order] Sort order
         * @param {number} [limit] 
         * @param {number} [offset] The query offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNodes(authorization: string, kbId: string, type?: 'chattering' | 'response' | 'content', status?: 'published' | 'unpublished' | 'deleted', sourceName?: string, title?: string, labels?: string, sortBy?: 'source_name' | 'title' | 'node_type' | 'node_status' | 'created_at' | 'updated_at' | 'queries_count' | 'webexts_count' | 'workflows_count' | 'choices_count', order?: 'asc' | 'desc', limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListNodesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNodes(authorization, kbId, type, status, sourceName, title, labels, sortBy, order, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to list all tags for an organization.
         * @summary List tags
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {'published' | 'unpublished' | 'deleted'} [status] Filter by status
         * @param {'chattering' | 'response' | 'content'} [type] Filter by node type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTags(authorization: string, kbId: string, status?: 'published' | 'unpublished' | 'deleted', type?: 'chattering' | 'response' | 'content', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListTagsOKBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTags(authorization, kbId, status, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the registered web extensions in this organisation. 
         * @summary Get client registered web extensions.
         * @param {string} authorization Authorization Bearer header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWebExt(authorization: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WebExtensionDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listWebExt(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * \'List all widgets.\' 
         * @summary List Widgets
         * @param {string} authorization Authorization Bearer header
         * @param {'AAA' | 'ASA' | 'DSS'} [licenseType] The license type that can be associated with organisation, application and knowledgebase. 
         * @param {'published' | 'unpublished' | 'archived'} [status] Filter widget by their status
         * @param {string} [name] Filter widgets by their name
         * @param {'name' | 'license_type' | 'created_at' | 'updated_at'} [sortBy] Sort content by field
         * @param {'asc' | 'desc'} [order] Sort order
         * @param {number} [limit] 
         * @param {number} [offset] The query offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWidgets(authorization: string, licenseType?: 'AAA' | 'ASA' | 'DSS', status?: 'published' | 'unpublished' | 'archived', name?: string, sortBy?: 'name' | 'license_type' | 'created_at' | 'updated_at', order?: 'asc' | 'desc', limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListWidgetsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listWidgets(authorization, licenseType, status, name, sortBy, order, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Allow administrators to update knowledgebase settings. 
         * @summary Update knowledgebase
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {Knowledgebase} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchKnowledgebase(authorization: string, kbId: string, body: Knowledgebase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Knowledgebase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchKnowledgebase(authorization, kbId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to edit an existing node
         * @summary Patch node
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} nodeId Node ID
         * @param {PatchNodeRequest} [body] Body containing all the node information all all fields to patch.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchNode(authorization: string, kbId: string, nodeId: string, body?: PatchNodeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchNode(authorization, kbId, nodeId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to patch a list of nodes.
         * @summary Patch nodes
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {PatchNodesRequest} [body] Body with a list of nodes formed with ID and changes to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchNodes(authorization: string, kbId: string, body?: PatchNodesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatchNodesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchNodes(authorization, kbId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Allow administrators to update widget settings. 
         * @summary Update widget
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId Widget application ID
         * @param {Widget} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchWidget(authorization: string, appId: string, body: Widget, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Widget>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchWidget(authorization, appId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Uploads an asset to our CDN. 
         * @summary Upload asset
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} name asset name.
         * @param {File} asset The content to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAsset(authorization: string, kbId: string, name: string, asset: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAsset(authorization, kbId, name, asset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Allow administrators to create a new knowledgebase. 
         * @summary Create knowledgebase
         * @param {string} authorization Authorization Bearer header
         * @param {PostKnowledgebaseRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postKnowledgebase(authorization: string, body: PostKnowledgebaseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Knowledgebase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postKnowledgebase(authorization, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new node of type response or chattering.
         * @summary Post node
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {PutNodeRequest} [body] Create node request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postNode(authorization: string, kbId: string, body?: PutNodeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postNode(authorization, kbId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * \'Updates the raw content of an existing content node.\' 
         * @summary Update the content of a node
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} nodeId The ID of the content node to update
         * @param {File} content The content to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUpdateContent(authorization: string, kbId: string, nodeId: string, content: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUpdateContent(authorization, kbId, nodeId, content, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Allow administrators to create a new widget. 
         * @summary Create widget
         * @param {string} authorization Authorization Bearer header
         * @param {PostWidgetRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postWidget(authorization: string, body: PostWidgetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Widget>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postWidget(authorization, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to edit an existing node
         * @summary Put node
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} nodeId Node ID
         * @param {PutNodeRequest} [body] Body containing all the node information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putNode(authorization: string, kbId: string, nodeId: string, body?: PutNodeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putNode(authorization, kbId, nodeId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KnowledgeApi - factory interface
 * @export
 */
export const KnowledgeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KnowledgeApiFp(configuration)
    return {
        /**
         * Deletes a knowledgebase. 
         * @summary Delete knowledgebase
         * @param {string} authorization Authorization Bearer header.
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteKnowledgebase(authorization: string, kbId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteKnowledgebase(authorization, kbId, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a widget. 
         * @summary Delete widget
         * @param {string} authorization Authorization Bearer header.
         * @param {string} appId Widget application ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWidget(authorization: string, appId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteWidget(authorization, appId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the details of a knowledgebase. 
         * @summary Get knowledgebase
         * @param {string} authorization Authorization Bearer header.
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKnowledgebase(authorization: string, kbId: string, options?: any): AxiosPromise<Knowledgebase> {
            return localVarFp.getKnowledgebase(authorization, kbId, options).then((request) => request(axios, basePath));
        },
        /**
         * Download the markdown representation of this content node.  
         * @summary Download markdown content
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} nodeId Node ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarkdownContent(authorization: string, kbId: string, nodeId: string, options?: any): AxiosPromise<string> {
            return localVarFp.getMarkdownContent(authorization, kbId, nodeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to get a node
         * @summary Get node
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} nodeId Node ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNode(authorization: string, kbId: string, nodeId: string, options?: any): AxiosPromise<NodeDetail> {
            return localVarFp.getNode(authorization, kbId, nodeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Count nodes in the organisation grouping by responses, chattering and content.
         * @summary Get node stats
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodeStats(authorization: string, kbId: string, options?: any): AxiosPromise<GetNodeStatsResponse> {
            return localVarFp.getNodeStats(authorization, kbId, options).then((request) => request(axios, basePath));
        },
        /**
         * Download the public version of this content node. Client applications should use this method to serve  the original version of a content node to the end user.  The content is only gonna be accessible if the node is in state `published`.  
         * @summary Download content
         * @param {string} token Authorization Token Query
         * @param {string} kbId Knowledgebase ID
         * @param {string} nodeId Node ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicNodeContent(token: string, kbId: string, nodeId: string, options?: any): AxiosPromise<File> {
            return localVarFp.getPublicNodeContent(token, kbId, nodeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable authenticated users  the raw version of a content  node. Client applications serving the end user should not use this. 
         * @summary Download raw content
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} nodeId Node ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRawContent(authorization: string, kbId: string, nodeId: string, options?: any): AxiosPromise<File> {
            return localVarFp.getRawContent(authorization, kbId, nodeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a subgraph from a given node
         * @summary Get subgraph
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} nodeId Node ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubgraph(authorization: string, kbId: string, nodeId: string, options?: any): AxiosPromise<GetSubgraphResponse> {
            return localVarFp.getSubgraph(authorization, kbId, nodeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the details of a widget. 
         * @summary Get widget
         * @param {string} authorization Authorization Bearer header.
         * @param {string} appId Widget application ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWidget(authorization: string, appId: string, options?: any): AxiosPromise<Widget> {
            return localVarFp.getWidget(authorization, appId, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to get a node\'s inbound edges. Will only return edges referencing published nodes.
         * @summary List inbound edges
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} nodeId Node ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEdgesInbound(authorization: string, kbId: string, nodeId: string, options?: any): AxiosPromise<ListEdgesInboundResponse> {
            return localVarFp.listEdgesInbound(authorization, kbId, nodeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to get a node\'s outbound edges. This only returns published nodes.
         * @summary List outbound edges
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} nodeId Node ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEdgesOutbound(authorization: string, kbId: string, nodeId: string, options?: any): AxiosPromise<ListEdgesOutboundResponse> {
            return localVarFp.listEdgesOutbound(authorization, kbId, nodeId, options).then((request) => request(axios, basePath));
        },
        /**
         * \'List all knowledgebases matching the given filters.\' 
         * @summary List Knowledgebases
         * @param {string} authorization Authorization Bearer header
         * @param {string} [appId] Filter knowledgebases by app ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listKnowledgebases(authorization: string, appId?: string, options?: any): AxiosPromise<ListKnowledgebasesResponse> {
            return localVarFp.listKnowledgebases(authorization, appId, options).then((request) => request(axios, basePath));
        },
        /**
         * \'List all nodes matching the given filters.\' 
         * @summary List nodes
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {'chattering' | 'response' | 'content'} [type] Filter by node type
         * @param {'published' | 'unpublished' | 'deleted'} [status] Filter by status
         * @param {string} [sourceName] Filter by content source name
         * @param {string} [title] Filter content by node title
         * @param {string} [labels] Filter content by labels. Multiple labels are operated as a logical AND.
         * @param {'source_name' | 'title' | 'node_type' | 'node_status' | 'created_at' | 'updated_at' | 'queries_count' | 'webexts_count' | 'workflows_count' | 'choices_count'} [sortBy] Sort content by field
         * @param {'asc' | 'desc'} [order] Sort order
         * @param {number} [limit] 
         * @param {number} [offset] The query offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNodes(authorization: string, kbId: string, type?: 'chattering' | 'response' | 'content', status?: 'published' | 'unpublished' | 'deleted', sourceName?: string, title?: string, labels?: string, sortBy?: 'source_name' | 'title' | 'node_type' | 'node_status' | 'created_at' | 'updated_at' | 'queries_count' | 'webexts_count' | 'workflows_count' | 'choices_count', order?: 'asc' | 'desc', limit?: number, offset?: number, options?: any): AxiosPromise<ListNodesResponse> {
            return localVarFp.listNodes(authorization, kbId, type, status, sourceName, title, labels, sortBy, order, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to list all tags for an organization.
         * @summary List tags
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {'published' | 'unpublished' | 'deleted'} [status] Filter by status
         * @param {'chattering' | 'response' | 'content'} [type] Filter by node type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTags(authorization: string, kbId: string, status?: 'published' | 'unpublished' | 'deleted', type?: 'chattering' | 'response' | 'content', options?: any): AxiosPromise<ListTagsOKBody> {
            return localVarFp.listTags(authorization, kbId, status, type, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the registered web extensions in this organisation. 
         * @summary Get client registered web extensions.
         * @param {string} authorization Authorization Bearer header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWebExt(authorization: string, options?: any): AxiosPromise<Array<WebExtensionDetail>> {
            return localVarFp.listWebExt(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * \'List all widgets.\' 
         * @summary List Widgets
         * @param {string} authorization Authorization Bearer header
         * @param {'AAA' | 'ASA' | 'DSS'} [licenseType] The license type that can be associated with organisation, application and knowledgebase. 
         * @param {'published' | 'unpublished' | 'archived'} [status] Filter widget by their status
         * @param {string} [name] Filter widgets by their name
         * @param {'name' | 'license_type' | 'created_at' | 'updated_at'} [sortBy] Sort content by field
         * @param {'asc' | 'desc'} [order] Sort order
         * @param {number} [limit] 
         * @param {number} [offset] The query offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWidgets(authorization: string, licenseType?: 'AAA' | 'ASA' | 'DSS', status?: 'published' | 'unpublished' | 'archived', name?: string, sortBy?: 'name' | 'license_type' | 'created_at' | 'updated_at', order?: 'asc' | 'desc', limit?: number, offset?: number, options?: any): AxiosPromise<ListWidgetsResponse> {
            return localVarFp.listWidgets(authorization, licenseType, status, name, sortBy, order, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Allow administrators to update knowledgebase settings. 
         * @summary Update knowledgebase
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {Knowledgebase} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchKnowledgebase(authorization: string, kbId: string, body: Knowledgebase, options?: any): AxiosPromise<Knowledgebase> {
            return localVarFp.patchKnowledgebase(authorization, kbId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to edit an existing node
         * @summary Patch node
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} nodeId Node ID
         * @param {PatchNodeRequest} [body] Body containing all the node information all all fields to patch.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNode(authorization: string, kbId: string, nodeId: string, body?: PatchNodeRequest, options?: any): AxiosPromise<NodeDetail> {
            return localVarFp.patchNode(authorization, kbId, nodeId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to patch a list of nodes.
         * @summary Patch nodes
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {PatchNodesRequest} [body] Body with a list of nodes formed with ID and changes to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNodes(authorization: string, kbId: string, body?: PatchNodesRequest, options?: any): AxiosPromise<PatchNodesResponse> {
            return localVarFp.patchNodes(authorization, kbId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Allow administrators to update widget settings. 
         * @summary Update widget
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId Widget application ID
         * @param {Widget} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchWidget(authorization: string, appId: string, body: Widget, options?: any): AxiosPromise<Widget> {
            return localVarFp.patchWidget(authorization, appId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Uploads an asset to our CDN. 
         * @summary Upload asset
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} name asset name.
         * @param {File} asset The content to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAsset(authorization: string, kbId: string, name: string, asset: File, options?: any): AxiosPromise<AssetDetail> {
            return localVarFp.postAsset(authorization, kbId, name, asset, options).then((request) => request(axios, basePath));
        },
        /**
         * Allow administrators to create a new knowledgebase. 
         * @summary Create knowledgebase
         * @param {string} authorization Authorization Bearer header
         * @param {PostKnowledgebaseRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postKnowledgebase(authorization: string, body: PostKnowledgebaseRequest, options?: any): AxiosPromise<Knowledgebase> {
            return localVarFp.postKnowledgebase(authorization, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new node of type response or chattering.
         * @summary Post node
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {PutNodeRequest} [body] Create node request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNode(authorization: string, kbId: string, body?: PutNodeRequest, options?: any): AxiosPromise<NodeDetail> {
            return localVarFp.postNode(authorization, kbId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * \'Updates the raw content of an existing content node.\' 
         * @summary Update the content of a node
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} nodeId The ID of the content node to update
         * @param {File} content The content to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUpdateContent(authorization: string, kbId: string, nodeId: string, content: File, options?: any): AxiosPromise<NodeDetail> {
            return localVarFp.postUpdateContent(authorization, kbId, nodeId, content, options).then((request) => request(axios, basePath));
        },
        /**
         * Allow administrators to create a new widget. 
         * @summary Create widget
         * @param {string} authorization Authorization Bearer header
         * @param {PostWidgetRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWidget(authorization: string, body: PostWidgetRequest, options?: any): AxiosPromise<Widget> {
            return localVarFp.postWidget(authorization, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to edit an existing node
         * @summary Put node
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} nodeId Node ID
         * @param {PutNodeRequest} [body] Body containing all the node information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putNode(authorization: string, kbId: string, nodeId: string, body?: PutNodeRequest, options?: any): AxiosPromise<NodeDetail> {
            return localVarFp.putNode(authorization, kbId, nodeId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KnowledgeApi - object-oriented interface
 * @export
 * @class KnowledgeApi
 * @extends {BaseAPI}
 */
export class KnowledgeApi extends BaseAPI {
    /**
     * Deletes a knowledgebase. 
     * @summary Delete knowledgebase
     * @param {string} authorization Authorization Bearer header.
     * @param {string} kbId Knowledgebase ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeApi
     */
    public deleteKnowledgebase(authorization: string, kbId: string, options?: AxiosRequestConfig) {
        return KnowledgeApiFp(this.configuration).deleteKnowledgebase(authorization, kbId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a widget. 
     * @summary Delete widget
     * @param {string} authorization Authorization Bearer header.
     * @param {string} appId Widget application ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeApi
     */
    public deleteWidget(authorization: string, appId: string, options?: AxiosRequestConfig) {
        return KnowledgeApiFp(this.configuration).deleteWidget(authorization, appId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the details of a knowledgebase. 
     * @summary Get knowledgebase
     * @param {string} authorization Authorization Bearer header.
     * @param {string} kbId Knowledgebase ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeApi
     */
    public getKnowledgebase(authorization: string, kbId: string, options?: AxiosRequestConfig) {
        return KnowledgeApiFp(this.configuration).getKnowledgebase(authorization, kbId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Download the markdown representation of this content node.  
     * @summary Download markdown content
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {string} nodeId Node ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeApi
     */
    public getMarkdownContent(authorization: string, kbId: string, nodeId: string, options?: AxiosRequestConfig) {
        return KnowledgeApiFp(this.configuration).getMarkdownContent(authorization, kbId, nodeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to get a node
     * @summary Get node
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {string} nodeId Node ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeApi
     */
    public getNode(authorization: string, kbId: string, nodeId: string, options?: AxiosRequestConfig) {
        return KnowledgeApiFp(this.configuration).getNode(authorization, kbId, nodeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Count nodes in the organisation grouping by responses, chattering and content.
     * @summary Get node stats
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeApi
     */
    public getNodeStats(authorization: string, kbId: string, options?: AxiosRequestConfig) {
        return KnowledgeApiFp(this.configuration).getNodeStats(authorization, kbId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Download the public version of this content node. Client applications should use this method to serve  the original version of a content node to the end user.  The content is only gonna be accessible if the node is in state `published`.  
     * @summary Download content
     * @param {string} token Authorization Token Query
     * @param {string} kbId Knowledgebase ID
     * @param {string} nodeId Node ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeApi
     */
    public getPublicNodeContent(token: string, kbId: string, nodeId: string, options?: AxiosRequestConfig) {
        return KnowledgeApiFp(this.configuration).getPublicNodeContent(token, kbId, nodeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable authenticated users  the raw version of a content  node. Client applications serving the end user should not use this. 
     * @summary Download raw content
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {string} nodeId Node ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeApi
     */
    public getRawContent(authorization: string, kbId: string, nodeId: string, options?: AxiosRequestConfig) {
        return KnowledgeApiFp(this.configuration).getRawContent(authorization, kbId, nodeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a subgraph from a given node
     * @summary Get subgraph
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {string} nodeId Node ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeApi
     */
    public getSubgraph(authorization: string, kbId: string, nodeId: string, options?: AxiosRequestConfig) {
        return KnowledgeApiFp(this.configuration).getSubgraph(authorization, kbId, nodeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the details of a widget. 
     * @summary Get widget
     * @param {string} authorization Authorization Bearer header.
     * @param {string} appId Widget application ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeApi
     */
    public getWidget(authorization: string, appId: string, options?: AxiosRequestConfig) {
        return KnowledgeApiFp(this.configuration).getWidget(authorization, appId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to get a node\'s inbound edges. Will only return edges referencing published nodes.
     * @summary List inbound edges
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {string} nodeId Node ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeApi
     */
    public listEdgesInbound(authorization: string, kbId: string, nodeId: string, options?: AxiosRequestConfig) {
        return KnowledgeApiFp(this.configuration).listEdgesInbound(authorization, kbId, nodeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to get a node\'s outbound edges. This only returns published nodes.
     * @summary List outbound edges
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {string} nodeId Node ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeApi
     */
    public listEdgesOutbound(authorization: string, kbId: string, nodeId: string, options?: AxiosRequestConfig) {
        return KnowledgeApiFp(this.configuration).listEdgesOutbound(authorization, kbId, nodeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * \'List all knowledgebases matching the given filters.\' 
     * @summary List Knowledgebases
     * @param {string} authorization Authorization Bearer header
     * @param {string} [appId] Filter knowledgebases by app ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeApi
     */
    public listKnowledgebases(authorization: string, appId?: string, options?: AxiosRequestConfig) {
        return KnowledgeApiFp(this.configuration).listKnowledgebases(authorization, appId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * \'List all nodes matching the given filters.\' 
     * @summary List nodes
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {'chattering' | 'response' | 'content'} [type] Filter by node type
     * @param {'published' | 'unpublished' | 'deleted'} [status] Filter by status
     * @param {string} [sourceName] Filter by content source name
     * @param {string} [title] Filter content by node title
     * @param {string} [labels] Filter content by labels. Multiple labels are operated as a logical AND.
     * @param {'source_name' | 'title' | 'node_type' | 'node_status' | 'created_at' | 'updated_at' | 'queries_count' | 'webexts_count' | 'workflows_count' | 'choices_count'} [sortBy] Sort content by field
     * @param {'asc' | 'desc'} [order] Sort order
     * @param {number} [limit] 
     * @param {number} [offset] The query offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeApi
     */
    public listNodes(authorization: string, kbId: string, type?: 'chattering' | 'response' | 'content', status?: 'published' | 'unpublished' | 'deleted', sourceName?: string, title?: string, labels?: string, sortBy?: 'source_name' | 'title' | 'node_type' | 'node_status' | 'created_at' | 'updated_at' | 'queries_count' | 'webexts_count' | 'workflows_count' | 'choices_count', order?: 'asc' | 'desc', limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return KnowledgeApiFp(this.configuration).listNodes(authorization, kbId, type, status, sourceName, title, labels, sortBy, order, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to list all tags for an organization.
     * @summary List tags
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {'published' | 'unpublished' | 'deleted'} [status] Filter by status
     * @param {'chattering' | 'response' | 'content'} [type] Filter by node type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeApi
     */
    public listTags(authorization: string, kbId: string, status?: 'published' | 'unpublished' | 'deleted', type?: 'chattering' | 'response' | 'content', options?: AxiosRequestConfig) {
        return KnowledgeApiFp(this.configuration).listTags(authorization, kbId, status, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the registered web extensions in this organisation. 
     * @summary Get client registered web extensions.
     * @param {string} authorization Authorization Bearer header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeApi
     */
    public listWebExt(authorization: string, options?: AxiosRequestConfig) {
        return KnowledgeApiFp(this.configuration).listWebExt(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * \'List all widgets.\' 
     * @summary List Widgets
     * @param {string} authorization Authorization Bearer header
     * @param {'AAA' | 'ASA' | 'DSS'} [licenseType] The license type that can be associated with organisation, application and knowledgebase. 
     * @param {'published' | 'unpublished' | 'archived'} [status] Filter widget by their status
     * @param {string} [name] Filter widgets by their name
     * @param {'name' | 'license_type' | 'created_at' | 'updated_at'} [sortBy] Sort content by field
     * @param {'asc' | 'desc'} [order] Sort order
     * @param {number} [limit] 
     * @param {number} [offset] The query offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeApi
     */
    public listWidgets(authorization: string, licenseType?: 'AAA' | 'ASA' | 'DSS', status?: 'published' | 'unpublished' | 'archived', name?: string, sortBy?: 'name' | 'license_type' | 'created_at' | 'updated_at', order?: 'asc' | 'desc', limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return KnowledgeApiFp(this.configuration).listWidgets(authorization, licenseType, status, name, sortBy, order, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Allow administrators to update knowledgebase settings. 
     * @summary Update knowledgebase
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {Knowledgebase} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeApi
     */
    public patchKnowledgebase(authorization: string, kbId: string, body: Knowledgebase, options?: AxiosRequestConfig) {
        return KnowledgeApiFp(this.configuration).patchKnowledgebase(authorization, kbId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to edit an existing node
     * @summary Patch node
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {string} nodeId Node ID
     * @param {PatchNodeRequest} [body] Body containing all the node information all all fields to patch.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeApi
     */
    public patchNode(authorization: string, kbId: string, nodeId: string, body?: PatchNodeRequest, options?: AxiosRequestConfig) {
        return KnowledgeApiFp(this.configuration).patchNode(authorization, kbId, nodeId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to patch a list of nodes.
     * @summary Patch nodes
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {PatchNodesRequest} [body] Body with a list of nodes formed with ID and changes to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeApi
     */
    public patchNodes(authorization: string, kbId: string, body?: PatchNodesRequest, options?: AxiosRequestConfig) {
        return KnowledgeApiFp(this.configuration).patchNodes(authorization, kbId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Allow administrators to update widget settings. 
     * @summary Update widget
     * @param {string} authorization Authorization Bearer header
     * @param {string} appId Widget application ID
     * @param {Widget} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeApi
     */
    public patchWidget(authorization: string, appId: string, body: Widget, options?: AxiosRequestConfig) {
        return KnowledgeApiFp(this.configuration).patchWidget(authorization, appId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Uploads an asset to our CDN. 
     * @summary Upload asset
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {string} name asset name.
     * @param {File} asset The content to upload.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeApi
     */
    public postAsset(authorization: string, kbId: string, name: string, asset: File, options?: AxiosRequestConfig) {
        return KnowledgeApiFp(this.configuration).postAsset(authorization, kbId, name, asset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Allow administrators to create a new knowledgebase. 
     * @summary Create knowledgebase
     * @param {string} authorization Authorization Bearer header
     * @param {PostKnowledgebaseRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeApi
     */
    public postKnowledgebase(authorization: string, body: PostKnowledgebaseRequest, options?: AxiosRequestConfig) {
        return KnowledgeApiFp(this.configuration).postKnowledgebase(authorization, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new node of type response or chattering.
     * @summary Post node
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {PutNodeRequest} [body] Create node request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeApi
     */
    public postNode(authorization: string, kbId: string, body?: PutNodeRequest, options?: AxiosRequestConfig) {
        return KnowledgeApiFp(this.configuration).postNode(authorization, kbId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * \'Updates the raw content of an existing content node.\' 
     * @summary Update the content of a node
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {string} nodeId The ID of the content node to update
     * @param {File} content The content to upload.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeApi
     */
    public postUpdateContent(authorization: string, kbId: string, nodeId: string, content: File, options?: AxiosRequestConfig) {
        return KnowledgeApiFp(this.configuration).postUpdateContent(authorization, kbId, nodeId, content, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Allow administrators to create a new widget. 
     * @summary Create widget
     * @param {string} authorization Authorization Bearer header
     * @param {PostWidgetRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeApi
     */
    public postWidget(authorization: string, body: PostWidgetRequest, options?: AxiosRequestConfig) {
        return KnowledgeApiFp(this.configuration).postWidget(authorization, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to edit an existing node
     * @summary Put node
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {string} nodeId Node ID
     * @param {PutNodeRequest} [body] Body containing all the node information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeApi
     */
    public putNode(authorization: string, kbId: string, nodeId: string, body?: PutNodeRequest, options?: AxiosRequestConfig) {
        return KnowledgeApiFp(this.configuration).putNode(authorization, kbId, nodeId, body, options).then((request) => request(this.axios, this.basePath));
    }
}
